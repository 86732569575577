import {Role} from './role';

export const SIDEBAR_MENU = [
  {
    type: "menu-item",
    title: "Dashboard",
    class: "menu-link",
    link: "/",
    icon: "bx-home-circle",
    authorize: [Role.ADMIN]
  },
  {
    type: "menu-header",
    title: "Main Pages",
    class: "menu-header-text",
  },
  {
    type: "menu-toggle",
    title: "Partner",
    class: "",
    link: "",
    icon: "",
    authorize: [Role.ADMIN],
    children: [
      {
        type: "menu-item",
        title: "Code Registration",
        class: "menu-link",
        link: "/partner-registration",
        authorize: [Role.ADMIN],
      },
      {
        type: "menu-item",
        title: "Detail Input",
        class: "menu-link",
        link: "/partner/add",
        authorize: [Role.ADMIN],
      },
      {
        type: "menu-item",
        title: "List",
        class: "menu-link",
        link: "/partner",
        authorize: [Role.ADMIN],
      },
    ]
  }
]

export const MAIN_MENU = [
  {
    type: "menu-toggle",
    title: "Partner",
    class: "",
    link: "",
    icon: "menu-icon tf-icons bx bxl-unity",
    authorize: [Role.ADMIN],
    children: [
      {
        type: "menu-item",
        title: "Code Registration",
        class: "menu-link",
        link: "/partner-registration",
        authorize: [Role.ADMIN]
      },
      {
        type: "menu-item",
        title: "List",
        class: "menu-link",
        link: "/partner",
        authorize: [Role.ADMIN],
      }
    ]
  },
  {
    type: "menu-toggle",
    title: "Product",
    class: "",
    link: "",
    icon: "menu-icon tf-icons bx bx-category",
    authorize: [Role.ADMIN],
    children: [
      {
        type: "menu-item",
        title: "Category",
        class: "menu-link",
        link: "/category",
        authorize: [Role.ADMIN]
      },
      {
        type: "menu-item",
        title: "Subcategory",
        class: "menu-link",
        link: "/subcategory",
        authorize: [Role.ADMIN]
      },
      {
        type: "menu-item",
        title: "Code Registration",
        class: "menu-link",
        link: "/product-registration",
        authorize: [Role.ADMIN]
      },
      {
        type: "menu-item",
        title: "List",
        class: "menu-link",
        link: "/product",
        authorize: [Role.ADMIN]
      },
      {
        type: "menu-item",
        title: "Mass Registration",
        class: "menu-link",
        link: "/product/mass",
        authorize: [Role.ADMIN]
      }
    ]
  },
  {
    type: "menu-toggle",
    title: "Contract",
    class: "",
    link: "",
    icon: "menu-icon tf-icons bx bx-file",
    authorize: [Role.ADMIN],
    children: [
      {
        type: "menu-item",
        title: "Code Registration",
        class: "menu-link",
        link: "/contract-registration",
        authorize: [Role.ADMIN]
      },
      {
        type: "menu-item",
        title: "List",
        class: "menu-link",
        link: "/contract",
        authorize: [Role.ADMIN]
      },
    ]
  },
  {
    type: "menu-toggle",
    title: "Shipment",
    class: "",
    link: "",
    icon: "menu-icon tf-icons bx bxs-package",
    authorize: [Role.ADMIN],
    children: [
      {
        type: "menu-item",
        title: "Invoice Registration",
        class: "menu-link",
        link: "/invoice-registration",
        authorize: [Role.ADMIN]
      },
      {
        type: "menu-item",
        title: "Shipment List",
        class: "menu-link",
        link: "/shipment",
        authorize: [Role.ADMIN]
      },
      // {
      //   type: "menu-item",
      //   title: "Invoice",
      //   class: "menu-link",
      //   link: "/invoice",
      //   authorize: [Role.ADMIN]
      // },
      // {
      //   type: "menu-item",
      //   title: "Receiving Voucher",
      //   class: "menu-link",
      //   link: "/receiving",
      //   authorize: [Role.ADMIN]
      // },
      // {
      //   type: "menu-item",
      //   title: "Delivery Voucher",
      //   class: "menu-link",
      //   link: "/delivery",
      //   authorize: [Role.ADMIN]
      // }
    ]
  },
  {
    type: "menu-toggle",
    title: "Cost Management",
    class: "",
    link: "",
    icon: "menu-icon tf-icons bx bx-dollar-circle",
    authorize: [Role.ADMIN],
    children: [
      // {
      //   type: "menu-item",
      //   title: "Add Fixed Cost",
      //   class: "menu-link",
      //   link: "/fixedcost/add",
      //   authorize: [Role.ADMIN]
      // },
      {
        type: "menu-item",
        title: "Fixed Cost",
        class: "menu-link",
        link: "/fixed-cost",
        authorize: [Role.ADMIN]
      },
    ]
  },
  {
    type: "menu-toggle",
    title: "Statistics",
    class: "",
    link: "",
    icon: "menu-icon tf-icons bx bx-bar-chart",
    authorize: [Role.ADMIN],
    children: [
      {
        type: "menu-item",
        title: "Shipment Revenue",
        class: "menu-link",
        link: "/statistic/shipment-revenue",
        authorize: [Role.ADMIN]
      }
      // {
      //   type: "menu-item",
      //   title: "Shipment Profit",
      //   class: "menu-link",
      //   link: "/statistic/shipment-profit",
      //   authorize: [Role.ADMIN]
      // }
    ]
  }
]

export const SETTING_MENU = [
  {
    type: "menu-toggle",
    title: "User Account",
    class: "",
    link: "",
    icon: "tf-icons bx bxs-user-account",
    authorize: [Role.ADMIN, Role.ACCOUNTING, Role.USER],
    children: [
      {
        type: "menu-item",
        title: "List",
        class: "menu-link",
        link: "/account",
        authorize: [Role.ADMIN]
      },
      {
        type: "menu-item",
        title: "Change Password",
        class: "menu-link",
        link: "/account/change-password",
        authorize: [Role.ADMIN, Role.ACCOUNTING, Role.USER]
      },
      {
        type: "menu-item",
        title: "Profile",
        class: "menu-link",
        link: "/account/profile",
        authorize: [Role.ADMIN, Role.ACCOUNTING, Role.USER]
      }
    ]
  },
  {
    type: "menu-toggle",
    title: "Tools",
    class: "",
    link: "",
    icon: "menu-icon tf-icons bx bx-cog",
    authorize: [Role.ADMIN],
    children: [
      {
        type: "menu-item",
        title: "Import Data",
        class: "menu-link",
        link: "/tool/import",
        authorize: [Role.ADMIN]
      },
      {
        type: "menu-item",
        title: "Export Data",
        class: "menu-link",
        link: "/tool/export",
        authorize: [Role.ADMIN]
      }
    ]
  },
]

export const ADVANCED_MENU = [
  {
    type: "menu-item",
    title: "Code Definition",
    class: "menu-link",
    link: "/definition",
    icon: "tf-icons bx bx-home-circle",
    authorize: [Role.ADMIN]
  },
  {
    type: "menu-item",
    title: "Test Page",
    class: "menu-link",
    link: "/test",
    icon: "tf-icons bx bx-home-circle",
    authorize: [Role.ADMIN]
  },
]