import AccountService from '../services/account.service';
import _ from 'lodash';

const getDefaultState = () => {
	return {
		id: "",
		username: "",
		first_name: "",
		last_name: "",
		email: "",
		company: "",
		phone: "",
		address: "",
		avatar_url: "",
	}
}

export const accountEdit = {
	namespaced: true,
	state: {
		stateData: getDefaultState()
	},
	actions: {
		resetState({commit}) {
			commit("resetState");
		},
		uploadImage({commit},formdata) {
			return AccountService.uploadImage(formdata).then(
				(response) => {
					commit('uploadSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit("uploadFailure");
					return Promise.reject(error);
				}
			);
		},
		getAccountProfile({commit}, id) {
			return AccountService.getAccountProfile(id).then(
				(response) => {
					commit('getAccountProfileSuccess', response);
					return Promise.resolve(response);
				},
				error => {
					commit('getAccountProfileFailure');
					return Promise.reject(error);
				}
			);
		},
		updateAccountProfile({commit}, data) {
			return AccountService.saveAccountProfile(data).then(
				(response) => {
					return Promise.resolve(response);
				},
				(error) => {
					commit("updateAccountFailure");
					return Promise.reject(error);
				}
			);
		},
		deactivateAccount({commit}, id) {
			return AccountService.deactivateAccount(id).then(
				(response) => {
					return Promise.resolve(response);
				},
				(error) => {
					commit("deactivateAccountFailure");
					return Promise.reject(error);
				}
			);
		},

	},
	mutations: {
		resetState (state) {
			console.log("run resetState");
			Object.assign(state.stateData, getDefaultState());
		},
		uploadSuccess(state, response) {
			state.stateData.avatar_url = response.image_names[0];
		},
		uploadFailure(state) {
			state.stateData.avatar_url = "";
		},
		getAccountProfileSuccess(state, response) {
			state.stateData = _.merge(state.stateData, response);
		},
		getAccountProfileFailure() {
			console.log("run getAccountProfileFailure");
		},
		updateAccountFailure() {
			console.log("run updateAccountFailure");
		},
		deactivateAccountFailure() {
			console.log("run deactivateAccountFailure");
		}
	},
	getters: {
	}
}