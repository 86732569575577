import { createWebHistory, createRouter } from "vue-router";
import Dashboard from './components/Dashboard.vue';
import DefinitionAdd from './components/registration/DefinitionAdd.vue';
import DefinitionEdit from './components/registration/DefinitionEdit.vue';
import DefinitionList from './components/registration/DefinitionList.vue';
import QuotationRegistrationList from './components/registration/QuotationRegistrationList.vue';
import QuotationRegistrationAdd from './components/registration/QuotationRegistrationAdd.vue';
import QuotationRegistrationEdit from './components/registration/QuotationRegistrationEdit.vue';
import PartnerRegistrationList from './components/registration/PartnerRegistrationList.vue';
import PartnerRegistrationAdd from './components/registration/PartnerRegistrationAdd.vue';
import PartnerRegistrationEdit from './components/registration/PartnerRegistrationEdit.vue';
import ContractRegistrationList from './components/registration/ContractRegistrationList.vue';
import ContractRegistrationAdd from './components/registration/ContractRegistrationAdd.vue';
import ContractRegistrationEdit from './components/registration/ContractRegistrationEdit.vue';
import ProductRegistrationList from './components/registration/ProductRegistrationList.vue';
import ProductRegistrationAdd from './components/registration/ProductRegistrationAdd.vue';
import ProductRegistrationEdit from './components/registration/ProductRegistrationEdit.vue';
import InvoiceRegistrationList from './components/registration/InvoiceRegistrationList.vue';
import InvoiceRegistrationAdd from './components/registration/InvoiceRegistrationAdd.vue';
import InvoiceRegistrationEdit from './components/registration/InvoiceRegistrationEdit.vue';
import CategoryList from './components/category/CategoryList.vue'
import CategoryAdd from './components/category/CategoryAdd.vue'
import CategoryEdit from './components/category/CategoryEdit.vue'
import SubCategoryList from './components/category/SubCategoryList.vue'
import SubCategoryAdd from './components/category/SubCategoryAdd.vue'
import SubCategoryEdit from './components/category/SubCategoryEdit.vue'
import ProductList from './components/product/ProductList.vue'
import ProductAdd from './components/product/ProductAdd.vue'
import ProductEdit from './components/product/ProductEdit.vue'
import ProducMass from './components/product/ProductMassAdd.vue'
import PartnerList from './components/partner/PartnerList.vue'
import PartnerAdd from './components/partner/PartnerAdd.vue'
import PartnerEdit from './components/partner/PartnerEdit.vue'
import QuotationList from './components/quotation/QuotationList.vue'
import QuotationAdd from './components/quotation/QuotationAdd.vue'
import QuotationEdit from './components/quotation/QuotationEdit.vue'
import ContractList from './components/contract/ContractList.vue'
import ContractAdd from './components/contract/ContractAdd.vue'
import ContractEdit from './components/contract/ContractEdit.vue'
import Shipment from './components/invoice/Shipment.vue'
import ShipmentList from './components/invoice/ShipmentList.vue'
import InvoiceList from './components/invoice/InvoiceList.vue'
// import InvoiceAdd from './components/invoice/InvoiceAdd.vue'
import InvoiceEdit from './components/invoice/InvoiceEdit.vue'
import PackingListList from './components/packinglist/PackingListList.vue'
import PackingListAdd from './components/packinglist/PackingListAdd.vue'
import ReceivingVoucherList from './components/receiving/ReceivingVoucherList.vue'
import ReceivingVoucherAdd from './components/receiving/ReceivingVoucherAdd.vue'
import ReceivingVoucherEdit from './components/receiving/ReceivingVoucherEdit.vue'
import DeliveryVoucherList from './components/delivery/DeliveryVoucherList.vue'
import DeliveryVoucherAdd from './components/delivery/DeliveryVoucherAdd.vue'
import DeliveryVoucherEdit from './components/delivery/DeliveryVoucherEdit.vue'
import AccountList from './components/account/AccountList.vue'
import AccountChangePassword from './components/account/AccountChangePassword.vue'
import AccountProfile from './components/account/AccountProfile.vue'
import AccountEdit from './components/account/AccountEdit.vue'
import Login from './components/authentication/Login.vue'
import Register from './components/authentication/Register.vue'
import ForgotPassword from './components/authentication/ForgotPassword.vue'
import ImportData from './components/tool/ImportData.vue'
import ExportData from './components/tool/ExportData.vue'
import {Role} from './config/role';
import ShipmentRevenue from './components/statistic/ShipmentRevenue.vue'
import PageForTest from "./components/PageForTest";
import NotAuthorized from "./components/common/NotAuthorized.vue";
import FixedCostList from './components/cost/FixedCostList.vue'
import FixedCostAdd from './components/cost/FixedCostAdd.vue'
import FixedCostEdit from './components/cost/FixedCostEdit.vue'

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/definition",
    component: DefinitionList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/definition/add",
    name: "DefinitionAdd",
    component: DefinitionAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/definition/edit/:id",
    name: "DefinitionEdit",
    component: DefinitionEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/quotation-registration",
    name: "QuotationRegistrationList",
    component: QuotationRegistrationList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/quotation-registration/add",
    name: "QuotationRegistrationAdd",
    component: QuotationRegistrationAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/quotation-registration/edit/:id",
    name: "QuotationRegistrationEdit",
    component: QuotationRegistrationEdit
    ,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/partner-registration",
    name: "PartnerRegistrationList",
    component: PartnerRegistrationList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/partner-registration/add",
    name: "PartnerRegistrationAdd",
    component: PartnerRegistrationAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/partner-registration/edit/:id",
    name: "PartnerRegistrationEdit",
    component: PartnerRegistrationEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/contract-registration",
    name: "ContractRegistrationList",
    component: ContractRegistrationList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/contract-registration/add",
    name: "ContractRegistrationAdd",
    component: ContractRegistrationAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/contract-registration/edit/:id",
    name: "ContractRegistrationEdit",
    component: ContractRegistrationEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/product-registration",
    name: "ProductRegistrationList",
    component: ProductRegistrationList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/product-registration/add",
    name: "ProductRegistrationAdd",
    component: ProductRegistrationAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/product-registration/edit/:id",
    name: "ProductRegistrationEdit",
    component: ProductRegistrationEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/invoice-registration",
    name: "InvoiceRegistrationList",
    component: InvoiceRegistrationList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/invoice-registration/add",
    name: "InvoiceRegistrationAdd",
    component: InvoiceRegistrationAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/invoice-registration/edit/:id",
    name: "InvoiceRegistrationEdit",
    component: InvoiceRegistrationEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/category",
    name: "CategoryList",
    component: CategoryList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/category/add",
    name: "CategoryAdd",
    component: CategoryAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/category/edit/:id",
    name: "CategoryEdit",
    component: CategoryEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/subcategory",
    name: "SubCategoryList",
    component: SubCategoryList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/subcategory/add",
    name: "SubCategoryAdd",
    component: SubCategoryAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/subcategory/edit/:id",
    name: "SubCategoryEdit",
    component: SubCategoryEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/product",
    name: "ProductList",
    component: ProductList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/product/add",
    name: "ProductAdd",
    component: ProductAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/product/edit/:id",
    name: "ProductEdit",
    component: ProductEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/product/mass",
    name: "ProducMass",
    component: ProducMass,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/partner",
    name: "PartnerList",
    component: PartnerList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/partner/add",
    name: "PartnerAdd",
    component: PartnerAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/partner/edit/:id",
    name: "PartnerEdit",
    component: PartnerEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/quotation",
    name: "QuotationList",
    component: QuotationList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/quotation/add",
    name: "QuotationAdd",
    component: QuotationAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/quotation/edit/:id",
    name: "QuotationEdit",
    component: QuotationEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/contract",
    name: "ContractList",
    component: ContractList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/contract/add",
    name: "ContractAdd",
    component: ContractAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/contract/edit/:id",
    name: "ContractEdit",
    component: ContractEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/invoice",
    name: "InvoiceList",
    component: InvoiceList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/invoice/add",
    name: "Shipment",
    component: Shipment,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/invoice/edit/:id",
    name: "InvoiceEdit",
    component: InvoiceEdit,
    meta: { authorize: [Role.ADMIN] },
    params: true
  },
  {
    path: "/shipment",
    name: "ShipmentList",
    component: ShipmentList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/statistic/shipment-revenue",
    name: "ShipmentRevenue",
    component: ShipmentRevenue,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/packinglist",
    name: "PackingListList",
    component: PackingListList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/packinglist/add",
    name: "PackingListAdd",
    component: PackingListAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/receiving",
    name: "ReceivingVoucherList",
    component: ReceivingVoucherList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/receiving/add",
    name: "ReceivingVoucherAdd",
    component: ReceivingVoucherAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/receiving/edit/:id",
    name: "ReceivingVoucherEdit",
    component: ReceivingVoucherEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/delivery",
    name: "DeliveryVoucherList",
    component: DeliveryVoucherList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/delivery/add",
    name: "DeliveryVoucherAdd",
    component: DeliveryVoucherAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/delivery/edit/:id",
    name: "DeliveryVoucherEdit",
    component: DeliveryVoucherEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/account",
    name: "AccountList",
    component: AccountList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/account/change-password",
    name: "AccountChangePassword",
    component: AccountChangePassword
  },
  {
    path: "/account/profile",
    name: "AccountProfile",
    component: AccountProfile,
  },
  {
    path: "/account/edit/:id",
    name: "AccountEdit",
    component: AccountEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/tool/import",
    name: "ImportData",
    component: ImportData,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/tool/export",
    name: "ExportData",
    component: ExportData,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/not_authorized",
    name: "NotAuthorized",
    component: NotAuthorized
  },
  {
    path: "/fixed-cost",
    name: "FixedCostList",
    component: FixedCostList,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/fixed-cost/add",
    name: "FixedCostAdd",
    component: FixedCostAdd,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/fixed-cost/edit/:id",
    name: "FixedCostEdit",
    component: FixedCostEdit,
    meta: { authorize: [Role.ADMIN] }
  },
  {
    path: "/test",
    name: "PageForTest",
    component: PageForTest,
    meta: { authorize: [Role.ADMIN] } //Used for input url directly, redirect to register user page
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// function isEmptyObject(obj){
//   return JSON.stringify(obj) === '{}'
// }

router.beforeEach((to, from, next) => {
  let storage = JSON.parse(localStorage.getItem("user"));
  const { authorize } = to.meta;
  
  if (authorize) {
    if (storage == null) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/login'});
    }
    else {
      const tokenData = JSON.parse(atob(storage.access_token.split('.')[1]));
      // Get the expiration time from the token data
      const expirationTime = tokenData.exp * 1000; // Convert to milliseconds
      // Get the current time
      const currentTime = new Date().getTime();
      
      if (currentTime > expirationTime) {
        console.log("Access token is expired!");
        return next({ path: '/login'});
      } 
      else {
        let role = storage.user_info.role;
        // check if route is restricted by role
        if (authorize.length && !authorize.includes(role)) {
            // role not authorised so redirect to Not Authorized Page
            return next({ path: '/not_authorized' });
        }
      }
    }
  }
  next();
})

export default router;