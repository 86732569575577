<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Contract List</span>
    </h4>

    <div class="row">
      <!-- Contract List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="">
              <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                </div>
                <div class="col-12 col-md-5 mt-2 mb-4">
                  <div class="input-group input-group-merge">
                    <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
                    <input 
                      type="text" 
                      class="form-control" 
                      placeholder="Search contract code" 
                      v-model="keyword"
                      @change="onChangeSearch(page, keyword)"
                    >
                  </div>
                </div>
              </div>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Contract Code</th>
                    <th>Supplier</th>
                    <th>Cumtomer</th>
                    <th>Products</th>
                    <th class="text-start">Last Delivery</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0" style="font-size: 0.86rem !important">
                  <tr v-for="(item, index) in listItems" :key="index">
                    <td class="width-20-percent">
                      <strong>{{ item.contract_code }}</strong>
                    </td>
                    <td class="width-25-percent">
                      <div>
                        <span v-tooltip="item.supplier_name">
                          {{ item.supplier_name }}
                        </span>
                      </div>
                    </td>
                    <td class="width-25-percent">
                      <div>
                        <span v-tooltip="item.customer_name">
                          {{ item.customer_name }}
                        </span>
                      </div>
                    </td>
                    <td class="width-15-percent">
                      <div class="text-truncate" style="max-width: 150px">
                        <span v-tooltip="item.product_name">
                          {{ item.product_name }}
                        </span>
                      </div>
                    </td>
                    <td class="width-15-percent text-start">{{ changeFormateDate(item.last_delivery_date) }}</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <a href="#" 
                          class="text-body me-3" 
                          data-bs-toggle="offcanvas" 
                          data-bs-target="#offcanvasScroll"
                          @click="handleShowDetail(item.id)"
                        >
                          <i class="bx bx-show mx-1"></i>
                        </a>
                        <router-link :to='"/contract/edit/" + item.id' data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a class="text-body icon-delete" @click="deleteContract(item.id)">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <div class="row mx-2">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <common-paging 
                    :items="listItems" 
                    :totalItems="totalItems" 
                    @changePage="onChangePage"
                    @changeSearchPage="onChangeSearch"
                    :activePageId="page"
                    v-show="showPaging"
                    :isSearch="isSearchContractCode"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- OffCanvas from the right -->
    <div class="row display-position">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScroll" 
        aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility:visible; width:78%;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0">
          <div class="d-flex flex-wrap my-2" :key="componentKey">
            <div class="col-sm-6">
              <span class="fw-bold text-primary me-3">{{ "# " + contractDetail.contract_code }}</span>
            </div>
            <div class="col-sm-6 text-end">
              <span class="fw-bold me-2">Reference No:</span>
              <span class="fw-bold text-primary">{{ contractDetail.reference_no }}</span>
            </div>
            <div class="col-sm-6 mt-2">
              <label class="fw-bold me-2" style="float: left;">Supplier:</label>
              <div class="fw-bold text-truncate1" v-tooltip="contractDetail.supplier_name">
                {{ contractDetail.supplier_name }}
              </div>
            </div>
            <div class="col-sm-6 mt-2 text-end">
              <label class="fw-bold me-2">Customer:</label>
              <div class="fw-bold text-truncate1 text-end" style="float: right;" v-tooltip="contractDetail.customer_name">
                {{ contractDetail.customer_name }}
              </div>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold">Total Purchase Quantity: </span>
              <span class="fw-bold">{{ getPurchaseQuantity(contractDetail.contract_detail_list) }}</span>
            </div>
            <div class="col-sm-6 text-end mt-2">
              <span class="fw-bold">Total Sell Quantity: </span>
              <span class="fw-bold">{{ getSellQuantity(contractDetail.contract_detail_list)  }}</span>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold">Purchase Amount (before Tax): </span>
              <span class="fw-bold">{{ contractDetail.buy_currency + " "}} {{ formatCurrency(contractDetail.grand_total_buy_price + totalCost) }}</span>
            </div>
            <div class="col-sm-6 text-end mt-2">
              <span class="fw-bold">Sale Amount (before tax): </span>
              <span class="fw-bold">{{ contractDetail.sell_currency + " "}} {{ formatCurrency(contractDetail.grand_total_sell_price) }}</span>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold">Purchase Amount (in VND): </span>
              <span class="fw-bold me-1">VND</span>
              <span class="fw-bold">{{ formatCurrency(getTotalPurchaseAmount(contractDetail)) }}</span>
            </div>
            <div class="col-sm-6 text-end mt-2">
              <span class="fw-bold">Sale Amount (in VND): </span>
              <span class="fw-bold me-1">VND</span>
              <span class="fw-bold">{{ formatCurrency(getTotalSaleAmount(contractDetail)) }}</span>
            </div>
            <div class="col-sm-12 mt-2">
              <span class="fw-bold me-2">Total Profit Amount ~ Rate:</span>
              <span class="fw-bold text-success me-2" v-if="getTotalProfitAmount(contractDetail) > 0">{{ "VND " + formatCurrency(getTotalProfitAmount(contractDetail)) + " 〜 "}}</span>
              <span class="fw-bold text-danger me-2" v-else>{{ "VND " + formatCurrency(getTotalProfitAmount(contractDetail)) + "&nbsp;&nbsp;&nbsp; 〜 &nbsp;" }}</span>
              <span class="fw-bold text-success" v-if="getTotalProfitAmount(contractDetail) > 0">{{ formatCurrency(getTotalProfitPercent(contractDetail)) + " %"}}</span>
              <span class="fw-bold text-danger" v-else>{{ formatCurrency(getTotalProfitPercent(contractDetail)) + " %"}}</span>
            </div>
          </div>
          <h5 class="pb-2 border-bottom mb-2"></h5>
          <div class="info-container">
            <ul class="list-unstyled">
              <li class="mb-3">
                <div class="d-flex flex-wrap">
                  <div class="col-sm-6 mt-2">
                    <span class="fw-bold me-1">Last Derivery Date:</span>
                    <span class="">{{ changeFormateDate(contractDetail.last_delivery_date) }}</span>
                  </div>
                  <div class="col-sm-6 mt-2 text-end">
                    <span class="fw-bold me-1">Contract Date:</span>
                    <span class="">{{ changeFormateDate(contractDetail.contract_date) }}</span>
                  </div>
                </div>
              </li>
							<li class="mb-3">
								<div class="table-responsive">
									<table class="table ">
										<thead>
											<tr>
												<th class="border-top-0">Product</th>
                        <th class="border-top-0">Product Code</th>
												<th class="border-top-0 text-end">PUR. Qty</th>
												<th class="border-top-0 text-end">PUR. Price</th>
                        <th class="border-top-0 text-end">Sell Qty</th>
                        <th class="border-top-0 text-end">Sell Price</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(detail, index) in contractDetail.contract_detail_list" :key="index">
												<td>
													<div class="text-truncate" style="max-width: 200px;">
														{{ detail.product_name }}
													</div>
												</td>
                        <td>{{ detail.product_code }}</td>
												<td class="text-end">{{ formatCurrency(detail.quantity_in) }}</td>
												<td class="text-end">{{ contractDetail.buy_currency + " "}} {{ formatCurrency(detail.buy_price) }}</td>
                        <td class="text-end">{{ formatCurrency(detail.quantity_out) }}</td>
                        <td class="text-end">{{ contractDetail.sell_currency + " "}} {{ formatCurrency(detail.sell_price) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</li>
              <li class="mb-3">
                <div class="row mt-5">
                  <div class="col-sm-3 pt-2">
                    <span class="fw-bold text-primary">COST DETAILS (Unit VND)</span>
                  </div>
                  <div class="col-sm-3 pt-2 px-4 text-end">
                    <span class="fw-bold text-primary me-2">VND</span>
                    <span class="fw-bold text-primary">{{ formatCurrency(calculateTotalCost()) }}</span>
                  </div>
                  <div class="col-sm-6 text-end">
                    <button type="button" class="btn btn-outline-primary w-50" @click="modifyCost()">
                      <i class="menu-icon tf-icons bx bx-edit-alt"></i> 
                      Modify Cost 
                    </button>
                  </div>
                  <div class="col-sm-6 mt-3">
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center active">
                        <span class="fw-bold">IMPORT / EXPORT FEE</span>
                        <span class="fw-bold text-end">{{ formatCurrency(getImportExportFee(contractDetail)) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Customs Duty Fee</span>
                        </div>
                        <div class="col-sm-6 text-end">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_customs_duty"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Freight Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_freight"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Customs & LCC Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input-last"
                            placeholder="0"
                            v-model="contractDetail.fee_customs_lcc"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-6 mt-3">
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center active">
                        <span class="fw-bold">TRANSPORT & WAREHOUSE FEE</span>
                        <span class="fw-bold text-end">{{ formatCurrency(getTransportWarehouseFee(contractDetail)) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Trucking Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_trucking"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Storage Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_storage"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Labeling Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input-last"
                            placeholder="0"
                            v-model="contractDetail.fee_labeling"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-6 mt-1">
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center active">
                        <span class="fw-bold">TESTING & INSURANCE FEE</span>
                        <span class="fw-bold text-end">{{ formatCurrency(getTestingInsuranceFee(contractDetail)) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Inspection Fee</span>
                        </div>
                        <div class="col-sm-6 text-end">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_inspection"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Testing Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_testing"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Sample Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_sample"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Ocean Marine Fee</span>
                        </div>
                        <div class="col-sm-6 text-end">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_ocean_marine"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Inland Logistic Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input-last"
                            placeholder="0"
                            v-model="contractDetail.fee_inland_logistic"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  
                  <div class="col-sm-6 mt-1">
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center active">
                        <span class="fw-bold">OTHER FEE</span>
                        <span class="fw-bold text-end">{{ formatCurrency(getOtherFee(contractDetail)) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Design Fee</span>
                        </div>
                        <div class="col-sm-6 text-end">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_design"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Accesories Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_accesories"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Develop Fee</span>
                        </div>
                        <div class="col-sm-6">
                           <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_develop"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Commission Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_commission"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Royalty Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input-last"
                            placeholder="0"
                            v-model="contractDetail.fee_royalty"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row mt-4">
              <div class="col-md-3">
                <router-link :to='"/contract/edit/" + contractDetail.id'  data-i18n="Contract Registration" class="btn btn-primary w-100">
                  Edit Contract
                </router-link>
              </div>
              <div class="col-md-3">
                <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
                  <i class='bx bx-arrow-back' ></i>
                  Back to List
                </button>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <button type="button" class="btn btn-outline-warning w-100" @click="handlingExportContract(contractDetail.id)">
                  <i class="menu-icon tf-icons bx bx-detail"></i>
                  Export Contract
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import commonPaging from "../common/CommonPaging"
import {errorAlert, deletedAlert} from "../../helper/error.js"
import showConfirmDeleteAlert from "../../helper/error.js"
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import banks from "../../../public/assets/vendor/js/bank.js"
import { CLOSING_TIME } from "../../config/common.js"
import { utilities } from "../../services/utilities.js";
import _ from 'lodash';
import { saveAs } from 'file-saver';
import axios from 'axios';


export default {
  name: 'ContractList',
  components: {
    commonPaging,
    RiseLoader
  },
  data() {
    return {
      page: 1,
      showPaging: false,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      keyword: "",
      isSearchContractCode: false,
      contractDetail: {},
      totalCost: null,
      domain: process.env.VUE_APP_DOMAIN_FILE_SERVER,
      componentKey: 0,
      cleaveOptions: {
        fee: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      }
    }
  },
  computed: {
    ...mapState({
      listItems: state => state.contractList.stateData.listItems,
      totalItems: state => state.contractList.stateData.totalItems
    }),
    bankOptions() {
      return banks;
    },
    closingTimeOptions() {
      return CLOSING_TIME;
    }
  },
  watch: {
    "contractDetail.fee_customs_duty" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_freight" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_customs_lcc" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_trucking" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_storage" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_labeling" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_ocean_marine" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_inland_logistic" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_inspection" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_testing" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_sample" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_design" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_accesories" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_develop" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_commission" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    },
    "contractDetail.fee_royalty" (newVal, oldVal) {
      if(!newVal || (newVal == oldVal)) return;
      this.totalCost = this.calculateTotalCost();
      this.componentKey += 1; 
    }
  },
  beforeCreate() {
    this.page = 1;
  },
  created() {
    this.$store.dispatch('contractList/getListData').then(
      () => { 
        this.showPaging = true;
        this.isSearchContractCode = false;
      },
      (error) => {
        console.log(error);
        this.showPaging = false;
        errorAlert("error","Network Error",'Can not get Contract list data!');
      }
    );
  },
  mounted() {
  },
  methods: {
    onChangePage(page, wantCallEmit=true) {
      this.page = page;
      this.isLoading = true;
      this.isSearchContractCode = false;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('contractList/getListData', page).then(
        () => { 
          this.isLoading = false;
          this.showPaging = true;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showPaging = false;
          errorAlert("error", "Network Error", "Can not get contract registration list data!");
        }
      );
    },
    onChangeSearch(page, searchKey, wantCallEmit=true) {
      if(!this.keyword) {
        this.onChangePage(1);
        return true;
      }
      this.isLoading = true;
      this.isSearchContractCode = true;
      this.page = page;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('contractList/searchContract', {"page": page, "searchKey":  this.keyword})
      .then(
        () => { 
          this.isLoading = false;
          this.showPaging = this.totalItems > 0 ? true : false;
        },
        (error) => {
          this.isLoading = false;
          this.showPaging = false;
          console.log(error);
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    deleteContract(contractID) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;

          this.$store.dispatch('contractList/deleteContract', contractID).then(
            () => { 
              this.isLoading = false;
              this.showPaging = true;
              deletedAlert();
              this.isSearchContractCode = false;
              if (this.keyword) {
                this.onChangeSearch(1);
              } else {
                this.onChangePage(1);
              }
            },
            (error) => {
              console.log(error);
              this.isLoading = false;
              this.showPaging = false;
              let resp = error.response;
              if (resp.status == 406){
                errorAlert("error", "Delete Not Allowed", resp.data.detail);
              }
              else {
                errorAlert("error", "Network Error", "Failed to deleted contract information!");
              }
              
            }
          );
        }
      )
    },
    handleShowDetail(contractID) {
      this.isLoading = true;
      this.$store.dispatch('contractList/getContractInfo', contractID).then(
        (response) => { 
          this.isLoading = false;
          this.contractDetail = response;
          this.totalCost = this.calculateTotalCost();
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.contractDetail = {};
          errorAlert("error","Network Error",'Can not get Contract information !');
        }
      );
    },
    getPurchaseQuantity(list_prouct) {
      if (list_prouct !== undefined){
        if (list_prouct.length == 0) return 0;
        let quantity = 0;
        list_prouct.forEach(item => {
          quantity += parseInt(item.quantity_in);
        })
        return this.formatCurrency(quantity);
      }
      return 0;
    },
    getSellQuantity(list_prouct) {
      if (list_prouct !== undefined){
        if (list_prouct.length == 0) return 0;
        let quantity = 0;
        list_prouct.forEach(item => {
          quantity += parseInt(item.quantity_out);
        })
        return this.formatCurrency(quantity);
      }
      return 0;
    },
    getTotalPurchaseAmount(contractObject) {
      if (_.isEmpty(contractObject) || _.isNil(contractObject)) return 0;
      let result = 0;
      result = (contractObject.grand_total_buy_price * contractObject.buy_exchange_rates) + this.totalCost;
      return result;
    },
    getTotalSaleAmount(contractObject) {
      if (_.isEmpty(contractObject) || _.isNil(contractObject)) return 0;
      let result = 0;
      result = contractObject.grand_total_sell_price * contractObject.sell_exchange_rates;
      return result;
    },
    getTotalProfitAmount(contractObject) {
      if (_.isEmpty(contractObject) || _.isNil(contractObject)) return 0;
      let result = 0;
      let sell =  contractObject.grand_total_sell_price * contractObject.sell_exchange_rates;
      let buy = (contractObject.grand_total_buy_price * contractObject.buy_exchange_rates) + this.totalCost;
      result = sell - buy;
      return result;
    },
    getTotalProfitPercent(contractObject) {
      if (_.isEmpty(contractObject) || _.isNil(contractObject)) return 0;
      let result = 0;
      let sell =  contractObject.grand_total_sell_price * contractObject.sell_exchange_rates;
      let buy = (contractObject.grand_total_buy_price * contractObject.buy_exchange_rates) + this.totalCost;
      result = (sell - buy) * 100 / sell;
      return result;
    },
    changeFormateDate(date) {
      return this.$moment(date).format("DD MMMM YYYY");
    },
    formatCurrency(value) {
      return utilities.getFormatCurrency(value);
    },
    handlingExportContract(contract_id) {
      this.isLoading = true;
      this.$store.dispatch('contractList/exportContractFile', contract_id).then(
        (response) => { 
          this.isLoading = false;
          this.downloadFile(response.file_url, "");
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          errorAlert("error", "Network Error", "Sorry, server can not export the contract file!");
        }
      );
    },
    downloadFile(url, code) {
      let code_name = code ? code + "-" : ""
      axios
        .get(this.domain+url, {responseType: 'blob'})
        .then(response => {
            saveAs(response.data, code_name + this.getNameFile(url));
        })
    },
    getNameFile(file_name) {
      if (!file_name) return "";
      let file_type = file_name.split(".");
      let split_file =  file_name.split("/");
      return split_file[split_file.length-1].split("___")[0] +"."+file_type[file_type.length-1];
    },
    getImportExportFee(contractObject) {
      if (_.isEmpty(contractObject) || _.isNil(contractObject)) return 0;
      
      let fee_customs_duty = contractObject.fee_customs_duty ? contractObject.fee_customs_duty : 0;
      let fee_freight = contractObject.fee_freight ? contractObject.fee_freight : 0;
      let fee_customs_lcc = contractObject.fee_customs_lcc ? contractObject.fee_customs_lcc : 0;
      return parseFloat(fee_customs_duty) + parseFloat(fee_freight) + parseFloat(fee_customs_lcc);
    },
    getTransportWarehouseFee(contractObject) {
      if (_.isEmpty(contractObject) || _.isNil(contractObject)) return 0;

      let fee_trucking = contractObject.fee_trucking ? contractObject.fee_trucking : 0;
      let fee_storage = contractObject.fee_storage ? contractObject.fee_storage : 0;
      let fee_labeling = contractObject.fee_labeling ? contractObject.fee_labeling : 0;
      return parseFloat(fee_trucking) + parseFloat(fee_storage) + parseFloat(fee_labeling);
    },
    getTestingInsuranceFee(contractObject) {
      if (_.isEmpty(contractObject) || _.isNil(contractObject)) return 0;

      let fee_inspection = contractObject.fee_inspection ? contractObject.fee_inspection : 0;
      let fee_testing = contractObject.fee_testing ? contractObject.fee_testing : 0;
      let fee_sample = contractObject.fee_sample ? contractObject.fee_sample : 0;
      let fee_ocean_marine = contractObject.fee_ocean_marine ? contractObject.fee_ocean_marine : 0;
      let fee_inland_logistic = contractObject.fee_inland_logistic ? contractObject.fee_inland_logistic : 0;
      return parseFloat(fee_inspection) + parseFloat(fee_testing) + parseFloat(fee_sample) + 
        parseFloat(fee_ocean_marine) + parseFloat(fee_inland_logistic);
    },
    getOtherFee(contractObject) {
      if (_.isEmpty(contractObject) || _.isNil(contractObject)) return 0;

      let fee_design = contractObject.fee_design ? contractObject.fee_design : 0;
      let fee_accesories = contractObject.fee_accesories ? contractObject.fee_accesories : 0;
      let fee_develop = contractObject.fee_develop ? contractObject.fee_develop : 0;
      let fee_commission = contractObject.fee_commission ? contractObject.fee_commission : 0;
      let fee_royalty = contractObject.fee_royalty ? contractObject.fee_royalty : 0;
      return parseFloat(fee_design) + parseFloat(fee_accesories) + parseFloat(fee_develop) + 
        parseFloat(fee_commission) + parseFloat(fee_royalty);
    },
    modifyCost() {
      this.isLoading = true;
      let request = {  
        id: this.contractDetail.id,
        fee_customs_duty: this.contractDetail.fee_customs_duty,
        fee_freight: this.contractDetail.fee_freight,
        fee_customs_lcc: this.contractDetail.fee_customs_lcc,
        fee_trucking: this.contractDetail.fee_trucking,
        fee_storage: this.contractDetail.fee_storage,
        fee_labeling: this.contractDetail.fee_labeling,
        fee_ocean_marine: this.contractDetail.fee_ocean_marine,
        fee_inland_logistic: this.contractDetail.fee_inland_logistic,
        fee_inspection: this.contractDetail.fee_inspection,
        fee_testing: this.contractDetail.fee_testing,
        fee_sample: this.contractDetail.fee_sample,
        fee_design: this.contractDetail.fee_design,
        fee_accesories: this.contractDetail.fee_accesories,
        fee_develop: this.contractDetail.fee_develop,
        fee_commission: this.contractDetail.fee_commission,
        fee_royalty: this.contractDetail.fee_royalty
      }
      this.$store.dispatch('contractList/modifyCostContract', request).then(
        (response) => {
          this.isLoading = false;
          this.totalCost = this.calculateTotalCost();
          this.componentKey += 1; 
          this.$swal.fire(
            'Saved',
            response.message,
            'success'
          );
        },
        error => {
          console.log(error);
          this.isLoading = false;
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to modify cost information !'
          });
        }
      );
    },
    calculateTotalCost() {
      if (_.isEmpty(this.contractDetail) || _.isNil(this.contractDetail)) return 0;
      let result = 0;
      let fee_customs_duty = this.contractDetail.fee_customs_duty ? this.contractDetail.fee_customs_duty : 0;
      let fee_freight = this.contractDetail.fee_freight ? this.contractDetail.fee_freight : 0;
      let fee_customs_lcc = this.contractDetail.fee_customs_lcc ? this.contractDetail.fee_customs_lcc : 0;
      let fee_trucking = this.contractDetail.fee_trucking ? this.contractDetail.fee_trucking : 0;
      let fee_storage = this.contractDetail.fee_storage ? this.contractDetail.fee_storage : 0;
      let fee_labeling = this.contractDetail.fee_labeling ? this.contractDetail.fee_labeling : 0;
      let fee_inspection = this.contractDetail.fee_inspection ? this.contractDetail.fee_inspection : 0;
      let fee_testing = this.contractDetail.fee_testing ? this.contractDetail.fee_testing : 0;
      let fee_sample = this.contractDetail.fee_sample ? this.contractDetail.fee_sample : 0;
      let fee_ocean_marine = this.contractDetail.fee_ocean_marine ? this.contractDetail.fee_ocean_marine : 0;
      let fee_inland_logistic = this.contractDetail.fee_inland_logistic ? this.contractDetail.fee_inland_logistic : 0;
      let fee_design = this.contractDetail.fee_design ? this.contractDetail.fee_design : 0;
      let fee_accesories = this.contractDetail.fee_accesories ? this.contractDetail.fee_accesories : 0;
      let fee_develop = this.contractDetail.fee_develop ? this.contractDetail.fee_develop : 0;
      let fee_commission = this.contractDetail.fee_commission ? this.contractDetail.fee_commission : 0;
      let fee_royalty = this.contractDetail.fee_royalty ? this.contractDetail.fee_royalty : 0;

      result = parseFloat(fee_customs_duty) + parseFloat(fee_freight) + parseFloat(fee_customs_lcc) + 
        parseFloat(fee_trucking) + parseFloat(fee_storage) + parseFloat(fee_labeling) + 
        parseFloat(fee_ocean_marine) + parseFloat(fee_inland_logistic) + parseFloat(fee_inspection) + 
        parseFloat(fee_testing) + parseFloat(fee_sample) + parseFloat(fee_design) +
        parseFloat(fee_accesories) + parseFloat(fee_develop) + parseFloat(fee_commission) + parseFloat(fee_royalty);

      return result
    }
  }
}
</script>

<style scoped>
  .cost-input {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: 0px;
    border-top: 0px;
    background-color: lightyellow;
  }
  .cost-input-last {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: 0px;
    border-top: 0px;
    background-color: lightyellow;
    border-bottom-right-radius: 10px;
  }
  div.display-position {
    position: relative;
    z-index: 999;
  }
</style>