import ProductService from '../services/product.service';


const getDefaultState = () => {
	return {
		product_code_id: "",
    product_name: "",
		product_name_en: "",
    product_category_id: "",
    product_sub_category_id: "",
    supplier_id: "",
    image_link: "",
    product_unit: "",
    description: "",
    product_code: "",
		supplier_name: "",
		list_categories: [],
		list_sub_categories: [],
		list_suppliers: [],
		list_variations: [
			{
				variation_label: "SIZE",
				variation_symbol: "SIZ",
				attributes: "S,M,L,XL,XXL"
			}
		],
		list_generated_sku: []
	}
}

export const productAdd = {
	namespaced: true,
	state: {
		stateData: getDefaultState()
	},
	actions: {
		resetState({commit}) {
      commit("resetState");
    },
    searchProductRegByCodeName({commit}, {"page": page, "searchKey": searchKey}) {
      return ProductService.searchProductRegByCodeName({"page": page, "searchKey": searchKey})
      .then(
        (response) => {
          commit('searchProductRegSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('searchProductRegFailure');
          return Promise.reject(error);
        }
      );
    },
		getListCategory({commit}, page=1, size=-1) {
			return ProductService.getListCategory(page, size).then(
				(response) => {
					commit('getListCategorySuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getListCategoryFailure');
					return Promise.reject(error);
				}
			);
		},
		getListSubCategory({commit}, categoryId) {
			return ProductService.getListSubCategory(categoryId).then(
				(response) => {
					commit('getListSubCategorySuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getListSubCategoryFailure');
					return Promise.reject(error);
				}
			);
		},
    saveProduct({commit}, data) {
      return ProductService.saveProduct(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveFailure");
          return Promise.reject(error);
        }
      );
    },
		getListSupplier({commit}, page=1, size=-1) {
			return ProductService.getListSupplier(page, size).then(
				(response) => {
					commit('getListSupplierSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getListSupplierFailure');
					return Promise.reject(error);
				}
			);
		},
		uploadImage({commit}, formdata) {
			return ProductService.uploadImage(formdata).then(
				(response) => {
					commit('uploadSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit("uploadFailure");
					return Promise.reject(error);
				}
			);
		},
		getProductInfo ({commit}, id="") {
			return ProductService.getProductInfo(id).then(
				(response) => {
					commit('getProductSuccess', response);
					// return Promise.resolve(response);
				},
				(error) => {
					commit('getProductFailure');
					return Promise.reject(error);
				}
			);
		},
		updateProduct({commit}, data) {
      return ProductService.updateProduct(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },
		saveProductMass({commit}, data) {
      return ProductService.saveProductMass(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveProductMassFailure");
          return Promise.reject(error);
        }
      );
    },
	},
	mutations: {
		resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState())
    },
		searchProductRegSuccess(state, response) {
			if(response.listItems.length > 0) {
				if (!response.listItems[0].product_name){
					state.stateData.product_code_id = response.listItems[0].id;
					state.stateData.product_category_id = response.listItems[0].category_id;
					state.stateData.product_sub_category_id = response.listItems[0].sub_category_id;
				}
			}
		},
		searchProductRegFailure(state) {
			console.log("run searchProductRegFailure");
			state.stateData.product_code_id = "";
			state.stateData.product_code = "";
		},
		getListCategorySuccess(state, response) {
			state.stateData.list_categories = response.listItems
		},
		getListCategoryFailure (state) {
			state.stateData.list_categories = [];
		},
		getListSubCategorySuccess(state, response) {
			state.stateData.list_sub_categories = response.listItems
		},
		getListSubCategoryFailure(state) {
			state.stateData.list_sub_categories = [];
		},
		getListSupplierSuccess(state, response) {
			state.stateData.list_suppliers = response.listItems
		},
		getListSupplierFailure(state) {
			state.stateData.list_suppliers = [];
		},
		uploadSuccess(state, response) {
			state.stateData.image_link = JSON.stringify(response.image_names);
		},
		uploadFailure(state) {
			state.stateData.image_link = "";
		},
		getProductSuccess(state, response) {
			state.stateData = {...state.stateData, ...response};
		},
		getProductFailure(state) {
			Object.assign(state.stateData, getDefaultState())
		}
	},
	getters: {

	}
}