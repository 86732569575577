<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
			<span class="text-muted fw-light">Invoice /</span> Edit
    </h4>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row p-sm-3 p-0">
          <div class="col-md-6 mb-md-0 mb-4">
            <div class="d-flex svg-illustration mb-4 gap-2">
            <span class="app-brand-logo demo">
                <svg width="25" viewBox="0 0 25 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="M13.7918663,0.358365126 L3.39788168,7.44174259 C0.566865006,9.69408886 -0.379795268,12.4788597 0.557900856,15.7960551 C0.68998853,16.2305145 1.09562888,17.7872135 3.12357076,19.2293357 C3.8146334,19.7207684 5.32369333,20.3834223 7.65075054,21.2172976 L7.59773219,21.2525164 L2.63468769,24.5493413 C0.445452254,26.3002124 0.0884951797,28.5083815 1.56381646,31.1738486 C2.83770406,32.8170431 5.20850219,33.2640127 7.09180128,32.5391577 C8.347334,32.0559211 11.4559176,30.0011079 16.4175519,26.3747182 C18.0338572,24.4997857 18.6973423,22.4544883 18.4080071,20.2388261 C17.963753,17.5346866 16.1776345,15.5799961 13.0496516,14.3747546 L10.9194936,13.4715819 L18.6192054,7.984237 L13.7918663,0.358365126 Z" id="path-1"></path>
                    <path d="M5.47320593,6.00457225 C4.05321814,8.216144 4.36334763,10.0722806 6.40359441,11.5729822 C8.61520715,12.571656 10.0999176,13.2171421 10.8577257,13.5094407 L15.5088241,14.433041 L18.6192054,7.984237 C15.5364148,3.11535317 13.9273018,0.573395879 13.7918663,0.358365126 C13.5790555,0.511491653 10.8061687,2.3935607 5.47320593,6.00457225 Z" id="path-3"></path>
                    <path d="M7.50063644,21.2294429 L12.3234468,23.3159332 C14.1688022,24.7579751 14.397098,26.4880487 13.008334,28.506154 C11.6195701,30.5242593 10.3099883,31.790241 9.07958868,32.3040991 C5.78142938,33.4346997 4.13234973,34 4.13234973,34 C4.13234973,34 2.75489982,33.0538207 2.37032616e-14,31.1614621 C-0.55822714,27.8186216 -0.55822714,26.0572515 -4.05231404e-15,25.8773518 C0.83734071,25.6075023 2.77988457,22.8248993 3.3049379,22.52991 C3.65497346,22.3332504 5.05353963,21.8997614 7.50063644,21.2294429 Z" id="path-4"></path>
                    <path d="M20.6,7.13333333 L25.6,13.8 C26.2627417,14.6836556 26.0836556,15.9372583 25.2,16.6 C24.8538077,16.8596443 24.4327404,17 24,17 L14,17 C12.8954305,17 12,16.1045695 12,15 C12,14.5672596 12.1403557,14.1461923 12.4,13.8 L17.4,7.13333333 C18.0627417,6.24967773 19.3163444,6.07059163 20.2,6.73333333 C20.3516113,6.84704183 20.4862915,6.981722 20.6,7.13333333 Z" id="path-5"></path>
                </defs>
                <g id="g-app-brand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Brand-Logo" transform="translate(-27.000000, -15.000000)">
                    <g id="Icon" transform="translate(27.000000, 15.000000)">
                        <g id="Mask" transform="translate(0.000000, 8.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlink:href="#path-1"></use>
                        </mask>
                        <use fill="#696cff" xlink:href="#path-1"></use>
                        <g id="Path-3" mask="url(#mask-2)">
                            <use fill="#696cff" xlink:href="#path-3"></use>
                            <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-3"></use>
                        </g>
                        <g id="Path-4" mask="url(#mask-2)">
                            <use fill="#696cff" xlink:href="#path-4"></use>
                            <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-4"></use>
                        </g>
                        </g>
                        <g id="Triangle" transform="translate(19.000000, 11.000000) rotate(-300.000000) translate(-19.000000, -11.000000) ">
                        <use fill="#696cff" xlink:href="#path-5"></use>
                        <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-5"></use>
                        </g>
                    </g>
                    </g>
                </g>
                </svg>
            </span>
            <span class="app-brand-text demo text-body fw-bolder">Sneat</span>
            </div>
            <p class="mb-1">LAMODA VIETNAM COMPANY LIMITED</p>
            <p class="mb-1">Ho Chi Minh City, Vietnam</p>
            <p class="mb-0">+84 (0) 394 577 366</p>
          </div>
          <div class="col-md-6">
              <dl class="row mb-2">
              <dt class="col-sm-6 mb-2 mb-sm-0 text-md-end mt-2">
                <span class="h5 text-capitalize mb-0 text-nowrap">Invoice No</span>
              </dt>
              <dd class="col-sm-6 d-flex justify-content-md-end">
                <div class="w-px-200">
                  <input type="text" class="form-control" disabled="" placeholder="0001" value="LMDVN221001-0001" id="invoiceId">
                </div>
              </dd>
              <dt class="col-sm-6 mb-2 mb-sm-0 text-md-end mt-2">
                <span class="fw-normal">Contract No</span>
              </dt>
              <dd class="col-sm-6 d-flex justify-content-md-end">
                <div class="w-px-200">
                  <input type="text" class="form-control" disabled placeholder="CONTR221001-0001" value="" id="">
                </div>
              </dd>
              <dt class="col-sm-6 mb-2 mb-sm-0 text-md-end mt-2">
                  <span class="fw-normal">Issued Date</span>
              </dt>
              <dd class="col-sm-6 d-flex justify-content-md-end">
                <div class="w-px-200">
                  <flat-pickr 
                    v-model="date1" 
                    :config="calendar_config" 
                    placeholder="Select a date" 
                    name="issued-date" 
                    class="form-control">
                  </flat-pickr>
                </div>
              </dd>
              </dl>
          </div>
        </div>
        <hr class="my-4 mx-n4">
        <div class="row p-sm-3 p-0">
          <div class="col-md-6 col-sm-5 col-12 mb-sm-0 mb-4">
            <h6 class="pb-2 fw-bold">Invoice To:</h6>
            <div class="alert alert-primary mt-1" role="alert">
              <div class="row">
                <div class="mb-2">
                  <span class="fw-bold me-3">Customer Name ABC XYZ QWERTY</span>
                  <div class="mt-2">
                    <span class="fw-bold me-3">Address:</span>
                    Address of Customer Company ABC XYZ QWERTY BMW POIUY ALADERT MECESASRAS IPHONE SILICON ARIZONA CALIFORNIA
                  </div>
                  <div class="mt-3">
                    <span class="fw-bold me-3">Payment method:</span> TT 20 days ABC XYZ QWERTY
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-7">
            <h6 class="pb-2 fw-bold">Bill To:</h6>
            <div class="alert alert-primary mt-1" role="alert">
              <div class="row">
                <div class="mb-2">
                  <span class="alert-heading fw-bold mt-2">BANK INFORMATION</span><br/>
                  <span class="fw-bold me-3">Sai Gon Joint Stock Commercial Bank (SCB)</span><br/>
                  <span class="fw-bold me-3">Account Name:</span>Account Name ABC<br/>
                  <span class="fw-bold me-3">Account No:</span>0001-9876-1234-6868<br/>
                  <span class="fw-bold me-3">SWIFT code:</span>BR91905 <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mx-n4">
        <form class="source-item py-sm-3">
          <div class="mb-3" data-repeater-list="group-a">
            <div class="repeater-wrapper pt-0 pt-md-4" data-repeater-item="">
              <div class="d-flex border rounded position-relative pe-0">
                <div class="row w-100 m-0 p-2">
                  <div class="col-md-5 col-12 mb-md-0 mb-3 ps-md-0">
                    <p class="mb-2 repeater-title">Item</p>
                    <input type="text" class="form-control mb-2" disabled placeholder="0001" value="Item-0001">
                    <textarea class="form-control" rows="2" placeholder="Item Description content ..." disabled></textarea>
                  </div>
                  <div class="col-md-3 col-12 mb-md-0 mb-3 text-end">
                    <p class="mb-2 repeater-title">Quantity Out</p>
                    <input type="text" class="form-control invoice-item-price mb-3" disabled placeholder="100" value="100" style="text-align:right;">
                    <div>
                      <p class="mb-2 repeater-title">Sell Price</p>
                      <p class="mb-0">VND 999,999,999</p>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-3 col-12 mb-md-0 mb-3">
                    <p class="mb-2 repeater-title">Tax</p>
                    <div class="tax-1 mt-2" data-bs-toggle="tooltip" data-bs-placement="top">Tax-ABC-DEF: 10%</div>
                    <div class="tax-2 mt-2" data-bs-toggle="tooltip" data-bs-placement="top">Tax-XYZ-QWERTY: 8%</div>
                    <div class="tax-3 mt-2" data-bs-toggle="tooltip" data-bs-placement="top">Tax-POIUTYT-BMW: 2%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr class="my-4 mx-n4">
        <div class="row py-sm-3">
          <div class="col-md-2 mb-md-0 mb-3"></div>
          <div class="col-md-5 d-flex justify-content-end" style="margin-left:5rem;">
            <div class="invoice-calculations">
              <div class="d-flex justify-content-between mb-2">
                <span class="w-px-150">Subtotal:</span>
                <span class="fw-semibold">VND 999,999,999,999.00</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="w-px-150">Tax:</span>
                <span class="fw-semibold">VND 888,888,888,888.00</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span class="w-px-150">Total:</span>
                <span class="fw-semibold">VND 999,999,999,999.00</span>
              </div>
            </div>
          </div>
          <div class="col-md-12 d-flex mt-5">
            <span class="me-2">TOTAL AMOUNT: </span> 
            <span class="text-warning">US DOLLARS NINETY NINE THOUSAND NINE HUNDRED NINETY NINE AND CENTS NINTY NINE.</span>
          </div>
        </div>
        <hr class="my-4">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="note" class="form-label fw-semibold">Date of Shipment</label>
              <input type="text" class="form-control date-picker flatpickr-input" disabled placeholder="YYYY-MM-DD">
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="note" class="form-label fw-semibold">Country of Origin</label>
              <input type="text" class="form-control" disabled id="" placeholder="VIETNAM">
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="note" class="form-label fw-semibold">Means of Transport</label>
              <input type="text" class="form-control" disabled id="" placeholder="PEARL V.22N123">
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="note" class="form-label fw-semibold">HAWB NO</label>
              <input type="text" class="form-control" disabled id="" placeholder="SGN00001">
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="note" class="form-label fw-semibold">Port of Loading</label>
              <input type="text" class="form-control" disabled id="" placeholder="HO CHI MINH PORT, VIETNAM">
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="note" class="form-label fw-semibold">Port of Discharge</label>
              <input type="text" class="form-control" disabled id="" placeholder="OSAKA PORT, JAPAN">
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="note" class="form-label fw-semibold">Upload File 1</label>
              <input class="form-control" type="file" id="formFile">
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="note" class="form-label fw-semibold">Upload File 2</label>
              <input class="form-control" type="file" id="formFile">
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="mb-3 col-sm-4"></div>
          <div class="mb-3 col-sm-6">
            <button type="submit" class="btn btn-primary me-3">Save Product</button>
            <router-link to="/invoice" data-i18n="Add Product" class="btn btn-outline-secondary w-45">
              <i class='bx bx-arrow-back'></i>
              Back To List
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';

export default {
  name: 'InvoiceEdit',
  components: {
    flatPickr   
  },
  data() {
    return {
      date1: null,
      date2: null,
      calendar_config: configs
    }
  },
  created() {

  },
  methods: {
    onChangeCurrentTab(index) {
      this.currentTabIndex = index;
    },
    onTabBeforeChange() {
      if (this.currentTabIndex === 0) {
        console.log('First Tab');
      }
      console.log('All Tabs');
    },
    wizardCompleted() {
      console.log('Wizard Completed');
    },
  },
}
</script>

<style>
  .form-wizard-vue .fw-body-content {
      padding: 20px 30px;
  }
</style>