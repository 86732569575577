<template>
	<div class="dataTables_paginate paging_simple_numbers" id="">
		<ul class="pagination" v-if="!isSearch">
			<li class="page-item" :class="{'disabled': pager.currentPage === 1}">
				<a class="page-link" @click="setPage(1, true)">
					{{labels.first}}
				</a>
			</li>
			<li class="page-item prev" :class="{'disabled': pager.currentPage === 1}">
				<a class="page-link" @click="setPage(pager.currentPage - 1, true)">
					<i class="tf-icon bx bx-chevrons-left"></i></a>
			</li>
			<li class="page-item" v-for="page in pager.pages" :key="page" :class="{'active': activePageId === page, 'disabled': activePageId === page}">
				<a class="page-link" @click="setPage(page, true)">
					{{page}}
				</a>
			</li>
			<li class="page-item next" :class="{'disabled': pager.currentPage === pager.totalPages}">
				<a class="page-link" @click="setPage(pager.currentPage + 1, true)">
					<i class="tf-icon bx bx-chevrons-right"></i>
				</a>
			</li>
			<li class="page-item" :class="{'disabled': pager.currentPage === pager.totalPages}">
				<a class="page-link" @click="setPage(pager.totalPages, true)">
					{{labels.last}}
				</a>
			</li>
    </ul>
		<ul class="pagination" v-else>
			<li class="page-item" :class="{'disabled': pager.currentPage === 1}">
				<a class="page-link" @click="setSearchPage(1, true)">
					{{labels.first}}
				</a>
			</li>
			<li class="page-item prev" :class="{'disabled': pager.currentPage === 1}">
				<a class="page-link" @click="setSearchPage(pager.currentPage - 1, true)">
					<i class="tf-icon bx bx-chevrons-left"></i></a>
			</li>
			<li class="page-item" v-for="page in pager.pages" :key="page" :class="{'active': activePageId === page, 'disabled': activePageId === page}">
				<a class="page-link" @click="setSearchPage(page, true)">
					{{page}}
				</a>
			</li>
			<li class="page-item next" :class="{'disabled': pager.currentPage === pager.totalPages}">
				<a class="page-link" @click="setSearchPage(pager.currentPage + 1, true)">
					<i class="tf-icon bx bx-chevrons-right"></i>
				</a>
			</li>
			<li class="page-item" :class="{'disabled': pager.currentPage === pager.totalPages}">
				<a class="page-link" @click="setSearchPage(pager.totalPages, true)">
					{{labels.last}}
				</a>
			</li>
    </ul>
  </div>
</template>

<script>
import paginate from '../../helper/paginate.js';

const defaultLabels = {
	first: 'First',
	last: 'Last',
	previous: 'Previous',
	next: 'Next'
};

export default {
	name: 'common-paging',
	props: {
		totalItems: {
			type: Number,
			required: false
		},
		items: {
			type: Array,
			required: true
		},
		initialPage: {
			type: Number,
			default: 1
		},
		labels: {
			type: Object,
			default: () => defaultLabels
		},
		pageSize: {
			type: Number,
			default: paginate.MAX_DISPLAY_PAGE
		},
		maxPages: {
			type: Number,
			default: paginate.PAGE_LIMIT
		},
		activePageId: {
			type: Number,
			default: 1
		},
		isSearch: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			pager: {}
		}
	},
	created() {
		// set page if items array isn't empty
		if (this.items && this.items.length) {
			if (!this.isSearch) {
				this.setPage(this.initialPage, false);
			} 
			else {
				this.setSearchPage(this.initialPage, false);
			}
			
		}
	},
	computed: {
	},
	watch: {
		totalItems: function(newVal, oldVal) {
			console.log(oldVal, newVal);
			if (this.isSearch){
				this.setSearchPage(this.initialPage, true);
			}
			else {
				this.setPage(this.initialPage, false);
			}
		}
	},
	mounted() {
	},
	methods: {
		setPage(page, wantCallEmit=false) {
			const { pageSize, maxPages } = this;
			// get new pager object for specified page
			const pager = paginate(this.totalItems, page, pageSize, maxPages);

			// get new page of items from items array
			// const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

			// update pager
			this.pager = pager;

			// emit change page event to parent component
			this.$emit('changePage', page, wantCallEmit);
		},
		setSearchPage(page, wantCallEmit=false) {
			// const { items, pageSize, maxPages } = this;
			const { pageSize, maxPages } = this;
			// get new pager object for specified page
			const pager = paginate(this.totalItems, page, pageSize, maxPages);

			// get new page of items from items array
			// const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

			// update pager
			this.pager = pager;

			// emit change page event to parent component
			this.$emit('changeSearchPage', page, "" ,wantCallEmit);
		}
	}

}
</script>

<style>
	a.page-link {
		cursor: pointer;
	}
</style>