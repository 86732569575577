<template>
 <body>
    <!-- Content -->
    <div class="container-xxl">
      <div class="authentication-wrapper authentication-basic container-p-y">
        <div class="authentication-inner">
          <!-- Register -->
          <div class="card">
            <div class="card-body">
              <!-- Logo -->
              <div class="app-brand justify-content-center">
                <a href="/login" class="app-brand-link gap-2">
                  <span class="app-brand-logo demo">
                    <img src="/assets/img/common/logo.png" width="150">
                  </span>
                </a>
              </div>
              <!-- /Logo -->
              <h4 class="mb-2">Welcome to Lamoda System 👋</h4>
              <p class="mb-4">Please sign-in to your account and start managing your business</p>

              <form id="formAuthentication" class="mb-3" @submit.prevent="handleLogin">
                <div class="mb-3">
                  <label for="email" class="form-label">Email or Username</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your email or username"
                    autofocus
                    name="username"
                    v-model="user.username"
                  />
                  <div class="error-msg" v-if="v$.user.username.$error"> 
                    {{ v$.user.username.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-4 form-password-toggle">
                  <div class="d-flex justify-content-between">
                    <label class="form-label" for="password">Password</label>
                    <router-link to="/forgot-password" data-i18n="Forgot Password?">
                      <small>Forgot Password?</small>
                    </router-link>
                  </div>
                  <div class="input-group input-group-merge">
                    <input
                      :type="field_pass"
                      class="form-control"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      name="password"
                      v-model="user.password"
                    />
                    <span class="input-group-text cursor-pointer" @click="changeFieldType"><i class="bx bx-hide"></i></span>
                    <div class="error-msg" v-if="v$.user.password.$error"> 
                      {{ v$.user.password.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <!-- <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="remember-me" />
                    <label class="form-check-label" for="remember-me"> Remember Me </label>
                  </div>
                </div> -->
                <div class="mb-3">
                  <button class="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                </div>
              </form>

              <p class="text-center">
                <span>You are a new user?</span>
                <router-link to="/register" data-i18n="Create an account">
                  Create an account
                </router-link>
              </p>
            </div>
          </div>
          <!-- /Register -->
        </div>
      </div>
    </div>

    <!-- / Content -->
  </body>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'

export default {
  name: 'Login',
  data() {
    return {
      v$: useValidate(),
      user: {
        username: "",
        password: ""
      },
      loading: false,
      message: "",
      field_pass: "password"
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    handleLogin () {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$store.dispatch('auth/login', this.user).then(
          () => {
            window.location.href = '/'
          },
          (error) => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Wrong Username or Password !'
            });
          }
        );
      }
    },
    changeFieldType() {
      this.field_pass = this.field_pass == "password" ? "text" : "password";
    }
  },
  validations() {
    return {
      user: {
        username: { required: helpers.withMessage('Username cannot be empty', required)},
        password: { 
          required: helpers.withMessage('Password cannot be empty', required),
          minLength: helpers.withMessage('Minimum 8 letters', minLength(8))
        }
      }
    }
  },
}
</script>

<style scoped>
  .error-msg {
    /* display: none; */
    width: 100%;
    margin-top: 0.3rem;
    font-size: 90%;
    color: #ff3e1d;
  }
</style>