<template>
	<!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Fixed Cost /</span> Add New
    </h4>
    <!-- General Information -->
    <div class="card mb-4">
      <h5 class="card-header">General Information</h5>
      <hr class="my-0">
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-md-3 fv-plugins-icon-container">
            <label for="firstName" class="form-label">Issue Date</label>
						<span class="m-1">(*)</span>
            <flat-pickr 
							v-model="today" 
							:config="calendarConfig" 
							placeholder="Select a date" 
							name="created-date" 
							class="form-control">
						</flat-pickr>
          </div>
        </div>
				<div class="row">
					<div class="mb-8 col-md-8">
						<label for="" class="form-label">Partner Name</label>
						<span class="m-1">(*)</span>
						<select class="form-select text-capitalize">
							<option value="1">CTY TNHH MTV SAN XUAT & PHAN PHOI THANH PHAT</option>
							<option value="Scheduled">Scheduled</option>
							<option value="Publish">Publish</option>
							<option value="Inactive">Inactive</option>
						</select>
						<!-- <VueMultiselect 
							placeholder="Please choose supplier" 
							label="partner_name" 
							:close-on-select="true"
							:clear-on-select="false"
							:show-labels="false"
						/> -->
					</div>
					<div class="mb-4 col-md-4">
						<label class="form-label">Partner Code</label>
						<span class="m-1">(*)</span>
						<input class="form-control" type="text" placeholder="PM240101-01">
					</div>
				</div>
      </div>
    </div>
    <!-- / General Information -->

    <!-- Cost Information -->
    <div class="card mb-4">
      <h5 class="card-header">Main Information</h5>
      <hr class="my-0">
      <div class="card-body">
				<div class="row">
					<div class="mb-6 col-md-6 mb-4">
						<label for="" class="form-label">Category Fixed Cost</label>
						<span class="m-1">(*)</span>
						<select class="form-select text-capitalize">
							<option value="1">ABC</option>
							<option value="Scheduled">QWERTT</option>
							<option value="Publish">FFJKJLKJ</option>
							<option value="Inactive">ZZZZZZ</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="mb-6 col-md-6 mb-4">
						<label for="" class="form-label">Cost Name</label>
						<span class="m-1">(*)</span>
						<input class="form-control" type="text" placeholder="Office Rental Cost 2024, Dec">
					</div>
					<div class="mb-3 col-md-3 mb-4">
						<label for="" class="form-label">Cost Value</label>
						<span class="m-1">(*)</span>
						<div class="input-group">
							<span class="input-group-text px-2">VND</span>
							<input type="text" class="form-control px-1" placeholder="999,999,999,000" style="text-align: right;">
						</div>
					</div>
					<div class="mb-3 col-md-3 mb-4">
						<label for="" class="form-label">Tax Value</label>
						<span class="m-1">(*)</span>
						<div class="input-group">
							<input type="text" class="form-control px-1" placeholder="10" style="text-align: right;">
							<span class="input-group-text px-2">%</span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="mb-3 col-md-3 mb-4">
            <label class="form-label">Payment Due Date</label>
						<span class="m-1">(*)</span>
            <flat-pickr 
							v-model="today" 
							:config="calendarConfig" 
							placeholder="Select a date" 
							name="payment-due-date" 
							class="form-control">
						</flat-pickr>
          </div>
					<div class="mb-3 col-md-3 mb-4">
            <label class="form-label">Actual Payment Date</label>
            <flat-pickr 
							v-model="today" 
							:config="calendarConfig" 
							placeholder="Select a date" 
							name="actual-payment-date" 
							class="form-control">
						</flat-pickr>
          </div>
					<div class="mb-3 col-md-3 mb-4">
						<label for="" class="form-label">Actual Amount</label>
						<div class="input-group">
							<span class="input-group-text px-2">VND</span>
							<input type="text" class="form-control px-1" placeholder="999,999,999,000" style="text-align: right;">
						</div>
					</div>
				</div>
				<div class="row">
					<label for="apiAccess" class="form-label">Remark</label>
					<div class="mb-9 col-md-9">
						<textarea class="form-control" rows="3"></textarea>
					</div>
				</div>
        <div class="row mt-5">
          <form id="" @submit.prevent="handleSave">
            <div class="col-sm-6">
              <button type="submit" class="btn btn-primary me-3">Save Fixed Cost</button>
              <router-link to="/fixed-cost" data-i18n="Add Product" class="btn btn-outline-secondary w-45">
                <i class='bx bx-arrow-back' ></i>
                Back To List
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- / Cost Information -->

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core';
// import VueMultiselect from 'vue-multiselect';

export default {
	name: 'FixedCostAdd',
  components: {
    RiseLoader,
		flatPickr
  },
	data() {
    return {
			today: "2024/03/08",
      v$: useValidate(),
      calendarConfig: configs,
      color: '#696cff',
      size: '20px',
      isLoading: false
		}
	}
}
</script>

<style scoped>

</style>