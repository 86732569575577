import API from "@/config/api.js";
import moment from 'moment'

class PartnerRegistrationService {
  
  getListData(page) {
    return API.get(`partner-registration/?page=${page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  searchPartnerCode(data) {
    return API.get(`partner-registration/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  deletePartnerCode(partRegId) {
    return API.delete(`partner-registration/delete/${partRegId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getLatestCode() {
    return API.get('partner-registration/get-latest')
    .then((response) => {
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
  }

  initConsectiveNo(data){
    let today = new Date()
    let flag = moment(data.date).isSame(today, 'day');
    let result = "01"
    if (flag) {
      let num = data.consective_no;
      return ("00" + (parseInt(num) + 1)).slice(-2);
    }
    return result;
  }

  saveCode(data) {
    return API.post('partner-registration/add', {
      partner_code: data.partner_code,
      pr: data.select_pay_receive,
      mcs: data.select_customer,
      date: data.date,
      consective_no: data.consective_no
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  updateCode(data) {
    return API.put(`partner-registration/edit/${data.id}`, {
      id: data.id,
      partner_code: data.partner_code,
      pr: data.select_pay_receive,
      mcs: data.select_customer,
      date: data.date,
      consective_no: data.consective_no,
      updated_dated: moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getPartnerRegCode(id) {
    return API.get(`partner-registration/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
  }

  generateCode(dataObject) {
    if (!Object.hasOwn(dataObject, "select_pay_receive") | 
      !Object.hasOwn(dataObject, "select_customer") |
      !Object.hasOwn(dataObject, "consective_no") |
      !Object.hasOwn(dataObject, "date")) {
      return false;
    }
    let selected_date = moment(dataObject.date).format("YYMMDD");
    return dataObject.select_pay_receive + dataObject.select_customer + 
      selected_date +"-" + dataObject.consective_no;
  }
}

export default new PartnerRegistrationService();