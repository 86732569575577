import PartnerRegistrationService from '../services/partner_registration.service';
import moment from 'moment'

const getDefaultState = () => {
  return {
    select_pay_receive: "P",
    select_customer: "M",
    date: null,
    consective_no: "",
    partner_code: "",
  }
}

export const partnerRegistrationAdd = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    getLatestCode({commit}) {
      return PartnerRegistrationService.getLatestCode().then(
        (response) => {
          commit('getSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getFailure');
          return Promise.reject(error);
        }
      );
    },
    saveCode({commit}, data) {
      return PartnerRegistrationService.saveCode(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveFailure");
          return Promise.reject(error);
        }
      );
    },
    resetState({commit}) {
      commit("resetState");
    }
  },
  mutations: {
    getSuccess(state, response) {
      state.stateData.consective_no = PartnerRegistrationService.initConsectiveNo(response);
    },
    getFailure(state) {
      state.stateData.consective_no = ""
    },
    generateCode(state) {
      let selected_date = moment(state.stateData.date).format("YYMMDD");
      state.stateData.partner_code = state.stateData.select_pay_receive + 
        state.stateData.select_customer + 
        selected_date +"-" + state.stateData.consective_no;
    },
    resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState())
    }
  },
  getters: {

  }
}