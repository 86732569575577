<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span class="text-muted fw-light">Invoice /</span> Add New
    </h4>
    <!-- Wizzard -->
    <Wizard
      squared-tabs
      card-background
      navigable-tabs
      scrollable-tabs
      :nextButton="{
        text: 'Next',
        icon: 'next',
        hideIcon: false, // default false but selected for sample
        hideText: false, // default false but selected for sample
      }"
      :custom-tabs="[
        {title: 'Shipment Information'},
        {title: 'Receiving Voucher'},
        {title: 'Delivery Voucher'},
        {title: 'Invoice'},
      ]"
      :beforeChange="onTabBeforeChange"
      @change="onChangeCurrentTab"
      @complete:wizard="wizardCompleted"
    >
      <div v-show="currentTabIndex === 0">
        <shipment-add></shipment-add>
      </div>
      <div v-show="currentTabIndex === 1">
        <receiving-voucher-add></receiving-voucher-add>
      </div>
      <div v-show="currentTabIndex === 2">
        <delivery-voucher-add></delivery-voucher-add>
      </div>
      <div v-show="currentTabIndex === 3">
        <invoice-add></invoice-add>
      </div>
    </Wizard>
    <!-- /Wizzard -->
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import Wizard from 'form-wizard-vue3'
import 'form-wizard-vue3/dist/form-wizard-vue3.css';
import ShipmentAdd from "./ShipmentAdd.vue";
import DeliveryVoucherAdd from "../delivery/DeliveryVoucherAdd.vue";
import ReceivingVoucherAdd from "../receiving/ReceivingVoucherAdd.vue";
import InvoiceAdd from "./InvoiceAdd.vue";

export default {
  name: 'Shipment',
  components: {
    Wizard,
    ShipmentAdd,
    DeliveryVoucherAdd,
    ReceivingVoucherAdd,
    InvoiceAdd
  },
  data(){
    return {
      currentTabIndex: 0
    };
  },
  computed: {
    ...mapState({
      stateData: state => state.shipmentAdd.stateData
    })
  },
  beforeCreate() {
    this.$store.dispatch('shipmentAdd/resetState');
  },
  created() {

  },
  methods: {
    onTabBeforeChange() {
      if (this.currentTabIndex === 0) {
        console.log('First Tab');
      }
    },
    onChangeCurrentTab(index, oldIndex) {
      console.log(index, oldIndex);
      if (!this.stateData.contract_id) {
        this.currentTabIndex = 0;
        return;
      }
      if (this.stateData.error_lists.length > 0) {
        this.currentTabIndex = 0;
        return;
      }
      this.currentTabIndex = index;
    },
    wizardCompleted() {
      console.log('Wizard Completed');
    },
  },
}
</script>

<style>
  .form-wizard-vue .fw-body-content {
      padding: 20px 30px;
  }
  .fw-overflow-scroll .fw-body-list:hover {
    overflow-x: auto;
  }
</style>