import API from "@/config/api.js";
// import moment from 'moment'
import ContractRegistrationService from "./contract_registration.service";
import PartnerService from "../services/partner.service"
import ProductService from "../services/product.service";


class ContractService {
  
  searchContractRegByCode(data) {
    return ContractRegistrationService.searchContractCode(data);
  }
  
  getListSupplier(page, size, type) {
    return PartnerService.getListData(page, size, type)
  }

  getPartnerInfo(id) {
    return PartnerService.getPartnerInfo(id);
  }

  searchProduct(data) {
    return ProductService.searchProduct(data);
  }

  saveContract(request) {
		return API.post('contract/add', request)
		.then(
			(response) => {
        return response.data;
			},
			(error) => {
        return Promise.reject(error);
			}
		)
	}

  getListData(page) {
    return API.get(`contract/?page=${page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  searchContract(data) {
    return API.get(`contract/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  deleteContract(contractId) {
    return API.delete(`contract/delete/${contractId}`)
    .then(
      (response) => {
        if(response.data.status_code == 406) {
          return Promise.reject(response.data);
        }
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getContractInfo(contractId) {
		return API.get(`contract/${contractId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

  getShipmentQuantityRemain(contractId) {
		return API.get(`contract/shipment/${contractId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

  updateContract(request) {
		return API.put(`contract/edit/${request.id}`, request)
		.then(
			(response) => {
			return response.data;
			},
			(error) => {
			return Promise.reject(error);
			}
		)
	}

  exportContractFile(contractId) {
		return API.get(`contract/export/${contractId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

  modifyCostContract(request) {
		return API.put('contract/modify_cost', request)
		.then(
			(response) => {
        return response.data;
			},
			(error) => {
        return Promise.reject(error);
			}
		)
	}
}

export default new ContractService();