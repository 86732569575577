<template>
	<!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Fixed Cost List</span>
        <div class="dt-buttons btn-add-fcost">
          <router-link to="/fixed-cost/add" data-i18n="Create New Fixed Cost" class="dt-button btn btn-primary">
            <i class="bx bx-plus me-md-2"></i>
            Create New
          </router-link>
        </div>
    </h4>
    <div class="row g-4 mb-4">
      <div class="col-sm-12 col-xl-12">
				<div class="card">
          <!-- <h5 class="card-header">Search Condition</h5> -->
					<div class="card-body">
            <!-- <label class="mb-2 fw-bold">Search Condition</label> -->
            <div class="col-12">
              <div class="row">
                <div class="col-sm-3">
                  <label class="form-label">Start Date</label>
                  <flat-pickr 
                    :config="calendarConfig" 
                    placeholder="Select a date" 
                    id="register_date"
                    name="register-date" 
                    class="form-control"
                    v-model="today" 
                  >
                  </flat-pickr>
                  <!-- <div class="error-msg" v-if="v$.conditions.start_date.$error"> 
                    {{ v$.conditions.start_date.$errors[0].$message }}
                  </div> -->
                </div>
                <div class="col-sm-3">
                  <label class="form-label">End Date</label>
                  <flat-pickr 
                    :config="calendarConfig" 
                    placeholder="Select a date" 
                    id="register_date"
                    name="register-date" 
                    class="form-control"
                    v-model="today" 
                  >
                  </flat-pickr>
                  <!-- <div class="error-msg" v-if="error_date"> 
                    Wrong End Date Value !
                  </div> -->
                </div>
                <div class="col-sm-4">
                  <label class="form-label">Category Fixed Cost</label>
                  <select class="form-select text-capitalize">
                    <option value="1">ABC</option>
                    <option value="Scheduled">QWERTT</option>
                    <option value="Publish">FFJKJLKJ</option>
                    <option value="Inactive">ZZZZZZ</option>
                  </select>
                </div>
                <div class="col-sm-2 text-center">
                  <button class="btn btn-outline-primary" 
                    style="margin-top: 1.9rem;"
                    @click="handleSearch"
                  >
                    Search Cost
                  </button>
                </div>
                <div class="col-sm-3 mt-3">
                  <label class="form-label">Partner Code</label>
                  <input class="form-control" type="text" placeholder="PM240101-01">
                </div>
                <div class="col-sm-7 mt-3">
                  <label for="" class="form-label">Partner Name</label>
                  <select class="form-select text-capitalize">
                    <option value="1">CTY TNHH MTV SAN XUAT & PHAN PHOI THANH PHAT</option>
                    <option value="Scheduled">ABC XYZ QWERTY</option>
                  </select>
                  <!-- <VueMultiselect 
                    placeholder="Please choose supplier" 
                    label="partner_name" 
                    :close-on-select="true"
                    :clear-on-select="false"
                    :show-labels="false"
                  /> -->
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
    <div class="row">
			<!-- Fixed Cost List -->
			<div class="col-md-12">
				<div class="card">
					<div class="card-body">
						<div class="">
              <div class="col-sm-12 px-3 row">
                <div class="col-sm-2">
                  <label class="form-label fw-bold me-3">Sum Chi:</label>
                </div>
                <div class="col-sm-3">
                  <span>999,999,999,000</span>
                  <span>VND</span>
                </div>
              </div>
              <div class="col-sm-12 mb-2 px-3">
                <label class="form-label fw-bold me-3">Sum Thu:</label>
                <span>999,999,999,000</span>
                <span>VND</span>
              </div>
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Contract Code</th>
										<th>Supplier</th>
										<th>Cumtomer</th>
										<th class="text-start">Last Delivery</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody class="table-border-bottom-0">
									<tr >
										<td>1</td>
										<td>2</td>
										<td>3</td>
										<td>4</td>
										<td>5</td>
										<!-- <td>
											<div class="d-flex align-items-center">
												<a href="#" 
													class="text-body me-3" 
													data-bs-toggle="offcanvas" 
													data-bs-target="#offcanvasScroll"
													@click="handleShowDetail(item.id)"
												>
													<i class="bx bx-show mx-1"></i>
												</a>
												<router-link :to='"/contract/edit/" + item.id' data-i18n="Create Definition" class="text-body me-3">
													<i class='bx bx-edit-alt'></i>
												</router-link>
												<a class="text-body icon-delete" @click="deleteContract(item.id)">
													<i class='bx bx-x-circle'></i>
												</a>
											</div>
										</td> -->
									</tr>
								</tbody>
							</table>
							<br/>
							<div class="row mx-2">
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
									</div>
								</div>
								<div class="col-sm-12 col-md-6">
									<!-- <common-paging 
										:items="listItems" 
										:totalItems="totalItems" 
										@changePage="onChangePage"
										@changeSearchPage="onChangeSearch"
										:activePageId="page"
										v-show="showPaging"
										:isSearch="isSearchContractCode"
									/> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>

    <!-- OffCanvas from the right -->
    <!-- <div class="row display-position">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScroll" 
        aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility:visible; width:78%;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0">
          <div class="d-flex flex-wrap my-2" :key="componentKey">
            <div class="col-sm-6">
              <span class="fw-bold text-primary me-3">{{ "# " + contractDetail.contract_code }}</span>
            </div>
            <div class="col-sm-6 text-end">
              <span class="fw-bold me-2">Reference No:</span>
              <span class="fw-bold text-primary">{{ contractDetail.reference_no }}</span>
            </div>
            <div class="col-sm-6 mt-2">
              <label class="fw-bold me-2" style="float: left;">Supplier:</label>
              <div class="fw-bold text-truncate1" v-tooltip="contractDetail.supplier_name">
                {{ contractDetail.supplier_name }}
              </div>
            </div>
            <div class="col-sm-6 mt-2 text-end">
              <label class="fw-bold me-2">Customer:</label>
              <div class="fw-bold text-truncate1 text-end" style="float: right;" v-tooltip="contractDetail.customer_name">
                {{ contractDetail.customer_name }}
              </div>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold">Total Purchase Quantity: </span>
              <span class="fw-bold">{{ getPurchaseQuantity(contractDetail.contract_detail_list) }}</span>
            </div>
            <div class="col-sm-6 text-end mt-2">
              <span class="fw-bold">Total Sell Quantity: </span>
              <span class="fw-bold">{{ getSellQuantity(contractDetail.contract_detail_list)  }}</span>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold">Purchase Amount (before Tax): </span>
              <span class="fw-bold">{{ contractDetail.buy_currency + " "}} {{ formatCurrency(contractDetail.grand_total_buy_price + totalCost) }}</span>
            </div>
            <div class="col-sm-6 text-end mt-2">
              <span class="fw-bold">Sale Amount (before tax): </span>
              <span class="fw-bold">{{ contractDetail.sell_currency + " "}} {{ formatCurrency(contractDetail.grand_total_sell_price) }}</span>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold">Purchase Amount (in VND): </span>
              <span class="fw-bold me-1">VND</span>
              <span class="fw-bold">{{ formatCurrency(getTotalPurchaseAmount(contractDetail)) }}</span>
            </div>
            <div class="col-sm-6 text-end mt-2">
              <span class="fw-bold">Sale Amount (in VND): </span>
              <span class="fw-bold me-1">VND</span>
              <span class="fw-bold">{{ formatCurrency(getTotalSaleAmount(contractDetail)) }}</span>
            </div>
            <div class="col-sm-12 mt-2">
              <span class="fw-bold me-2">Total Profit Amount ~ Rate:</span>
              <span class="fw-bold text-success me-2" v-if="getTotalProfitAmount(contractDetail) > 0">{{ "VND " + formatCurrency(getTotalProfitAmount(contractDetail)) + " 〜 "}}</span>
              <span class="fw-bold text-danger me-2" v-else>{{ "VND " + formatCurrency(getTotalProfitAmount(contractDetail)) + "&nbsp;&nbsp;&nbsp; 〜 &nbsp;" }}</span>
              <span class="fw-bold text-success" v-if="getTotalProfitAmount(contractDetail) > 0">{{ formatCurrency(getTotalProfitPercent(contractDetail)) + " %"}}</span>
              <span class="fw-bold text-danger" v-else>{{ formatCurrency(getTotalProfitPercent(contractDetail)) + " %"}}</span>
            </div>
          </div>
          <h5 class="pb-2 border-bottom mb-2"></h5>
          <div class="info-container">
            <ul class="list-unstyled">
              <li class="mb-3">
                <div class="d-flex flex-wrap">
                  <div class="col-sm-6 mt-2">
                    <span class="fw-bold me-1">Last Derivery Date:</span>
                    <span class="">{{ changeFormateDate(contractDetail.last_delivery_date) }}</span>
                  </div>
                  <div class="col-sm-6 mt-2 text-end">
                    <span class="fw-bold me-1">Contract Date:</span>
                    <span class="">{{ changeFormateDate(contractDetail.contract_date) }}</span>
                  </div>
                </div>
              </li>
							<li class="mb-3">
								<div class="table-responsive">
									<table class="table ">
										<thead>
											<tr>
												<th class="border-top-0">Product</th>
                        <th class="border-top-0">Product Code</th>
												<th class="border-top-0 text-end">PUR. Qty</th>
												<th class="border-top-0 text-end">PUR. Price</th>
                        <th class="border-top-0 text-end">Sell Qty</th>
                        <th class="border-top-0 text-end">Sell Price</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(detail, index) in contractDetail.contract_detail_list" :key="index">
												<td>
													<div class="text-truncate" style="max-width: 200px;">
														{{ detail.product_name }}
													</div>
												</td>
                        <td>{{ detail.product_code }}</td>
												<td class="text-end">{{ formatCurrency(detail.quantity_in) }}</td>
												<td class="text-end">{{ contractDetail.buy_currency + " "}} {{ formatCurrency(detail.buy_price) }}</td>
                        <td class="text-end">{{ formatCurrency(detail.quantity_out) }}</td>
                        <td class="text-end">{{ contractDetail.sell_currency + " "}} {{ formatCurrency(detail.sell_price) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</li>
              <li class="mb-3">
                <div class="row mt-5">
                  <div class="col-sm-3 pt-2">
                    <span class="fw-bold text-primary">COST DETAILS (Unit VND)</span>
                  </div>
                  <div class="col-sm-3 pt-2 px-4 text-end">
                    <span class="fw-bold text-primary me-2">VND</span>
                    <span class="fw-bold text-primary">{{ formatCurrency(calculateTotalCost()) }}</span>
                  </div>
                  <div class="col-sm-6 text-end">
                    <button type="button" class="btn btn-outline-primary w-50" @click="modifyCost()">
                      <i class="menu-icon tf-icons bx bx-edit-alt"></i> 
                      Modify Cost 
                    </button>
                  </div>
                  <div class="col-sm-6 mt-3">
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center active">
                        <span class="fw-bold">IMPORT / EXPORT FEE</span>
                        <span class="fw-bold text-end">{{ formatCurrency(getImportExportFee(contractDetail)) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Customs Duty Fee</span>
                        </div>
                        <div class="col-sm-6 text-end">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_customs_duty"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Freight Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_freight"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Customs & LCC Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input-last"
                            placeholder="0"
                            v-model="contractDetail.fee_customs_lcc"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-6 mt-3">
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center active">
                        <span class="fw-bold">TRANSPORT & WAREHOUSE FEE</span>
                        <span class="fw-bold text-end">{{ formatCurrency(getTransportWarehouseFee(contractDetail)) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Trucking Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_trucking"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Storage Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_storage"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Labeling Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input-last"
                            placeholder="0"
                            v-model="contractDetail.fee_labeling"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-6 mt-1">
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center active">
                        <span class="fw-bold">TESTING & INSURANCE FEE</span>
                        <span class="fw-bold text-end">{{ formatCurrency(getTestingInsuranceFee(contractDetail)) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Inspection Fee</span>
                        </div>
                        <div class="col-sm-6 text-end">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_inspection"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Testing Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_testing"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Sample Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_sample"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Ocean Marine Fee</span>
                        </div>
                        <div class="col-sm-6 text-end">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_ocean_marine"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Inland Logistic Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input-last"
                            placeholder="0"
                            v-model="contractDetail.fee_inland_logistic"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  
                  <div class="col-sm-6 mt-1">
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center active">
                        <span class="fw-bold">OTHER FEE</span>
                        <span class="fw-bold text-end">{{ formatCurrency(getOtherFee(contractDetail)) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Design Fee</span>
                        </div>
                        <div class="col-sm-6 text-end">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_design"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Accesories Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_accesories"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Develop Fee</span>
                        </div>
                        <div class="col-sm-6">
                           <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_develop"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Commission Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input"
                            placeholder="0"
                            v-model="contractDetail.fee_commission"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center p-0">
                        <div class="col-sm-6">
                          <span class="fw-bold mx-2">Royalty Fee</span>
                        </div>
                        <div class="col-sm-6">
                          <cleave
                            class="form-control text-end cost-input-last"
                            placeholder="0"
                            v-model="contractDetail.fee_royalty"
                            :options="cleaveOptions.fee"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row mt-4">
              <div class="col-md-3">
                <router-link :to='"/contract/edit/" + contractDetail.id'  data-i18n="Contract Registration" class="btn btn-primary w-100">
                  Edit Contract
                </router-link>
              </div>
              <div class="col-md-3">
                <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
                  <i class='bx bx-arrow-back' ></i>
                  Back to List
                </button>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <button type="button" class="btn btn-outline-warning w-100" @click="handlingExportContract(contractDetail.id)">
                  <i class="menu-icon tf-icons bx bx-detail"></i>
                  Export Contract
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';

export default {
	name: 'FixedCostList',
  components: {
    // commonPaging,
    RiseLoader,
    flatPickr
  },
	data() {
    return {
      page: 1,
      showPaging: false,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      today: new Date(),
      calendarConfig: configs,
		}
	},
  created() {
    
  }
}
</script>

<style scoped>
  .btn-add-fcost {
    float: right;
    margin-top: -6px;
  }
</style>