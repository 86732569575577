import API from "@/config/api.js";
import {API_FORM} from "@/config/api.js";
// import moment from 'moment'


class CategoryService {

	saveCategory(data) {
		return API.post('category/add', {
			category_name: data.category_name,
			symbol: data.symbol,
			description: data.description,
			image_link: data.image_link
		})
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

	uploadImage(formdata) {
		return API_FORM.post('common/upload_image', formdata)
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

	getListData(page, size) {
    let size_text = size !== undefined ? "&size="+size : "";
    return API.get(`category/?page=${page}` + size_text)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	searchCategory(data) {
    return API.get(`category/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	deleteCategory(partRegId) {
    return API.delete(`category/delete/${partRegId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	getCategoryInfo(categoryId) {
		return API.get(`category/${categoryId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

  getCategory(id) {
    return API.get(`category/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
  }

  updateCategory(data) {
    return API.put(`category/edit/${data.id}`, {
      id: data.id,
      category_name: data.category_name,
      symbol: data.symbol,
      description: data.description,
      image_link: data.image_link
      // updated_date: moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }
}

export default new CategoryService();