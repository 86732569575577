import AccountService from '../services/account.service';

const getDefaultState = () => {
	return {
		username: "",
		first_name: "",
		last_name: "",
		email: "",
		company: "",
		phone: "",
		address: "",
		avatar_url: "",
	}
}

export const accountProfile = {
	namespaced: true,
	state: {
		stateData: getDefaultState()
	},
	actions: {
		resetState({commit}) {
			commit("resetState");
		},
		uploadImage({commit},formdata) {
			return AccountService.uploadImage(formdata).then(
				(response) => {
					commit('uploadSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit("uploadFailure");
					return Promise.reject(error);
				}
			);
		},
		saveAccountProfile({commit}, data) {
			return AccountService.saveAccountProfile(data).then(
				(response) => {
					return Promise.resolve(response);
				},
				(error) => {
					commit("saveAccountProfileFailure");
					return Promise.reject(error);
				}
			);
		},
	},
	mutations: {
		resetState (state) {
			console.log("run resetState");
			Object.assign(state.stateData, getDefaultState());
		},
		uploadSuccess(state, response) {
			state.stateData.avatar_url = response.image_names[0];
		},
		uploadFailure(state) {
			state.stateData.avatar_url = "";
		},
		saveAccountProfileFailure(state) {
			console.log("run saveAccountProfileFailure");
			Object.assign(state.stateData, getDefaultState());
		}
	},
	getters: {
	}
}