let utilities = {
  getHash (input) {
    let hash = 0, len = input.length;
    for (let i = 0; i < len; i++) {
      hash  = ((hash << 5)) + input.charCodeAt(i);
      hash |= 0; // to 32bit integer
    }
    return Math.abs(hash).toString() + new Date().getTime().toString();
  },
  generateRandomLetter (string, size) {
    let result = ""
    for (let i = 0; i < size; i++) {
      result += string[Math.floor(Math.random() * string.length)];
    }
    return result;
  },
  calculateTotalAmount(quantiy, price, tax_1=null, tax_2=null, tax_3=null) {
    let result = null;
    if (quantiy && price) {
      result = quantiy * price;
      if (tax_1) {
        result += result * (tax_1 / 100)
      }
      if (tax_2) {
        result += result * (tax_2 / 100)
      }
      if (tax_3) {
        result += result * (tax_3 / 100)
      }
    }
    return result;
  },
  getFormatCurrency(value) {
    return Intl.NumberFormat().format(value);
  },
  getFormatCurrencyText(value) {
    return value.toUpperCase();
  },
  getAvatarText(text) {
    if (!text) return "";
    return text.substr(0, 2);
  },
  generatePassword(pass_length=10) {
    let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$*ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = pass_length;
    let password_generated = "";
    for (let i = 0; i <= passwordLength; i++) {
      let randomNumber = Math.floor(Math.random() * chars.length);
      password_generated += chars.substring(randomNumber, randomNumber +1);
    }
    return password_generated;
  }
}

export {utilities}