<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Invoice Code Registration</span>
    </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <div class="row ms-2 me-3">
                <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                  <div class="dataTables_length" id="DataTables_Table_0_length">
                    <div class="dt-buttons">
                      <router-link to="/invoice-registration/add" data-i18n="Create Definition" class="dt-button btn btn-primary">
                        <i class="bx bx-plus me-md-2"></i>
                        Create New
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <div class="input-group input-group-merge">
                    <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
                    <!-- <input type="text" class="form-control" placeholder="Search invoice code" aria-label="Search..." aria-describedby="basic-addon-search31"> -->
                    <input 
                      type="text" 
                      class="form-control" 
                      placeholder="Search invoice code, name" 
                      v-model="keyword"
                      @change="onChangeSearch(page, keyword)"
                    >
                  </div>
                </div>
              </div>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Invoice Code</th>
                    <th>Supplier</th>
                    <th>Customer</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0">
                  <tr v-for="(item, index) in listItems" :key="index">
                    <td class="width-20-percent">
                      <router-link 
                        :to='getInvoiceLink(item.invoice_code)' 
                        data-i18n="Create Definition" 
                        class="text-body me-3"
                        v-if="!item.supplier_id"
                      >
                        <strong>{{item.invoice_code}}</strong>
                      </router-link>
                      <strong v-else>{{item.invoice_code}}</strong>
                    </td>
                    <td class="width-35-percent">
                      <div>
                        <span v-tooltip="item.supplier_name">
                          <strong>{{ item.supplier_name }}</strong>
                        </span>
                      </div>
                    </td>
                    <td class="width-35-percent">
                      <div>
                        <span v-tooltip="item.customer_name">
                          <strong>{{ item.customer_name }}</strong>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <router-link :to='"/invoice-registration/edit/" + item.id' data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a class="text-body icon-delete" @click="deleteInvoiceRegistration(item.id)">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <div class="row mx-2">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <common-paging 
                    :items="listItems" 
                    :totalItems="totalItems" 
                    @changePage="onChangePage"
                    @changeSearchPage="onChangeSearch"
                    :activePageId="page"
                    v-show="showPaging"
                    :isSearch="isSearchInvoiceCode"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card-body"></div> -->
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import commonPaging from "../common/CommonPaging"
import {errorAlert, deletedAlert} from "../../helper/error.js"
import showConfirmDeleteAlert from "../../helper/error.js"
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'


export default {
  name: 'InvoiceRegistrationList',
  components: {
    commonPaging,
    RiseLoader
  },
  data(){
    return {
      page: 1,
      showPaging: false,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      keyword: "",
      isSearchInvoiceCode: false
    }
  },
  computed: {
    ...mapState({
      listItems: state => state.invoiceRegistrationList.stateData.listItems,
      totalItems: state => state.invoiceRegistrationList.stateData.totalItems
    })
  },
  beforeCreate() {
    this.page = 1;
  },
  created() {
    this.$store.dispatch('invoiceRegistrationList/getListData').then(
      () => { 
        this.showPaging = true;
        this.isSearchInvoiceCode = false;
      },
      (error) => {
        console.log(error);
        this.showPaging = false;
        errorAlert("error","Network Error",'Can not get invoice registration list data!');
      }
    );
  },
  methods: {
    onChangePage(page, wantCallEmit=true) {
      this.page = page;
      this.isLoading = true;
      this.isSearchInvoiceCode = false;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('invoiceRegistrationList/getListData', page).then(
        () => { 
          this.isLoading = false;
          this.showPaging = true;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showPaging = false;
          errorAlert("error", "Network Error", "Can not get invoice registration list data!");
        }
      );
    },
    onChangeSearch(page, searchKey, wantCallEmit=true) {
      if(!this.keyword) {
        this.onChangePage(1);
        return true;
      }
      this.isLoading = true;
      this.isSearchInvoiceCode = true;
      this.page = page;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('invoiceRegistrationList/searchInvoiceCode', {"page": page, "searchKey":  this.keyword})
      .then(
        () => { 
          this.isLoading = false;
          this.showPaging = this.totalItems > 0 ? true : false;
        },
        (error) => {
          this.isLoading = false;
          this.showPaging = false;
          console.log(error);
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    deleteInvoiceRegistration(invoiceRegId) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;
          this.$store.dispatch('invoiceRegistrationList/deleteInvoiceCode', invoiceRegId).then(
            () => { 
              this.isLoading = false;
              this.showPaging = true;
              deletedAlert();
              this.isSearchInvoiceCode = false;
              if (this.keyword) {
                this.onChangeSearch(1);
              } else {
                this.onChangePage(1);
              }
              
            },
            (error) => {
              this.isLoading = false;
              this.showPaging = false;
              let resp = error.response;
              if (resp.status == 406 || resp.status == 404){
                errorAlert("error", "Delete Not Allowed", resp.data.detail);
              }
              else {
                errorAlert("error", "Network Error", "Failed to deleted invoice registration code!");
              }
            }
          );
        }
      )
    },
    getInvoiceLink (code) {
      return "/invoice/add/?invoice_code=" + code;
    }
  }
}
</script>

<style>
  a.icon-delete {
    cursor: pointer;
  }
  a.icon-delete:hover {
    color: red !important;
  }
</style>