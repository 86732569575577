import API from "@/config/api.js";
import moment from 'moment'

class InvoiceRegistrationService {
  
  getListData(page) {
    return API.get(`invoice-registration/?page=${page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  searchInvoiceCode(data) {
    return API.get(`invoice-registration/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  deleteInvoiceCode(partRegId) {
    return API.delete(`invoice-registration/delete/${partRegId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getLatestCode() {
    return API.get('invoice-registration/get-latest')
    .then((response) => {
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
  }

  initConsectiveNo(data){
    let today = new Date()
    let flag = moment(data.date).isSame(today, 'day');
    let result = "01"
    if (flag) {
      let num = data.consective_no;
      return ("0" + (parseInt(num) + 1)).slice(-2);
    }
    return result;
  }

  saveCode(data) {
    return API.post('invoice-registration/add', {
      invoice_code: data.invoice_code,
      prefix: data.prefix,
      vcj: data.vcj,
      date: data.date,
      consective_no: data.consective_no
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  updateCode(data) {
    return API.put(`invoice-registration/edit/${data.id}`, data)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getInvoiceRegCode(id) {
    return API.get(`invoice-registration/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
  }
}

export default new InvoiceRegistrationService();