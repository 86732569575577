import ShipmentService from '../services/shipment.service';
import moment from 'moment'
import _ from 'lodash';

let today = new Date();
const getDefaultState = () => {
  return {
    contract_id: "",
    contract_code_id: "",
    contract_code: "",
    invoice_code_id: "",
    invoice_code: "",
    invoice_date: moment(today).format("YYYY-MM-DD"),
    shipment_date: moment(today).format("YYYY-MM-DD"),
    inbound_date: moment(today).format("YYYY-MM-DD"),
    country_origin: "COUNTRY OF ORIGIN content",
    mean_transport: "MEANS OF TRANSPORT content",
    hawb_no: "HAWB NO content",
    port_landing: "PORT OF LOADING content",
    port_discharge: "PORT OF DISCHARGE content",
    for_account: "",
    risks_messrs: "",
    supplier_id: "",
    supplier_code: "",
    customer_id: "",
    customer_code: "",
    buy_currency: "",
    buy_exchange_rates: "",
    sell_currency: "",
    sell_exchange_rates: "",
    grand_total_buy_price: "",
    grand_total_buy_text: "",
    grand_total_sell_price: "",
    grand_total_sell_text: "",
    balance: null,
    invoice_detail_list: [],
    list_products: [],
    file_link_1: "",
    file_link_2: "",
    file_link_3: "",
    file_link_4: "",
    supplierInfo: {},
    customerInfo: {},
    receiving_voucher_date: moment(today).format("YYYY-MM-DD"),
    delivery_voucher_date: moment(today).format("YYYY-MM-DD"),
    delivery_voucher_no: "PXK-" + moment(today).format("YYMMDDhhmmss") + _.random(100, 999),
    receiving_voucher_no: "PNK-" + moment(today).format("YYMMDDhhmmss") + _.random(100, 999),
    error_lists: []
  }
}

export const shipmentAdd = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    resetState({commit}) {
      commit("resetState");
    },
    searchContract({commit}, {"page": page, "searchKey": searchKey}) {
      return ShipmentService.searchContract({"page": page, "searchKey": searchKey})
      .then(
        (response) => {
          // commit('searchProductSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('searchProductFailure');
          return Promise.reject(error);
        }
      );
    },
    getPartnerInfo ({commit}, id="") {
      return ShipmentService.getPartnerInfo(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('getPartnerFailure');
          return Promise.reject(error);
        }
      );
    },
    searchInvoiceRegByCode({commit}, {"page": page, "searchKey": searchKey}) {
      return ShipmentService.searchInvoiceRegByCode({"page": page, "searchKey": searchKey})
      .then(
        (response) => {
          commit('searchInvoiceRegSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('searchInvoiceRegFailure');
          return Promise.reject(error);
        }
      );
    },
    getContractInfo({commit}, id="") {
      return ShipmentService.getContractInfo(id).then(
        (response) => {
          commit('getContractSuccess', response);
          // return Promise.resolve(response);
        },
        (error) => {
          commit('getContractFailure');
          return Promise.reject(error);
        }
      );
    },
    getShipmentQuantityRemain({commit}, id="") {
      return ShipmentService.getShipmentQuantityRemain(id).then(
        (response) => {
          commit('getShipmentQuantityRemainSuccess', response);
          // return Promise.resolve(response);
        },
        (error) => {
          commit('getShipmentQuantityRemainFailure');
          return Promise.reject(error);
        }
      );
    },
    saveShipment({commit}, data) {
      return ShipmentService.saveShipment(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveShipmentFailure");
          return Promise.reject(error);
        }
      );
    },
    uploadShipmentFile({commit},formdata) {
			return ShipmentService.uploadShipmentFile(formdata).then(
				(response) => {
					commit('uploadSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit("uploadFailure");
					return Promise.reject(error);
				}
			);
		},
  },
  mutations: {
    resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState())
    },
    searchInvoiceRegSuccess(state, response) {
			if(response.listItems.length > 0) {
        state.stateData.invoice_code_id = response.listItems[0].id;
        state.stateData.invoice_code = response.listItems[0].invoice_code;
			}
		},
		searchInvoiceRegFailure(state) {
			console.log("run searchInvoiceRegFailure");
			state.stateData.contract_code_id = "";
			state.stateData.contract_code = "";
		},
    getContractSuccess(state, response) {
      let res = JSON.parse(JSON.stringify(response));
      state.stateData = _.merge(state.stateData, response)
      let listProducts = res.contract_detail_list
      state.stateData.list_products = listProducts;
      
		},
		getContractFailure(state) {
			Object.assign(state.stateData, getDefaultState())
		},
    getShipmentQuantityRemainSuccess(state, response) {
      let delivery_lists = response.delivery_voucher_lists;
      let receiving_lists = response.receiving_voucher_lists;
      
      if(delivery_lists.length > 0 && receiving_lists.length > 0) {
        if (state.stateData.list_products.length > 0){
          state.stateData.list_products.forEach((item) => {
            let quantity_in = 0;
            let quantity_out = 0;
            receiving_lists.forEach(receiving => {
              if (receiving.product_id == parseInt(item.product_id)) {
                quantity_in += receiving.quantity_in;
              }
            });
            let quantity_in_remain = (parseInt(item.quantity_in) - quantity_in) <= 0 ? 0 : (parseInt(item.quantity_in) - quantity_in);
            item["quantity_in_contract"] = item["quantity_in"];
            item["quantity_in"] = quantity_in_remain;
            item["quantity_in_progressive"] = quantity_in;
            delivery_lists.forEach(receiving => {
              if (receiving.product_id == parseInt(item.product_id)) {
                quantity_out += receiving.quantity_out;
              }
            });
            let quantity_out_remain = (parseInt(item.quantity_out) - quantity_out) <= 0 ? 0 : (parseInt(item.quantity_out) - quantity_out);
            item["quantity_out_contract"] = item["quantity_out"];
            item["quantity_out"] = quantity_out_remain;
            item["quantity_out_progressive"] = quantity_out;
          });
          
        }
      }
      else {
        state.stateData.list_products.forEach((item) => {
          item["quantity_in_progressive"] = 0;
          item["quantity_out_progressive"] = 0;
        })
      }
    },
    uploadSuccess(state, response) {
      if (response.el_name == "file_1"){
        state.stateData.file_link_1 = response.image_names[0];
      }
			if (response.el_name == "file_2"){
        state.stateData.file_link_2 = response.image_names[0];
      }
      if (response.el_name == "file_3"){
        state.stateData.file_link_3 = response.image_names[0];
      }
      if (response.el_name == "file_4"){
        state.stateData.file_link_4 = response.image_names[0];
      }
		},
		uploadFailure(state) {
			state.stateData.file_link_1 = "";
      state.stateData.file_link_2 = "";
      state.stateData.file_link_3 = "";
      state.stateData.file_link_4 = "";
		},
  },
  getters: {

  }
}