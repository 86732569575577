import AccountService from '../services/account.service';


const getDefaultState = () => {
	return {
		currentPage: 1,
		totalItems: 0,
		totalPages: 0,
		listItems: []
	}
}

export const accountList = {
	namespaced: true,
  state: {
    stateData: getDefaultState()
  },
	actions: {
		getListData({commit}, page=1) {
      return AccountService.getListData(page).then(
        (response) => {
          commit('getSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getFailure');
          return Promise.reject(error);
        }
      );
    },
		searchAccount({commit}, {"page": page, "searchKey": searchKey}) {
      return AccountService.searchAccount({"page": page, "searchKey": searchKey}).then(
        (response) => {
          commit('getSearchSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getSearchFailure');
          return Promise.reject(error);
        }
      );
    },
		deleteAccount ({commit}, id="") {
      return AccountService.deactivateAccount(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('deleteFailure');
          return Promise.reject(error);
        }
      );
    },
    restoreAccount ({commit}, id="") {
      return AccountService.restoreAccount(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('restoreAccountFailure');
          return Promise.reject(error);
        }
      );
    },
		getAccountInfo ({commit}, id="") {
      return AccountService.getAccountProfile(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('getAccountInfoFailure');
          return Promise.reject(error);
        }
      );
    },
    addNewAccount({commit}, data) {
			return AccountService.addNewAccount(data).then(
				(response) => {
					return Promise.resolve(response);
				},
				(error) => {
					commit("addNewAccountFailure");
					return Promise.reject(error);
				}
			);
		},
    resetAccountPassword ({commit}, id="") {
      return AccountService.resetAccountPassword(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('resetAccountPasswordFailure');
          return Promise.reject(error);
        }
      );
    },
	},
	mutations: {
		getSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getFailure(state) {
      console.log("run getFailure");
      Object.assign(state.stateData, getDefaultState())
    },
		getSearchSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getSearchFailure() {
      console.log("run getSearchFailure");
    },
		deleteSuccess () {
      console.log("run deleteSuccess");
    },
    deleteFailure() {
      console.log("run deleteFailure");
    },
    getAccountInfoFailure() {
      console.log("run getAccountInfoFailure");
    },
    restoreAccountFailure() {
      console.log("run restoreAccountFailure");
    },
    addNewAccountFailure() {
      console.log("run addNewAccountFailure");
    },
    resetAccountPasswordFailure() {
      console.log("run resetAccountPasswordFailure");
    }
	},
	getters: {
	}
}