import API from "@/config/api.js";
import moment from 'moment'
import CategoryService from "./category.service";
import SubCategoryService from "./subCategory.service"

class ProductRegistrationService {
  
  getListData(page) {
    return API.get(`product-registration/?page=${page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  searchProductCode(data) {
    return API.get(`product-registration/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  deleteProductCode(producrRegId) {
    return API.delete(`product-registration/delete/${producrRegId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getLatestCode() {
    return API.get('product-registration/get-latest')
    .then((response) => {
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
  }

  initConsectiveNo(data){
    let today = new Date()
    let flag = moment(data.date).isSame(today, 'day');
    let result = "01"
    if (flag) {
      let num = data.consective_no;
      return ("00" + (parseInt(num) + 1)).slice(-2);
    }
    return result;
  }

  getCategoryList(page, size) {
    return CategoryService.getListData(page, size);
  }

  getSubCategoryList(categoryId) {
    return SubCategoryService.getListSubCategoryByCategoryId(categoryId);
  }

  saveCode(data) {
    return API.post('product-registration/add', data)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  updateCode(data) {
    return API.put(`product-registration/edit/${data.id}`, {
      id: data.id,
      product_code: data.product_code,
      category_id: data.category_id,
      sub_category_id: data.sub_category_id,
      date: data.date,
      consective_no: data.consective_no,
      quality_control_status: data.quality_control_status,
      updated_dated: moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getProductRegCode(id) {
    return API.get(`product-registration/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
  }

  generateCode(dataObject) {
    if (!Object.hasOwn(dataObject, "select_pay_receive") | 
      !Object.hasOwn(dataObject, "select_customer") |
      !Object.hasOwn(dataObject, "consective_no") |
      !Object.hasOwn(dataObject, "date")) {
      return false;
    }
    let selected_date = moment(dataObject.date).format("YYMMDD");
    return dataObject.select_pay_receive + dataObject.select_customer + 
      selected_date +"-" + dataObject.consective_no;
  }
}

export default new ProductRegistrationService();