<template>
  <!-- Content -->
  <div class="row col-md-12 m-0 mt-2 p-2">
    <div class="col-md-6 col-6 mb-3 px-1 pe-2">
      <p class="mb-2">Product Name</p>
      <span class="form-control disabled">
        {{ productInfo.product_name }}
      </span>
    </div>
    <div class="col-md-3 col-3 mb-3">
      <p class="mb-2 repeater-title">Product Code</p>
      <input type="text" 
        class="form-control invoice-item-price" 
        v-model="productInfo.product_code"
        disabled
      >
    </div>
    <div class="col-md-3 col-3"></div>

    <div class="col-md-3 col-3 px-1">
      <p class="mb-2 repeater-title">Quantity In</p>
      <cleave class="form-control invoice-item-price mb-3 edit-input" 
        placeholder="100" style="text-align:right;"
        v-model="productInfo.quantity_in"
        :options="cleaveOptions.quantity_in"
      />
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Product Unit</p>
      <span type="text" class="form-control" style="background-color: #eceef1;">{{ getProductUnitName(productInfo.product_unit) }}&nbsp;</span>
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Quantity Out</p>
      <cleave class="form-control invoice-item-price mb-3 edit-input" 
        placeholder="100" 
        style="text-align:right;"
        v-model="productInfo.quantity_out"
        :options="cleaveOptions.quantity_out"
      />
      <div class="error-msg" v-if="stateData.error_lists.length > 0"> 
        {{ stateData.error_lists[0].message}}
      </div>
    </div>
    <div class="col-md-3 col-3 pe-1">
      <p class="mb-2 repeater-title">Product Unit</p>
      <span type="text" class="form-control"  style="background-color: #eceef1;">{{ getProductUnitName(productInfo.product_unit) }}&nbsp;</span>
    </div>
    <div class="col-md-3 col-3 px-1">
      <p class="mb-2 repeater-title">Buy Price</p>
      <div class="input-group mb-3">
        <span class="input-group-text px-2 disabled">{{ stateData.buy_currency }}</span>
        <span class="form-control px-1 disabled" 
          style="text-align:right;">
          {{ getFormatCurrency(productInfo.buy_price) }}
        </span>
      </div>
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Buy Price in VND</p>
      <div class="input-group">
        <span class="input-group-text px-2 disabled">VND</span>
        <span class="form-control px-1 disabled" 
          style="text-align:right;">
          {{ getFormatCurrency(productInfo.buy_price_vnd) }}
        </span>
      </div>
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Sell Price</p>
      <div class="input-group mb-3">
        <span class="input-group-text px-2 disabled">{{ stateData.sell_currency }}</span>
        <span class="form-control px-1 disabled" 
          style="text-align:right;">
          {{ getFormatCurrency(productInfo.sell_price) }}
        </span>
      </div>
    </div>
    <div class="col-md-3 col-3 pe-1">
      <p class="mb-2 repeater-title">Sell Price in VND</p>
      <div class="input-group">
        <span class="input-group-text px-2 disabled">VND</span>
        <span class="form-control px-1 disabled" 
          style="text-align:right;">
          {{ getFormatCurrency(productInfo.sell_price_vnd) }}
        </span>
      </div>
    </div>

    <div class="row col-md-6 col-6 px-2 pe-0">
      <p class="mb-2 repeater-title">Buy TAX</p>
      <div class="col-md-4 col-4 px-1 mx-1">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_1.name"
            disabled
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-2 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_1.value"
            :options="cleaveOptions.tax"
            disabled
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-1" style="margin-left: -0.5rem;">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_2.name"
            disabled
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-2 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_2.value"
            :options="cleaveOptions.tax"
            disabled
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-0">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_3.name"
            disabled
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-2 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_3.value"
            :options="cleaveOptions.tax"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="row col-md-6 col-6 px-0 pe-0" style="margin-left: 1.6rem;">
      <p class="mb-2 repeater-title">Sell TAX</p>
      <div class="col-md-4 col-4 px-1" style="margin-left: 4px;">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_sell_1.name"
            disabled
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-2 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_sell_1.value"
            :options="cleaveOptions.tax"
            disabled
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-1" style="margin-left: -0.3rem;">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_sell_2.name"
            disabled
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-2 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_sell_2.value"
            :options="cleaveOptions.tax"
            disabled
          />
        </div>
      </div>
      <div class="col-md-4 col-4 px-0">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_sell_3.name"
            disabled
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-2 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_sell_3.value"
            :options="cleaveOptions.tax"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import { TAX_CONFIG, PRODUCT_UNIT } from "../../config/common.js"
import { utilities } from "../../services/utilities.js"

export default {
  name: 'ProductDetail',
  components: {
  },
  props: {
    "product": {
			type: Object,
			required: true
		},
    "index": {
      type: Number,
			required: true
    }
  },
  emits :{
    removeOneProduct: null,
    changeAmountValue: null
  },
  data() {
    return {
      productInfo: this.product,
      key: this.index,
      page: 1,
      productSelected: null,
      cleaveOptions: {
        buy_price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        sell_price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        purchase_total_amount: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        },
        sell_total_amount: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        },
        quantity_in: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        quantity_out: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        tax: {
          numeral: true,
          numeralDecimalScale: 1
        }
      }
    }
  },
  watch: {
    "productInfo.quantity_in" (newVal) {
      if (!newVal) newVal = 0;
      let qty_in_now = parseInt(newVal) + parseInt(this.productInfo.quantity_in_progressive);
      let qty_out_now = parseInt(this.productInfo.quantity_out) + parseInt(this.productInfo.quantity_out_progressive);
      if (qty_out_now > qty_in_now ){
        let error = {
          "id": this.productInfo.product_id,
          "message": "Product quantity of delivery voucher is over the quantity of receiving voucher."
        }
        this.stateData.error_lists.push(error);
      }
      else {
        this.stateData.error_lists = this.stateData.error_lists.filter((item) => {
          return item.id !== this.productInfo.product_id;
        });
      }
      this.productInfo.purchase_total_amount = 
        utilities.calculateTotalAmount(
          newVal,
          this.productInfo.buy_price, 
          this.productInfo.tax_buy_1.value,
          this.productInfo.tax_buy_2.value,
          this.productInfo.tax_buy_3.value
        );
    },
    "productInfo.quantity_out" (newVal) {
      if (!newVal) newVal = 0;
      this.productInfo.quantity_out = newVal;
      let qty_in_now = parseInt(this.productInfo.quantity_in) + parseInt(this.productInfo.quantity_in_progressive);
      let qty_out_now = parseInt(newVal) + parseInt(this.productInfo.quantity_out_progressive);
      if (qty_out_now > qty_in_now ){
        let error = {
          "id": this.productInfo.product_id,
          "message": "Product quantity of delivery voucher is over the quantity of receiving voucher."
        }
        this.stateData.error_lists.push(error);
      }
      else {
        this.stateData.error_lists = this.stateData.error_lists.filter((item) => {
          return item.id !== this.productInfo.product_id;
        });
      }
      this.productInfo.sell_total_amount = 
        utilities.calculateTotalAmount( 
          newVal,
          this.productInfo.sell_price,
          this.productInfo.tax_sell_1.value,
          this.productInfo.tax_sell_2.value,
          this.productInfo.tax_sell_3.value
        );
    },
    "productInfo.tax_buy_1.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_buy_1.value = null;
        this.productInfo.tax_buy_1.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_buy_1.value = query[0].value;
      this.productInfo.tax_buy_1.id = query[0].id;
      if (!this.productInfo.tax_buy_value_1) {
        this.productInfo.tax_buy_1.value = query[0].value;
      }
    },
    "productInfo.tax_buy_1.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.purchase_total_amount = 
        utilities.calculateTotalAmount(
          this.productInfo.quantity_in,
          this.productInfo.buy_price, 
          tax_value,
          this.productInfo.tax_buy_2.value,
          this.productInfo.tax_buy_3.value
        );
    },
    "productInfo.tax_buy_2.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_buy_2.value = null;
        this.productInfo.tax_buy_2.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_buy_2.value = query[0].value;
      this.productInfo.tax_buy_2.id = query[0].id;
      if (!this.productInfo.tax_buy_value_2) {
        this.productInfo.tax_buy_2.value = query[0].value;
      }
    },
    "productInfo.tax_buy_2.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.purchase_total_amount = 
        utilities.calculateTotalAmount(
          this.productInfo.quantity_in,
          this.productInfo.buy_price, 
          this.productInfo.tax_buy_1.value,
          tax_value,
          this.productInfo.tax_buy_3.value
        );
    },
    "productInfo.tax_buy_3.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_buy_3.value = null;
        this.productInfo.tax_buy_3.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_buy_3.value = query[0].value;
      this.productInfo.tax_buy_3.id = query[0].id;
      if (!this.productInfo.tax_buy_value_3) {
        this.productInfo.tax_buy_3.value = query[0].value;
      }
    },
    "productInfo.tax_buy_3.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.purchase_total_amount = 
        utilities.calculateTotalAmount(
          this.productInfo.quantity_in,
          this.productInfo.buy_price, 
          this.productInfo.tax_buy_1.value,
          this.productInfo.tax_buy_2.value,
          tax_value
        );
    },
    "productInfo.tax_sell_1.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_sell_1.value = null;
        this.productInfo.tax_sell_1.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_sell_1.value = query[0].value;
      this.productInfo.tax_sell_1.id = query[0].id;
      if (!this.productInfo.tax_sell_value_1) {
        this.productInfo.tax_sell_1.value = query[0].value;
      }
    },
    "productInfo.tax_sell_1.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.sell_total_amount = 
        utilities.calculateTotalAmount( 
          this.productInfo.quantity_out,
          this.productInfo.sell_price,
          tax_value,
          this.productInfo.tax_sell_2.value,
          this.productInfo.tax_sell_3.value
        );
    },
    "productInfo.tax_sell_2.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_sell_2.value = null;
        this.productInfo.tax_sell_2.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_sell_2.value = query[0].value;
      this.productInfo.tax_sell_2.id = query[0].id;
      if (!this.productInfo.tax_sell_value_2) {
        this.productInfo.tax_sell_2.value = query[0].value;
      }
    },
    "productInfo.tax_sell_2.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.sell_total_amount = 
        utilities.calculateTotalAmount( 
          this.productInfo.quantity_out,
          this.productInfo.sell_price,
          this.productInfo.tax_sell_1.value,
          tax_value,
          this.productInfo.tax_sell_3.value
        );
    },
    "productInfo.tax_sell_3.name" (newVal) {
      if (!newVal) {
        this.productInfo.tax_sell_3.value = null;
        this.productInfo.tax_sell_3.id = null;
        return;
      }
      let query = this.taxOptions.filter(item => item.symbol == newVal);
      // this.productInfo.tax_sell_3.value = query[0].value;
      this.productInfo.tax_sell_3.id = query[0].id;
      if (!this.productInfo.tax_sell_value_3) {
        this.productInfo.tax_sell_3.value = query[0].value;
      }
    },
    "productInfo.tax_sell_3.value" (newVal) {
      let tax_value = !newVal ? 0: newVal;
      this.productInfo.sell_total_amount = 
        utilities.calculateTotalAmount( 
          this.productInfo.quantity_out,
          this.productInfo.sell_price,
          this.productInfo.tax_sell_1.value,
          this.productInfo.tax_sell_2.value,
          tax_value
        );
    },
    "productInfo.purchase_total_amount" (newVal) {
      if (!newVal) return;
      this.$emit('changeAmountValue', newVal);
    },
    "productInfo.sell_total_amount" (newVal) {
      if (!newVal) return;
      this.$emit('changeAmountValue', newVal);
    },
    "productSelected" (newVal) {
      if (!newVal) return;
      this.productInfo.product_id = newVal.id;
      this.productInfo.product_code = newVal.product_code;
      this.productInfo.product_code_id = newVal.product_code_id;
      this.productInfo.product_unit = newVal.product_unit
    },
    "stateData.buy_exchange_rates" (newVal) {
      if (!newVal) return;
      this.productInfo.buy_price_vnd = this.productInfo.buy_price * newVal;
      this.$emit('changeAmountValue', newVal);
    },
    "stateData.sell_exchange_rates" (newVal) {
      if (!newVal) return;
      this.productInfo.sell_price_vnd = this.productInfo.sell_price * newVal;
      this.$emit('changeAmountValue', newVal);
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.shipmentAdd.stateData
    }),
    taxOptions() {
      return TAX_CONFIG;
    },
    productUnits() {
      return PRODUCT_UNIT;
    }
  },
  created() {
    // Handling Product Name selected
    for (let i=1; i<=3; i++) {
      if(this.productInfo["tax_buy_name_"+i]) {
        this.productInfo["tax_buy_"+i] = {
          id: i,
          name: this.productInfo["tax_buy_name_"+i],
          value: this.productInfo["tax_buy_value_"+i],
          is_display: true, 
        }
      }
      else {
        this.productInfo["tax_buy_"+i] = {
          id: i,
          name: "",
          value: "",
          is_display: true, 
        }
      }

      if(this.productInfo["tax_sell_name_"+i]) {
        this.productInfo["tax_sell_"+i] = {
          id: i,
          name: this.productInfo["tax_sell_name_"+i],
          value: this.productInfo["tax_sell_value_"+i],
          is_display: true, 
        }
      }
      else {
        this.productInfo["tax_sell_"+i] = {
          id: i,
          name: "",
          value: "",
          is_display: true, 
        }
      }
    }
  }, 
  mounted() {
    this.productInfo.buy_price_vnd = this.productInfo.buy_price * this.stateData.buy_exchange_rates;
    this.productInfo.sell_price_vnd = this.productInfo.sell_price * this.stateData.sell_exchange_rates;
  },
  methods: {
    getProductUnitName(unit) {
      if(unit == undefined) return "";
      let punit = this.productUnits.filter(item => {
        return item.value == unit;
      });
      if(punit.length > 0) {
        return punit[0].name;
      }
      else {
        return "";
      }
    },
    getFormatCurrency(value) {
      return Intl.NumberFormat().format(value);
    }
  },
}
</script>

<style>
  .delete-item {
    padding: 0.2rem 0.37rem !important;
  }
  .disabled {
    background-color: #eceef1 !important;
  }
  .edit-input {
    background-color: lightyellow !important;
  }
</style>