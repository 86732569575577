<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Product List</span>
    </h4>

    <div class="row">
      <!-- Product List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="">
              <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                  <!-- <div class="dataTables_length" id="DataTables_Table_0_length">
                    <div class="dt-buttons">
                      <router-link to="/product/add" data-i18n="Create Definition" class="dt-button btn btn-primary">
                        <i class="bx bx-plus me-md-2"></i>
                        Create New
                      </router-link>
                    </div>
                  </div> -->
                </div>
                <div class="col-12 col-md-5 mt-2 mb-4">
                  <div class="input-group input-group-merge">
                    <span class="input-group-text"><i class="bx bx-search"></i></span>
                    <input 
                      type="text" 
                      class="form-control" 
                      placeholder="Search product code, name, category, subcategory" 
                      v-model="keyword"
                      @change="onChangeSearch(page, keyword)"
                    >
                  </div>
                </div>
              </div>
            <table class="table table-hover">
              <thead>
                <tr>
                <th>Product</th>
                <th>Code</th>
                <th>Category</th>
                <th>SubCategory</th>
                <th>Actions</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr v-for="(item, index) in listItems" :key="index">
                  <td class="width-35-percent">
                    <div>
                      <span v-tooltip="item.product_name">
                        <strong>{{ item.product_name }}</strong>
                      </span>
                    </div>
                  </td>
                  <td class="width-25-percent">{{item.product_code}}</td>
                  <td class="width-15-percent">{{ item.category_name }}</td>
                  <td class="width-15-percent">{{ item.sub_category_name }}</td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" 
                        class="text-body me-3" 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasScroll"
                        @click="handleShowDetail(item.id)"
                      >
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link :to='"/product/edit/" + item.id' data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a class="text-body icon-delete" @click="deleteProduct(item.id)">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div class="row mx-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <common-paging 
                :items="listItems" 
                :totalItems="totalItems" 
                @changePage="onChangePage"
                @changeSearchPage="onChangeSearch"
                :activePageId="page"
                v-show="showPaging"
                :isSearch="isSearchProduct"
              />
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <!-- OffCanvas from the right -->
    <div class="row">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" 
        id="offcanvasScroll" aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility:visible; width:40%;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0 overflow-hidden1">
          <div class="user-avatar-section">
            <div class=" d-flex align-items-center flex-column">
              <div id="carouselExample" class="carousel slide w-75 mt-1" data-bs-ride="carousel" v-if="productDetail.image_link">
                <ol class="carousel-indicators">
                  <li data-bs-target="#carouselExample" 
                    :data-bs-slide-to="key1" 
                    v-for="(image1, key1) in JSON.parse(productDetail.image_link)" :key="key1"
                    :class="{active: getClassActive(key1)}"
                    class="carousel-image"
                  >
                  </li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item" v-for="(image, key) in JSON.parse(productDetail.image_link)" :key="key" :class="{active: getClassActive(key)}">
                    <img class="d-block w-75" :src='domain+image' alt="First slide" style="margin-left: 2.5rem;">
                  </div>
                </div>
              </div>
              <img class="img-fluid rounded my-1 img-sample" 
                :src='domain + "/upload/sample/sample.png"'
                alt="User avatar"
                v-else
              >
            </div>
          </div>
          <div class="d-flex justify-content-around flex-wrap my-1 py-1">
          </div>
          <h5 class="pb-2 border-bottom mb-4"></h5>
          <div class="info-container">
            <ul class="list-unstyled">
              <li class="mb-1">
                <span class="fw-bold">{{ productDetail.product_name }}</span>
              </li>
              <li class="mb-3">
                <span v-tooltip="'English Product Name'">
                  <a href="#"><strong>{{ "# " }}</strong></a>
                </span>
                <span class="fw-bold">{{ productDetail.product_name_en }}</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Code:</span>
                <span class="">{{ productDetail.product_code }}</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Category:</span>
                <span class="">{{ productDetail.category_name }}</span>
                &nbsp;&nbsp;&raquo;&nbsp;&nbsp;
                <span class="">{{ productDetail.sub_category_name }}</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Description:</span>
                <div class="col-sm-12 description-height">
                  {{ productDetail.description }}
                </div>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">VARIATION</span>
                <div class="col-sm-12 mb-2 mt-1" v-for="(variaton, key) in productDetail.list_variations_result" :key="key">
                  <span class="fw-bold me-3">{{ variaton.variation_name + ":"}}</span>
                  <span class="me-2">{{ getAttribute(variaton) }}</span>
                </div>
              </li>
            </ul>
            <router-link :to='"/product/edit/" + productDetail.id' data-i18n="Contract Registration" class="btn btn-primary w-45 mb-2 w-100 mt-3">
              Edit Product
            </router-link>
            <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
              <i class='bx bx-arrow-back' ></i>
              Back to List
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import commonPaging from "../common/CommonPaging"
import {errorAlert, deletedAlert} from "../../helper/error.js"
import showConfirmDeleteAlert from "../../helper/error.js"
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'


export default {
  name: 'ProductList',
  components: {
    commonPaging,
    RiseLoader
  },
  data(){
    return {
      page: 1,
      showPaging: false,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      keyword: "",
      isSearchProduct: false,
      productDetail: {},
      domain: process.env.VUE_APP_DOMAIN_FILE_SERVER,
    }
  },
  computed: {
    ...mapState({
      listItems: state => state.productList.stateData.listItems,
      totalItems: state => state.productList.stateData.totalItems
    }),
  },
  beforeCreate() {
    this.page = 1;
  },
  created() {
    this.$store.dispatch('productList/getListData').then(
      () => { 
        this.showPaging = true;
        this.isSearchProduct = false;
      },
      (error) => {
        console.log(error);
        this.showPaging = false;
        errorAlert("error","Network Error",'Can not get Product list data!');
      }
    );
  },
  methods: {
    onChangePage(page, wantCallEmit=true) {
      this.page = page;
      this.isLoading = true;
      this.isSearchProduct = false;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('productList/getListData', page).then(
        () => { 
          this.isLoading = false;
          this.showPaging = true;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showPaging = false;
          errorAlert("error", "Network Error", "Can not get product list data!");
        }
      );
    },
    onChangeSearch(page, searchKey, wantCallEmit=true) {
      if(!this.keyword) {
        this.onChangePage(1);
        return true;
      }
      this.isLoading = true;
      this.isSearchProduct = true;
      this.page = page;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('productList/searchProduct', {"page": page, "searchKey":  this.keyword})
      .then(
        () => { 
          this.isLoading = false;
          this.showPaging = this.totalItems > 0 ? true : false;
        },
        (error) => {
          this.isLoading = false;
          this.showPaging = false;
          console.log(error);
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    deleteProduct(productID) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;

          this.$store.dispatch('productList/deleteProduct', productID).then(
            () => { 
              this.isLoading = false;
              this.showPaging = true;
              deletedAlert();
              this.isSearchProduct = false;
              if (this.keyword) {
                this.onChangeSearch(1);
              } else {
                this.onChangePage(1);
              }
            },
            (error) => {
              this.isLoading = false;
              this.showPaging = false;
              let resp = error.response;
              if (resp.status == 406){
                errorAlert("error", "Delete Not Allowed", resp.data.detail);
              }
              else {
                errorAlert("error", "Network Error", "Failed to deleted product information!");
              }
              
            }
          );
        }
      )
    },
    handleShowDetail(productID) {
      this.isLoading = true;
      this.$store.dispatch('productList/getProductInfo', productID).then(
        (response) => { 
          this.isLoading = false;
          this.productDetail = response;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.productDetail = {};
          errorAlert("error","Network Error",'Can not get Product information !');
        }
      );
    },
    getClassActive (key) {
      return key == 0 ? "active" : "";
    },
    getAttribute(variation) {
      let list_attr = this.productDetail.list_attributes_result.map(item => {
        return variation.id == item.variation_id ? item.symbol : "";
      }).filter(ele => ele);

      return list_attr.join(", ")
    }
  }
}
</script>

<style>
  .img-sample {
    max-height: 160px;
  }
  .carousel-image {
    background-color: gray !important;
  }
  .carousel-indicators {
    bottom: -45px !important;
    left: 25px !important;
  }
</style>