<template>
  <div class="container-xxl">
    <div class="authentication-wrapper authentication-basic container-p-y">
      <div class="authentication-inner">
        <!-- Register Card -->
        <div class="card">
          <div class="card-body">
            <!-- Logo -->
            <div class="app-brand justify-content-center">
              <a href="/register" class="app-brand-link gap-2">
                <span class="app-brand-logo demo">
                  <img src="/assets/img/common/logo.png" width="150">
                </span>
              </a>
            </div>

            <form id="formAuthentication" class="mb-3" @submit.prevent="handleRegister">
              <div class="mb-3">
                <label for="username" class="form-label">Username</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your username"
                  autofocus
                  name="username"
                  v-model="user.username"
                />
                <div class="error-msg" v-if="v$.user.username.$error"> 
                  {{ v$.user.username.$errors[0].$message }}
                </div>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input 
                  type="text" 
                  class="form-control" 
                  placeholder="Enter your email" 
                  name="email" 
                  v-model="user.email"
                />
                <div class="error-msg" v-if="v$.user.email.$error"> 
                  {{ v$.user.email.$errors[0].$message }}
                </div>
              </div>
              <div class="mb-3 form-password-toggle">
                <label class="form-label" for="password">Password</label>
                <div class="input-group input-group-merge">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    name="password"
                    v-model="user.password"
                  />
                  <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                  <div class="error-msg" v-if="v$.user.password.$error"> 
                    {{ v$.user.password.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="mb-3 form-password-toggle">
                <label class="form-label" for="password">Password Confirm</label>
                <div class="input-group input-group-merge">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    name="password-confirm"
                    v-model="user.password_confirm"
                  />
                  <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                  <div class="error-msg" v-if="v$.user.password_confirm.$error"> 
                    {{ v$.user.password_confirm.$errors[0].$message }}
                  </div>
                </div>
              </div>

              <!-- <div class="mb-3">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="terms-conditions" name="terms" />
                  <label class="form-check-label" for="terms-conditions">
                    I agree to
                    <a href="javascript:void(0);">privacy policy & terms</a>
                  </label>
                </div>
              </div> -->
              <button class="btn btn-primary d-grid w-100 mt-5">Sign up</button>
            </form>

            <p class="text-center">
              <span>Already have an account?</span>
              <router-link to="/login" data-i18n="Sign in instead">
                Sign in instead
              </router-link>
            </p>
          </div>
        </div>
        <!-- Register Card -->
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, sameAs, email, minLength, helpers } from '@vuelidate/validators'


export default {
  name: 'Register',
  data() {
    return {
      v$: useValidate(),
      user: {
        username: "",
        password: "",
        password_confirm: "",
        email: ""
      },
      loading: false,
      message: '',
      submitted: false
    };
  },
  components: {
      
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    handleRegister () {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$store.dispatch('auth/register', this.user).then(
          () => {
            this.$router.push('/login');
          },
          error => {
            // this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      } 
    }
  },
  validations() {
    return {
      user: {
        username: { required: helpers.withMessage('Username cannot be empty', required)},
        password: { 
          required: helpers.withMessage('Password cannot be empty', required),
          minLength: helpers.withMessage('Minimum 8 letters', minLength(8))
        },
        password_confirm: { 
          required: helpers.withMessage('Password Confirm cannot be empty', required),
          sameAs: helpers.withMessage('The confirm password is not matched', sameAs(this.user.password)),
          minLength: helpers.withMessage('Minimum 8 letters', minLength(8))
        },
        email: { 
          required: helpers.withMessage('Email cannot be empty', required),
          email: helpers.withMessage('Wrong emaill format! ', email),
        }
      }
    }
  },
}
</script>

<style scoped>
  .error-msg {
    /* display: none; */
    width: 100%;
    margin-top: 0.3rem;
    font-size: 90%;
    color: #ff3e1d;
  }
</style>