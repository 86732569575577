<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Partner List</span>
    </h4>

    <div class="row">
      <!-- Partner List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
          <div class="text-nowrap1">
            <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                <div class="dataTables_length" id="">
                  <div class="dt-buttons">
                    <router-link to="/partner/add" data-i18n="Create Definition" class="dt-button btn btn-primary">
                      <i class="bx bx-plus me-md-2"></i>
                      Create New
                    </router-link>
                  </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-3">
              <div class="input-group input-group-merge">
                <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
                <input 
                  type="text" 
                  class="form-control" 
                  placeholder="Search partner code, name..." 
                  v-model="keyword"
                  @change="onChangeSearch(page, keyword)"
                >
                
              </div>
            </div>
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                <th>Partner</th>
                <th>Code</th>
                <th>Payment</th>
                <th>Term</th>
                <th>Closing time</th>
                <th>Actions</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr v-for="(item, index) in listItems" :key="index">
                  <td class="width-35-percent">
                    <div>
                      <span v-tooltip="item.partner_name">
                        <strong>{{ item.partner_name }}</strong>
                      </span>
                    </div>
                  </td>
                  <td class="width-20-percent">{{ item.partner_code }}</td>
                  <td>
                    <div>
                      {{ item.payment_method }}
                    </div>
                  </td>
                  <td>{{ item.term }}</td>
                  <td class="width-30-percent">
                    <div v-tooltip='getClosingTime(item.closing_time)+(item.closing_time == 7 ? " on " + item.exact_date : "")'
                    >
                      {{ getClosingTime(item.closing_time) }}
                      {{ item.closing_time == 7 ? " on " + item.exact_date : "" }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" 
                        class="text-body me-3" 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasScroll"
                        @click="handleShowDetail(item.id)"
                      >
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link :to='"/partner/edit/" + item.id' data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a class="text-body icon-delete" @click="deletePartner(item.id)">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div class="row mx-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <common-paging 
                  :items="listItems" 
                  :totalItems="totalItems" 
                  @changePage="onChangePage"
                  @changeSearchPage="onChangeSearch"
                  :activePageId="page"
                  v-show="showPaging"
                  :isSearch="isSearchPartnerCode"
                />
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <!-- OffCanvas from the right -->
    <div class="row">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" 
        id="offcanvasScroll" aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility: visible; width:50%;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0">
          <div class="d-flex flex-wrap my-1">
            <div class="col-sm-9">
              <span class="fw-bold">{{partnerDetail.partner_name}}</span>
            </div>
            <div class="col-sm-3">
              <span class="badge bg-label-danger" v-if="partnerDetail.type == 1">supplier</span>
              <span class="badge bg-label-warning" v-else-if="partnerDetail.type == 2">customer</span>
              <span class="badge bg-label-info" v-else>service</span>
            </div>
          </div>
          <h5 class="pb-2 border-bottom mb-3"></h5>
          <div class="info-container">
            <ul class="list-unstyled">
              <li class="mb-1">
                <span class="fw-bold me-2">Short Name:</span>
                <span class="">{{partnerDetail.partner_short_name}}</span>
              </li>
              <li class="mb-1">
                <span class="fw-bold me-2">Partner Code:</span>
                <span class="">{{partnerDetail.partner_code}}</span>
              </li>
              <li class="mb-1">
                <span class="fw-bold me-2">Person In Charge:</span>
                <span class="">{{partnerDetail.person_in_charge}}</span>
              </li>
              <li class="mb-1">
                <span class="fw-bold me-2">Email:</span>
                <span class="">{{partnerDetail.email}}</span>
              </li>
              <li class="mb-1">
                <span class="fw-bold me-2">Phone:</span>
                <span class="">{{partnerDetail.phone}}</span>
              </li>
              <li class="mb-1">
                <span class="fw-bold me-2">Address:</span>
                <span class="">
                  {{ partnerDetail.address ? partnerDetail.address + '&#44;' : "" }} 
                  {{ partnerDetail.ward_name ? partnerDetail.ward_name + '&#44;' : "" }}
                  {{ partnerDetail.district_name ? partnerDetail.district_name + '&#44;' : "" }}
                  {{ partnerDetail.city_name ? partnerDetail.city_name + '&#44;' : "" }}
                  {{ partnerDetail.country ? partnerDetail.country : "" }}
                </span>
                <!-- <br/> -->
                <span class="me-2" v-if="partnerDetail.zipcode">, zipcode: {{partnerDetail.zipcode}}</span>
              </li>
              <li class="mb-1">
                <span class="fw-bold me-2">Tax Code:</span>
                <span>{{partnerDetail.tax_code}}</span>
              </li>
              <li class="mt-2">
                <div class="col-md-12">
                  <div class="alert alert-warning" role="alert">
                    <div class="row">
                      <div class="">
                        <span class="alert-heading fw-bold">PAYMENT INFORMATION</span> <br />
                        <span class="fw-bold me-3">Payment method:</span>{{ getPaymentName(partnerDetail.payment_method) }} <br />
                        <div v-if="partnerDetail.has_deposit == 1">
                          <span class="fw-bold me-2">Deposit:</span>{{partnerDetail.percent_deposit}} % , &nbsp;&nbsp; 
                          <span class="fw-bold me-2">Balance:</span>{{partnerDetail.percent_balance}} % <br />
                          <span class="fw-bold me-3">Term :</span>{{ partnerDetail.term }} days <br />
                          <span class="fw-bold me-1">Closing Time : </span>{{ getClosingTime(partnerDetail.closing_time) }}
                          <span class="me-3" v-if="partnerDetail.closing_time == 7">( on date {{ partnerDetail.exact_date }} per month )
                          </span>
                        </div>
                        <div v-else>
                          <span class="fw-bold me-3">Term :</span>{{ partnerDetail.term }} days <br />
                          <span class="fw-bold me-1">Closing Time : </span>{{ getClosingTime(partnerDetail.closing_time) }}
                          <span class="me-3" v-if="partnerDetail.closing_time == 7">( on date {{ partnerDetail.exact_date }} per month )</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="col-md-12">
                  <div class="alert alert-primary" role="alert">
                    <div class="row">
                      <div class="">
                        <span class="alert-heading fw-bold">BANK INFORMATION</span> <br />
                        <span class="fw-bold me-3">Account Number:</span>{{partnerDetail.bank_account_number}} <br />
                        <span class="fw-bold me-3">Account Name:</span>{{partnerDetail.bank_account_name}}  <br />
                        <span class="fw-bold me-3">Bank Name:</span>{{ getBankName(partnerDetail.bank_name) }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row mt-2">
              <div class="col-md-6">
                <router-link :to='"/partner/edit/" + partnerDetail.id' 
                  data-i18n="Contract Registration" 
                  class="btn btn-primary w-100 mb-2 me-2"
                >
                  Edit Partner
                </router-link>
              </div>
              <div class="col-md-6">
                <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
                  <i class='bx bx-arrow-back' ></i>
                  Back to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import commonPaging from "../common/CommonPaging"
import {errorAlert, deletedAlert} from "../../helper/error.js"
import showConfirmDeleteAlert from "../../helper/error.js"
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import banks from "../../../public/assets/vendor/js/bank.js"
import { CLOSING_TIME, PAYMENT_METHOD } from "../../config/common.js"

export default {
  name: 'PartnerList',
  components: {
    commonPaging,
    RiseLoader
  },
  data() {
    return {
      page: 1,
      showPaging: false,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      keyword: "",
      isSearchPartnerCode: false,
      partnerDetail: {}
    }
  },
  computed: {
    ...mapState({
      listItems: state => state.partnerList.stateData.listItems,
      totalItems: state => state.partnerList.stateData.totalItems
    }),
    bankOptions() {
      return banks;
    },
    closingTimeOptions() {
      return CLOSING_TIME;
    },
    paymentMethodOptions() {
      return PAYMENT_METHOD;
    }
  },
  beforeCreate() {
    this.page = 1;
  },
  created() {
    this.$store.dispatch('partnerList/getListData').then(
      () => { 
        this.showPaging = true;
        this.isSearchPartnerCode = false;
      },
      (error) => {
        console.log(error);
        this.showPaging = false;
        errorAlert("error","Network Error",'Can not get Partner list data!');
      }
    );
  },
  methods: {
    onChangePage(page, wantCallEmit=true) {
      this.page = page;
      this.isLoading = true;
      this.isSearchPartnerCode = false;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('partnerList/getListData', page).then(
        () => { 
          this.isLoading = false;
          this.showPaging = true;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showPaging = false;
          errorAlert("error", "Network Error", "Can not get partner registration list data!");
        }
      );
    },
    onChangeSearch(page, searchKey, wantCallEmit=true) {
      if(!this.keyword) {
        this.onChangePage(1);
        return true;
      }
      this.isLoading = true;
      this.isSearchPartnerCode = true;
      this.page = page;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('partnerList/searchPartner', {"page": page, "searchKey":  this.keyword})
      .then(
        () => { 
          this.isLoading = false;
          this.showPaging = this.totalItems > 0 ? true : false;
        },
        (error) => {
          this.isLoading = false;
          this.showPaging = false;
          console.log(error);
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    deletePartner(partnerID) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          console.log(result);
          if(!result) return;

          this.$store.dispatch('partnerList/deletePartner', partnerID).then(
            () => { 
              this.isLoading = false;
              this.showPaging = true;
              deletedAlert();
              this.isSearchPartnerCode = false;
              if (this.keyword) {
                this.onChangeSearch(1);
              } else {
                this.onChangePage(1);
              }
            },
            (error) => {
              this.isLoading = false;
              this.showPaging = false;
              let resp = error.response;
              if (resp.status == 406){
                errorAlert("error", "Delete Not Allowed", resp.data.detail);
              }
              else {
                errorAlert("error", "Network Error", "Failed to deleted partner information!");
              }
              
            }
          );
        }
      )
    },
    handleShowDetail(partnerID) {
      this.isLoading = true;
      this.$store.dispatch('partnerList/getPartnerInfo', partnerID).then(
        (response) => { 
          this.isLoading = false;
          this.partnerDetail = response;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.partnerDetail = {};
          errorAlert("error","Network Error",'Can not get Partner information !');
        }
      );
    },
    getBankName(bank_code) {
      if (!bank_code) return "";
      let selectedBank = this.bankOptions.filter((bank) => {
        return bank.code == bank_code;
      });
      if (selectedBank.length == 0){
        return bank_code;
      }
      return selectedBank[0].name + ` (${selectedBank[0].shortName})`;
    },
    getClosingTime(id) {
      let query = this.closingTimeOptions.filter((item) => {
        return item.id == id;
      });
      return query.length > 0 ? query[0].name : ""
    },
    getPaymentName(val) {
      let query = this.paymentMethodOptions.filter((item) => {
        return item.value == val;
      });
      return query.length > 0 ? query[0].name_en : ""
    }
  }
}
</script>