<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
			<span class="text-muted fw-light">Account /</span> Edit
    </h4>
    <div class="card mb-4">
      <h5 class="card-header">Account Information</h5>
      <hr class="my-0">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex align-items-start align-items-sm-center gap-4">
							<div class="" v-if="images.length == 0 && stateData.avatar_url">
								<img class="d-block rounded" height="100" width="100" :src='stateData.avatar_url ? domain + stateData.avatar_url : ""'>
							</div>
							<div class="" v-if="images.length == 0 && !stateData.avatar_url">
								<div class="avatar avatar-xl me-2">
									<span class="avatar-initial rounded bg-label-primary">{{ getAvatarText(stateData.username) }}</span>
								</div>
							</div>
							<div class="" v-for="(image, key) in images" :key="key">
								<img class="d-block rounded" height="100" width="100" :ref="'image'">
							</div>
							<div class="button-wrapper">
								<p class="fw-bold mb-4">{{ "Hello, " + stateData.username }}</p>
								<button class="dt-button btn btn-primary me-3" onclick="document.getElementById('file_upload').click()">
									Upload new photo
								</button>
								<input 
									class="form-control d-none" 
									type="file" 
									accept=".jpg, .png"
									id="file_upload"
									@change="onFileChange"
								>
								<button type="button" 
									class="btn btn-label-secondary account-image-reset"
									@click="clearImageFile"
								>
									<i class="bx bx-reset d-block d-sm-none"></i>
									<span class="d-none d-sm-block">Reset Image</span>
								</button>

								<!-- <p class="text-muted mb-0">Allowed JPG, PNG. Max size of 1MB.</p> -->
							</div>
						</div>
          </div>
        </div>
      </div>
			<hr class="my-0">
			<div class="card-body">
				<div class="row">
					<div class="mb-3 col-md-6 fv-plugins-icon-container">
						<label class="form-label">First Name</label>
						<input class="form-control" 
							type="text" 
							v-model="stateData.first_name"
						>
					<div class="fv-plugins-message-container invalid-feedback"></div></div>
					<div class="mb-3 col-md-6 fv-plugins-icon-container">
						<label class="form-label">Last Name</label>
						<input class="form-control" 
							type="text"
							v-model="stateData.last_name"
						>
					<div class="fv-plugins-message-container invalid-feedback"></div></div>
					<div class="mb-3 col-md-6">
						<label class="form-label">E-mail</label>
						<input class="form-control" 
							type="text" 
							placeholder="user_mail@example.com"
							v-model="stateData.email"
						>
						<div class="error-msg" v-if="v$.stateData.email.$error"> 
							{{ v$.stateData.email.$errors[0].$message }}
						</div>
					</div>
					<div class="mb-3 col-md-6">
						<label class="form-label">Organization</label>
						<input type="text" 
							class="form-control"
							v-model="stateData.company"
						>
					</div>
					<div class="mb-3 col-md-6">
						<label class="form-label" for="phoneNumber">Phone Number</label>
						<div class="input-group input-group-merge">
							<span class="input-group-text">VN (+84)</span>
							<input type="text" 
								class="form-control" 
								v-model="stateData.phone"
							>
						</div>
					</div>
					<div class="mb-3 col-md-6">
						<label class="form-label">Address</label>
						<input type="text" 
							class="form-control" 
							placeholder="Ho Chi Minh city, Viet Nam"
							v-model="stateData.address"
						>
					</div>
				</div>
				<form id="" @submit.prevent="handleUpdate">
					<div class="mt-2">
						<button type="submit" class="btn btn-primary me-3">Update Account</button>
						<router-link to="/account" class="btn btn-outline-secondary w-45">
							<i class='bx bx-arrow-back' ></i>
							Back To List
						</router-link>
					</div>
				</form>
			</div>
    </div>
	
		<!-- This part is just used for Admin -->
		<div class="card">
			<h5 class="card-header">Delete Account</h5>
			<div class="card-body">
				<div class="mb-3 col-12 mb-0">
					<div class="alert alert-warning">
						<h6 class="alert-heading fw-bold mb-1">Are you sure you want to delete this account?</h6>
						<p class="mb-0">You can activate this account again with the administrator's permission.</p>
					</div>
				</div>
				<div>
					<div class="form-check mb-3">
						<input class="form-check-input" 
							type="checkbox" 
							name="accountActivation" 
							id="accountActivation"
							v-model="is_check_deactivation"
						>
						<label class="form-check-label" for="accountActivation">I confirm this account deactivation</label>
						<div class="fv-plugins-message-container invalid-feedback"></div>
					</div>
					<div class="error-msg mb-3" v-if="isErrorCheck"> 
						Confirm checkbox is required !
					</div>
					<form id="" @submit.prevent="handleDeactivateAccount">
						<button type="submit" class="btn btn-danger deactivate-account">Deactivate Account</button>
					</form>
				</div>
			</div>
		</div>

  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { ROLE } from "../../config/role"
// import { FILE_SERVER } from "../../config/common.js";
import showConfirmDeleteAlert from "../../helper/error.js"
import {errorAlert} from "../../helper/error.js"

export default {
	name: 'AccountEdit',
	data() {
    return {
      v$: useValidate(),
      images: [],
      isError: false,
      isUploadSuccess: false,
      categoryNameSelected: null,
			is_check_deactivation: false,
			isErrorCheck: false,
			domain: process.env.VUE_APP_DOMAIN_FILE_SERVER
    }
  },
	computed: {
    ...mapState({
			signedInAccount: state => state.auth,
			stateData: state => state.accountEdit.stateData
    }),
		getRole() {
			return ROLE;
		}
  },
	beforeCreate() {
    this.$store.dispatch('accountEdit/resetState');
  },
  created() {
		let paramID = this.$router.currentRoute._value.params.id;
		if (paramID) {
			this.stateData.id = paramID;
		}
		let self = this;
    this.$store.dispatch('accountEdit/getAccountProfile', paramID).then(
      () => { 
        console.log("Get Account Profile Data Successfully !!!");
      },
      (error) => {
        console.log(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'The Account Profile is not found or may be deactivated !'
        }).then(function() {
					self.$router.push('/account');
				});
      }
    );
  },
	methods: {
		onFileChange(e) {
      let selectedFiles = e.target.files;
      if (selectedFiles.length > 0) {
        this.isError = false;
        this.images = [];
        for (let i = 0; i < selectedFiles.length; i++) {
          this.images.push(selectedFiles[i]);
        }

        if (this.images.length > 0) {
          this.stateData.avatar_url = this.images[0].name;
        }
        
        for (let i = 0; i < this.images.length; i++) {
          let reader = new FileReader();
          reader.onload = () => {
              this.$refs.image[i].src = reader.result;
            };
            reader.readAsDataURL(this.images[i]);
        }

        // Handling upload file onto server
        this.submitImageFile();
      }
    },
    submitImageFile() {
      let formData = new FormData();

      for( let i = 0; i < this.images.length; i++ ){
        let file = this.images[i];
        formData.append("files", file);
      }
      formData.append("service_name", "account_profile");
      this.$store.dispatch('accountEdit/uploadImage', formData).then(
        () => {
          this.isUploadSuccess = true;
          this.isError = false;
        },
        error => {
          console.log(error);
          this.isUploadSuccess = false;
          this.isError = true;

          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to upload image !'
          });
        }
      );
    },
    clearImageFile() {
      this.images = [];
      let items =  this.$refs["image"];
      if (items != undefined){
        items.splice(0, items.length);
      }
      this.stateData.avatar_url = this.signedInAccount.user.user_info.avatar_url;
      this.isUploadSuccess = false;
      this.isError = false;
    },
		handleUpdate() {
			this.v$.$validate();
			if (!this.v$.$error) {
        this.$store.dispatch('accountEdit/updateAccountProfile', this.stateData).then(
          () => {
            let self = this;
            this.$swal.fire(
              'Updated',
              'Account Profile information is updated sucessfully !',
              'success'
            ).then(function() {
              // self.$router.push('/');
							if (self.stateData.username == self.signedInAccount.user.user_info.username){
								self.$store.dispatch('auth/logout');
								self.$router.push('/login');
							}
            });
          },
          error => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to save Account Profile information !'
            });
          }
        );
      }
		},
		handleDeactivateAccount() {
			if (!this.is_check_deactivation) {
				this.isErrorCheck = true;
				return;
			}
			this.isErrorCheck = false;
			showConfirmDeleteAlert()
      .then(
				(result) => {
          if(!result) return;

					this.$store.dispatch('accountEdit/deactivateAccount', this.stateData.id).then(
						() => {
							let self = this;
							this.$swal.fire(
								'Updated',
								'Account is deactivated sucessfully !',
								'success'
							).then(function() {
								self.$router.push('/account');
							});
						},
						error => {
							console.log(error);
							errorAlert("error","Error",'Failed to deactivate this account !');
						}
					);
        }
			);
		},
		getAvatarText(text) {
			if (!text) return "";
			return text.substr(0, 2);
		}
	},
	validations() {
    return {
      stateData: {
        email: {
          required: helpers.withMessage('E-MAIL cannot be empty', required)
        }
      }
    }
  }
}
</script>

<style scoped>
.avatar-xl {
	width: 6rem;
	height: 6rem;
}
.avatar-xl .avatar-initial {
	font-size: 2.5rem !important;
}
</style>