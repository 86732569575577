import PartnerRegistrationService from '../services/partner_registration.service';
import moment from 'moment'

const getDefaultState = () => {
  return {
    id: "",
    select_pay_receive: "",
    select_customer: "",
    date: null,
    consective_no: "",
    partner_code: "",
    created_date: ""
  }
}

export const partnerRegistrationEdit = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    getPartnerRegCode({commit}, id) {
      return PartnerRegistrationService.getPartnerRegCode(id).then(
        (response) => {
          commit('getSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getFailure');
          return Promise.reject(error);
        }
      );
    },
    updateCode({commit}, data) {
      console.log("call updateCode module");
      return PartnerRegistrationService.updateCode(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveFailure");
          return Promise.reject(error);
        }
      );
    },
    resetState({commit}) {
      commit("resetState");
    }
  },
  mutations: {
    getSuccess(state, response) {
      state.stateData.id = response.id;
      state.stateData.partner_code = response.partner_code;
      state.stateData.select_pay_receive = response.pr;
      state.stateData.select_customer = response.mcs;
      state.stateData.date = response.date;
      state.stateData.consective_no = response.consective_no;
      state.stateData.created_date = moment(response.created_date).format("YYYY-MM-DD hh:mm:ss");
    },
    getFailure(state) {
      this.resetState(state);
    },
    generateCode(state) {
      state.stateData.partner_code = PartnerRegistrationService.generateCode(state.stateData);
    },
    resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState())
    }
  },
  getters: {

  }
}