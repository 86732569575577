import { createStore } from "vuex";
import { auth } from './auth.module';
import { partnerRegistrationAdd } from './partner_reg_add.module'
import { partnerRegistrationList } from './partner_reg_list.module'
import { partnerRegistrationEdit } from './partner_reg_edit.module'
import { partnerAdd } from "./partner_add.module";
import { partnerList } from "./partner_list.module";
import { categoryAdd } from "./category_add.module";
import { categoryList } from "./category_list.module"
import { subCategoryAdd } from "./subcategory_add.module";
import {subCategoryList} from "./subcategory_list.module"
import { productRegistrationAdd } from "./product_reg_add.module";
import { productRegistrationList } from "./product_reg_list.module";
import { contractRegistrationAdd } from "./contract_reg_add.module";
import { contractRegistrationList } from "./contract_reg_list.module";
import { invoiceRegistrationList } from "./invoice_reg_list.module";
import { invoiceRegistrationAdd } from "./invoice_reg_add.module";
import { productList } from "./product_list.module";
import { productAdd } from "./product_add.module";
import { contractAdd } from "./contract_add.module";
import { contractList } from "./contract_list.module";
import { shipmentAdd } from "./shipment_add.module";
import { shipmentList } from "./shipment_list.module";
import { accountProfile } from "./account_profile.module";
import { accountChangePassword } from "./account_changepass.module";
import { accountEdit } from "./account_edit.module";
import { accountList } from "./account_list.module";
import { revenueStatistic } from "./revenue_statistic.module";


export default createStore({
  modules: {
    auth,
    partnerRegistrationAdd,
    partnerRegistrationList,
    partnerRegistrationEdit,
    partnerAdd,
    partnerList,
    categoryAdd,
    categoryList,
    subCategoryAdd,
    subCategoryList,
    productRegistrationAdd,
    productRegistrationList,
    contractRegistrationAdd,
    contractRegistrationList,
    invoiceRegistrationList,
    invoiceRegistrationAdd,
    productList,
    productAdd,
    contractAdd,
    contractList,
    shipmentAdd,
    shipmentList,
    accountProfile,
    accountChangePassword,
    accountEdit,
    accountList,
    revenueStatistic
  }
});