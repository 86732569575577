import AccountService from '../services/account.service';

const getDefaultState = () => {
	return {
		username: "",
		password: "",
		new_password: "",
		new_password_confirm: ""
	}
}

export const accountChangePassword = {
	namespaced: true,
	state: {
		stateData: getDefaultState()
	},
	actions: {
		resetState({commit}) {
			commit("resetState");
		},
		changeAccountPassword({commit}, data) {
			return AccountService.changeAccountPassword(data).then(
				(response) => {
					return Promise.resolve(response);
				},
				(error) => {
					commit("changeAccountPasswordFailure");
					return Promise.reject(error);
				}
			);
		},
	},
	mutations: {
		resetState (state) {
			console.log("run resetState");
			Object.assign(state.stateData, getDefaultState());
		},
		changeAccountPasswordFailure() {
			console.log("run saveAccountProfileFailure");
		}
	},
	getters: {
	}
}