<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span class="text-muted fw-light">Partner Code Registration /</span> Edit
    </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <h5 class="card-header">Edit Partner Code</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="card-body">
                <form id="formAccountSettingsApiKey" method="POST" onsubmit="return false" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                  <div class="row fv-plugins-icon-container">
                    <div class="mb-3 col-12">
                      <label for="apiAccess" class="form-label">Payable / Receivable</label>
                      <div class="position-relative">
                        <select id="select_pay_receive" 
                          class="form-select" 
                          name="select-pay-receive" 
                          v-model="stateData.select_pay_receive"
                          @change="onchange()"
                        >
                          <option value="P">Payable</option>
                          <option value="R">Receivable</option>
                        </select>
                      </div>
                    </div>
                    <div class="mb-3 col-12">
                      <label for="apiAccess" class="form-label">Manufacturer / Customer / Service</label>
                      <div class="position-relative">
                        <select id="select_customer" 
                          class="form-select" 
                          name="select-customer" 
                          v-model="stateData.select_customer"
                          @change="onchange()"
                        >
                          <option value="M">Manufacturer</option>
                          <option value="C">Customer</option>
                          <option value="S">Service</option>
                        </select>
                      </div>
                    </div>
                    <div class="mb-3 col-6">
                      <label for="apiAccess" class="form-label">Register Date</label>
                      <div class="position-relative">
                        <flat-pickr 
                          v-model="stateData.date" 
                          :config="calendarConfig" 
                          placeholder="Select a date" 
                          id="register_date"
                          name="register-date" 
                          class="form-control"
                          @on-close="onchange()"  
                        >
                        </flat-pickr>
                      </div>
                    </div>
                    <div class="mb-3 col-6">
                      <label for="apiAccess" class="form-label">Consective No</label>
                      <div class="position-relative">
                        <input class="form-control text-end" 
                          type="text" 
                          name="consective-no" 
                          id="consective_no" 
                          v-model="stateData.consective_no"
                          v-on:keyup=onchange()
                        >
                        <div class="error-msg" v-if="v$.stateData.consective_no.$error"> 
                          {{ v$.stateData.consective_no.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-4">
                      <button type="submit" @click="generateCode" class="btn btn-outline-success me-2 d-grid w-100">
                        Generate Partner Code
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 pe-5">
              <div class="bg-lighter rounded p-4 mb-3 position-relative">
                <div class="d-flex align-items-center flex-wrap mt-4">
                  <h5 class="mb-0 me-3">Generated Partner Code</h5> 
                </div>
                <div class="alert alert-success d-flex mt-4" role="alert">
                  <div class="d-flex flex-column ps-1">
                    <h6 class="alert-heading d-flex align-items-center fw-bold mb-3 mt-3">
                      {{stateData.partner_code}}
                    </h6>
                  </div>
                </div>
                <div class="mt-3 p-1">
                  <span v-if="stateData.created_date">Created on {{stateData.created_date}}</span>
                  <div class="error-msg" v-if="v$.stateData.partner_code.$error"> 
                    {{ v$.stateData.partner_code.$errors[0].$message }}
                  </div>
                </div>
                <form id="" @submit.prevent="handleUpdate">
                  <div class="row" style="margin-top:4rem">
                    <div class="col col-sm-12 text-center">
                      <button type="submit" class="btn btn-primary me-2">Update Code</button>
                      <router-link to="/partner-registration" data-i18n="Partner Registration" class="btn btn-outline-secondary w-45">
                        <i class='bx bx-arrow-back' ></i>
                        Back To Definition
                      </router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core'
import { required, numeric, maxLength, helpers } from '@vuelidate/validators'
import { mapState } from "vuex";

export default {
  name: 'PartnerRegistrationEdit',
    components: {
      flatPickr
    },
    data() {
      return {
        v$: useValidate(),
        calendarConfig: configs,
      }
    },
    computed: {
      ...mapState({
        stateData: state => state.partnerRegistrationEdit.stateData
      })
    },
    beforeCreate() {
      this.$store.dispatch('partnerRegistrationEdit/resetState');
    },
    created() {
      let paramID = this.$router.currentRoute._value.params.id;
      this.$store.dispatch('partnerRegistrationEdit/getPartnerRegCode', paramID).then(
        () => { 
          console.log("Get Partner Code Successfully !!!");
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Can not get the partner code value !'
          });
        }
      );
    },
    mounted() {

    },
    methods: {
      handleUpdate() {
        this.v$.$validate();
        if (!this.v$.$error) {
          this.$store.dispatch('partnerRegistrationEdit/updateCode', this.stateData).then(
            () => {
              let self = this;
              this.$swal.fire(
                'Saved',
                'Partner Code is updated sucessfully !',
                'success'
              ).then(function() {
                self.$router.push('/partner-registration');
              });
            },
            error => {
              console.log(error);
              this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to update Partner Code !'
              });
            }
          );
        }
      },
      generateCode() {
        this.$store.commit('partnerRegistrationEdit/generateCode');
      },
      onchange() {
        this.stateData.partner_code = "";
        this.stateData.created_date = "";
      }
    },
    validations() {
      return {
        stateData: {
          consective_no: { 
            required: helpers.withMessage('Consective No cannot be empty', required),
            numeric: helpers.withMessage('Please input number only', numeric),
            maxLength: helpers.withMessage('Please input maximum 3 letters', maxLength(3)),
          },
          partner_code: {
            required: helpers.withMessage('Please generate new partner code', required)
          }
        }
      }
    },
}
</script>
<style scoped>
  .error-msg {
    /* display: none; */
    width: 100%;
    margin-top: 0.3rem;
    font-size: 90%;
    color: #ff3e1d;
  }
</style>