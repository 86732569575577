import API from "@/config/api.js";
import TokenService from "./token.service";
import moment from 'moment';


class AuthService {
  login(user) {
    return API.post('auth/login', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.access_token) {
          let today = new Date();
          response.data.login_datetime = moment(today).format("YYYY-MM-DD HH:mm:ss");
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  register(user) {
    return API.post('auth/register', {
      username: user.username,
      email: user.email,
      password: user.password,
      role: user.role
    });
  }
}

export default new AuthService();