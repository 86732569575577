import CategoryService from '../services/category.service.js';
// import moment from 'moment'


const getDefaultState = () => {
  return {
    id: "",
    category_name: "",
    symbol: "",
    description: "",
    image_link: ""
  }
}

export const categoryAdd = {
    namespaced: true,
    state: {
        stateData: getDefaultState()
    },
    actions: {
      resetState({commit}) {
        commit("resetState");
      },
      saveCategory({commit}, data) {
        return CategoryService.saveCategory(data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit("saveFailure");
            return Promise.reject(error);
          }
        );
      },
      uploadImage({commit}, formdata) {
        return CategoryService.uploadImage(formdata).then(
          (response) => {
            commit('uploadSuccess', response);
            return Promise.resolve(response);
          },
          (error) => {
            commit("uploadFailure");
            return Promise.reject(error);
          }
        );
      },
      getCategory({commit}, id) {
        return CategoryService.getCategory(id).then(
          (response) => {
            commit('getSuccess', response);
            return Promise.resolve(response);
          },
          error => {
            commit('getFailure');
            return Promise.reject(error);
          }
        );
      },
      updateCategory({commit}, data) {
        return CategoryService.updateCategory(data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (error) => {
            commit("updateFailure");
            return Promise.reject(error);
          }
        );
      },
    },
    mutations: {
      resetState (state) {
        console.log("run resetState");
        Object.assign(state.stateData, getDefaultState());
      },
      uploadSuccess(state, response) {
        state.stateData.image_link = response.image_names[0];
      },
      uploadFailure(state) {
        state.stateData.image_link = "";
      },
      getSuccess(state, response) {
        state.stateData.id = response.id;
        state.stateData.category_name = response.category_name;
        state.stateData.symbol = response.symbol;
        state.stateData.description = response.description;
        state.stateData.image_link = response.image_link;
      },
      getFailure(state) {
        this.resetState(state);
      },
    },
    getters: {

    }

}