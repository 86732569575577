<template>
  <!-- Footer -->
  <footer class="content-footer footer bg-footer-theme">
    <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
      <div class="mb-2 mb-md-0">
        ©
        2022
        , LAMODA VIET NAM CO.,LTD
      </div>
    </div>
  </footer>
  <!-- / Footer -->
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>