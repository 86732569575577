<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Contract /</span> Edit
    </h4>
    <!-- General Information -->
    <div class="card mb-4">
      <h5 class="card-header">General Information</h5>
      <hr class="my-0">
      <div class="card-body">
        <form id="formAccountSettings" method="POST" onsubmit="return false" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
          <div class="row">
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label for="firstName" class="form-label">Contract Code</label>
              <div class="input-group input-group-merge">
                <span class="input-group-text" id=""><i class="bx bx-search"></i></span>
                <!-- <input class="form-control input-search" type="text" id="" name="" value="CONTRACT-2220111-0001" placeholder="CONTRACT-2220111-0001" disabled> -->
                <input class="form-control input-search" 
                  type="text"
                  v-model="stateData.contract_code"
                  disabled
                >
              </div>
            </div>
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label for="firstName" class="form-label">Reference No</label>
              <!-- <input class="form-control" type="text" id="" name="" value="REF-2220111-0001" placeholder="REF-2220111-0001"> -->
              <input class="form-control" 
                type="text" 
                placeholder="REF-2220111-0001"
                v-model="stateData.reference_no"
              >
            </div>
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label for="lastName" class="form-label">Contract Date</label>
              <flat-pickr 
                v-model="stateData.contract_date" 
                :config="calendarConfig" 
                placeholder="Select a date" 
                name="contract-date" 
                class="form-control">
              </flat-pickr>
            </div>
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label for="firstName" class="form-label">Lastest Delivery Date</label>
              <flat-pickr 
                v-model="stateData.last_delivery_date" 
                :config="calendarConfig" 
                placeholder="Select a date" 
                name="latest-delivery-date" 
                class="form-control">
              </flat-pickr>
            </div>
            <div class="mb-3 col-md-12 fv-plugins-icon-container">
              <label for="firstName" class="form-label">Place of Delivery</label>
              <!-- <input class="form-control" type="text" id="" name="" value="Place of Delivery Name ABC XYZ QWERTY" placeholder=""> -->
              <input class="form-control" 
                type="text" 
                placeholder="Ho Chi Minh city"
                v-model="stateData.delivery_place"
              >
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- / General Information -->

    <!-- Partner Information -->
    <div class="card mb-4">
      <h5 class="card-header">Partner Information</h5>
      <hr class="my-0">
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label for="firstName" class="form-label">Supplier Name</label>
            <VueMultiselect 
              v-model="supplierSelected" 
              :options="stateData.list_suppliers" 
              placeholder="Please choose supplier" 
              label="partner_name" 
              :close-on-select="true"
              :clear-on-select="false"
              :show-labels="false"
            />
            <div class="error-msg" v-if="v$.stateData.supplier_id.$error"> 
              {{ v$.stateData.supplier_id.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3 col-md-2 fv-plugins-icon-container">
            <label for="" class="form-label">Supplier Code</label>
            <input type="text" class="form-control px-2" v-model="stateData.supplier_code" disabled>
          </div>
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label for="firstName" class="form-label">Customer Name</label>
            <VueMultiselect 
              v-model="customerSelected" 
              :options="stateData.list_customers" 
              placeholder="Please choose customer" 
              label="partner_name" 
              :close-on-select="true"
              :clear-on-select="false"
              :show-labels="false"
            />
            <div class="error-msg" v-if="v$.stateData.customer_id.$error"> 
              {{ v$.stateData.customer_id.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3 col-md-2 fv-plugins-icon-container">
            <label for="" class="form-label">Customer Code</label>
            <input type="text" class="form-control px-2" v-model="stateData.customer_code" disabled>
          </div>
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label for="firstName" class="form-label">Purchase Currency</label>
            <VueMultiselect 
              v-model="buyCurrencySelected" 
              :options="currencyOptions" 
              label="name" 
              :close-on-select="true"
              :clear-on-select="false"
              :show-labels="false"
            />
            <div class="error-msg" v-if="v$.stateData.buy_currency.$error"> 
              {{ v$.stateData.buy_currency.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3 col-md-2 fv-plugins-icon-container">
            <label for="firstName" class="form-label">Purchase Ex.Rates</label>
            <!-- <input class="form-control text-end" 
              type="text"
              placeholder="1.00"
              v-model="stateData.buy_exchange_rates"
            > -->
            <cleave class="form-control text-end" 
              placeholder="1.00"
              v-model="stateData.buy_exchange_rates"
              :options="cleaveOptions.ex_rate"
            />
          </div>
          <div class="mb-3 col-md-4 fv-plugins-icon-container">
            <label for="firstName" class="form-label">Sale Currency</label>
            <VueMultiselect 
              v-model="sellCurrencySelected" 
              :options="currencyOptions" 
              label="name" 
              :close-on-select="true"
              :clear-on-select="false"
              :show-labels="false"
            />
            <div class="error-msg" v-if="v$.stateData.sell_currency.$error"> 
              {{ v$.stateData.sell_currency.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3 col-md-2 fv-plugins-icon-container">
            <label for="firstName" class="form-label">Sale Ex.Rates</label>
            <!-- <input class="form-control text-end" 
              type="text"
              placeholder="1.00"
              v-model="stateData.sell_exchange_rates"
            > -->
            <cleave class="form-control text-end" 
              placeholder="1.00"
              v-model="stateData.sell_exchange_rates"
              :options="cleaveOptions.ex_rate"
            />
          </div>
          <div class="col-md-6 mt-3 fv-plugins-icon-container" v-if="supplierInfo.id">
            <h6 class="fw-bold">Supplier Information</h6>
            <div class="alert alert-primary mt-1" role="alert">
              <div class="row">
                <div class="mb-2">
                  <span class="fw-bold me-3">{{ supplierInfo.partner_name }}</span>
                  <div class="mt-2">
                    <span class="fw-bold me-3">Address:</span>
                    {{ supplierInfo.address +" "+ supplierInfo.ward_name +" "+ 
                      supplierInfo.district_name +" "+ supplierInfo.city_name +" "+ supplierInfo.country}}
                    {{ supplierInfo.zipcode ? ", zipcode: " + supplierInfo.zipcode : "" }}
                  </div>
                  <div class="mt-3">
                    <span class="fw-bold me-1">Payment method:</span>
                    {{ getPaymentName(supplierInfo.payment_method) +" "+ supplierInfo.term +" days "+ getClosingTime(supplierInfo.closing_time) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-3 fv-plugins-icon-container" v-if="customerInfo.id">
            <h6 class="fw-bold">Customer Information</h6>
            <div class="alert alert-warning mt-1" role="alert">
              <div class="row">
                <div class="mb-2">
                  <span class="fw-bold me-3">{{ customerInfo.partner_name }}</span>
                  <div class="mt-2">
                    <span class="fw-bold me-3">Address:</span>
                    {{ customerInfo.address +" "+ customerInfo.ward_name +" "+ 
                      customerInfo.district_name +" "+ customerInfo.city_name +" "+ customerInfo.country}}
                    {{ customerInfo.zipcode ? ", zipcode: " + customerInfo.zipcode : "" }}
                  </div>
                  <div class="mt-3">
                    <span class="fw-bold me-1">Payment method:</span>
                    {{ getPaymentName(customerInfo.payment_method) +" "+ customerInfo.term +" days "+ getClosingTime(customerInfo.closing_time) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Partner Information -->

    <!-- Product Adding -->
    <div class="card mb-4">
      <h5 class="card-header">Product Information</h5>
      <hr class="my-0">
      <div class="card-body">
        <div class="d-flex border rounded position-relative mb-3" v-for="(product, key) in stateData.list_products" :key="key">
          <contract-detail-edit
            :product="product"
            :index="key"
            :buy_unit="buyCurrencyUnit"
            :sell_unit="sellCurrencyUnit"
            @removeOneProduct="removeProduct"
            @changeAmountValue="changeGrandTotalAmount"
          >
          </contract-detail-edit>
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-12">
            <button type="button" 
              class="btn btn-outline-primary" 
              @click="addMoreProduct"
            >Add Item</button>
          </div>
        </div>
        <!-- Grand Total Amount -->
        <div class="row col-md-12 m-0" v-if="stateData.grand_total_buy_price & stateData.grand_total_sell_price">
          <div class="col-md-7 d-flex justify-content-end">
            <div class="invoice-calculations">
              <div class="d-flex justify-content-between mb-4">
                <span class="fw-bold w-px-250"></span>
                <span class="fw-bold">PURCHASE</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-250">Subtotal</span>
                <span class="fw-semibold">{{ buyCurrencyUnit }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalBuyPrice) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-250">Tax</span>
                <span class="fw-semibold">{{ buyCurrencyUnit }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalBuyTax) }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-250">Grand Total (Tax included)</span>
                <span class="fw-semibold">{{ buyCurrencyUnit }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalBuyPriceWithTax) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-250">Grand Total in VND (before Tax)</span>
                <span class="fw-semibold">VND&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalBuyPriceVND) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-250">Profit (VND)</span>
                <span class="fw-semibold text-success">VND&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalProfit) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-250">Profit (%)</span>
                <span class="fw-semibold text-success">{{ getFormatCurrency(grandTotalProfitPercent) }} %</span>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-end" style="margin-left:-1.7rem; text-align: right;">
            <div class="invoice-calculations">
              <div class="d-flex justify-content-between mb-4">
                <span class="fw-bold w-px-250"></span>
                <span class="fw-bold">SELL</span>
              </div>
              <div class="d-flex1 justify-content-between mb-2">
                <span class="fw-semibold">&nbsp;</span>
                <span class="fw-semibold">{{ sellCurrencyUnit }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalSellPrice) }}</span>
              </div>
              <div class="d-flex1 justify-content-between mb-2">
                <span class="fw-semibold">&nbsp;</span>
                <span class="fw-semibold">{{ sellCurrencyUnit }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalSellTax) }}</span>
              </div>
              <hr>
              <div class="d-flex1 justify-content-between mb-2">
                <span class="fw-semibold">&nbsp;</span>
                <span class="fw-semibold">{{ sellCurrencyUnit }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalSellPriceWithTax) }}</span>
              </div>
              <div class="d-flex1 justify-content-between mb-2">
                <span class="fw-semibold">&nbsp;</span>
                <span class="fw-semibold">VND&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalSellPriceVND) }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-5">
            <span class="fw-bold me-2">GRAND PURCHASE AMOUNT: </span> 
            <span class="fw-bold text-warning">{{ getFormatCurrencyText(stateData.grand_total_buy_text) }}&nbsp;{{ buyCurrencyUnit }}</span>
          </div>
          <div class="col-md-12 mt-3">
            <span class="fw-bold me-2">GRAND SELL AMOUNT: </span> 
            <span class="fw-bold text-warning">{{ getFormatCurrencyText(stateData.grand_total_sell_text) }}&nbsp;{{ sellCurrencyUnit }}</span>
          </div>
        </div>
        <!-- / Grand Total Amount -->
      </div>
    </div>
    <!-- / Product Adding -->

    <!-- TERMS & CONDITIONS -->
    <div class="card mb-4">
      <h5 class="card-header">TERMS & CONDITIONS</h5>
      <hr class="my-0">
      <div class="card-body">
        <div class="row" v-for="(item, key) in stateData.list_conditions" :key="key">
          <div class="mb-3 col-lg-11 col-xl-11 col-11">
            <textarea class="form-control" 
              rows="1" 
              placeholder="Term and condition content..."
              v-model="item.condition"
            ></textarea>
          </div>
          <div class="mb-3 col-lg-1 col-xl-1 col-1 d-flex align-items-center mb-0" v-if="key == (stateData.list_conditions.length - 1)">
            <button type="button" 
              class="btn btn-sm rounded-pill btn-icon btn-outline-danger"
              @click="removeOneCondition(item.id)"
            >
              <span class="tf-icons bx bx-minus"></span>
            </button>
          </div>
        </div>
        <div class="mb-0 mt-3">
          <button class="btn btn-primary" @click="addMoreCondition" v-if="stateData.list_conditions != undefined && stateData.list_conditions.length < 10">
            <i class="bx bx-plus"></i>
            <span class="align-middle">Add</span>
          </button>
        </div>
        <div class="row mt-5">
          <form id="" @submit.prevent="handleUpdate">
            <div class="col-sm-6">
              <button type="submit" class="btn btn-primary me-3">Update Contract</button>
              <router-link to="/contract" data-i18n="Update Contract" class="btn btn-outline-secondary w-45">
                <i class='bx bx-arrow-back' ></i>
                Back To List
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- / TERMS & CONDITIONS -->

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
// import { required, numeric, maxLength, helpers } from '@vuelidate/validators'
import { mapState } from "vuex";
import _ from 'lodash';
import VueMultiselect from 'vue-multiselect'
import { CURRENCY_EXCHANGE, CLOSING_TIME, TAX_CONFIG, PAYMENT_METHOD } from "../../config/common.js"
import {errorAlert} from "../../helper/error.js"
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import showConfirmDeleteAlert from "../../helper/error.js"
import ContractDetailEdit from "./ContractDetailEdit.vue";
import {Converter} from "any-number-to-words";


export default {
  name: 'ContractEdit',
  components: {
    flatPickr,
    VueMultiselect,
    RiseLoader,
    ContractDetailEdit
  },
  props: [],
  data() {
    return {
      v$: useValidate(),
      calendarConfig: configs,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      supplierSelected: null,
      customerSelected: null,
      buyCurrencySelected: null,
      sellCurrencySelected: null,
      supplierInfo: {},
      customerInfo: {},
      buyCurrencyUnit: "VND",
      sellCurrencyUnit: "VND",
      subTotalBuyPrice: null,
      subTotalSellPrice: null,
      subTotalBuyPriceWithTax: null,
      subTotalSellPriceWithTax: null,
      grandTotalBuyTax: null,
      grandTotalSellTax: null,
      grandTotalBuyPriceVND: null,
      grandTotalSellPriceVND: null,
      grandTotalProfit: null,
      grandTotalProfitPercent: null,
      productInfo: {
        product_id: "",
        product_code: "",
        quantity_in: null,
        quantity_out: null,
        buy_price: null,
        buy_price_vnd: null,
        sell_price: null,
        sell_price_vnd: null,
        purchase_total_amount: null,
        sell_total_amount: null,
        sku: null,
        tax_buy_1: {
          id: null,
          name: "",
          value: null,
          is_display: true, 
        },
        tax_buy_2: {
          id: null,
          name: "",
          value: null,
          is_display: false
        },
        tax_buy_3: {
          id: null,
          name: "",
          value: null,
          is_display: false
        },
        tax_sell_1: {
          id: null,
          name: "",
          value: null,
          is_display: true, 
        },
        tax_sell_2: {
          id: null,
          name: "",
          value: null,
          is_display: false
        },
        tax_sell_3: {
          id: null,
          name: "",
          value: null,
          is_display: false
        }
      },
      cleaveOptions: {
        ex_rate: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        }
      }
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.contractAdd.stateData
    }),
    currencyOptions() {
      return CURRENCY_EXCHANGE;
    },
    taxOptions() {
      return TAX_CONFIG;
    },
    paymentMethodOptions() {
      return PAYMENT_METHOD;
    }
  },
  watch: {
    "buyCurrencySelected" (newVal) {
      if(!newVal) return;
      let selected = this.currencyOptions.filter(item => {
        return item.id == newVal.id
      })[0];
      this.stateData.buy_exchange_rates = this.stateData.buy_exchange_rates ? this.stateData.buy_exchange_rates : selected.ex_rate.toFixed(2);
      this.stateData.buy_currency = selected.code;
      this.buyCurrencyUnit = selected.code;
    },
    "sellCurrencySelected" (newVal) {
      if(!newVal) return;
      let selected = this.currencyOptions.filter(item => {
        return item.id == newVal.id
      })[0];
      this.stateData.sell_exchange_rates = this.stateData.sell_exchange_rates ? this.stateData.sell_exchange_rates : selected.ex_rate.toFixed(2);
      this.stateData.sell_currency = selected.code;
      this.sellCurrencyUnit = selected.code;
    },
    "supplierSelected" (newVal) {
      if(!newVal) return;
      let selected = this.stateData.list_suppliers.filter(item => {
        return item.id == newVal.id
      })[0];
      this.stateData.supplier_code = selected.partner_code;
      this.stateData.supplier_id = newVal.id;
      // Get Supplier Information to display on screen
      this.$store.dispatch('contractAdd/getPartnerInfo', newVal.id).then(
        (response) => { 
          this.isLoading = false;
          this.supplierInfo = response;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.supplierInfo = {};
          errorAlert("error","Network Error",'Can not get Supplier information !');
        }
      );
    },
    "customerSelected" (newVal) {
      if(!newVal) return;
      let selected = this.stateData.list_customers.filter(item => {
        return item.id == newVal.id
      })[0];
      this.stateData.customer_code = selected.partner_code;
      this.stateData.customer_id = newVal.id;

      // Get Customer Information to display on screen
      this.$store.dispatch('contractAdd/getPartnerInfo', newVal.id).then(
        (response) => { 
          this.isLoading = false;
          this.customerInfo = response;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.customerInfo = {};
          errorAlert("error","Network Error",'Can not get Customer information !');
        }
      );
    }
  },
  beforeCreate() {
    this.$store.dispatch('contractAdd/resetState');
  },
  created() {
    let contractID = this.$router.currentRoute._value.params.id;
    if (!_.isEmpty(contractID)){
      this.$store.dispatch("contractAdd/getContractInfo", contractID).then(
        () => { 
          // Get list supplier
          this.$store.dispatch('contractAdd/getListSupplier').then(
            () => { 
              this.supplierSelected = this.stateData.list_suppliers.filter(item => {
                return item.id == this.stateData.supplier_id
              })[0];
            },
            (error) => {
              console.log(error);
              this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Can not get the supplier value !'
              });
            }
          );

          // Get list customer
          this.$store.dispatch('contractAdd/getListCustomer').then(
            () => { 
              this.customerSelected = this.stateData.list_customers.filter(item => {
                return item.id == this.stateData.customer_id;
              })[0];
            },
            (error) => {
              console.log(error);
              this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Can not get the customer value !'
              });
            }
          );

          // Handle Selected Unit
          this.buyCurrencySelected =  this.currencyOptions.filter(item => {
            return item.code == this.stateData.buy_currency;
          })[0];
          this.sellCurrencySelected =  this.currencyOptions.filter(item => {
            return item.code == this.stateData.sell_currency;
          })[0];

          // Handling Term & Condition content
          this.stateData.list_conditions = [];
          for (let i=1; i<=10; i++) {
            if(this.stateData["condition_"+i]) {
              this.stateData.list_conditions.push({
                id: i,
                condition: this.stateData["condition_"+i]
              });
            }
          }
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Can not get Contract Information !'
          });
        }
      );
    }
    else {
      return;
    }

  },
  mounted() {

  },
  methods: {
    getClosingTime(close_time_id) {
      let query = CLOSING_TIME.filter(item => item.id == close_time_id);
      return query[0].name
    },
    getPaymentName(val) {
      let query = this.paymentMethodOptions.filter((item) => {
        return item.value == val;
      });
      return query.length > 0 ? query[0].name_en : ""
    },
    addMoreCondition() {
      let len = this.stateData.list_conditions.length;
      this.stateData.list_conditions.push({
        "id": len+1,
        "condition": ""
      });
    },
    removeOneCondition(id) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;

          const arrCopy = Array.from(this.stateData.list_conditions);
          const objWithIdIndex = arrCopy.findIndex((obj) => obj.id === id);
          arrCopy.splice(objWithIdIndex, 1);
          this.stateData.list_conditions = arrCopy;

        }
      )
    },
    addMoreProduct() {
      let copiedObj = JSON.parse(JSON.stringify(this.productInfo));
      this.stateData.list_products.push(copiedObj);
    },
    removeProduct(index) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;

          this.stateData.list_products.splice(index, 1);
          this.changeGrandTotalAmount(index)
        }
      )
    },
    changeGrandTotalAmount(new_value) {
      if(new_value){
        this.subTotalBuyPrice = 0;
        this.subTotalSellPrice = 0;
        this.subTotalBuyPriceWithTax = 0;
        this.subTotalSellPriceWithTax = 0;
        this.grandTotalBuyTax = 0;
        this.grandTotalSellTax = 0;
        this.grandTotalBuyPriceVND = 0;
        this.grandTotalSellPriceVND = 0;
        this.grandTotalProfit = 0;
        this.grandTotalProfitPercent = 0;
        this.stateData.grand_total_buy_price = 0;
        this.stateData.grand_total_sell_price = 0;
        
        this.stateData.list_products.forEach(product => {

          this.subTotalBuyPrice += (parseFloat(product.buy_price)*parseInt(product.quantity_in));
          this.subTotalSellPrice += (parseFloat(product.sell_price)*parseInt(product.quantity_out));

          this.grandTotalBuyTax += parseFloat(product.purchase_total_amount) - (parseFloat(product.buy_price)*parseInt(product.quantity_in))
          this.grandTotalSellTax += parseFloat(product.sell_total_amount) - (parseFloat(product.sell_price)*parseInt(product.quantity_out))
        });
        this.subTotalBuyPriceWithTax = parseFloat(this.subTotalBuyPrice) + parseFloat(this.grandTotalBuyTax);
        this.subTotalSellPriceWithTax = parseFloat(this.subTotalSellPrice) + parseFloat(this.grandTotalSellTax);

        this.stateData.grand_total_buy_price = this.subTotalBuyPrice;
        this.stateData.grand_total_sell_price = this.subTotalSellPrice;
      
        this.grandTotalBuyPriceVND = this.subTotalBuyPrice * parseFloat(this.stateData.buy_exchange_rates);
        this.grandTotalSellPriceVND = this.subTotalSellPrice * parseFloat(this.stateData.sell_exchange_rates);

        if(this.grandTotalBuyPriceVND) {
          this.grandTotalProfit = (this.subTotalSellPrice * this.stateData.sell_exchange_rates) - (this.subTotalBuyPrice * this.stateData.buy_exchange_rates);
          this.grandTotalProfitPercent = this.grandTotalProfit * 100 / (this.subTotalSellPrice * this.stateData.sell_exchange_rates)
        }
        
        if(this.stateData.grand_total_buy_price){
          this.stateData.grand_total_buy_text = new Converter().toWords(this.stateData.grand_total_buy_price);
        }
        if(this.stateData.grand_total_sell_price){
          this.stateData.grand_total_sell_text = new Converter().toWords(this.stateData.grand_total_sell_price);
        }
        
      }
    },
    handleUpdate() {
      this.v$.$validate();

      if (!this.v$.$error) {
        let request = {
          ...this.stateData
        }
        request = _.omit(request, ["list_suppliers", "list_customers", "country_buy", "country_sell"]);
        this.$store.dispatch('contractAdd/updateContract', request).then(
          () => {
            let self = this;
            this.$swal.fire(
              'Saved',
              'Contract information is saved sucessfully !',
              'success'
            ).then(function() {
              self.$router.push('/contract');
            });
          },
          error => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to save Contract information !'
            });
          }
        );
      }
      else {
        window.scrollTo(0,0);
      }
    },
    getFormatCurrency(value) {
      return Intl.NumberFormat().format(value);
    },
    getFormatCurrencyText(value) {
      return value.toUpperCase();
    }
  },
  validations() {
    return {
      stateData: {
        contract_code: {
          required: helpers.withMessage('CONTRACT CODE can not be empty', required)
        },
        supplier_id: {
          required: helpers.withMessage('SUPPLIER CODE can not be empty', required)
        },
        customer_id: {
          required: helpers.withMessage('CUSTOMER CODE can not be empty', required)
        },
        buy_currency: {
          required: helpers.withMessage('PURCHASE CURRENCY can not be empty', required)
        },
        buy_exchange_rates: {
          required: helpers.withMessage('PURCHASE EXCHANGE RATES can not be empty', required)
        },
        sell_currency: {
          required: helpers.withMessage('SELL CURRENCY can not be empty', required)
        }
      }
    }
  }
}
</script>

<style scoped>
  input.input-search {
    padding-left: 0.5rem !important;
  }
  div.multiselect__tags {
    padding-top: 5px;
  }
  span.multiselect__single {
    margin-top: 4px;
  }
</style>