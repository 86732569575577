<template>
  <!-- Content -->
  <div class="card1 mb-3">
    <div class="card-body">
      <div class="row p-sm-3 p-0">
        <div class="col-md-6 mb-md-0 mb-4">
          <div class="d-flex svg-illustration mb-4 gap-2">
          <span class="app-brand-logo demo">
              <svg width="25" viewBox="0 0 25 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                  <path d="M13.7918663,0.358365126 L3.39788168,7.44174259 C0.566865006,9.69408886 -0.379795268,12.4788597 0.557900856,15.7960551 C0.68998853,16.2305145 1.09562888,17.7872135 3.12357076,19.2293357 C3.8146334,19.7207684 5.32369333,20.3834223 7.65075054,21.2172976 L7.59773219,21.2525164 L2.63468769,24.5493413 C0.445452254,26.3002124 0.0884951797,28.5083815 1.56381646,31.1738486 C2.83770406,32.8170431 5.20850219,33.2640127 7.09180128,32.5391577 C8.347334,32.0559211 11.4559176,30.0011079 16.4175519,26.3747182 C18.0338572,24.4997857 18.6973423,22.4544883 18.4080071,20.2388261 C17.963753,17.5346866 16.1776345,15.5799961 13.0496516,14.3747546 L10.9194936,13.4715819 L18.6192054,7.984237 L13.7918663,0.358365126 Z" id="path-1"></path>
                  <path d="M5.47320593,6.00457225 C4.05321814,8.216144 4.36334763,10.0722806 6.40359441,11.5729822 C8.61520715,12.571656 10.0999176,13.2171421 10.8577257,13.5094407 L15.5088241,14.433041 L18.6192054,7.984237 C15.5364148,3.11535317 13.9273018,0.573395879 13.7918663,0.358365126 C13.5790555,0.511491653 10.8061687,2.3935607 5.47320593,6.00457225 Z" id="path-3"></path>
                  <path d="M7.50063644,21.2294429 L12.3234468,23.3159332 C14.1688022,24.7579751 14.397098,26.4880487 13.008334,28.506154 C11.6195701,30.5242593 10.3099883,31.790241 9.07958868,32.3040991 C5.78142938,33.4346997 4.13234973,34 4.13234973,34 C4.13234973,34 2.75489982,33.0538207 2.37032616e-14,31.1614621 C-0.55822714,27.8186216 -0.55822714,26.0572515 -4.05231404e-15,25.8773518 C0.83734071,25.6075023 2.77988457,22.8248993 3.3049379,22.52991 C3.65497346,22.3332504 5.05353963,21.8997614 7.50063644,21.2294429 Z" id="path-4"></path>
                  <path d="M20.6,7.13333333 L25.6,13.8 C26.2627417,14.6836556 26.0836556,15.9372583 25.2,16.6 C24.8538077,16.8596443 24.4327404,17 24,17 L14,17 C12.8954305,17 12,16.1045695 12,15 C12,14.5672596 12.1403557,14.1461923 12.4,13.8 L17.4,7.13333333 C18.0627417,6.24967773 19.3163444,6.07059163 20.2,6.73333333 C20.3516113,6.84704183 20.4862915,6.981722 20.6,7.13333333 Z" id="path-5"></path>
              </defs>
              <g id="g-app-brand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Brand-Logo" transform="translate(-27.000000, -15.000000)">
                  <g id="Icon" transform="translate(27.000000, 15.000000)">
                      <g id="Mask" transform="translate(0.000000, 8.000000)">
                      <mask id="mask-2" fill="white">
                          <use xlink:href="#path-1"></use>
                      </mask>
                      <use fill="#696cff" xlink:href="#path-1"></use>
                      <g id="Path-3" mask="url(#mask-2)">
                          <use fill="#696cff" xlink:href="#path-3"></use>
                          <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-3"></use>
                      </g>
                      <g id="Path-4" mask="url(#mask-2)">
                          <use fill="#696cff" xlink:href="#path-4"></use>
                          <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-4"></use>
                      </g>
                      </g>
                      <g id="Triangle" transform="translate(19.000000, 11.000000) rotate(-300.000000) translate(-19.000000, -11.000000) ">
                      <use fill="#696cff" xlink:href="#path-5"></use>
                      <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-5"></use>
                      </g>
                  </g>
                  </g>
              </g>
              </svg>
          </span>
          <span class="app-brand-text demo text-body fw-bolder">Sneat</span>
          </div>
          <p class="mb-1">LAMODA VIETNAM COMPANY LIMITED</p>
          <p class="mb-1">Ho Chi Minh City, Vietnam</p>
          <p class="mb-0">+84 (0) 394 577 366</p>
        </div>
        <div class="col-md-6">
            <dl class="row mb-2">
            <dt class="col-sm-5 mb-2 mb-sm-0 text-md-end mt-2">
              <span class="h5 text-capitalize mb-0 text-nowrap">Invoice Code</span>
            </dt>
            <dd class="col-sm-7 d-flex justify-content-md-end">
              <div class="w-px-250">
                <input type="text" 
                  class="form-control" 
                  v-model="stateData.invoice_code"  
                >
              </div>
            </dd>
            <dt class="col-sm-5 mb-2 mb-sm-0 text-md-end mt-2">
              <span class="fw-normal">Contract Code</span>
            </dt>
            <dd class="col-sm-7 d-flex justify-content-md-end">
              <div class="w-px-250">
                <VueMultiselect 
                  v-model="contractSelected"
                  :options="list_search_contract" 
                  placeholder="Search product name, Code" 
                  label="contract_code" 
                  @search-change="asyncFind"
                  @remove="removeSelectedContract"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :show-labels="false"
                />
              </div>
            </dd>
            <dt class="col-sm-5 mb-2 mb-sm-0 text-md-end mt-2">
                <span class="fw-normal">Issued Date</span>
            </dt>
            <dd class="col-sm-7 d-flex justify-content-md-end">
              <div class="w-px-250">
                <flat-pickr 
                  v-model="stateData.invoice_date" 
                  :config="calendarConfig" 
                  placeholder="Select a date" 
                  name="contract-date" 
                  class="form-control">
                </flat-pickr>
              </div>
            </dd>
            </dl>
        </div>
      </div>
      <hr class="my-4 mx-n4">
      <div class="row p-sm-0 p-0">
        <div class="col-md-6 col-sm-6 col-12" v-if="stateData.supplierInfo.id">
          <h6 class="pb-2 fw-bold">Supplier Information</h6>
          <div class="alert alert-primary mt-1" role="alert">
            <div class="row">
              <div class="mb-2">
                <span class="fw-bold me-3">{{ stateData.supplierInfo.partner_name }}</span>
                <div class="mt-2">
                  <span class="fw-bold me-3">Address:</span>
                  {{ stateData.supplierInfo.address +" "+ stateData.supplierInfo.ward_name +" "+ 
                    stateData.supplierInfo.district_name +" "+ stateData.supplierInfo.city_name +" "+ stateData.supplierInfo.country}}
                  {{ stateData.supplierInfo.zipcode ? ", zipcode: " + stateData.supplierInfo.zipcode : "" }}
                </div>
                <div class="mt-3">
                  <span class="fw-bold me-3">Payment method:</span>
                  {{ getPaymentName(stateData.supplierInfo.payment_method) +" "+ stateData.supplierInfo.term +" days "+ getClosingTime(stateData.supplierInfo.closing_time) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6" v-if="stateData.customerInfo.id">
          <h6 class="pb-2 fw-bold">Customer Information</h6>
          <div class="alert alert-warning mt-1" role="alert">
            <div class="row">
              <div class="mb-2">
                <span class="fw-bold me-3">{{ stateData.customerInfo.partner_name }}</span>
                <div class="mt-2">
                  <span class="fw-bold me-3">Address:</span>
                  {{ stateData.customerInfo.address +" "+ stateData.customerInfo.ward_name +" "+ 
                    stateData.customerInfo.district_name +" "+ stateData.customerInfo.city_name +" "+ stateData.customerInfo.country}}
                  {{ stateData.customerInfo.zipcode ? ", zipcode: " + stateData.customerInfo.zipcode : "" }}
                </div>
                <div class="mt-3">
                  <span class="fw-bold me-3">Payment method:</span>
                  {{ getPaymentName(stateData.customerInfo.payment_method) +" "+ stateData.customerInfo.term +" days "+ getClosingTime(stateData.customerInfo.closing_time) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4" v-if="stateData.supplierInfo.id">
          <label class="form-label">Purchase Exchange Rates</label>
          <cleave class="form-control text-end" 
            placeholder="1.00"
            v-model="stateData.buy_exchange_rates"
            :options="cleaveOptions.ex_rate"
          />
        </div>
        <div class="col-md-2 col-sm-2" v-if="stateData.supplierInfo.id"></div>
        <div class="col-md-4 col-sm-4" v-if="stateData.customerInfo.id">
          <label class="form-label">Sale Exchange Rates</label>
          <cleave class="form-control text-end" 
            placeholder="1.00"
            v-model="stateData.sell_exchange_rates"
            :options="cleaveOptions.ex_rate"
          />
        </div>
      </div>
      <hr class="mx-n4" v-if="stateData.contract_id">
      <div class="card-body">
        <div class="row" v-if="stateData.contract_id">
          <div class="col col-md-6 text-start mb-3">
            <button type="button" 
              class="btn btn-warning me-3" 
              @click="openModalQuantity"
              v-if="csv_content"
            >
              Check Quantity Support
            </button>
          </div>
          <div class="col col-md-6 text-end mb-3">
            <button type="button" 
              class="btn btn-primary me-3"
              onclick="document.getElementById('file_upload').click()"
            >
              Upload Quantity
            </button>
            <input 
              class="form-control d-none" 
              type="file" 
              accept=".csv"
              id="file_upload"
              name="file-upload"
              @change="handleFileUpload($event)"
            >
            <button type="button" 
              class="btn btn-primary"
              @click="downloadSampleQuanity"
            >
              Download Sample File
            </button>
          </div>
        </div>
        
        <div class="d-flex border rounded position-relative mb-3" v-for="(product, key) in stateData.list_products" :key="key">
        <!-- <div class="d-flex border rounded position-relative mb-3"> -->
          <shipment-product-detail
            :product="product"
            :index="key"
            @changeAmountValue="changeGrandTotalAmount"
          >
          </shipment-product-detail>
        </div>
        <!-- Grand Total Amount -->
        <div class="row col-md-12 m-0" v-if="stateData.contract_id">
          <div class="col-md-5 d-flex justify-content-end" style="margin-left: 5rem">
            <div class="invoice-calculations">
              <div class="d-flex justify-content-between mb-4">
                <span class="fw-bold w-px-200"></span>
                <span class="fw-bold">PURCHASE</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Subtotal:</span>
                <span class="fw-semibold">{{ stateData.buy_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalBuyPrice) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Tax:</span>
                <span class="fw-semibold">{{ stateData.buy_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalBuyTax) }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Grand Total:</span>
                <span class="fw-semibold">{{ stateData.buy_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalBuyPriceWithTax) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Grand Total in VND (-Tax)</span>
                <span class="fw-semibold">VND&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalBuyPriceVND) }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-end" style="margin-left:-1.7rem; text-align: right;">
            <div class="invoice-calculations">
              <div class="d-flex justify-content-between mb-4">
                <span class="fw-bold w-px-200"></span>
                <span class="fw-bold">SELL</span>
              </div>
              <div class="d-flex1 justify-content-between mb-2">
                <span class="fw-semibold">&nbsp;</span>
                <span class="fw-semibold">{{ stateData.sell_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalSellPrice) }}</span>
              </div>
              <div class="d-flex1 justify-content-between mb-2">
                <span class="fw-semibold">&nbsp;</span>
                <span class="fw-semibold">{{ stateData.sell_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalSellTax) }}</span>
              </div>
              <hr>
              <div class="d-flex1 justify-content-between mb-2">
                <span class="fw-semibold">&nbsp;</span>
                <span class="fw-semibold">{{ stateData.sell_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalSellPriceWithTax) }}</span>
              </div>
              <div class="d-flex1 justify-content-between mb-2">
                <span class="fw-semibold">&nbsp;</span>
                <span class="fw-semibold">VND&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalSellPriceVND) }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-5">
            <span class="fw-bold me-2">GRAND PURCHASE AMOUNT: </span> 
            <span class="fw-bold text-primary">{{ getFormatCurrencyText(stateData.grand_total_buy_text) }}&nbsp;{{ stateData.buy_currency }}</span>
          </div>
          <div class="col-md-12 mt-3">
            <span class="fw-bold me-2">GRAND SELL AMOUNT: </span> 
            <span class="fw-bold text-warning">{{ getFormatCurrencyText(stateData.grand_total_sell_text) }}&nbsp;{{ stateData.sell_currency }}</span>
          </div>
        </div>
        <!-- / Grand Total Amount -->
      </div>
      <hr class="my-4" v-if="stateData.contract_id">
      <div class="row" v-if="stateData.contract_id">
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Date of Inbound</label>
            <flat-pickr 
              v-model="stateData.inbound_date" 
              :config="calendarConfig" 
              placeholder="Select a date" 
              name="contract-date" 
              class="form-control">
            </flat-pickr>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Date of Shipment</label>
            <flat-pickr 
              v-model="stateData.shipment_date" 
              :config="calendarConfig" 
              placeholder="Select a date" 
              name="contract-date" 
              class="form-control">
            </flat-pickr>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Country of Origin</label>
            <input type="text" 
              class="form-control" 
              v-model="stateData.country_origin"
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Means of Transport</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.mean_transport"
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">HAWB NO</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.hawb_no"
            >
          </div>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Port of Loading</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.port_landing"
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Port of Discharge</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.port_discharge"
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Upload Supplier PXK</label>
            <div class="position-relative">
              <input 
                class="form-control" 
                type="file" 
                accept=".jpg, .png, .pdf"
                name="file_1"
                @change="onFileChange"
              >
            </div>
            <div class="mt-2" v-if="isUploadSuccess_1">
              <span class="fw-bold text-success">The image is uploaded to server successfully.</span>
            </div>
            <div class="mt-2" v-if="isError_1">
              <span class="fw-bold text-danger">The image isn't still uploaded to server.</span>
            </div>
            <!-- Show Image 1 -->
            <div class="mb-3 col-md-12">
              <div class="p-4 category-left1 category-top1" v-for="(image, key) in images_1" :key="key">
                <img class="card-img-top card-img-bottom" :ref="'image_1'">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Upload Input VAT</label>
            <div class="position-relative">
              <input 
                class="form-control" 
                type="file" 
                accept=".jpg, .png, .pdf"
                name="file_2"
                @change="onFileChange"
              >
            </div>
            <div class="mt-2" v-if="isUploadSuccess_2">
              <span class="fw-bold text-success">The image is uploaded to server successfully.</span>
            </div>
            <div class="mt-2" v-if="isError_2">
              <span class="fw-bold text-danger">The image isn't still uploaded to server.</span>
            </div>
            <!-- Show Image 2 -->
            <div class="mb-3 col-md-12">
              <div class="p-4" v-for="(image, key) in images_2" :key="key">
                <img class="card-img-top card-img-bottom" :ref="'image_2'">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Upload PXK Lamoda</label>
            <div class="position-relative">
              <input 
                class="form-control" 
                type="file" 
                accept=".jpg, .png, .pdf"
                name="file_3"
                @change="onFileChange"
              >
            </div>
            <div class="mt-2" v-if="isUploadSuccess_3">
              <span class="fw-bold text-success">The image is uploaded to server successfully.</span>
            </div>
            <div class="mt-2" v-if="isError_3">
              <span class="fw-bold text-danger">The image isn't still uploaded to server.</span>
            </div>
            <!-- Show Image 3 -->
            <div class="mb-3 col-md-12">
              <div class="p-4" v-for="(image, key) in images_3" :key="key">
                <img class="card-img-top card-img-bottom" :ref="'image_3'">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Upload output VAT</label>
            <div class="position-relative">
              <input 
                class="form-control" 
                type="file" 
                accept=".jpg, .png, .pdf"
                name="file_4"
                @change="onFileChange"
              >
            </div>
            <div class="mt-2" v-if="isUploadSuccess_4">
              <span class="fw-bold text-success">The image is uploaded to server successfully.</span>
            </div>
            <div class="mt-2" v-if="isError_4">
              <span class="fw-bold text-danger">The image isn't still uploaded to server.</span>
            </div>
            <!-- Show Image 4 -->
            <div class="mb-3 col-md-12">
              <div class="p-4" v-for="(image, key) in images_4" :key="key">
                <img class="card-img-top card-img-bottom" :ref="'image_4'">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="mb-3 col-sm-6">
          <router-link to="/shipment" data-i18n="Add Product" class="btn btn-outline-secondary w-45">
            <i class='bx bx-arrow-back' ></i>
            Back To List
          </router-link>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Check Quantity -->
    <div class="modal show" id="backDropModal" data-bs-backdrop="static" style="display: block;" v-if="isModalQuantity">
      <div class="modal-dialog">
        <form class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="backDropModalTitle">Modal Check & Confirm Quantity</h5>
            <button type="button" class="btn-close" 
              data-bs-dismiss="modal"
              @click="closeModalQuantity"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row px-3">
              <div class="col-sm-4 mb-3 px-0" style="font-size:0.8rem;">
                <ul class="list-group list-group-flush mb-31">
                  <li class="list-group-item d-flex justify-content-between align-items-center active">
                    <span class="fw-bold">PRODUCT CODE</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-1"
                    v-for="(prod, key) in csv_content.data" :key="key"
                  >
                    <span class="fw-bold">{{ prod.product_code }}</span>
                  </li>
                </ul>
              </div>
              <div class="col-sm-4 mb-3 px-0" style="font-size:0.8rem;">
                <ul class="list-group list-group-flush mb-31">
                  <li class="list-group-item d-flex justify-content-between align-items-center active">
                    <span class="fw-bold">QUANTITY IN</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center p-0"
                    v-for="(qtt_in, key) in csv_content.data" :key="key"
                  >
                    <div class="col-sm-12">
                      <cleave
                        class="form-control text-end cost-input"
                        placeholder="0"
                        v-model="qtt_in.quantity_in"
                        :options="cleaveOptions.fee"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-sm-4 mb-3 px-0" style="font-size:0.8rem;">
                <ul class="list-group list-group-flush mb-31">
                  <li class="list-group-item d-flex justify-content-between align-items-center active">
                    <span class="fw-bold">QUANTITY OUT</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center p-0"
                    v-for="(qtt_out, key) in csv_content.data" :key="key"
                  >
                    <div class="col-sm-12">
                      <cleave
                        class="form-control text-end cost-input"
                        placeholder="0"
                        v-model="qtt_out.quantity_out"
                        :options="cleaveOptions.fee"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" 
              data-bs-dismiss="modal" 
              @click="closeModalQuantity"
            >Close</button>
            <button type="button" 
              class="btn btn-primary"
              @click="applyNewQuantity"
            >Apply</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import ShipmentProductDetail from "./ShipmentProductDetail.vue";
// import showConfirmDeleteAlert from "../../helper/error.js"
import {Converter} from "any-number-to-words";
import VueMultiselect from 'vue-multiselect'
import {errorAlert} from "../../helper/error.js"
import { CURRENCY_EXCHANGE, CLOSING_TIME, TAX_CONFIG, SHIPMENT_CSV_HEADER, PAYMENT_METHOD } from "../../config/common.js"
import _ from 'lodash';
import RiseLoader from 'vue-spinner/src/RiseLoader.vue';
import { saveAs } from 'file-saver';
import axios from 'axios';


export default {
  name: 'ShipmentAdd',
  components: {
    flatPickr,
    ShipmentProductDetail,
    VueMultiselect,
    RiseLoader 
  },
  data() {
    return {
      v$: useValidate(),
      calendarConfig: configs,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      isModalQuantity: false,
      domain: process.env.VUE_APP_DOMAIN_FILE_SERVER,
      images: [],
      images_1: [],
      images_2: [],
      images_3: [],
      images_4: [],
      isError_1: false,
      isError_2: false,
      isError_3: false,
      isError_4: false,
      isUploadSuccess_1: false,
      isUploadSuccess_2: false,
      isUploadSuccess_3: false,
      isUploadSuccess_4: false,
      page:1,
      list_search_contract: [],
      contractSelected: null,
      subTotalBuyPrice: null,
      subTotalSellPrice: null,
      subTotalBuyPriceWithTax: null,
      subTotalSellPriceWithTax: null,
      grandTotalBuyTax: null,
      grandTotalSellTax: null,
      grandTotalBuyPriceVND: null,
      grandTotalSellPriceVND: null,
      productInfo: {
        product_id: "",
        product_code: "",
        quantity_in: null,
        quantity_out: null,
        buy_price: null,
        sell_price: null,
        purchase_total_amount: null,
        sell_total_amount: null,
        sku: null,
        tax_1: {
          id: null,
          name: "",
          value: null,
          is_display: true, 
        },
        tax_2: {
          id: null,
          name: "",
          value: null,
          is_display: false
        },
        tax_3: {
          id: null,
          name: "",
          value: null,
          is_display: false
        }
      },
      cleaveOptions: {
        ex_rate: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        },
        fee: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      },
      csv_file: '',
      csv_content: null,
      csv_errors: []
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.shipmentAdd.stateData
    }),
    currencyOptions() {
      return CURRENCY_EXCHANGE;
    },
    taxOptions() {
      return TAX_CONFIG;
    },
    CSV_HEADER () {
      return SHIPMENT_CSV_HEADER;
    },
    paymentMethodOptions() {
      return PAYMENT_METHOD;
    }
  },
  watch: {
    "contractSelected" (newVal) {
      if (!newVal) return;
      this.isLoading = true;
      this.stateData.contract_id = newVal.id;
      this.stateData.supplier_id = newVal.supplier_id;
      // Get Supplier Information to display on screen
      this.$store.dispatch('shipmentAdd/getPartnerInfo', this.stateData.supplier_id).then(
        (response) => { 
          this.stateData.supplierInfo = response;
        },
        (error) => {
          console.log(error);
          this.stateData.supplierInfo = {};
          errorAlert("error","Network Error",'Can not get Supplier information !');
        }
      );
      
      // Get Customer Information to display on screen
      this.stateData.customer_id = newVal.customer_id;
      this.$store.dispatch('shipmentAdd/getPartnerInfo', this.stateData.customer_id).then(
        (response) => { 
          this.stateData.customerInfo = response;
        },
        (error) => {
          console.log(error);
          this.stateData.customerInfo = {};
          errorAlert("error","Network Error",'Can not get Customer information !');
        }
      );

      // Handling get product list
      this.stateData.contract_detail_list = [];
      this.$store.dispatch("shipmentAdd/getContractInfo", newVal.id).then(
        () => { 
          this.stateData.buy_exchange_rates = parseFloat(this.stateData.buy_exchange_rates).toFixed(2);
          this.stateData.sell_exchange_rates = parseFloat(this.stateData.sell_exchange_rates).toFixed(2);

          // Handling calculate remain quantity from contract
          this.$store.dispatch('shipmentAdd/getShipmentQuantityRemain', newVal.id).then(
            () => { 
              this.changeGrandTotalAmount(this.stateData.buy_currency);
              this.isLoading = false;
            },
            (error) => {
              this.isLoading = false;
              console.log(error);
              errorAlert("error","Network Error",'Can not get Shipment Quantiy Remain information !');
            }
          );

          
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Can not get Contract Information !'
          });
        }
      );
    },
    "stateData.inbound_date" (newVal) {
      this.stateData.receiving_voucher_date = newVal;
    },
    "stateData.shipment_date" (newVal) {
      this.stateData.delivery_voucher_date = newVal;
      this.stateData.invoice_date = newVal;
    },
    "stateData.buy_exchange_rates" (newVal) {
      this.changeGrandTotalAmount(newVal);
    },
    "stateData.sell_exchange_rates" (newVal) {
      this.changeGrandTotalAmount(newVal);
    }
  },
  beforeCreate() {
    this.$store.dispatch('shipmentAdd/resetState');
  },
  created() {
    // Handling get contract information to show supplier and customer
    let query_param = this.$router.currentRoute._value.query;
    if (!_.isEmpty(query_param)){
      if (_.has(query_param, "invoice_code")){
        if(this.stateData.contract_id) {
          this.asyncFind(this.stateData.contract_code);
        }

        this.isLoading = true;
        this.$store.dispatch('shipmentAdd/searchInvoiceRegByCode', {"page": 1, "searchKey": query_param.invoice_code})
        .then(
          () => { 
            this.isLoading = false;
            if (!this.stateData.invoice_code_id) {
              return;
            }
          },
          (error) => {
            this.isLoading = false;
            console.log(error);
            errorAlert("error", "Network Error",'Can not get Contract Code data!');
          }
        );
      }
      else {
        return;
      }
    }
    else {
      return;
    }
  },
  mounted() {
  },
  methods: {
    onFileChange(e) {
      let selectedFiles = e.target.files;
      if (selectedFiles.length > 0) {
        let index = 0;
        if(e.target.name == "file_1") {
          this.images_1 = [];
          this.images_1.push(selectedFiles[0]);
          this.stateData.file_link_1 = selectedFiles[0].name;
          index = 1;
          this.isError_1 = false;
        }
        if(e.target.name == "file_2") {
          this.images_2 = [];
          this.images_2.push(selectedFiles[0]);
          this.stateData.file_link_2 = selectedFiles[0].name;
          index = 2;
          this.isError_2 = false;
        }
        if(e.target.name == "file_3") {
          this.images_3 = [];
          this.images_3.push(selectedFiles[0]);
          this.stateData.file_link_3 = selectedFiles[0].name;
          index = 3;
          this.isError_3 = false;
        }
        if(e.target.name == "file_4") {
          this.images_4 = [];
          this.images_4.push(selectedFiles[0]);
          this.stateData.file_link_4 = selectedFiles[0].name;
          index = 4;
          this.isError_4 = false;
        }
        
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs["image_"+index][0].src = reader.result;
        };
        reader.readAsDataURL(selectedFiles[0]);

        // Handling upload file onto server
        this.submitImageFile(e.target.name, selectedFiles[0]);
      }
    },
    submitImageFile(el_name, file_upload) {
      let formData = new FormData();

      formData.append("files", file_upload);
      formData.append("el_name", el_name);
      formData.append("contract_code", this.stateData.contract_code);
      formData.append("invoice_code", this.stateData.invoice_code);

      this.$store.dispatch('shipmentAdd/uploadShipmentFile', formData).then(
        () => {
          if(el_name == "file_1") {
            this.isUploadSuccess_1 = true;
            this.isError_1 = false;
          }
          if(el_name == "file_2") {
            this.isUploadSuccess_2 = true;
            this.isError_2 = false;
          }
          if(el_name == "file_3") {
            this.isUploadSuccess_3 = true;
            this.isError_3 = false;
          }
          if(el_name == "file_4") {
            this.isUploadSuccess_4 = true;
            this.isError_4 = false;
          }
        },
        error => {
          console.log(error);
          if(el_name == "file_1") {
            this.isUploadSuccess_1 = false;
            this.isError_1 = true;
          }
          if(el_name == "file_2") {
            this.isUploadSuccess_2 = false;
            this.isError_2 = true;
          }
          if(el_name == "file_3") {
            this.isUploadSuccess_3 = false;
            this.isError_3 = true;
          }
          if(el_name == "file_4") {
            this.isUploadSuccess_4 = false;
            this.isError_4 = true;
          }

          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to upload image !'
          });
        }
      );
    },
    changeGrandTotalAmount(new_value) {
      if(new_value){
        this.subTotalBuyPrice = 0;
        this.subTotalSellPrice = 0;
        this.subTotalBuyPriceWithTax = 0;
        this.subTotalSellPriceWithTax = 0;
        this.grandTotalBuyTax = 0;
        this.grandTotalSellTax = 0;
        this.grandTotalBuyPriceVND = 0;
        this.grandTotalSellPriceVND = 0;
        this.grandTotalProfit = 0;
        this.grandTotalProfitPercent = 0;
        this.stateData.grand_total_buy_price = 0;
        this.stateData.grand_total_sell_price = 0;
        
        this.stateData.list_products.forEach(product => {

          this.subTotalBuyPrice += (parseFloat(product.buy_price)*parseInt(product.quantity_in));
          this.subTotalSellPrice += (parseFloat(product.sell_price)*parseInt(product.quantity_out));

          this.grandTotalBuyTax += (parseFloat(product.purchase_total_amount) - (parseFloat(product.buy_price)*parseInt(product.quantity_in)));
          this.grandTotalSellTax += (parseFloat(product.sell_total_amount) - (parseFloat(product.sell_price)*parseInt(product.quantity_out)));
        });
        this.subTotalBuyPriceWithTax = parseFloat(this.subTotalBuyPrice) + parseFloat(this.grandTotalBuyTax);
        this.subTotalSellPriceWithTax = parseFloat(this.subTotalSellPrice) + parseFloat(this.grandTotalSellTax);

        this.stateData.grand_total_buy_price = this.subTotalBuyPrice;
        this.stateData.grand_total_sell_price = this.subTotalSellPrice;

        this.grandTotalBuyPriceVND = this.subTotalBuyPrice * parseFloat(this.stateData.buy_exchange_rates);
        this.grandTotalSellPriceVND = this.subTotalSellPrice * parseFloat(this.stateData.sell_exchange_rates);

        if(this.grandTotalBuyPriceVND) {
          this.grandTotalProfit = (this.subTotalSellPrice * this.stateData.sell_exchange_rates) - (this.subTotalBuyPrice * this.stateData.buy_exchange_rates);
          this.grandTotalProfitPercent = this.grandTotalProfit * 100 / (this.subTotalSellPrice * this.stateData.sell_exchange_rates)
        }
        
        this.stateData.grand_total_buy_text = new Converter().toWords(this.stateData.grand_total_buy_price);
        this.stateData.grand_total_sell_text = new Converter().toWords(this.stateData.grand_total_sell_price);
      }
    },
    asyncFind(query) {
      if (query.length < 3) return;
      
      // Get List contract belong to input value
      this.$store.dispatch('shipmentAdd/searchContract', {"page": this.page, "searchKey":  query})
      .then(
        (response) => { 
          this.list_search_contract = response.listItems;
          if(this.stateData.contract_id) {
            this.contractSelected = this.list_search_contract.filter(item => {
              return item.id == this.stateData.contract_id;
            })[0];
          }
        },
        (error) => {
          console.log(error);
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    removeSelectedContract() {
      let invoice_code_id = this.stateData.invoice_code_id;
      let invoice_code = this.stateData.invoice_code;
      this.$store.dispatch('shipmentAdd/resetState');
      this.stateData.invoice_code_id = invoice_code_id;
      this.stateData.invoice_code = invoice_code;
    },
    getFormatCurrency(value) {
      return Intl.NumberFormat().format(value);
    },
    getFormatCurrencyText(value) {
      return value.toUpperCase();
    },
    getClosingTime(close_time_id) {
      let query = CLOSING_TIME.filter(item => item.id == close_time_id);
      return query[0].name
    },
    getPaymentName(val) {
      let query = this.paymentMethodOptions.filter((item) => {
        return item.value == val;
      });
      return query.length > 0 ? query[0].name_en : ""
    },
    openModalQuantity() {
      this.isModalQuantity = true;
    },
    closeModalQuantity() {
      this.isModalQuantity = false;
    },
    handleFileUpload(event){
      this.csv_file = event.target.files[0];
      this.parseFile(event);
    },
    parseFile (event){
      this.$papa.parse(this.csv_file, {
        header: true,
        skipEmptyLines: true,
        complete: function(results) {
          this.csv_errors.length = 0;
          this.csv_content = results;
          this.csv_content.data.forEach(itemObject => {
            let error_obj = {} 
            Object.keys(itemObject).forEach(item => {
              let result = this.checkValidateData(item, itemObject[item], this.csv_content.data);
              if (!result) {
                error_obj[item] = itemObject[item];
              }
            });
            this.csv_errors.push(error_obj);
          });
          this.checkHasErrors(this.csv_errors).then(
            () => {
              // Handle change quantity value of current list product
              if (this.csv_errors.length == 0){
                this.stateData.list_products.forEach(prodItem => {
                  this.csv_content.data.forEach(csvItem => {
                    if (prodItem.product_code == csvItem.product_code){
                      prodItem.quantity_in = csvItem.quantity_in;
                      prodItem.quantity_out = csvItem.quantity_out;
                    }
                  });
                });
              } 
              else {
                let error_messages = "";
                for (let errorItem of this.csv_errors) {
                  Object.keys(errorItem).forEach(item => {
                    error_messages += item + ": " + errorItem[item] + "<br/>";
                  });
                  if (error_messages !== "") {
                    break;
                  }
                }
                errorAlert("error","Invalid Data Error", error_messages);
              }
            }
          );
          
          event.target.value = "";
        }.bind(this)
      });
    },
    checkValidateData(field_name, value, array) {
      let result = true;
      if (field_name == this.CSV_HEADER[0].code){
        result = this.checkNumber(value);
      }
      else if (field_name == this.CSV_HEADER[1].code){
        result = this.checkProductCodeFormat(value);
        if (result) {
          result = this.checkDuplicatedProductCode(field_name, value, array);
        }
        if (result){
          result = this.checkProductExistedInList(value);
        }
      }
      else if (field_name == this.CSV_HEADER[2].code){
        result = this.checkNumber(value);
      }
      else if (field_name == this.CSV_HEADER[3].code) {
        result = this.checkNumber(value);
      }
      else {
        return false;
      }

      if (this.checkIsEmpty(value)) {
        return false;
      }

      return result;
    },
    checkIsEmpty(value) {
      return !value;
    },
    checkNumber(value) {
      return !isNaN(value) && _.isInteger(parseInt(value));
    },
    checkProductCodeFormat(value) {
      // SB-PTSH230210-01
      let pattern =/^([A-Z]{2})-([A-Z,0-9]{10,12})-([0-9]{2})$/;
      return pattern.test(value);
    },
    checkDuplicatedProductCode(field_name, value, array) {
      let result = array.filter(item => {
        return item[field_name] == value;
      });
      return result.length == 1;
    },
    checkProductExistedInList( value) {
      if (this.stateData.list_products.length == 0) return false;

      let result = this.stateData.list_products.filter(item => {
        return item.product_code == value;
      });
      return result.length > 0;
    },
    async checkHasErrors(array_error) {
      if (array_error.length > 0) {
        let check = array_error.filter(item => {
          return Object.keys(item).length > 0;
        });
        if (check.length == 0) {
          array_error.length = 0;
        }
      }
      return array_error;
    },
    applyNewQuantity() {
      this.stateData.list_products.forEach(prodItem => {
        this.csv_content.data.forEach(csvItem => {
          if (prodItem.product_code == csvItem.product_code){
            prodItem.quantity_in = csvItem.quantity_in;
            prodItem.quantity_out = csvItem.quantity_out;
          }
        });
      });
      this.closeModalQuantity();
    },
    downloadSampleQuanity() {
      let url = "/upload/sample/sample_shipment_quantity.csv"
      axios
        .get(this.domain+url, {responseType: 'blob'})
        .then(response => {
            saveAs(response.data, "sample_shipment_quantity.csv");
        })
    }
  },
  validations() {
    return {
      stateData: {
        invoice_code: {
          required: helpers.withMessage('INVOICE CODE can not be empty', required)
        }
      }
    }
  }
}
</script>

<style scoped>
  .cost-contract {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: solid 1px lightgrey;
    border-top: 0px;
    font-size: 0.8rem;
  }
  .cost-input {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: 0px;
    border-top: 0px;
    background-color: lightyellow;
    font-size: 0.8rem;
    font-weight: 700;
    height: 1.7rem;
  }
  .cost-input-last {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: 0px;
    border-top: 0px;
    background-color: lightyellow;
    border-bottom-right-radius: 10px;
    font-size: 0.8rem;
  }
  .list-group .fee-border {
    border-left: solid 1px lightgray !important;
  }
</style>