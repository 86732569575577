<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Product Category</span>
    </h4>

    <div class="row">
      <!-- Category List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
          <div class="">
            <div class="row">
              <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                <div class="dataTables_length" id="DataTables_Table_0_length">
                  <div class="dt-buttons">
                    <router-link to="/category/add" data-i18n="Create Definition" class="dt-button btn btn-primary">
                      <i class="bx bx-plus me-md-2"></i>
                      Create New
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <div class="input-group input-group-merge">
                  <span class="input-group-text"><i class="bx bx-search"></i></span>
                  <input type="text" 
                    class="form-control" 
                    placeholder="Search category name, symbol" 
                    v-model="keyword"
                    @change="onChangeSearch(page, keyword)"
                  >
                </div>
              </div>
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                <th>Category Name</th>
                <th>Symbol</th>
                <th>Description</th>
                <th>Actions</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr v-for="(item, index) in listItems" :key="index">
                  <td>
                    <strong>{{ item.category_name }}</strong>
                  </td>
                  <td><strong>{{ item.symbol }}</strong></td>
                  <td class="width-40-percent">
                    <div>
                      <span v-tooltip="item.description">
                        <strong>{{ item.description }}</strong>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" 
                        class="text-body me-3" 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasScroll"
                        @click="handleShowDetail(item.id)"
                      >
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link :to='"/category/edit/" + item.id' data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a class="text-body icon-delete" @click="deleteCategory(item.id)">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div class="row mx-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <common-paging 
                :items="listItems" 
                :totalItems="totalItems" 
                @changePage="onChangePage"
                @changeSearchPage="onChangeSearch"
                :activePageId="page"
                v-show="showPaging"
                :isSearch="isSearchCategory"
              />
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- OffCanvas from the right -->
    <div class="row">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScroll" aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility: visible;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0">
          <div class="user-avatar-section">
            <div class=" d-flex align-items-center flex-column">
              <img class="img-fluid rounded my-1" 
                :src='domain + detailInfo.image_link' 
                height="200" 
                width="200" 
                alt="User avatar"
                v-if="detailInfo.image_link"
              >
              <img class="img-fluid rounded my-1" 
                :src='domain + "/upload/sample/sample.png"'
                alt="User avatar"
                v-else
              >
            </div>
          </div>
          <div class="d-flex justify-content-around flex-wrap my-1 py-1">
          </div>
          <h5 class="pb-2 border-bottom mb-4"></h5>
          <div class="info-container">
            <ul class="list-unstyled">
              <li class="mb-3">
                <span class="fw-bold me-2">CATEGORY NAME</span>
                <span>{{ detailInfo.category_name }}</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">SYMBOL:</span>
                <span class="">{{ detailInfo.symbol }}</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Description:</span>
                <div class="col-sm-12">
                  {{ detailInfo.description }}
                </div>
              </li>
            </ul>
            <router-link :to='"/category/edit/" + detailInfo.id' data-i18n="Edit Category" class="btn btn-primary w-45 mb-2 w-100 mt-5">
              Edit Category
            </router-link>
            <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
              <i class='bx bx-arrow-back' ></i>
              Back to List
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import commonPaging from "../common/CommonPaging"
import {errorAlert} from "../../helper/error.js"
import {deletedAlert} from "../../helper/error.js"
import showConfirmDeleteAlert from "../../helper/error.js"
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'


export default {
  name: 'CategoryList',
  components: {
    commonPaging,
    RiseLoader
  },
  data(){
    return {
      page: 1,
      showPaging: false,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      keyword: "",
      isSearchCategory: false,
      detailInfo: {},
      domain: process.env.VUE_APP_DOMAIN_FILE_SERVER
    }
  },
  computed: {
    ...mapState({
      listItems: state => state.categoryList.stateData.listItems,
      totalItems: state => state.categoryList.stateData.totalItems
    })
  },
  beforeCreate() {
    this.page = 1;
  },
  created() {
    this.$store.dispatch('categoryList/getListData').then(
      () => { 
        this.showPaging = true;
        this.isSearchCategory = false;
      },
      (error) => {
        console.log(error);
        this.showPaging = false;
        errorAlert("error","Network Error",'Can not get list data category!');
      }
    );
  },
  methods: {
    onChangePage(page, wantCallEmit=true) {
      this.page = page;
      this.isLoading = true;
      this.isSearchCategory = false;

      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('categoryList/getListData', page).then(
        () => { 
          this.isLoading = false;
          this.showPaging = true;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showPaging = false;
          errorAlert("error", "Network Error", "Can not get list data category !");
        }
      );
    },
    onChangeSearch(page, searchKey, wantCallEmit=true) {
      if(!this.keyword) {
        this.onChangePage(1);
        return true;
      }
      this.isLoading = true;
      this.isSearchCategory = true;
      this.page = page;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('categoryList/searchCategory', {"page": page, "searchKey":  this.keyword})
      .then(
        () => { 
          this.isLoading = false;
          this.showPaging = this.totalItems > 0 ? true : false;
        },
        (error) => {
          this.isLoading = false;
          this.showPaging = false;
          console.log(error);
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    deleteCategory(categoryId) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;

          this.$store.dispatch('categoryList/deleteCategory', categoryId).then(
            () => { 
              this.isLoading = false;
              this.showPaging = true;
              deletedAlert();
              this.isSearchCategory = false;
              if (this.keyword) {
                this.onChangeSearch(1);
              } else {
                this.onChangePage(1);
              }
            },
            (error) => {
              this.isLoading = false;
              this.showPaging = false;
              let resp = error.response;
              if (resp.status == 406){
                errorAlert("error", "Delete Not Allowed", resp.data.detail);
              }
              else{
                 errorAlert("error", "Network Error", "Failed to deleted Category !");
              }
            }
          );
        }
      )
    },
    handleShowDetail(categoryId) {
      this.isLoading = true;
      this.$store.dispatch('categoryList/getCategoryInfo', categoryId).then(
        (response) => { 
          this.isLoading = false;
          this.detailInfo = response;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.detailInfo = {};
          errorAlert("error","Network Error",'Can not get Category information !');
        }
      );
    },
  }
}
</script>

<style>
  a.icon-delete {
    cursor: pointer;
  }
  a.icon-delete:hover {
    color: red !important;
  }
</style>