<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Shipment List</span>
    </h4>

    <div class="row">
      <!-- Product List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body p-2">
            <div class="">
              <div class="row mb-4">
                <div class="col-12 col-md-6">
                </div>
                <div class="col-5 col-md-5 mt-3">
                  <div class="input-group input-group-merge">
                    <span class="input-group-text"><i class="bx bx-search"></i></span>
                    <input 
                      type="text" 
                      class="form-control" 
                      placeholder="Search contract, invoice, receiving, delivery" 
                      v-model="keyword"
                      @change="onChangeSearch(page, keyword)"
                    >
                  </div>
                </div>
              </div>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="pb-3 px-3">Contract No</th>
                    <th class="pb-3">Invoice No</th>
                    <th class="pb-3">Supplier</th>
                    <th class="pb-3">Customer</th>
                    <th class="pb-3">Products</th>
                    <th class="pb-3 px-2">Actions</th>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0" style="font-size: 0.86rem !important">
                  <tr v-for="(item, index) in listItems" :key="index">
                    <td class="py-2 px-1 width-15-percent">
                      <a href="#" class="text-body me-3" 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasScroll"
                        @click="handleShowShipmentDetail(item.contract_id)"
                      >
                        <strong>{{ item.contract_code }}</strong>
                      </a>
                    </td>
                    <td class="py-2 width-15-percent">
                      <strong>{{ item.invoice_code }}</strong>
                    </td>
                    <td class="py-2 width-25-percent">
                      <div>
                        <span v-tooltip="item.supplier_name">
                          {{ item.supplier_name }}
                        </span>
                      </div>
                    </td>
                    <td class="py-2 width-25-percent">
                      <div>
                        <span v-tooltip="item.customer_name">
                          {{ item.customer_name }}
                        </span>
                      </div>
                    </td>
                    <td class="py-2 px-1 width-20-percent">
                      <div class="text-truncate" style="max-width: 200px">
                        <span v-tooltip="item.product_name">
                          {{ item.product_name }}
                        </span>
                      </div>
                    </td>
                    <td class="py-2">
                      <div style="padding-left: 0.7rem;">
                        <a class="text-body icon-delete" 
                          data-bs-placement="top" 
                          @click="deleteShipment(item.receiving_voucher_id, item.delivery_voucher_id, item.invoice_id)"
                        >
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <div class="row mx-2">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <common-paging 
                    :items="listItems" 
                    :totalItems="totalItems" 
                    @changePage="onChangePage"
                    @changeSearchPage="onChangeSearch"
                    :activePageId="page"
                    v-show="showPaging"
                    :isSearch="isSearchCode"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- OffCanvas from the right -->
    <div class="row display-position" style="font-size: 0.9rem;">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScroll" 
        aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility:visible; width:78%;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0">
          <div class="d-flex flex-wrap my-2" :key="componentKey">
            <div class="col-sm-6">
              <span class="fw-bold me-2">CONTRACT CODE: </span>
              <span class="fw-bold text-primary">{{ shipmentDetail.contract_code }}</span>
            </div>
            <!-- <div class="col-sm-6 text-end">
              <span class="fw-bold me-2"></span>
              <span class="fw-bold text-primary"></span>
            </div> -->
            <div class="col-sm-6 text-end">
              <span class="fw-bold me-2">Number of Receiving-Delivery-Invoice:</span>
              <span class="fw-bold text-primary">{{ getNumberSets(shipmentDetail) + " set(s)"}}</span>
            </div>
             <div class="col-sm-6 mt-2">
              <span class="fw-bold me-2">Quantity in Contract:</span>
              <span class="fw-bold text-primary">{{ formatCurrency(getTotalContractQuantity(shipmentDetail)) + " PIECES"}}</span>
            </div>
            <div class="col-sm-6 text-end mt-2">
              <span class="fw-bold me-2">PRODUCT LIST:</span>
              <span class="fw-bold text-primary">{{ shipmentDetail.contract_details != undefined ? shipmentDetail.contract_details.length + " item(s)" : ""}}</span>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold me-2">Total Purchase Quantity:</span>
              <span class="fw-bold text-primary">{{ formatCurrency(getTotalPurchaseQuantity(shipmentDetail)) + " PIECES"}}</span>
            </div>
            <div class="col-sm-6 text-end mt-2">
              <span class="fw-bold me-2">Total Sales Quantity:</span>
              <span class="fw-bold text-primary">{{ formatCurrency(getTotalSalesQuantity(shipmentDetail)) + " PIECES"}}</span>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold me-2">Total Purchase Amount (before Tax):</span>
              <span class="fw-bold text-primary me-1">VND</span>
              <span class="fw-bold text-primary">{{ formatCurrency(getTotalPurchaseAmount(shipmentDetail)) }}</span>
            </div>
            <div class="col-sm-6 text-end mt-2">
              <span class="fw-bold me-2">Total Sales Amount (before Tax):</span>
              <span class="fw-bold text-primary me-1">VND</span>
              <span class="fw-bold text-primary">{{ formatCurrency(getTotalSalesAmount(shipmentDetail)) }}</span>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold me-2">Total Profit Amount ~ Rate:</span>
              <span class="fw-bold text-success me-2" v-if="getTotalProfitAmount(shipmentDetail) > 0">{{ "VND " + formatCurrency(getTotalProfitAmount(shipmentDetail)) + " 〜 "}}</span>
              <span class="fw-bold text-danger me-2" v-else>{{ "VND " + formatCurrency(getTotalProfitAmount(shipmentDetail)) + "&nbsp;&nbsp;&nbsp; 〜 &nbsp;" }}</span>
              <span class="fw-bold text-success" v-if="getTotalProfitAmount(shipmentDetail) > 0">{{ formatCurrency(getTotalProfitPercent(shipmentDetail)) + " %"}}</span>
              <span class="fw-bold text-danger" v-else>{{ formatCurrency(getTotalProfitPercent(shipmentDetail)) + " %"}}</span>
            </div>
          </div>
          <h5 class="pb-2 border-bottom mb-2"></h5>
          <div class="info-container">
            <ul class="list-unstyled" v-for="(detail, index) in shipmentDetail.contract_details" :key="index">
              <li>
                <div class="d-flex flex-wrap">
                  <div class="col-sm-6 mt-2">
                    <span class="fw-bold text-warning me-1">{{ detail.product_name }}</span>
                  </div>
                  <div class="col-sm-6 mt-2 text-end">
                    <span class="fw-bold me-2">Product Code:</span>
                    <span class="fw-bold text-warning">{{ detail.product_code }}</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex flex-wrap">
                  <div class="col-sm-6 mt-2">
                    <span class="fw-bold me-1">Purchase TAX: </span>
                    <span class="fw-bold text-warning me-1">{{ detail.tax_buy_name_1 ? detail.tax_buy_name_1 + ": " + detail.tax_buy_value_1 + "%" : ""}}</span>
                    <span class="fw-bold text-warning me-1">{{ detail.tax_buy_name_2 ? ", " + detail.tax_buy_name_2 + ": " + detail.tax_buy_value_2 + "%" : ""}}</span>
                    <span class="fw-bold text-warning me-1">{{ detail.tax_buy_name_3 ? ", " + detail.tax_buy_name_3 + ": " + detail.tax_buy_value_3 + "%" : ""}}</span>
                  </div>
                  <div class="col-sm-6 mt-2 text-end">
                    <span class="fw-bold me-2">Sell TAX:</span>
                    <span class="fw-bold text-warning me-1">{{ detail.tax_sell_name_1 ? detail.tax_sell_name_1 + ": " + detail.tax_sell_value_1 + "%" : ""}}</span>
                    <span class="fw-bold text-warning me-1">{{ detail.tax_sell_name_2 ? ", " + detail.tax_sell_name_2 + ": " + detail.tax_sell_value_2 + "%" : ""}}</span>
                    <span class="fw-bold text-warning me-1">{{ detail.tax_sell_name_3 ? ", " + detail.tax_sell_name_3 + ": " + detail.tax_sell_value_3 + "%" : ""}}</span>
                  </div>
                </div>
              </li>
              <li class="mb-2">
                <div class="d-flex flex-wrap">
                  <div class="col-sm-6 mt-2">
                    <span class="fw-bold me-1">Buy Price: </span>
                    <span class="fw-bold text-warning me-1">{{ shipmentDetail.buy_currency }}</span>
                    <span class="fw-bold text-warning me-1">{{ formatCurrency(detail.buy_price) }}</span>
                    <span class="fw-bold text-warning me-1">{{ shipmentDetail.buy_currency !== "VND" ? " (Ex.rate: VND " + formatCurrency(shipmentDetail.buy_exchange_rates) + ")" : "" }}</span>
                  </div>
                  <div class="col-sm-6 mt-2 text-end">
                    <span class="fw-bold me-2">Sell Price:</span>
                    <span class="fw-bold text-warning me-1">{{ shipmentDetail.sell_currency }}</span>
                    <span class="fw-bold text-warning me-1">{{ formatCurrency(detail.sell_price) }}</span>
                    <!-- <span class="fw-bold text-warning me-1">{{ shipmentDetail.sell_currency !== "VND" ? " (Ex.rate: VND " + formatCurrency(shipmentDetail.sell_exchange_rates) + ")" : "" }}</span> -->
                  </div>
                </div>
              </li>
              <!-- <li class="mb-2">
                <div class="d-flex flex-wrap">
                  <div class="col-sm-6 mt-2">
                    <span class="fw-bold me-1">Buy Price in VND: </span>
                    <span class="fw-bold text-warning me-1">VND</span>
                    <span class="fw-bold text-warning me-1">{{ formatCurrency(detail.buy_price * shipmentDetail.buy_exchange_rates) }}</span>
                  </div>
                  <div class="col-sm-6 mt-2 text-end">
                    <span class="fw-bold me-2">Sell Price in VND:</span>
                    <span class="fw-bold text-warning me-1">VND</span>
                    <span class="fw-bold text-warning me-1">{{ formatCurrency(detail.sell_price * shipmentDetail.sell_exchange_rates) }}</span>
                  </div>
                </div>
              </li> -->
							<li class="mb-3">
								<div class="table-responsive">
									<table class="table ">
										<thead>
											<tr>
												<th class="border-top-0">Invoice No</th>
                        <!-- <th class="border-top-0 text-end" style="max-width: 5rem;">Exchange Rate</th> -->
                        <th class="border-top-0 text-end" style="max-width: 5rem;">PURCHASE Ex.Rate</th>
                        <th class="border-top-0 text-end" style="max-width: 5rem;">SALES Ex.Rate</th>
                        <th class="border-top-0 text-end">Qty.In</th>
												<th class="border-top-0 text-end">Qty.Out</th>
												<th class="border-top-0 text-end">Purchase Amount</th>
                        <th class="border-top-0 text-end">Sell Amount</th>
											</tr>
										</thead>
										<tbody>
                      <template v-for="(productItem, index_prod) in shipmentDetail.shipment_lists" :key="index_prod">
                        <tr v-if="productItem.product_id == detail.product_id">
                          <td>{{ productItem.invoice_code }}</td>
                          <td class="text-end">VND {{ formatCurrency(productItem.buy_exchange_rates) }}</td>
                          <td class="text-end">VND {{ formatCurrency(productItem.sell_exchange_rates) }}</td>
                          <td class="text-end">{{ formatCurrency(productItem.quantity_in) }}</td>
                          <td class="text-end">{{ formatCurrency(productItem.quantity_out) }}</td>
                          <td class="text-end">VND {{ formatCurrency(productItem.quantity_in * productItem.buy_price * productItem.buy_exchange_rates) }}</td>
                          <td class="text-end">VND {{ formatCurrency(productItem.quantity_out * productItem.sell_price * productItem.sell_exchange_rates) }}</td>
                        </tr>
                      </template>
                      <tr>
                        <td colspan="3" class="fw-bold text-center">PRODUCT TOTAL AMOUNT (VND)</td>
                        <td class="text-end">{{ formatCurrency(getTotalQuantityIn(detail.product_id)) }}</td>
                        <td class="text-end">{{ formatCurrency(getTotalQuantityOut(detail.product_id)) }}</td>
                        <td class="text-end">VND {{ formatCurrency(getPurchaseTotalAmount(detail.product_id)) }}</td>
                        <td class="text-end">VND {{ formatCurrency(getSellTotalAmount(detail.product_id)) }}</td>
                      </tr>
                      <tr>
                        <td colspan="3" class="fw-bold text-center">PRODUCT TOTAL PROFIT (VND)</td>
                        <td colspan="3" class="fw-bold">
                          <span>{{"VND " + formatCurrency(getProfit(detail.product_id)) }}</span>
                          <span class="me-3 mx-3">〜</span>
                          <span>{{ getProfitPercentage(detail.product_id) + " %"}}</span>
                        </td>
                        <td></td>
                      </tr>
										</tbody>
									</table>
								</div>
							</li>
            </ul>
            <div class="row mt-5">
              <div class="col-sm-6">
                <span class="fw-bold text-primary">DOCUMENT FILES: </span>
              </div>
              <div class="col-sm-6 text-end">
                <span class="fw-bold me-2"></span>
                <span class="fw-bold text-primary"></span>
              </div>
              <div class="col-sm-6 mt-3">
                <ul class="list-group mb-4" v-for="(document, index_doc) in shipmentDetail.document_lists" :key="index_doc">
                  <li class="list-group-item d-flex justify-content-between align-items-center active">
                    <span class="fw-bold me-3">Receiving Voucher No:</span>
                    <span class="fw-bold text-end">{{ document.receiving_voucher_no }}</span>
                    <span class="fw-bold cursor-pointer print-hover" 
                      title="Export Receiving Voucher"
                      @click="exportReceivingVoucher(document.receiving_voucher_id)"
                    >
                      <i class='bx bxs-printer fs-4'></i>
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-if="document.supplier_PXK">
                    <a 
                      :href="domain + document.supplier_PXK"
                      target="_blank"
                    >
                      <span class="fw-bolder">{{ getNameFile(document.supplier_PXK) }}</span>
                    </a>
                    <div class="text-end">
                      <span class="badge bg-primary cursor-pointer hover-download me-2" @click="downloadFile(document.supplier_PXK, document.receiving_voucher_no)">
                        <i class='bx bxs-download fs-6'></i>
                      </span>
                      <span class="badge bg-primary cursor-pointer hover-color" @click="removeFile(document.invoice_code_id, 'file_1')">
                        <i class='bx bxs-eraser fs-6'></i>
                      </span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center py-1" v-else>
                    <span>SUPPLIER PXK: No File Uploaded !</span>
                    <input 
                      class="form-control" 
                      type="file" 
                      accept=".jpg, .png, .pdf"
                      style="max-width: 6.5rem; max-height: 2.3 rem;"
                      :name='"file_1["+document.receiving_voucher_id+"]"'
                      @change="onFileChange(shipmentDetail.contract_code, document.invoice_code_id, document.invoice_code)"
                    >
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-if="document.input_VAT">
                    <a 
                      :href="domain + document.input_VAT"
                      target="_blank"
                    >
                      <span class="fw-bolder">{{ getNameFile(document.input_VAT) }}</span>
                    </a>
                    <div class="text-end">
                      <span class="badge bg-primary cursor-pointer hover-download me-2" @click="downloadFile(document.input_VAT, document.receiving_voucher_no)">
                      <i class='bx bxs-download fs-6'></i>
                    </span>
                      <span class="badge bg-primary cursor-pointer hover-color" @click="removeFile(document.invoice_code_id, 'file_2')">
                        <i class='bx bxs-eraser fs-6'></i>
                      </span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center py-1" v-else>
                    <span>INPUT VAT: No File Uploaded !</span>
                    <input 
                      class="form-control" 
                      type="file" 
                      accept=".jpg, .png, .pdf"
                      style="max-width: 6.5rem; max-height: 2.3 rem;"
                      :name='"file_2["+document.receiving_voucher_id+"]"'
                      @change="onFileChange(shipmentDetail.contract_code, document.invoice_code_id, document.invoice_code)"
                    >
                  </li>
                </ul>
              </div>
              <div class="col-sm-6 mt-3">
                <ul class="list-group mb-4" v-for="(document, index_doc) in shipmentDetail.document_lists" :key="index_doc">
                  <li class="list-group-item d-flex justify-content-between align-items-center active">
                    <span class="fw-bold me-2">Delivery Voucher No:</span>
                    <span class="fw-bold text-end">
                      {{ document.delivery_voucher_no }}
                    </span>
                    <span class="fw-bold cursor-pointer print-hover" 
                      title="Export Delivery Voucher"
                      @click="exportDeliveryVoucher(document.delivery_voucher_id)"
                    >
                      <i class='bx bxs-printer fs-4'></i>
                    </span>
                    <span class="fw-bold cursor-pointer" 
                      title="Export Invoice File"
                      @click="exportInvoice(document.invoice_id)"
                    >
                      <span v-tooltip="'Export Invoice: ' + document.invoice_code">
                        <img src="/assets/img/icons/unicons/inv-icon.svg" width="22"/>
                      </span>
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-if="document.lamoda_PXK">
                    <a 
                      :href="domain + document.lamoda_PXK"
                      target="_blank"
                    >
                      <span class="fw-bolder">{{ getNameFile(document.lamoda_PXK) }}</span>
                    </a>
                    <div class="text-end">
                      <span class="badge bg-primary cursor-pointer hover-download me-2" @click="downloadFile(document.lamoda_PXK, document.invoice_code)">
                        <i class='bx bxs-download fs-6'></i>
                      </span>
                      <span class="badge bg-primary cursor-pointer hover-color" @click="removeFile(document.invoice_code_id, 'file_3')">
                        <i class='bx bxs-eraser fs-6'></i>
                      </span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center py-1" v-else>
                    <span>LAMODA PXK: No File Uploaded !</span>
                    <input 
                      class="form-control" 
                      type="file" 
                      accept=".jpg, .png, .pdf"
                      style="max-width: 6.5rem; max-height: 2.3 rem;"
                      :name='"file_3["+document.receiving_voucher_id+"]"'
                      @change="onFileChange(shipmentDetail.contract_code, document.invoice_code_id, document.invoice_code)"
                    >
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-if="document.output_VAT">
                    <a 
                      :href="domain + document.output_VAT"
                      target="_blank"
                    >
                      <span class="fw-bolder">{{ getNameFile(document.output_VAT) }}</span>
                    </a>
                    <div class="text-end">
                      <span class="badge bg-primary cursor-pointer hover-download me-2" @click="downloadFile(document.output_VAT, document.invoice_code)">
                      <i class='bx bxs-download fs-6'></i>
                    </span>
                      <span class="badge bg-primary cursor-pointer hover-color" @click="removeFile(document.invoice_code_id, 'file_4')">
                        <i class='bx bxs-eraser fs-6'></i>
                      </span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center py-1" v-else>
                    <span>OUTPUT VAT: No File Uploaded !</span>
                    <input 
                      class="form-control" 
                      type="file" 
                      accept=".jpg, .png, .pdf"
                      style="max-width: 6.5rem; max-height: 2.3 rem;"
                      :name='"file_4["+document.receiving_voucher_id+"]"'
                      @change="onFileChange(shipmentDetail.contract_code, document.invoice_code_id, document.invoice_code)"
                    >
                  </li>
                </ul>
              </div>
            </div>

            <!-- Cost Detail Part -->
            <div class="row mt-5" style="font-size: 0.8rem;">
              <div class="col-sm-3 pt-2" style="font-size: 0.9rem;">
                <span class="fw-bold text-primary">COST DETAILS (Unit: VND)</span>
              </div>
              <div class="col-sm-3 pt-2 px-4 text-end">

              </div>
              <div class="col-sm-6 text-end">
                <button type="button" class="btn btn-outline-primary w-50" @click="modifyCost()">
                  <i class="menu-icon tf-icons bx bx-edit-alt"></i> 
                  Modify Cost 
                </button>
              </div>
              <div class="col-sm-4 mt-3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between align-items-center total-cost">
                    <span class="fw-bold">TOTAL COST</span>
                    <span class="fw-bold text-end">{{ formatCurrency(calculateTotalContractCost()) }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center active">
                    <span class="fw-bold">IMPORT / EXPORT</span>
                    <span class="fw-bold text-end">{{ formatCurrency(getImportExportFee(shipmentDetail)) }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Customs Duty Fee</span>
                    </div>
                    <div class="col-sm-6 text-end">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_customs_duty) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Freight Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_freight) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Customs&LCC Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_customs_lcc) }}</span>
                    </div>
                  </li>
                </ul>
                <ul class="list-group list-group-flush mb-31">
                  <li class="list-group-item d-flex justify-content-between align-items-center active">
                    <span class="fw-bold">TRANS & WAREHOUSE</span>
                    <span class="fw-bold text-end">{{ formatCurrency(getTransportWarehouseFee(shipmentDetail)) }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Trucking Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_trucking) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Storage Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_storage) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Labeling Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_labeling) }}</span>
                    </div>
                  </li>
                </ul>
                <ul class="list-group list-group-flush mb-31">
                  <li class="list-group-item d-flex justify-content-between align-items-center active">
                    <span class="fw-bold">TEST & INSURANCE</span>
                    <span class="fw-bold text-end">{{ formatCurrency(getTestingInsuranceFee(shipmentDetail)) }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Inspection Fee</span>
                    </div>
                    <div class="col-sm-6 text-end">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_inspection) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Testing Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_testing) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Sample Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_sample) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Ocean Marine Fee</span>
                    </div>
                    <div class="col-sm-6 text-end">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_ocean_marine) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Inland Logistic Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_inland_logistic) }}</span>
                    </div>
                  </li>
                </ul>
                <ul class="list-group list-group-flush mb-31">
                  <li class="list-group-item d-flex justify-content-between align-items-center active">
                    <span class="fw-bold">OTHER</span>
                    <span class="fw-bold text-end">{{ formatCurrency(getOtherFee(shipmentDetail)) }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Design Fee</span>
                    </div>
                    <div class="col-sm-6 text-end">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_design) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Accesories Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_accesories) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Develop Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_develop) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0">
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Commission Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_commission) }}</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center fee-border p-0"
                    style="border-bottom: solid 1px lightgray;"
                  >
                    <div class="col-sm-6">
                      <span class="fw-bold mx-2">Royalty Fee</span>
                    </div>
                    <div class="col-sm-6">
                      <span class="form-control text-end cost-contract">{{ formatCurrency(shipmentDetail.fee_royalty) }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-sm-2 mt-3" v-for="(cost, index_cost) in shipmentDetail.invoice_lists" :key="index_cost">
                <shipment-cost-detail :cost_detail="cost" :key="componentKeyDetail"
                  @changeTotalCostShipment="handleChangeTotalCostShipment"
                >
                </shipment-cost-detail>
              </div>
            </div>
            <!-- / Cost Detail Part -->

            <div class="row mt-5">
              <div class="col-md-3">
                <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
                  <i class='bx bx-arrow-back' ></i>
                  Back to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import ShipmentCostDetail from "./ShipmentCostDetail";
import commonPaging from "../common/CommonPaging"
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import {errorAlert, deletedAlert} from "../../helper/error.js"
import showConfirmDeleteAlert from "../../helper/error.js"
import _ from 'lodash';
import { utilities } from "../../services/utilities.js";
import { saveAs } from 'file-saver';
import axios from 'axios';

export default {
  name: 'ShipmentList',
  components: {
    commonPaging,
    RiseLoader,
    ShipmentCostDetail
  },
  data() {
    return {
      page: 1,
      showPaging: false,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      keyword: "",
      isSearchCode: false,
      shipmentDetail: {},
      domain: process.env.VUE_APP_DOMAIN_FILE_SERVER,
      componentKey: 0,
      componentKeyDetail: 0,
      invoiceIds: null,
      cleaveOptions: {
        fee: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      }
    }
  },
  computed: {
    ...mapState({
      listItems: state => state.shipmentList.stateData.listItems,
      totalItems: state => state.shipmentList.stateData.totalItems
    })
  },
  beforeCreate() {
    this.page = 1;
  },
  created() {
    this.$store.dispatch('shipmentList/getListData').then(
      () => { 
        this.showPaging = true;
        this.isSearchCode = false;
      },
      (error) => {
        console.log(error);
        this.showPaging = false;
        errorAlert("error","Network Error",'Can not get Contract list data!');
      }
    );
  },
  methods: {
    onChangePage(page, wantCallEmit=true) {
      this.page = page;
      this.isLoading = true;
      this.isSearchContractCode = false;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('shipmentList/getListData', page).then(
        () => { 
          this.isLoading = false;
          this.showPaging = true;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showPaging = false;
          errorAlert("error", "Network Error", "Can not get contract registration list data!");
        }
      );
    },
    onChangeSearch(page, searchKey, wantCallEmit=true) {
      if(!this.keyword) {
        this.onChangePage(1);
        return true;
      }
      this.isLoading = true;
      this.isSearchContractCode = true;
      this.page = page;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('shipmentList/searchShipment', {"page": page, "searchKey":  this.keyword})
      .then(
        () => { 
          this.isLoading = false;
          this.showPaging = this.totalItems > 0 ? true : false;
        },
        (error) => {
          this.isLoading = false;
          this.showPaging = false;
          console.log(error);
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    handleShowShipmentDetail(contractID) {
      this.isLoading = true;
      this.$store.dispatch('shipmentList/getShipmentInfo', contractID).then(
        (response) => { 
          this.isLoading = false;
          this.shipmentDetail = response;
          this.totalCost = this.calculateTotalInvoiceCost();
          this.componentKeyDetail += 1;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.shipmentDetail = {};
          errorAlert("error","Network Error",'Can not get Shipment information !');
        }
      );
    },
    changeFormateDate(date) {
      return this.$moment(date).format("DD MMMM YYYY");
    },
    formatCurrency(value) {
      return utilities.getFormatCurrency(value);
    },
    getNumberSets(shipmentObject) {
      if (_.isEmpty(this.shipmentDetail) || _.isNil(this.shipmentDetail)) return 0;

      if (!_.isNil(shipmentObject.shipment_lists) && !_.isNil(shipmentObject.contract_details)) {
        return shipmentObject.shipment_lists.length / shipmentObject.contract_details.length;
      }
      return 0;
    },
    getTotalQuantityIn(productId) {
      let list = this.shipmentDetail.shipment_lists;
      let result = 0;
      if (list.length > 0) {
        list.forEach((item) => {
          if (item.product_id == productId) {
           result += item.quantity_in;
          }
        });
      }
      return result;
    },
    getTotalQuantityOut(productId) {
      let list = this.shipmentDetail.shipment_lists;
      let result = 0;
      if (list.length > 0) {
        list.forEach((item) => {
          if (item.product_id == productId) {
           result += item.quantity_out;
          }
        });
      }
      return result;
    },
    getPurchaseTotalAmount(productId) {
      let list = this.shipmentDetail.shipment_lists;
      let result = 0;
      if (list.length > 0) {
        list.forEach((item) => {
          if (item.product_id == productId) {
           result += (item.quantity_in * item.buy_price * item.buy_exchange_rates);
          }
        });
      }
      return result;
    },
    getSellTotalAmount(productId) {
      let list = this.shipmentDetail.shipment_lists;
      let result = 0;
      if (list.length > 0) {
        list.forEach((item) => {
          if (item.product_id == productId) {
           result += (item.quantity_out * item.sell_price * item.sell_exchange_rates);
          }
        });
      }
      return result;
    },
    getProfit(productId) {
      let list = this.shipmentDetail.shipment_lists;
      let result = 0;
      if (list.length > 0) {
        let buy_amount = this.getPurchaseTotalAmount(productId);
        let sell_amount = this.getSellTotalAmount(productId);
        result = sell_amount - buy_amount;
      }
      return result;
    },
    getProfitPercentage(productId) {
      let list = this.shipmentDetail.shipment_lists;
      let result = 0;
      if (list.length > 0) {
        let buy_amount = this.getPurchaseTotalAmount(productId);
        let sell_amount = this.getSellTotalAmount(productId);
        result = (sell_amount - buy_amount) * 100 / sell_amount;
      }
      return parseFloat(result).toFixed(2);
    },
    getTotalContractQuantity(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;
      let result = 0;
      if (!_.isNil(shipmentObject.contract_details)) {
        shipmentObject.contract_details.forEach(item => {
          // Always quantity_in = quantity_out in contract data
          result += item.quantity_in
        });
      }
      return result;
    },
    getTotalPurchaseQuantity(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;
      let result = 0;
      if (!_.isNil(shipmentObject.shipment_lists)) {
        shipmentObject.shipment_lists.forEach(item => {
          result += item.quantity_in
        });
      }
      return result;
    },
    getTotalSalesQuantity(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;
      let result = 0;
      if (!_.isNil(shipmentObject.shipment_lists)) {
        shipmentObject.shipment_lists.forEach(item => {
          result += item.quantity_out
        });
      }
      return result;
    },
    getTotalPurchaseAmount(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;
      let result = 0;
      if (!_.isNil(shipmentObject.shipment_lists)) {
        shipmentObject.shipment_lists.forEach(item => {
          result += (item.quantity_in * item.buy_price * item.buy_exchange_rates);
        });
      }
      return result + this.totalCost;
    },
    getTotalSalesAmount(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;
      let result = 0;
      if (!_.isNil(shipmentObject.shipment_lists)) {
        shipmentObject.shipment_lists.forEach(item => {
          result += (item.quantity_out * item.sell_price * item.sell_exchange_rates);
        });
      }
      return result;
    },
    getTotalProfitAmount(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;
      return  this.getTotalSalesAmount(shipmentObject) - this.getTotalPurchaseAmount(shipmentObject);
    },
    getTotalProfitPercent(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;
      return (this.getTotalSalesAmount(shipmentObject) - this.getTotalPurchaseAmount(shipmentObject)) * 100 / this.getTotalSalesAmount(shipmentObject);
    },
    deleteShipment(receivingVoucherId, deliveryVoucherId, invoiceId) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;

          this.$store.dispatch('shipmentList/deleteShipment', 
            {"receiving_id": receivingVoucherId, "delivery_id": deliveryVoucherId, "invoice_id": invoiceId}
          ).then(
            () => { 
              this.isLoading = false;
              this.showPaging = true;
              deletedAlert();
              this.isSearchCode = false;
              if (this.keyword) {
                this.onChangeSearch(1);
              } else {
                this.onChangePage(1);
              }
            },
            (error) => {
              console.log(error);
              this.isLoading = false;
              this.showPaging = false;
              errorAlert("error", "Network Error", "Failed to deleted shipment information!");
            }
          );
        }
      )
    },
    getNameFile(file_name) {
      if (!file_name) return "";
      let file_type = file_name.split(".");
      let split_file =  file_name.split("/");
      return split_file[split_file.length-1].split("___")[0] +"."+file_type[file_type.length-1];
    },
    downloadFile(url, code) {
      let code_name = code ? code + "-" : ""
      axios
        .get(this.domain+url, {responseType: 'blob'})
        .then(response => {
            saveAs(response.data, code_name + this.getNameFile(url));
        })
    },
    onFileChange(contract_code, invoice_code_id, invoice_code) {
      let selectedFiles = window.event.target.files;
      let fileName = window.event.target.name;
      if (selectedFiles.length > 0) {
        // Handling upload file onto server
        this.submitImageFile(contract_code, invoice_code_id, invoice_code, fileName, selectedFiles[0]);
      }
    },
    submitImageFile(contract_code, invoice_code_id, invoice_code, el_name, file_upload) {
      let formData = new FormData();

      formData.append("files", file_upload);
      formData.append("el_name", el_name);
      formData.append("invoice_code_id", invoice_code_id);
      formData.append("invoice_code", invoice_code);
      formData.append("contract_code", contract_code);

      this.$store.dispatch('shipmentList/uploadShipmentFileAfter', formData).then(
        (response) => {
          // Set upload value into document_list
          this.shipmentDetail.document_lists.forEach(item => {
            if (item.invoice_code_id == invoice_code_id) {
              if(el_name.includes("file_1")) {
                item.supplier_PXK = response.file_path
              }
              if(el_name.includes("file_2")) {
                item.input_VAT = response.file_path
              }
              if(el_name.includes("file_3")) {
                item.lamoda_PXK = response.file_path
              }
              if(el_name.includes("file_4")) {
                item.output_VAT = response.file_path
              }
            }
          });
        },
        error => {
          console.log(error);
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to upload image !'
          });
        }
      );
    },
    removeFile(invoice_code_id, el_name) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;

          this.$store.dispatch('shipmentList/deleteUploadedFile', 
            {"invoice_code_id": invoice_code_id, "el_name": el_name}
          ).then(
            () => { 
              deletedAlert();
              // Set upload value into document_list
              this.shipmentDetail.document_lists.forEach(item => {
                if (item.invoice_code_id == invoice_code_id) {
                  if(el_name.includes("file_1")) {
                    item.supplier_PXK = "";
                  }
                  if(el_name.includes("file_2")) {
                    item.input_VAT = "";
                  }
                  if(el_name.includes("file_3")) {
                    item.lamoda_PXK = "";
                  }
                  if(el_name.includes("file_4")) {
                    item.output_VAT = "";
                  }
                }
              });
              this.isLoading = false;
            },
            (error) => {
              console.log(error);
              this.isLoading = false;
              errorAlert("error", "Network Error", "Failed to deleted upload file!");
            }
          );
        }
      )
    },
    exportDeliveryVoucher(delivery_id) {
      this.isLoading = true;
      this.$store.dispatch('shipmentList/exportDeliveryVoucherFile', delivery_id).then(
        (response) => { 
          this.isLoading = false;
          this.downloadFile(response.file_url, "");
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          errorAlert("error", "Network Error", "Sorry, server can not export the delivery voucher file!");
        }
      );
    },
    exportInvoice(invoice_id) {
      this.isLoading = true;
      this.$store.dispatch('shipmentList/exportInvoiceFile', invoice_id).then(
        (response) => { 
          this.isLoading = false;
          this.downloadFile(response.file_url, "");
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          errorAlert("error", "Network Error", "Sorry, server can not export the invoice file!");
        }
      );
    },
    exportReceivingVoucher(receiving_id) {
      this.isLoading = true;
      this.$store.dispatch('shipmentList/exportReceivingVoucherFile', receiving_id).then(
        (response) => { 
          this.isLoading = false;
          this.downloadFile(response.file_url, "");
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          errorAlert("error", "Network Error", "Sorry, server can not export the receiving voucher file!");
        }
      );
    },
    getImportExportFee(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;
      
      let fee_customs_duty = shipmentObject.fee_customs_duty ? shipmentObject.fee_customs_duty : 0;
      let fee_freight = shipmentObject.fee_freight ? shipmentObject.fee_freight : 0;
      let fee_customs_lcc = shipmentObject.fee_customs_lcc ? shipmentObject.fee_customs_lcc : 0;
      return parseFloat(fee_customs_duty) + parseFloat(fee_freight) + parseFloat(fee_customs_lcc);
    },
    getTransportWarehouseFee(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;

      let fee_trucking = shipmentObject.fee_trucking ? shipmentObject.fee_trucking : 0;
      let fee_storage = shipmentObject.fee_storage ? shipmentObject.fee_storage : 0;
      let fee_labeling = shipmentObject.fee_labeling ? shipmentObject.fee_labeling : 0;
      return parseFloat(fee_trucking) + parseFloat(fee_storage) + parseFloat(fee_labeling);
    },
    getTestingInsuranceFee(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;

      let fee_inspection = shipmentObject.fee_inspection ? shipmentObject.fee_inspection : 0;
      let fee_testing = shipmentObject.fee_testing ? shipmentObject.fee_testing : 0;
      let fee_sample = shipmentObject.fee_sample ? shipmentObject.fee_sample : 0;
      let fee_ocean_marine = shipmentObject.fee_ocean_marine ? shipmentObject.fee_ocean_marine : 0;
      let fee_inland_logistic = shipmentObject.fee_inland_logistic ? shipmentObject.fee_inland_logistic : 0;
      return parseFloat(fee_inspection) + parseFloat(fee_testing) + parseFloat(fee_sample) + 
        parseFloat(fee_ocean_marine) + parseFloat(fee_inland_logistic);
    },
    getOtherFee(shipmentObject) {
      if (_.isEmpty(shipmentObject) || _.isNil(shipmentObject)) return 0;

      let fee_design = shipmentObject.fee_design ? shipmentObject.fee_design : 0;
      let fee_accesories = shipmentObject.fee_accesories ? shipmentObject.fee_accesories : 0;
      let fee_develop = shipmentObject.fee_develop ? shipmentObject.fee_develop : 0;
      let fee_commission = shipmentObject.fee_commission ? shipmentObject.fee_commission : 0;
      let fee_royalty = shipmentObject.fee_royalty ? shipmentObject.fee_royalty : 0;
      return parseFloat(fee_design) + parseFloat(fee_accesories) + parseFloat(fee_develop) + 
        parseFloat(fee_commission) + parseFloat(fee_royalty);
    },
    modifyCost() {
      this.isLoading = true;
      this.$store.dispatch('shipmentList/modifyCostInvoices', this.shipmentDetail.invoice_lists).then(
        (response) => {
          this.isLoading = false;
          this.totalCost = this.calculateTotalInvoiceCost();
          this.componentKey += 1; 
          this.$swal.fire(
            'Saved',
            response.message,
            'success'
          );
        },
        error => {
          console.log(error);
          this.isLoading = false;
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to modify cost information !'
          });
        }
      );
    },
    calculateTotalContractCost() {
      if (_.isEmpty(this.shipmentDetail) || _.isNil(this.shipmentDetail)) return 0;

      let fee_customs_duty = this.shipmentDetail.fee_customs_duty ? this.shipmentDetail.fee_customs_duty : 0;
      let fee_freight = this.shipmentDetail.fee_freight ? this.shipmentDetail.fee_freight : 0;
      let fee_customs_lcc = this.shipmentDetail.fee_customs_lcc ? this.shipmentDetail.fee_customs_lcc : 0;
      let fee_trucking = this.shipmentDetail.fee_trucking ? this.shipmentDetail.fee_trucking : 0;
      let fee_storage = this.shipmentDetail.fee_storage ? this.shipmentDetail.fee_storage : 0;
      let fee_labeling = this.shipmentDetail.fee_labeling ? this.shipmentDetail.fee_labeling : 0;
      let fee_inspection = this.shipmentDetail.fee_inspection ? this.shipmentDetail.fee_inspection : 0;
      let fee_testing = this.shipmentDetail.fee_testing ? this.shipmentDetail.fee_testing : 0;
      let fee_sample = this.shipmentDetail.fee_sample ? this.shipmentDetail.fee_sample : 0;
      let fee_ocean_marine = this.shipmentDetail.fee_ocean_marine ? this.shipmentDetail.fee_ocean_marine : 0;
      let fee_inland_logistic = this.shipmentDetail.fee_inland_logistic ? this.shipmentDetail.fee_inland_logistic : 0;
      let fee_design = this.shipmentDetail.fee_design ? this.shipmentDetail.fee_design : 0;
      let fee_accesories = this.shipmentDetail.fee_accesories ? this.shipmentDetail.fee_accesories : 0;
      let fee_develop = this.shipmentDetail.fee_develop ? this.shipmentDetail.fee_develop : 0;
      let fee_commission = this.shipmentDetail.fee_commission ? this.shipmentDetail.fee_commission : 0;
      let fee_royalty = this.shipmentDetail.fee_royalty ? this.shipmentDetail.fee_royalty : 0;

      let result = parseFloat(fee_customs_duty) + parseFloat(fee_freight) + parseFloat(fee_customs_lcc) + 
        parseFloat(fee_trucking) + parseFloat(fee_storage) + parseFloat(fee_labeling) + 
        parseFloat(fee_ocean_marine) + parseFloat(fee_inland_logistic) + parseFloat(fee_inspection) + 
        parseFloat(fee_testing) + parseFloat(fee_sample) + parseFloat(fee_design) +
        parseFloat(fee_accesories) + parseFloat(fee_develop) + parseFloat(fee_commission) + parseFloat(fee_royalty);

      return result
    },
    calculateTotalInvoiceCost() {
      if (_.isEmpty(this.shipmentDetail) || _.isNil(this.shipmentDetail)) return 0;
      if (this.shipmentDetail.invoice_lists.length == 0) return 0;

      let result = 0;
      this.shipmentDetail.invoice_lists.forEach(item => {
        let fee_customs_duty = item.fee_customs_duty ? item.fee_customs_duty : 0;
        let fee_freight = item.fee_freight ? item.fee_freight : 0;
        let fee_customs_lcc = item.fee_customs_lcc ? item.fee_customs_lcc : 0;
        let fee_trucking = item.fee_trucking ? item.fee_trucking : 0;
        let fee_storage = item.fee_storage ? item.fee_storage : 0;
        let fee_labeling = item.fee_labeling ? item.fee_labeling : 0;
        let fee_inspection = item.fee_inspection ? item.fee_inspection : 0;
        let fee_testing = item.fee_testing ? item.fee_testing : 0;
        let fee_sample = item.fee_sample ? item.fee_sample : 0;
        let fee_ocean_marine = item.fee_ocean_marine ? item.fee_ocean_marine : 0;
        let fee_inland_logistic = item.fee_inland_logistic ? item.fee_inland_logistic : 0;
        let fee_design = item.fee_design ? item.fee_design : 0;
        let fee_accesories = item.fee_accesories ? item.fee_accesories : 0;
        let fee_develop = item.fee_develop ? item.fee_develop : 0;
        let fee_commission = item.fee_commission ? item.fee_commission : 0;
        let fee_royalty = item.fee_royalty ? item.fee_royalty : 0;

        result += parseFloat(fee_customs_duty) + parseFloat(fee_freight) + parseFloat(fee_customs_lcc) + 
          parseFloat(fee_trucking) + parseFloat(fee_storage) + parseFloat(fee_labeling) + 
          parseFloat(fee_ocean_marine) + parseFloat(fee_inland_logistic) + parseFloat(fee_inspection) + 
          parseFloat(fee_testing) + parseFloat(fee_sample) + parseFloat(fee_design) +
          parseFloat(fee_accesories) + parseFloat(fee_develop) + parseFloat(fee_commission) + parseFloat(fee_royalty);
      });
      

      return result
    },
    handleChangeTotalCostShipment(new_value) {
      console.log(new_value);
      this.totalCost = this.calculateTotalInvoiceCost();
      this.componentKey += 1;
    }
  },
}
</script>

<style scoped>
  a.icon-color {
    color: white;
  }
  div.display-position {
    position: relative;
    z-index: 999;
  }
  .hover-color:hover {
    background-color: red !important;
  }
  .hover-download:hover {
    background-color: #71DD37 !important;
  }
  .print-hover:hover {
    color: #71DD37;
  }
  .cost-contract {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: solid 1px lightgrey;
    border-top: 0px;
    font-size: 0.85rem;
  }
  .cost-input {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: 0px;
    border-top: 0px;
    background-color: lightyellow;
    font-size: 0.85rem;
  }
  .cost-input-last {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: 0px;
    border-top: 0px;
    background-color: lightyellow;
    border-bottom-right-radius: 10px;
    font-size: 0.85rem;
  }
  .list-group-item.total-cost {
    border-color: #ffab00;
    background-color: #ffab00;
    color: white;
  }
  .list-group .fee-border {
    border-left: solid 1px lightgray !important;
  }
</style>