<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span class="text-muted fw-light">Definition /</span> Edit
    </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <h5 class="card-header">Edit Definition</h5>
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col-md-6 col-lg-5 mb-3">
                  <label class="form-label" for="basic-icon-default-fullname">Type</label>
                  <div class="input-group input-group-merge">
                    <select id="defaultSelect" class="form-select" disabled>
                      <option value="quotation">Quotation</option>
                      <option value="partner">Partner</option>
                      <option value="contract">Contract</option>
                      <option value="product">Product</option>
                      <option value="invoice">Invoice</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-5 mb-3">
                  <label class="form-label">Code Symbol</label>
                  <div class="input-group input-group-merge">
                    <input type="text" class="form-control" placeholder="E" value="E">
                  </div>
                </div>
                <div class="col-md-6 col-lg-5 mb-3">
                  <label class="form-label">Code Name</label>
                  <div class="input-group input-group-merge">
                    <input type="text" class="form-control" placeholder="Export" value="Export">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-5 mb-3">
                  <div class="input-group input-group-merge">
                    <input type="text" class="form-control" placeholder="I" value="I">
                  </div>
                </div>
                <div class="col-md-6 col-lg-5 mb-3">
                  <div class="input-group input-group-merge">
                    <input type="text" class="form-control" placeholder="Import" value="Import">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-5 mb-3">
                  <div class="input-group input-group-merge">
                    <input type="text" class="form-control" placeholder="D" value="D">
                  </div>
                </div>
                <div class="col-md-6 col-lg-5 mb-3">
                  <div class="input-group input-group-merge">
                    <input type="text" class="form-control" placeholder="Domestic" value="Domestic">
                  </div>
                </div>
                <div class="col-md-6 col-lg-2 mb-3 plus-minus">
                  <button type="button" class="btn btn-sm rounded-pill btn-icon btn-outline-primary me-2">
                    <span class="tf-icons bx bx-plus"></span>
                  </button>
                  <!-- <button type="button" class="btn btn-sm rounded-pill btn-icon btn-outline-danger">
                    <span class="tf-icons bx bx-minus"></span>
                  </button> -->
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-5 mb-3">
                  <div class="input-group input-group-merge">
                    <input type="text" class="form-control" placeholder="Add a new code ..." value="">
                  </div>
                </div>
                <div class="col-md-6 col-lg-5 mb-3">
                  <div class="input-group input-group-merge">
                    <input type="text" class="form-control" placeholder="Add name of code ..." value="">
                  </div>
                </div>
                <div class="col-md-6 col-lg-2 mb-3 plus-minus">
                  <button type="button" class="btn btn-sm rounded-pill btn-icon btn-outline-primary me-2">
                    <span class="tf-icons bx bx-plus"></span>
                  </button>
                  <button type="button" class="btn btn-sm rounded-pill btn-icon btn-outline-danger">
                    <span class="tf-icons bx bx-minus"></span>
                  </button>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-6 col-lg-5 mb-3">
                  <button type="submit" class="btn btn-primary me-2">Update Code</button>
                  <router-link to="/definition" data-i18n="Create Definition" class="btn btn-outline-secondary">
                    <i class='bx bx-arrow-back' ></i>
                    Back To Definition
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->

</template>

<script>

  export default {
    name: 'DefinitionEdit',
    components: {
        
    },
    created() {

    },
  }
</script>