<template>
  <!-- Content -->
	<div class="">
			<ul class="list-group list-group-flush mb-31">
				<li class="list-group-item text-end total-cost">
					<span class="fw-bold">{{ formatCurrency(calculateTotalCost()) }}</span>
				</li>
				<li class="list-group-item text-end active">
					<span class="fw-bold">{{ formatCurrency(getImportExportFee(invoiceCostDetail)) }}</span>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_customs_duty"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_freight"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input-last"
							placeholder="0"
							v-model="invoiceCostDetail.fee_customs_lcc"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
			</ul>
			<ul class="list-group list-group-flush mb-31">
				<li class="list-group-item text-end active">
					<span class="fw-bold text-end">{{ formatCurrency(getTransportWarehouseFee(invoiceCostDetail)) }}</span>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_trucking"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_storage"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input-last"
							placeholder="0"
							v-model="invoiceCostDetail.fee_labeling"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
			</ul>
			<ul class="list-group list-group-flush mb-31">
				<li class="list-group-item text-end active">
					<span class="fw-bold text-end">{{ formatCurrency(getTestingInsuranceFee(invoiceCostDetail)) }}</span>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_inspection"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_testing"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_sample"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_ocean_marine"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input-last"
							placeholder="0"
							v-model="invoiceCostDetail.fee_inland_logistic"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
			</ul>
			<ul class="list-group list-group-flush mb-31">
				<li class="list-group-item text-end active">
					<span class="fw-bold text-end">{{ formatCurrency(getOtherFee(invoiceCostDetail)) }}</span>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_design"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_accesories"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
							<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_develop"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input"
							placeholder="0"
							v-model="invoiceCostDetail.fee_commission"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
				<li class="list-group-item d-flex justify-content-between align-items-center p-0">
					<div class="">
						<cleave
							class="form-control text-end cost-input-last"
							style="border-bottom: solid 1px lightgrey;"
							placeholder="0"
							v-model="invoiceCostDetail.fee_royalty"
							:options="cleaveOptions.fee"
						/>
					</div>
				</li>
			</ul>
		</div>
  <!-- / Content -->
</template>

<script>
import _ from 'lodash';
import { utilities } from "../../services/utilities.js";

export default {
  name: 'ShipmentCostDetail',
  components: {
  },
	props: {
    "cost_detail": {
			type: Object,
			required: true
		},
	},
  data(){
    return {
			invoiceCostDetail: this.cost_detail,
			cleaveOptions: {
        fee: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      }
    };
  },
  computed: {
  },
	watch: {
    "invoiceCostDetail.fee_customs_duty" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
		"invoiceCostDetail.fee_freight" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_customs_lcc" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_trucking" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_storage" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_labeling" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_ocean_marine" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_inland_logistic" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_inspection" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_testing" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_sample" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_design" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_accesories" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_develop" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    },
    "invoiceCostDetail.fee_commission" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal); 
    },
    "invoiceCostDetail.fee_royalty" (newVal, oldVal) {
      if(newVal == oldVal) return;
      this.totalCost = this.calculateTotalCost();
      this.$emit('changeTotalCostShipment', newVal);
    }
	},
  beforeCreate() {
		this.invoiceCostDetail = {};
  },
  created() {
  },
  methods: {
		formatCurrency(value) {
      return utilities.getFormatCurrency(value);
    },
		getImportExportFee(invoiceObject) {
      if (_.isEmpty(invoiceObject) || _.isNil(invoiceObject)) return 0;
      
      let fee_customs_duty = invoiceObject.fee_customs_duty ? invoiceObject.fee_customs_duty : 0;
      let fee_freight = invoiceObject.fee_freight ? invoiceObject.fee_freight : 0;
      let fee_customs_lcc = invoiceObject.fee_customs_lcc ? invoiceObject.fee_customs_lcc : 0;
      return parseFloat(fee_customs_duty) + parseFloat(fee_freight) + parseFloat(fee_customs_lcc);
    },
    getTransportWarehouseFee(invoiceObject) {
      if (_.isEmpty(invoiceObject) || _.isNil(invoiceObject)) return 0;

      let fee_trucking = invoiceObject.fee_trucking ? invoiceObject.fee_trucking : 0;
      let fee_storage = invoiceObject.fee_storage ? invoiceObject.fee_storage : 0;
      let fee_labeling = invoiceObject.fee_labeling ? invoiceObject.fee_labeling : 0;
      return parseFloat(fee_trucking) + parseFloat(fee_storage) + parseFloat(fee_labeling);
    },
    getTestingInsuranceFee(invoiceObject) {
      if (_.isEmpty(invoiceObject) || _.isNil(invoiceObject)) return 0;

      let fee_inspection = invoiceObject.fee_inspection ? invoiceObject.fee_inspection : 0;
      let fee_testing = invoiceObject.fee_testing ? invoiceObject.fee_testing : 0;
      let fee_sample = invoiceObject.fee_sample ? invoiceObject.fee_sample : 0;
      let fee_ocean_marine = invoiceObject.fee_ocean_marine ? invoiceObject.fee_ocean_marine : 0;
      let fee_inland_logistic = invoiceObject.fee_inland_logistic ? invoiceObject.fee_inland_logistic : 0;
      return parseFloat(fee_inspection) + parseFloat(fee_testing) + parseFloat(fee_sample) + 
        parseFloat(fee_ocean_marine) + parseFloat(fee_inland_logistic);
    },
    getOtherFee(invoiceObject) {
      if (_.isEmpty(invoiceObject) || _.isNil(invoiceObject)) return 0;

      let fee_design = invoiceObject.fee_design ? invoiceObject.fee_design : 0;
      let fee_accesories = invoiceObject.fee_accesories ? invoiceObject.fee_accesories : 0;
      let fee_develop = invoiceObject.fee_develop ? invoiceObject.fee_develop : 0;
      let fee_commission = invoiceObject.fee_commission ? invoiceObject.fee_commission : 0;
      let fee_royalty = invoiceObject.fee_royalty ? invoiceObject.fee_royalty : 0;
      return parseFloat(fee_design) + parseFloat(fee_accesories) + parseFloat(fee_develop) + 
        parseFloat(fee_commission) + parseFloat(fee_royalty);
    },
		calculateTotalCost() {
      if (_.isEmpty(this.invoiceCostDetail) || _.isNil(this.invoiceCostDetail)) return 0;

      let fee_customs_duty = this.invoiceCostDetail.fee_customs_duty ? this.invoiceCostDetail.fee_customs_duty : 0;
      let fee_freight = this.invoiceCostDetail.fee_freight ? this.invoiceCostDetail.fee_freight : 0;
      let fee_customs_lcc = this.invoiceCostDetail.fee_customs_lcc ? this.invoiceCostDetail.fee_customs_lcc : 0;
      let fee_trucking = this.invoiceCostDetail.fee_trucking ? this.invoiceCostDetail.fee_trucking : 0;
      let fee_storage = this.invoiceCostDetail.fee_storage ? this.invoiceCostDetail.fee_storage : 0;
      let fee_labeling = this.invoiceCostDetail.fee_labeling ? this.invoiceCostDetail.fee_labeling : 0;
      let fee_inspection = this.invoiceCostDetail.fee_inspection ? this.invoiceCostDetail.fee_inspection : 0;
      let fee_testing = this.invoiceCostDetail.fee_testing ? this.invoiceCostDetail.fee_testing : 0;
      let fee_sample = this.invoiceCostDetail.fee_sample ? this.invoiceCostDetail.fee_sample : 0;
      let fee_ocean_marine = this.invoiceCostDetail.fee_ocean_marine ? this.invoiceCostDetail.fee_ocean_marine : 0;
      let fee_inland_logistic = this.invoiceCostDetail.fee_inland_logistic ? this.invoiceCostDetail.fee_inland_logistic : 0;
      let fee_design = this.invoiceCostDetail.fee_design ? this.invoiceCostDetail.fee_design : 0;
      let fee_accesories = this.invoiceCostDetail.fee_accesories ? this.invoiceCostDetail.fee_accesories : 0;
      let fee_develop = this.invoiceCostDetail.fee_develop ? this.invoiceCostDetail.fee_develop : 0;
      let fee_commission = this.invoiceCostDetail.fee_commission ? this.invoiceCostDetail.fee_commission : 0;
      let fee_royalty = this.invoiceCostDetail.fee_royalty ? this.invoiceCostDetail.fee_royalty : 0;

      let result = parseFloat(fee_customs_duty) + parseFloat(fee_freight) + parseFloat(fee_customs_lcc) + 
        parseFloat(fee_trucking) + parseFloat(fee_storage) + parseFloat(fee_labeling) + 
        parseFloat(fee_ocean_marine) + parseFloat(fee_inland_logistic) + parseFloat(fee_inspection) + 
        parseFloat(fee_testing) + parseFloat(fee_sample) + parseFloat(fee_design) +
        parseFloat(fee_accesories) + parseFloat(fee_develop) + parseFloat(fee_commission) + parseFloat(fee_royalty);

      return result
    }
  }
}
</script>

<style scoped>
	.cost-input {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: solid 1px lightgrey;
    border-top: 0px;
    background-color: lightyellow;
    font-size: 0.85rem;
  }
  .cost-input-last {
    border-radius: 0px;
    border-left: solid 1px lightgrey;
    border-bottom: 0px;
    border-right: solid 1px lightgrey;
    border-top: 0px;
    background-color: lightyellow;
    /* border-bottom-right-radius: 10px; */
    font-size: 0.85rem;
  }
  .list-group-item.total-cost {
    border-color: #ffab00;
    background-color: #ffab00;
    color: white;
  }
</style>