<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Quotation Code Registration</span>
    </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="text-nowrap">
              <div class="row ms-2 me-3">
                <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                  <div class="dataTables_length" id="DataTables_Table_0_length">
                    <div class="dt-buttons">
                      <router-link to="/quotation-registration/add" data-i18n="Create Definition" class="dt-button btn btn-primary">
                        <i class="bx bx-plus me-md-2"></i>
                        Create New
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <div class="input-group input-group-merge">
                    <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
                    <input type="text" class="form-control" placeholder="Search quotation code" aria-label="Search..." aria-describedby="basic-addon-search31">
                  </div>
                </div>
              </div>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Quotation Code</th>
                    <th>Customer</th>
                    <th>Issue Date</th>
                    <th>Valid Until</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0">
                  <tr>
                    <td>
                      <strong>E-VOICE-220809-0001</strong>
                    </td>
                    <td>Customer Name CBA</td>
                    <td>2022-11-01</td>
                    <td>2022-11-30</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <router-link to="/quotation-registration/edit/1" data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>E-VOICE-220809-0002</strong>
                    </td>
                    <td>Customer Name XYZ</td>
                    <td>2022-11-01</td>
                    <td>2022-11-30</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <router-link to="/quotation-registration/edit/1" data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>E-VOICE-220809-0003</strong>
                    </td>
                    <td>Customer Name QWERTY</td>
                    <td>2022-11-01</td>
                    <td>2022-11-30</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <router-link to="/quotation-registration/edit/1" data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>E-VOICE-220809-0004</strong>
                    </td>
                    <td>Customer Name BMWMEC</td>
                    <td>2022-11-01</td>
                    <td>2022-11-30</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <router-link to="/quotation-registration/edit/1" data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>E-VOICE-220809-0005</strong>
                    </td>
                    <td>Customer Name AUDITOYOTA</td>
                    <td>2022-11-01</td>
                    <td>2022-11-30</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <router-link to="/quotation-registration/edit/1" data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <div class="row mx-2">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_paginate paging_simple_numbers" id="">
                    <ul class="pagination">
                      <li class="page-item prev">
                        <a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-left"></i></a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link" href="javascript:void(0);">1</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="javascript:void(0);">2</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="javascript:void(0);">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="javascript:void(0);">4</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="javascript:void(0);">5</a>
                      </li>
                      <li class="page-item next">
                        <a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-right"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card-body"></div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>

  export default {
    name: 'QuotationRegistrationList',
    components: {
        
    },
    created() {

    },
  }
</script>