import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import setupInterceptors from './services/setupInterceptors';
import jQuery from "jquery"
global.jQuery = jQuery
global.$ = jQuery
import LoadScript from "vue-plugin-load-script";
import moment from 'moment'
import Cleave from 'vue-cleave-component';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import VuePapaParse from 'vue-papa-parse'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

setupInterceptors(store);

const app = createApp(App)
app.use(router)
app.use(store)
app.use(LoadScript)
app.config.globalProperties.$moment = moment;
app.use(VueSweetalert2);
app.use(Cleave);
app.use(SimpleTypeahead);
app.use(VuePapaParse);
app.use(FloatingVue);

app.mount('#app')
