import CategoryService from '../services/category.service.js';


const getDefaultState = () => {
	return {
		currentPage: 1,
		totalItems: 0,
		totalPages: 0,
		listItems: []
	}
}

export const categoryList = {
	namespaced: true,
  state: {
    stateData: getDefaultState()
  },
	actions: {
		getListData({commit}, page=1) {
      return CategoryService.getListData(page).then(
        (response) => {
          commit('getSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getFailure');
          return Promise.reject(error);
        }
      );
    },
		searchCategory({commit}, {"page": page, "searchKey": searchKey}) {
      return CategoryService.searchCategory({"page": page, "searchKey": searchKey}).then(
        (response) => {
          commit('getSearchSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getSearchFailure');
          return Promise.reject(error);
        }
      );
    },
		deleteCategory ({commit}, id="") {
      return CategoryService.deleteCategory(id).then(
        (response) => {
          // commit('deleteSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('deleteFailure');
          return Promise.reject(error);
        }
      );
    },
		getCategoryInfo ({commit}, id="") {
      return CategoryService.getCategoryInfo(id).then(
        (response) => {
          // commit('deleteSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getCategoryFailure');
          return Promise.reject(error);
        }
      );
    }
	},
	mutations: {
		getSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getFailure(state) {
      console.log("run getFailure");
      Object.assign(state.stateData, getDefaultState())
    },
		getSearchSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getSearchFailure() {
      console.log("run getSearchFailure");
    },
		deleteSuccess () {
      console.log("run deleteSuccess");
    },
    deleteFailure() {
      console.log("run deleteFailure");
    }
	},
	getters: {
	}
}