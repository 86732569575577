<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span class="text-muted fw-light">Quotation Code Registration /</span> Edit
    </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <h5 class="card-header">Edit Quotation Code</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="card-body">
                <form id="formAccountSettingsApiKey" method="POST" onsubmit="return false" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                  <div class="row fv-plugins-icon-container">
                    <div class="mb-3 col-12">
                      <label for="apiAccess" class="form-label">Export / Import / Domestic</label>
                      <div class="position-relative">
                        <select id="apiAccess" class="select2 form-select select2-hidden-accessible">
                          <option value="full">Export</option>
                          <option value="modify">Import</option>
                          <option value="modify">Domestic</option>
                        </select>
                      </div>
                    </div>
                    <div class="mb-2 col-12">
                      <label for="apiAccess" class="form-label">Customer</label>
                      <div class="position-relative">
                        <div class="input-group input-group-merge">
                          <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
                          <input type="text" class="form-control" placeholder="Search Customer Name ..." value="Customer Name ABC">
                        </div>
                      </div>
                      <div class="alert alert-primary mt-1" role="alert">
                        <h6 class="alert-heading d-flex align-items-center fw-bold">Customer Name ABC</h6>
                        <h6 class="alert-heading d-flex align-items-center fw-bold">Code ABC-EFG-XYZ</h6>
                        <span>Main Product: ABC, EFG, XYZ</span>
                      </div>
                    </div>
                    <div class="mb-3 col-6">
                      <label for="apiAccess" class="form-label">Register Date</label>
                      <div class="position-relative">
                        <flat-pickr 
                          v-model="date" 
                          :config="calendar_config" 
                          placeholder="Select a date" 
                          name="register-date" 
                          class="form-control">
                        </flat-pickr>
                      </div>
                    </div>
                    <div class="mb-3 col-6">
                      <label for="apiAccess" class="form-label">Consective No</label>
                      <div class="position-relative">
                        <input class="form-control" type="text" value="0001" id="">
                      </div>
                    </div>
                    <div class="col-12 mt-4">
                      <button type="submit" class="btn btn-outline-success me-2 d-grid w-100">Create Key</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 pe-5">
              <div class="bg-lighter rounded p-4 mb-3 position-relative">
                <div class="d-flex align-items-center flex-wrap mt-4">
                  <h5 class="mb-0 me-3">Generated Quotation Code</h5> 
                </div>
                <div class="alert alert-success d-flex mt-4" role="alert">
                  <div class="d-flex flex-column ps-1">
                    <h6 class="alert-heading d-flex align-items-center fw-bold mb-3 mt-3">E-VOICE-220809-0001</h6>
                  </div>
                </div>
                <div class="mt-3 p-1">
                  <span class="">Created on 28 Apr 2021, 18:20 GTM+4:10</span>
                </div>
                <div class="row" style="margin-top:12rem">
                  <div class="col col-sm-12 text-center">
                    <button type="submit" class="btn btn-primary me-2">Update Code</button>
                    <router-link to="/quotation-registration" data-i18n="Quotation Registration" class="btn btn-outline-secondary w-45">
                      <i class='bx bx-arrow-back' ></i>
                      Back To Definition
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';


export default {
  name: 'QuotationRegistrationEdit',
    components: {
      flatPickr  
    },
    data() {
      return {
        date: null,
        calendar_config: configs
      }
    },
    created() {

    }
}
</script>
<style lang="">
  
</style>