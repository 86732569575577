import axios from 'axios';
// const API_URL = 'http://127.0.0.1:8000/api/';
// const API_URL = 'https://erp.lamodavietnam.com/api/';

const user = JSON.parse(localStorage.getItem('user'));

let api_header = {
  "Content-Type": "application/json"
}
if (user){
  api_header["Authorization"] = `Bearer ${user.access_token}`;
}
const API = axios.create({
  "baseURL": process.env.VUE_APP_API_URL,
  "headers": api_header
});

export default API;


export const API_FORM = axios.create({
  "baseURL": process.env.VUE_APP_API_URL,
  "headers": {
    'Content-Type': 'multipart/form-data'
  }
});
