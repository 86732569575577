import API from "@/config/api.js";
import {API_FORM} from "@/config/api.js";
import AuthService from "./auth.service";


class AccountService {

	uploadImage(formdata) {
		return API_FORM.post('common/upload_image', formdata)
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

	saveAccountProfile(data) {
		return API.post('auth/account/edit-profile', data)
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

	changeAccountPassword(data) {
		return API.post('auth/account/change-password', data)
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

	getAccountProfile(userId) {
		return API.get(`auth/account/${userId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

	deactivateAccount(userId) {
    return API.delete(`auth/account/deactivate/${userId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	restoreAccount(userId) {
    return API.post(`auth/account/restore/${userId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	getListData(page, size) {
    let size_text = size !== undefined ? "&size="+size : "";
    return API.get(`auth/account/?page=${page}` + size_text)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	searchAccount(data) {
    return API.get(`auth/account/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	addNewAccount(data) {
		return AuthService.register(data);
	}

	resetAccountPassword(userId) {
    return API.post(`auth/account/reset-password/${userId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

}

export default new AccountService();