<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
			<span class="text-muted fw-light">Account /</span> Change Password
    </h4>
    <div class="card mb-4">
			<h5 class="card-header">Change Password</h5>
			<div class="card-body">
				<div class="row">
					<div class="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
						<label class="form-label" for="currentPassword">Current Password</label>
						<div class="input-group input-group-merge">
							<input
								:type="field_pass_1"
								class="form-control"
								placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
								name="password"
								v-model="stateData.password"
							/>
							<span class="input-group-text cursor-pointer" @click="changeFieldType(1)">
								<i class="bx" :class="icon_class_1"></i>
							</span>
							<div class="error-msg" v-if="v$.stateData.password.$error"> 
								{{ v$.stateData.password.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
						<label class="form-label" for="newPassword">New Password</label>
						<div class="input-group input-group-merge">
							<input
								:type="field_pass_2"
								class="form-control"
								placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
								name="password"
								v-model="stateData.new_password"
							/>
							<span class="input-group-text cursor-pointer" @click="changeFieldType(2)">
								<i class="bx" :class="icon_class_2"></i>
							</span>
							<div class="error-msg" v-if="v$.stateData.new_password.$error"> 
								{{ v$.stateData.new_password.$errors[0].$message }}
							</div>
						</div>
					</div>

					<div class="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
						<label class="form-label" for="confirmPassword">Confirm New Password</label>
						<div class="input-group input-group-merge">
							<input
								:type="field_pass_3"
								class="form-control"
								placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
								name="password"
								v-model="stateData.new_password_confirm"
							/>
							<span class="input-group-text cursor-pointer" @click="changeFieldType(3)">
								<i class="bx" :class="icon_class_3"></i>
							</span>
							<div class="error-msg" v-if="v$.stateData.new_password_confirm.$error"> 
								{{ v$.stateData.new_password_confirm.$errors[0].$message }}
							</div>
						</div>
					</div>
					<div class="error-msg mt-3" v-if="is_error"> 
						{{ error_message }}
					</div>
					<div class="col-12 mb-4">
						<p class="fw-semibold mt-2">Password Requirements:</p>
						<ul class="ps-3 mb-0">
							<li class="mb-1">
								Minimum 8 characters long - Maximum 255 characters
							</li>
							<li class="mb-1">At least one Upper character</li>
							<li>At least one number, symbol</li>
						</ul>
					</div>
					
					<form id="" @submit.prevent="handleSave">
						<div class="col-12 mt-1">
							<button type="submit" class="btn btn-primary me-2">Save changes</button>
							<router-link to="/account/profile" class="btn btn-outline-secondary w-45">
								<i class='bx bx-arrow-back' ></i>
								Back To Profile
							</router-link>
						</div>
					</form>
				</div>
			</div>
		</div>

  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import useValidate from '@vuelidate/core'
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'


export default {
	name: 'AccountChangePassword',
	data() {
    return {
      v$: useValidate(),
      field_pass_1: "password",
			field_pass_2: "password",
			field_pass_3: "password",
			icon_class_1: "bx-hide",
			icon_class_2: "bx-hide",
			icon_class_3: "bx-hide",
			is_error: false,
			error_message: ""
    };
  },
	computed: {
    ...mapState({
			signedInAccount: state => state.auth,
			stateData: state => state.accountChangePassword.stateData
    })
  },
	beforeCreate() {
    this.$store.dispatch('accountChangePassword/resetState');
  },
	created() {
		this.stateData.username = this.signedInAccount.user.user_info.username;
	},
	methods: {
		handleSave() {
			this.v$.$validate();
			if (this.stateData.new_password !== this.stateData.new_password_confirm){
				this.is_error = true;
				this.error_message = "The Password & Confirm Password do not match"
			}
			else if (!this.containsSpecialChars(this.stateData.new_password)){
				this.is_error = true;
				this.error_message = "The new password does not contain number or special character"
			}
			else if (!this.containsUppercase(this.stateData.new_password)){
				this.is_error = true;
				this.error_message = "The new password does not Upper-case character"
			}
			else {
				this.is_error = false;
				this.error_message = "";
			}

			if (this.is_error) return;

			if (!this.v$.$error) {
				this.$store.dispatch('accountChangePassword/changeAccountPassword', this.stateData).then(
          () => {
            let self = this;
            this.$swal.fire(
              'Updated',
              'Account Password is updated sucessfully !',
              'success'
            ).then(function() {
							self.$store.dispatch('auth/logout');
							self.$router.push('/login');
            });
          },
          error => {
						console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Wrong Password !',
              text: 'Failed to save Account Password !'
            });
          }
        );
			}
		},
    changeFieldType(field) {
			if (!field) return;
			if (field == 1) {
				this.field_pass_1 = this.field_pass_1 == "password" ? "text" : "password";
				this.icon_class_1 = this.field_pass_1 == "text" ? "bx-show" : "bx-hide";
			}
      if (field == 2) {
				this.field_pass_2 = this.field_pass_2 == "password" ? "text" : "password";
				this.icon_class_2 = this.field_pass_2 == "text" ? "bx-show" : "bx-hide";
			}
			if (field == 3) {
				this.field_pass_3 = this.field_pass_3 == "password" ? "text" : "password";
				this.icon_class_3 = this.field_pass_3 == "text" ? "bx-show" : "bx-hide";
			}
    },
		containsSpecialChars(str) {
			const specialChars = /[`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
			return specialChars.test(str);
		},
		containsUppercase(str) {
			return /[A-Z]/.test(str);
		}
  },
	validations() {
    return {
			stateData: {
				password: { 
					required: helpers.withMessage('Password cannot be empty', required),
					minLength: helpers.withMessage('Minimum 8 letters', minLength(8)),
					maxLength: helpers.withMessage('Maximum 255 letters', maxLength(255))
				},
				new_password: { 
					required: helpers.withMessage('Password cannot be empty', required),
					minLength: helpers.withMessage('Minimum 8 letters', minLength(8)),
					maxLength: helpers.withMessage('Maximum 255 letters', maxLength(255))
				},
				new_password_confirm: { 
					required: helpers.withMessage('Password cannot be empty', required),
					minLength: helpers.withMessage('Minimum 8 letters', minLength(8)),
					maxLength: helpers.withMessage('Maximum 255 letters', maxLength(255))
				}
			}
		}
  },
}
</script>

<style>

</style>