<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <div class="misc-wrapper">
        <h2 class="mb-2 mx-2">You are not authorized!</h2>
        <p class="mb-4 mx-2">
          You do not have permission to view this page using the credentials that you have provided while login. 
          <br> Please contact your site administrator.
        </p>
        <a href="/" class="btn btn-primary">Back to home</a>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>

export default {
  name: 'NotAuthorized'
}
</script>

<style>

</style>