import Swal from 'sweetalert2';

let ERROR_ICON = "error"
let ERROR_TITLE = "Error"
let ERROR_CONTENT = "Oops, something went wrong !"

export const errorAlert = function showErrorAlert(icon=ERROR_ICON, title=ERROR_TITLE, content=ERROR_CONTENT) {
  console.log("Error occured.");
  Swal.fire({
    icon: icon,
    title: title,
    html: content
  });
}

let DELETED_ICON = "success"
let DELETED_TITLE = "Deleted"
let DELETED_CONTENT = "Deleted successfully !"
export const deletedAlert = function showDeletedAlert(
  icon=DELETED_ICON, title=DELETED_TITLE, content=DELETED_CONTENT) {

  Swal.fire({
    icon: icon,
    title: title,
    text: content
  });
}

let RESTORE_ICON = "success"
let RESTORE_TITLE = "Restore Account"
let RESTORE_CONTENT = "Deleted successfully !"
export const restoredAlert = function showRestoredAlert(
  icon=RESTORE_ICON, title=RESTORE_TITLE, content=RESTORE_CONTENT) {

  Swal.fire({
    icon: icon,
    title: title,
    text: content
  });
}


let WARNING_ICON = "warning"
let WARNING_TITLE = "Are you sure?"
let WARNING_CONTENT = "You won't be able to revert this!"

function showConfirmDeleteAlert (icon=WARNING_ICON, title=WARNING_TITLE, content=WARNING_CONTENT) {
  return Swal.fire({
    title: title,
    text: content,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then(
    (result) => {
      return Promise.resolve(result.isConfirmed);
    }
  )
}
export default showConfirmDeleteAlert;