import InvoiceRegistrationService from '../services/invoice_registration.service';


const getDefaultState = () => {
  return {
    invoice_code: "",
    prefix: "",
    vcj: "",
    date: null,
    consective_no: ""
  }
}

export const invoiceRegistrationAdd = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    getLatestCode({commit}) {
      return InvoiceRegistrationService.getLatestCode().then(
        (response) => {
          commit('getSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getFailure');
          return Promise.reject(error);
        }
      );
    },
    saveCode({commit}, data) {
      return InvoiceRegistrationService.saveCode(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveFailure");
          return Promise.reject(error);
        }
      );
    },
    resetState({commit}) {
      commit("resetState");
    },
    getInvoiceRegCode({commit}, id) {
      return InvoiceRegistrationService.getInvoiceRegCode(id).then(
        (response) => {
          commit('getInvoiceRegSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getInvoiceRegFailure');
          return Promise.reject(error);
        }
      );
    },
    updateCode({commit}, data) {
      console.log("call updateCode module");
      return InvoiceRegistrationService.updateCode(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getSuccess(state, response) {
      state.stateData.consective_no = InvoiceRegistrationService.initConsectiveNo(response);
    },
    getFailure(state) {
      state.stateData.consective_no = ""
    },
    resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState())
    },
    getInvoiceRegSuccess(state, response) {
      state.stateData.id = response.id;
      state.stateData.invoice_code = response.invoice_code;
      state.stateData.prefix = response.prefix;
      state.stateData.vcj = response.vcj;
      state.stateData.date = response.date;
      state.stateData.consective_no = response.consective_no;
    },
    getInvoiceRegFailure(state) {
      this.resetState(state);
    },
  },
  getters: {

  }
}