<template>
  <!-- Content -->
  <div class="card1 mb-3">
    <div class="card-body">
      <div class="row p-sm-3 p-0">
        <div class="col-md-6 mb-md-0 mb-4">
          <div class="d-flex svg-illustration mb-4 gap-2">
          <span class="app-brand-logo demo">
              <svg width="25" viewBox="0 0 25 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                  <path d="M13.7918663,0.358365126 L3.39788168,7.44174259 C0.566865006,9.69408886 -0.379795268,12.4788597 0.557900856,15.7960551 C0.68998853,16.2305145 1.09562888,17.7872135 3.12357076,19.2293357 C3.8146334,19.7207684 5.32369333,20.3834223 7.65075054,21.2172976 L7.59773219,21.2525164 L2.63468769,24.5493413 C0.445452254,26.3002124 0.0884951797,28.5083815 1.56381646,31.1738486 C2.83770406,32.8170431 5.20850219,33.2640127 7.09180128,32.5391577 C8.347334,32.0559211 11.4559176,30.0011079 16.4175519,26.3747182 C18.0338572,24.4997857 18.6973423,22.4544883 18.4080071,20.2388261 C17.963753,17.5346866 16.1776345,15.5799961 13.0496516,14.3747546 L10.9194936,13.4715819 L18.6192054,7.984237 L13.7918663,0.358365126 Z" id="path-1"></path>
                  <path d="M5.47320593,6.00457225 C4.05321814,8.216144 4.36334763,10.0722806 6.40359441,11.5729822 C8.61520715,12.571656 10.0999176,13.2171421 10.8577257,13.5094407 L15.5088241,14.433041 L18.6192054,7.984237 C15.5364148,3.11535317 13.9273018,0.573395879 13.7918663,0.358365126 C13.5790555,0.511491653 10.8061687,2.3935607 5.47320593,6.00457225 Z" id="path-3"></path>
                  <path d="M7.50063644,21.2294429 L12.3234468,23.3159332 C14.1688022,24.7579751 14.397098,26.4880487 13.008334,28.506154 C11.6195701,30.5242593 10.3099883,31.790241 9.07958868,32.3040991 C5.78142938,33.4346997 4.13234973,34 4.13234973,34 C4.13234973,34 2.75489982,33.0538207 2.37032616e-14,31.1614621 C-0.55822714,27.8186216 -0.55822714,26.0572515 -4.05231404e-15,25.8773518 C0.83734071,25.6075023 2.77988457,22.8248993 3.3049379,22.52991 C3.65497346,22.3332504 5.05353963,21.8997614 7.50063644,21.2294429 Z" id="path-4"></path>
                  <path d="M20.6,7.13333333 L25.6,13.8 C26.2627417,14.6836556 26.0836556,15.9372583 25.2,16.6 C24.8538077,16.8596443 24.4327404,17 24,17 L14,17 C12.8954305,17 12,16.1045695 12,15 C12,14.5672596 12.1403557,14.1461923 12.4,13.8 L17.4,7.13333333 C18.0627417,6.24967773 19.3163444,6.07059163 20.2,6.73333333 C20.3516113,6.84704183 20.4862915,6.981722 20.6,7.13333333 Z" id="path-5"></path>
              </defs>
              <g id="g-app-brand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Brand-Logo" transform="translate(-27.000000, -15.000000)">
                  <g id="Icon" transform="translate(27.000000, 15.000000)">
                      <g id="Mask" transform="translate(0.000000, 8.000000)">
                      <mask id="mask-2" fill="white">
                          <use xlink:href="#path-1"></use>
                      </mask>
                      <use fill="#696cff" xlink:href="#path-1"></use>
                      <g id="Path-3" mask="url(#mask-2)">
                          <use fill="#696cff" xlink:href="#path-3"></use>
                          <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-3"></use>
                      </g>
                      <g id="Path-4" mask="url(#mask-2)">
                          <use fill="#696cff" xlink:href="#path-4"></use>
                          <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-4"></use>
                      </g>
                      </g>
                      <g id="Triangle" transform="translate(19.000000, 11.000000) rotate(-300.000000) translate(-19.000000, -11.000000) ">
                      <use fill="#696cff" xlink:href="#path-5"></use>
                      <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-5"></use>
                      </g>
                  </g>
                  </g>
              </g>
              </svg>
          </span>
          <span class="app-brand-text demo text-body fw-bolder">Sneat</span>
          </div>
          <p class="mb-1">LAMODA VIETNAM COMPANY LIMITED</p>
          <p class="mb-1">Ho Chi Minh City, Vietnam</p>
          <p class="mb-0">+84 (0) 394 577 366</p>
        </div>
        <div class="col-md-6">
            <dl class="row mb-2">
            <dt class="col-sm-6 mb-2 mb-sm-0 text-md-end mt-2">
              <span class="h5 text-capitalize mb-0 text-nowrap">Invoice No</span>
            </dt>
            <dd class="col-sm-6 d-flex justify-content-md-end">
              <div class="w-px-200">
                <input type="text" 
                  class="form-control" 
                  v-model="stateData.invoice_code" 
                  disabled
                >
              </div>
            </dd>
            <dt class="col-sm-6 mb-2 mb-sm-0 text-md-end mt-2">
              <span class="fw-normal">Contract No</span>
            </dt>
            <dd class="col-sm-6 d-flex justify-content-md-end">
              <div class="w-px-200">
                <input type="text" 
                  class="form-control"
                  v-model="this.stateData.contract_code"
                  disabled
                >
              </div>
            </dd>
            <dt class="col-sm-6 mb-2 mb-sm-0 text-md-end mt-2">
                <span class="fw-normal">Issued Date</span>
            </dt>
            <dd class="col-sm-6 d-flex justify-content-md-end">
              <div class="w-px-200">
                <flat-pickr 
                  v-model="stateData.invoice_date" 
                  :config="calendarConfig" 
                  placeholder="Select a date" 
                  name="contract-date" 
                  class="form-control">
                </flat-pickr>
              </div>
            </dd>
            </dl>
        </div>
      </div>
      <hr class="my-4 mx-n4">
      <div class="row p-sm-0 p-0">
        <div class="col-md-6 col-sm-5 col-12 mb-sm-0 mb-4" v-if="stateData.customerInfo.id">
          <h6 class="pb-2 fw-bold">Invoice To:</h6>
          <div class="alert alert-warning mt-1" role="alert">
            <div class="row">
              <div class="mb-2">
                <span class="fw-bold me-3">{{ stateData.customerInfo.partner_name }}</span>
                <div class="mt-2">
                  <span class="fw-bold me-3">Address:</span>
                  {{ stateData.customerInfo.address +" "+ stateData.customerInfo.ward_name +" "+ 
                    stateData.customerInfo.district_name +" "+ stateData.customerInfo.city_name +" "+ stateData.customerInfo.country}}
                  {{ stateData.customerInfo.zipcode ? ", zipcode: " + stateData.customerInfo.zipcode : "" }}
                </div>
                <div class="mt-3">
                  <span class="fw-bold me-3">Payment method:</span>
                  {{ getPaymentName(stateData.customerInfo.payment_method) +" "+ stateData.customerInfo.term +" days "+ getClosingTime(stateData.customerInfo.closing_time) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-7">
          <h6 class="pb-2 fw-bold">Bill To:</h6>
          <div class="alert alert-warning mt-1" role="alert">
            <div class="row">
              <div class="mb-2">
                <span class="alert-heading fw-bold mt-2">BANK INFORMATION</span><br/>
                <span class="fw-bold me-1">
                  {{ showBankName(stateData.customerInfo.bank_name) }}
                  {{ " (" + stateData.customerInfo.bank_name + ")" }}
                </span><br/>
                <span class="fw-bold me-3">Account Name:</span>{{ stateData.customerInfo.bank_account_name }}<br/>
                <span class="fw-bold me-3">Account No:</span>{{ stateData.customerInfo.bank_account_number }}<br/>
                <span class="fw-bold me-3">SWIFT code:</span>{{ stateData.customerInfo.swift_code }} <br/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mx-n4">
      <div class="card-body">
        <div class="d-flex border rounded position-relative mb-3" v-for="(product, key) in stateData.list_products" :key="key">
          <invoice-product-detail
            :product="product"
            :index="key"
            @changeAmountValue="changeGrandTotalAmount"
          >
          </invoice-product-detail>
        </div>
        <!-- Grand Total Amount -->
        <div class="row col-md-12 mt-5">
          <div class="col-md-5 d-flex justify-content-end" style="margin-left: 5rem">
            <div class="invoice-calculations">
              <div class="d-flex justify-content-between mb-4">
                <span class="fw-bold w-px-200"></span>
                <span class="fw-bold">SELL</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Subtotal:</span>
                <span class="fw-semibold">{{ stateData.sell_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(subTotalSellPrice) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Tax:</span>
                <span class="fw-semibold">{{ stateData.sell_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalSellTax) }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Grand Total:</span>
                <span class="fw-semibold">{{ stateData.sell_currency }}&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(stateData.grand_total_sell_price) }}</span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="fw-bold w-px-200">Grand Total in VND</span>
                <span class="fw-semibold">VND&nbsp;&nbsp;&nbsp;{{ getFormatCurrency(grandTotalSellPriceVND) }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <span class="fw-bold me-2">GRAND SELL AMOUNT: </span> 
            <span class="fw-bold text-warning">{{ getFormatCurrencyText(stateData.grand_total_sell_text) }}&nbsp;{{ stateData.sell_currency }}</span>
          </div>
        </div>
        <!-- / Grand Total Amount -->
      </div>
      <hr class="my-4">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Date of Shipment</label>
            <flat-pickr 
              v-model="stateData.shipment_date" 
              :config="calendarConfig" 
              placeholder="Select a date" 
              name="contract-date" 
              class="form-control"
              disabled
            >
            </flat-pickr>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Country of Origin</label>
            <input type="text" 
              class="form-control" 
              v-model="stateData.country_origin"
              disabled
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Means of Transport</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.mean_transport"
              disabled
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">HAWB NO</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.hawb_no"
              disabled
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Port of Loading</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.port_landing"
              disabled
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="note" class="form-label fw-semibold">Port of Discharge</label>
            <input type="text" 
              class="form-control"
              v-model="stateData.port_discharge"
              disabled
            >
          </div>
        </div>
      </div>
      <form @submit.prevent="handleSave">
        <div class="row mt-5">
          <div class="mb-3 col-sm-3"></div>
          <div class="mb-3 col-sm-6">
            <button type="submit" class="btn btn-primary me-3">Save Invoice, Receiving, Delivery</button>
            <router-link to="/invoice" data-i18n="Add Product" class="btn btn-outline-secondary w-45">
              <i class='bx bx-arrow-back'></i>
              Back To List
            </router-link>
          </div>
        </div>
      </form>
    </div>
    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { CLOSING_TIME, PAYMENT_METHOD } from "../../config/common.js"
import banks from "../../../public/assets/vendor/js/bank.js"
import InvoiceProductDetail from "./InvoiceProductDetail.vue";
import {Converter} from "any-number-to-words";
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'


export default {
  name: 'InvoiceAdd',
  components: {
    flatPickr,
    InvoiceProductDetail,
    RiseLoader
  },
  data() {
    return {
      v$: useValidate(),
      calendarConfig: configs,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      subTotalBuyPrice: null,
      subTotalSellPrice: null,
      grandTotalBuyTax: null,
      grandTotalSellTax: null,
      grandTotalSellPriceVND: null
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.shipmentAdd.stateData
    }),
    bankOptions() {
      return banks;
    },
    paymentMethodOptions() {
      return PAYMENT_METHOD;
    }
  },
  watch: {
    "stateData.grand_total_sell_price"(newVal) {
      this.changeGrandTotalAmount(newVal);
    }
  },
  created() {
    this.isLoading = true;
  },
  mounted(){
    // this.changeGrandTotalAmount(this.stateData.grand_total_sell_price);
    this.isLoading = false;
  },
  methods: {
    getFormatCurrency(value) {
      return Intl.NumberFormat().format(value);
    },
    getFormatCurrencyText(value) {
      return value.toUpperCase();
    },
    getClosingTime(close_time_id) {
      let query = CLOSING_TIME.filter(item => item.id == close_time_id);
      return query[0].name
    },
    getPaymentName(val) {
      let query = this.paymentMethodOptions.filter((item) => {
        return item.value == val;
      });
      return query.length > 0 ? query[0].name_en : ""
    },
    changeGrandTotalAmount(new_value) {
      if(new_value){
        this.subTotalBuyPrice = 0;
        this.subTotalSellPrice = 0;
        this.grandTotalBuyTax = 0;
        this.grandTotalSellTax = 0;
        this.grandTotalBuyPriceVND = 0;
        this.grandTotalSellPriceVND = 0;
        this.grandTotalProfit = 0;
        this.grandTotalProfitPercent = 0;
        this.stateData.grand_total_buy_price = 0;
        this.stateData.grand_total_sell_price = 0;
        
        this.stateData.list_products.forEach(product => {

          this.subTotalBuyPrice += (parseFloat(product.buy_price)*parseInt(product.quantity_in));
          this.subTotalSellPrice += (parseFloat(product.sell_price)*parseInt(product.quantity_out));

          this.grandTotalBuyTax += parseFloat(product.purchase_total_amount) - (parseFloat(product.buy_price)*parseInt(product.quantity_in))
          this.grandTotalSellTax += parseFloat(product.sell_total_amount) - (parseFloat(product.sell_price)*parseInt(product.quantity_out))
        });
        this.stateData.grand_total_buy_price = parseFloat(this.subTotalBuyPrice) + parseFloat(this.grandTotalBuyTax);
        this.stateData.grand_total_sell_price = parseFloat(this.subTotalSellPrice) + parseFloat(this.grandTotalSellTax);
      
        this.grandTotalBuyPriceVND = this.subTotalBuyPrice * parseFloat(this.stateData.buy_exchange_rates);
        this.grandTotalSellPriceVND = this.subTotalSellPrice * parseFloat(this.stateData.sell_exchange_rates);

        if(this.grandTotalBuyPriceVND) {
          this.grandTotalProfit = (this.subTotalSellPrice * this.stateData.sell_exchange_rates) - (this.subTotalBuyPrice * this.stateData.buy_exchange_rates);
          this.grandTotalProfitPercent = this.grandTotalProfit * 100 / (this.subTotalSellPrice * this.stateData.sell_exchange_rates)
        }
        
        if(this.stateData.grand_total_buy_price){
          this.stateData.grand_total_buy_text = new Converter().toWords(this.stateData.grand_total_buy_price);
        }
        if(this.stateData.grand_total_sell_price){
          this.stateData.grand_total_sell_text = new Converter().toWords(this.stateData.grand_total_sell_price);
        }
      }
    },
    handleSave() {
      this.v$.$validate();

      if (!this.v$.$error) {
        if(this.stateData.error_lists.length > 0) {
          return;
        }
        let request = {
          ...this.stateData
        }
        this.$store.dispatch('shipmentAdd/saveShipment', request).then(
          () => {
            let self = this;
            this.$swal.fire(
              'Saved',
              'Shipment information is saved sucessfully !',
              'success'
            ).then(function() {
              self.$router.push('/shipment');
            });
          },
          error => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to save Shipment information !'
            });
          }
        );

      }
      else {
        window.scrollTo(0,0);
      }
    },
    showBankName(bank_code) {
      if (!bank_code) return "";
      let bank_name = this.bankOptions.filter(item => {
        return item.code == bank_code
      });
      return bank_name.length > 0 ? bank_name[0].name : "";
    }
  },
  validations() {
    return {
      stateData: {
        invoice_date: {
          required: helpers.withMessage('Issued Date can not be empty', required)
        },
        contract_id: {
          required: helpers.withMessage('Contract Code can not be empty', required)
        }
      }
    }
  }
}
</script>