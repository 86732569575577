<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Quotation /</span> Add New
    </h4>
    <form id="formAccountSettings" method="POST" onsubmit="return false" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
      <div class="card mb-4">
        <h5 class="card-header">General Information</h5>
        <hr class="my-0">
        <div class="card-body">
          <div class="row">
            <div class="mb-3 col-md-6 fv-plugins-icon-container">
              <label for="firstName" class="form-label">Company Name</label>
              <input class="form-control" type="text" id="" name="" value="" autofocus="">
            </div>
            <div class="mb-3 col-md-6 fv-plugins-icon-container">
              <label for="lastName" class="form-label">Quote Number</label>
              <input class="form-control" type="text" name="" id="" value="">
            </div>
            <div class="mb-3 col-md-6 fv-plugins-icon-container">
              <label for="firstName" class="form-label">Attention Name</label>
              <input class="form-control" type="text" id="" name="" value="" autofocus="">
            </div>
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label for="lastName" class="form-label">Issue Date</label>
              <flat-pickr 
                v-model="date1" 
                :config="calendar_config" 
                placeholder="Select a date" 
                name="issued-date" 
                class="form-control">
              </flat-pickr>
            </div>
            <div class="mb-3 col-md-3 fv-plugins-icon-container">
              <label for="lastName" class="form-label">Valid Until</label>
              <flat-pickr 
                v-model="date2" 
                :config="calendar_config" 
                placeholder="Select a date" 
                name="valid-until" 
                class="form-control">
              </flat-pickr>
            </div>
          </div>
        </div>
      </div>

      <!-- List Quotation Product -->
      <div class="card mb-4">
        <h5 class="card-header">Product Quotation List</h5>
        <hr class="my-0">
        <div class="card-body">
          <div class="row">
            <form class="form-repeater">
              <div data-repeater-list="group-a">
                <div data-repeater-item="" style="">
                  <div class="row">
                    <div class="mb-3 col-xl-6 col-12 mb-0">
                      <label class="form-label" for="form-repeater-3-5">Description</label>
                      <textarea class="form-control" rows="3" placeholder="Description content ..."></textarea>
                    </div>
                    <div class="mb-3 col-xl-6 col-12 mb-0">
                      <div class="row">
                        <div class="mb-3 col-md-8">
                          <label class="form-label">Product Image</label>
                          <input class="form-control" type="file" id="" multiple="">
                          <div class="mt-2 text-primary">The-flower-of-garden.jpg</div>
                        </div>
                        <div class="mb-3 col-md-4">
                          <img class="d-block w-75" src="/img/2.e62ab16e.jpg" alt="Second slide">
                        </div>
                      </div>
                      
                    </div>
                    <div class="mb-3 col-lg-6 col-xl-2 col-12 mb-0">
                      <label class="form-label" for="form-repeater-3-8">Quantity</label>
                      <input class="form-control" type="text" id="" name="" value="" placeholder="0"  style="text-align:right;">
                    </div>
                    <div class="mb-3 col-lg-6 col-xl-3 col-12 mb-0">
                      <label class="form-label" for="form-repeater-3-8">Unit Price</label>
                      <input class="form-control" type="text" id="" name="" value="" placeholder="0.00" style="text-align:right;">
                    </div>
                    <div class="mb-3 col-lg-6 col-xl-2 col-12 mb-0">
                      <label class="form-label" for="form-repeater-3-8">Tax</label>
                      <input class="form-control" type="text" id="" name="" value="" placeholder="0.08" style="text-align:right;">
                    </div>
                    <div class="mb-3 col-lg-6 col-xl-3 col-12 mb-0">
                      <label class="form-label" for="form-repeater-3-8">Total Amount</label>
                      <input class="form-control" type="text" id="" name="" value="" placeholder="0.00" style="text-align:right;">
                    </div>
                    <div class="mb-3 col-lg-12 col-xl-2 col-12 d-flex align-items-center mb-0">
                      <button class="btn btn-label-danger mt-4" data-repeater-delete="">
                        <i class="bx bx-x"></i>
                        <span class="align-middle">Delete</span>
                      </button>
                    </div>
                  </div>
                  <hr>
                </div>
              </div>
              <div class="mb-0">
                <button class="btn btn-primary" data-repeater-create="">
                  <i class="bx bx-plus"></i>
                  <span class="align-middle">Add</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- / List Quotation Product -->

      <!-- TERMS & CONDITIONS -->
      <div class="card mb-4">
        <h5 class="card-header">TERMS & CONDITIONS</h5>
        <hr class="my-0">
        <div class="card-body">
          <form class="form-repeater">
            <div data-repeater-list="group-a">
              <div data-repeater-item="" style="">
                <div class="row">
                  <div class="mb-1 col-lg-12 col-xl-10 col-12 mb-0">
                    <input class="form-control" type="text" id="" name="" value="" placeholder="Term and condition content...">
                  </div>
                  <div class="mb-1 col-lg-12 col-xl-2 col-12 d-flex align-items-center mb-0">
                    <button class="btn btn-label-danger" data-repeater-delete="">
                      <i class="bx bx-x"></i>
                      <span class="align-middle">Delete</span>
                    </button>
                  </div>
                </div>
                <hr>
              </div>
            </div>
            <div class="mb-0">
              <button class="btn btn-primary" data-repeater-create="">
                <i class="bx bx-plus"></i>
                <span class="align-middle">Add</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- / TERMS & CONDITIONS -->
    </form>
  </div>
  <!-- / Content -->
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';


export default {
  name: 'QuotationAdd',
  components: {
    flatPickr
  },
  props: [],
  data() {
    return {
      date1: null,
      date2: null,
      calendar_config: configs
    }
  },
  computed: {
  },
  methods: {
  },
  created() {

  },
}
</script>