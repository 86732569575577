export const Role = {
  ADMIN: "administrator",
  MANAGER: "manager",
  ACCOUNTING: "accounting",
  USER: "user"
}

export const ROLE = {
  ADMIN: {
    name: "Administrator",
    value: 1
  },
  MANAGER: {
    name: "Manager",
    value: 2
  },
  ACCOUNTING: {
    name: "Accounting",
    value: 3
  },
  USER: {
    name: "User",
    value: 4
  }
}