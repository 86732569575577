<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4" v-if="userLogged">
      <span>Dashboard</span>
    </h4>
    <h4 class="fw-bold py-3 mb-4" v-else>
      <h5>Welcome to LAMODA System !!!</h5>
      <h5>Please
        <router-link :to='"/login"'>
          login
        </router-link>
        to use.
      </h5>
    </h4>

    <div class="row" v-if="userLogged">
      <div class="col-md-12">
        <div class="card">
          <!-- Notifications -->
          <h5 class="card-header">Recent Devices</h5>
          <div class="table-responsive">
            <table class="table table-striped table-borderless border-bottom">
              <thead>
                <tr>
                  <th class="text-nowrap">Type</th>
                  <th class="text-nowrap text-center">✉️ Email</th>
                  <th class="text-nowrap text-center">🖥 Browser</th>
                  <th class="text-nowrap text-center">👩🏻‍💻 App</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-nowrap">New for you</td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck1">
                    </div>
                  </td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck2">
                    </div>
                  </td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck3">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">Account activity</td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck4">
                    </div>
                  </td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck5">
                    </div>
                  </td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck6">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">A new browser used to sign in</td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck7">
                    </div>
                  </td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck8">
                    </div>
                  </td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck9">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">A new device is linked</td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck10">
                    </div>
                  </td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck11">
                    </div>
                  </td>
                  <td>
                    <div class="form-check d-flex justify-content-center">
                      <input class="form-check-input" type="checkbox" id="defaultCheck12">
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body">
            <h6>When should we send you notifications?</h6>
            <form action="javascript:void(0);">
              <div class="row">
                <div class="col-sm-6">
                  <select id="sendNotification" class="form-select" name="sendNotification">
                    <option>Only when I'm online</option>
                    <option>Anytime</option>
                  </select>
                </div>
                <div class="mt-4">
                  <button type="submit" class="btn btn-primary me-2">Save changes</button>
                  <button type="reset" class="btn btn-outline-secondary">Discard</button>
                </div>
              </div>
            </form>
          </div>
          <!-- /Notifications -->
        </div>
      </div>
    </div>
    <div class="row mt-4" v-else>
      <div class="col-12 text-center mb-4">
        <div class="badge bg-label-primary">Question?</div>
        <h4 class="my-2">You have a question ?</h4>
        <p>If you have any question, you can contact us via the following contact.</p>
      </div>
      <div class="row text-center justify-content-center gap-sm-0 gap-3">
        <div class="col-sm-6">
          <div class="py-3 rounded bg-faq-section text-center">
            <span class="badge bg-label-primary rounded-2 my-3">
              <i class="bx bx-phone bx-sm"></i>
            </span>
            <h4 class="mb-2"><a class="h4" href="tel:+(810)25482568">+ (84) 397 993 001</a></h4>
            <p>We are always happy to help</p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class=" py-3 rounded bg-faq-section text-center">
            <span class="badge bg-label-primary rounded-2 my-3">
              <i class="bx bx-envelope bx-sm"></i>
            </span>
            <h4 class="mb-2">
              <a class="h4" href="mailto:contact.lamodavietnam@gmail.com">contact.lamodavietnam@gmail.com</a>
            </h4>
            <p>Best way to get a quick answer</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
  export default {
    name: 'Dashboard',
    components: {

    },
    created() {

    },
    computed: {
      userLogged() {
        if (this.$store.state.auth?.user){
          return this.$store.state.auth.status.loggedIn;
        }
        return false;
      }
    }
  }
</script>

<style scoped>
  .light-style .bg-faq-section {
    background-color: rgba(67,89,113,.05);
  }
</style>