import ProductService from '../services/product.service';
// import moment from 'moment'

const getDefaultState = () => {
    return {
      currentPage: 1,
      totalItems: 0,
      totalPages: 0,
      listItems: []
    }
  }

  export const productList = {
    namespaced: true,
    state: {
      stateData: getDefaultState()
    },
    actions: {
      getListData({commit}, page=1) {
				return ProductService.getListData(page).then(
					(response) => {
						commit('getSuccess', response);
						return Promise.resolve(response);
					},
					(error) => {
						commit('getFailure');
						return Promise.reject(error);
					}
				);
			},
			searchProduct({commit}, {"page": page, "searchKey": searchKey}) {
				return ProductService.searchProduct({"page": page, "searchKey": searchKey}).then(
					(response) => {
						commit('getSearchSuccess', response);
						return Promise.resolve(response);
					},
					(error) => {
						commit('getSearchFailure');
						return Promise.reject(error);
					}
				);
			},
			deleteProduct ({commit}, id="") {
				return ProductService.deleteProduct(id).then(
					(response) => {
						// commit('deleteSuccess', response);
						return Promise.resolve(response);
					},
					(error) => {
						commit('deleteFailure');
						return Promise.reject(error);
					}
				);
			},
			getProductInfo ({commit}, id="") {
				return ProductService.getProductInfo(id).then(
					(response) => {
						// commit('deleteSuccess', response);
						return Promise.resolve(response);
					},
					(error) => {
						commit('getProductFailure');
						return Promise.reject(error);
					}
				);
			}
    },
    mutations: {
			getSuccess(state, response) {
				state.stateData.currentPage = response.currentPage;
				state.stateData.totalItems = response.totalItems;
				state.stateData.totalPages = response.totalPages;
				state.stateData.listItems = response.listItems;
			},
			getFailure(state) {
				console.log("run getFailure");
				Object.assign(state.stateData, getDefaultState())
			},
			getSearchSuccess(state, response) {
				state.stateData.currentPage = response.currentPage;
				state.stateData.totalItems = response.totalItems;
				state.stateData.totalPages = response.totalPages;
				state.stateData.listItems = response.listItems;
			},
			getSearchFailure(state) {
				console.log("run getSearchFailure");
				Object.assign(state.stateData, getDefaultState())
			},
			deleteSuccess () {
				console.log("run deleteSuccess");
			},
			getProductFailure() {
				console.log("run getProductFailure");
			}
    },
    getters: {

    }
}


