import API from "@/config/api.js";
// import moment from 'moment'

class PartnerService {

	getCityList() {
		return API.get('common/city')
		.then((response) => {
			return response.data;
		})
		.catch(error => {
			console.log(error)
		});	
	}

	getDistrict(city_id) {
		return API.get(`common/district?city_id=${city_id}`)
		.then((response) => {
			return response.data;
		})
		.catch(error => {
			console.log(error)
		});	
	}

	getWard(district_id) {
		return API.get(`common/ward?district_id=${district_id}`)
		.then((response) => {
			return response.data;
		})
		.catch(error => {
			console.log(error)
		});	
	}

	savePartner(request) {
		return API.post('partner/add', request)
		.then(
			(response) => {
			return response.data;
			},
			(error) => {
			return Promise.reject(error);
			}
		)
	}

	getListData(page, size, type) {
		let size_text = size !== undefined ? "&size="+size : "";
		let type_text = type !== undefined ? "&type="+type : "";
		return API.get(`partner/?page=${page}` + size_text + type_text)
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

	searchPartner(data) {
    return API.get(`partner/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	deletePartner(partnerId) {
    return API.delete(`partner/delete/${partnerId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	getPartnerInfo(partnerId) {
		return API.get(`partner/${partnerId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

	getPartnerInformation(partnerId) {
		return API.get(`partner/${partnerId}`)
		.then(
			(response) => {
			return response.data;
			},
			(error) => {
			return Promise.reject(error);
			}
		)
	}

	updatePartner(request) {
		return API.put(`partner/edit/${request.id}`, request)
		.then(
			(response) => {
			return response.data;
			},
			(error) => {
			return Promise.reject(error);
			}
		)
	}
}

export default new PartnerService();