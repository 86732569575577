<template>
  <!-- Menu -->
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <div class="app-brand demo">
      <a href="/" class="app-brand-link">
      <span class="app-brand-logo demo">
        <img src="/assets/img/common/logo.png" width="145">
      </span>
      </a>
      <a class="layout-menu-toggle menu-link text-large ms-auto">
      <i class="bx bx-chevron-left bx-sm align-middle"></i>
    </a>
    </div>

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1" v-if="userLogged">      
      <!-- Dashboard -->
      <li class="menu-item">
        <router-link to="/" data-i18n="Dashboard" class="menu-link" @click="handleMenuItem">
          <i class="menu-icon tf-icons bx bx-home-circle"></i>
          Dashboard
        </router-link>
      </li>

      <!-- Main Business -->
      <li class="menu-header small text-uppercase" v-if="main_menu.length > 0">
        <span class="menu-header-text">Main Pages</span>
      </li>
      <li class="menu-item" 
        v-for="(menu, index) in main_menu" 
        :key="index"
        :class="{ 'active open': getActiveMenuTogle($route.fullPath, menu) }"
      >
        <a class="menu-link menu-toggle" @click="handleMenuToogle">
          <i :class="menu.icon"></i>
          <div data-i18n="{{menu.title}}">{{menu.title}}</div>
        </a>
        <ul class="menu-sub" v-if="menu.children.length > 0">
          <li class="menu-item" 
            v-for="(item, inx) in menu.children" 
            :key="inx"
            :class="{ 'active': getActiveMenu($route.fullPath, item) }"
          >
            <router-link :to="item.link" :data-i18n="item.title" :class="item.class" @click="handleMenuItem">
              {{item.title}}
            </router-link>
            <ul class="menu-sub" v-if="item.children != undefined && item.children.length > 0">
              <li class="menu-item" v-for="(ele, ix) in item.children" :key="ix">
                <router-link :to="ele.link" :data-i18n="ele.title" :class="ele.class">
                  {{ele.title}}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <!-- Settings -->
      <li class="menu-header small text-uppercase" v-if="settings_menu.length > 0">
        <span class="menu-header-text">Settings</span>
      </li>
      <li class="menu-item" v-for="(menu, index) in settings_menu" :key="index">
        <a class="menu-link menu-toggle" @click="handleMenuToogle">
          <i class="menu-icon" :class="menu.icon"></i>
          <div :data-i18n="menu.title">{{menu.title}}</div>
        </a>
        <ul class="menu-sub" v-if="menu.children.length > 0">
          <!-- <li class="menu-item" v-for="(item, idx) in menu.children" :key="idx"> -->
          <li class="menu-item" v-for="(item, idx) in settings_sub_menu(menu.children)" :key="idx">
            <router-link :to="item.link" :data-i18n="item.title" :class="item.class" @click="handleMenuItem">
              {{item.title}}
            </router-link>
          </li>
        </ul>
      </li>

      <!-- Advanced -->
      <li class="menu-header small text-uppercase" v-if="advanced_menu.length > 0">
        <span class="menu-header-text">Advanced</span>
      </li>
      <li class="menu-item" v-for="(menu, index) in advanced_menu" :key="index">
        <router-link :to="menu.link" :data-i18n="menu.title" :class="menu.class" @click="handleMenuItem">
          <i class="menu-icon" :class="menu.icon"></i>
          {{menu.title}}
        </router-link>
      </li>
    </ul>
    <ul class="menu-inner py-1" v-else>
    </ul>
  </aside>
  <!-- / Menu -->
</template>

<script>
import {SIDEBAR_MENU, MAIN_MENU, SETTING_MENU, ADVANCED_MENU} from '../../config/menu';


export default {
  name: 'Sidebar',
  data() {
    return {
    }
  },
  computed: {
    menus() {
      return SIDEBAR_MENU;
    },
    main_menu() {
      return MAIN_MENU.filter(menu => {
        if (this.$store.state.auth?.user){
          return menu.authorize.includes(this.$store.state.auth.user.user_info.role);
        }
      });
    },
    settings_menu() {
      return SETTING_MENU.filter(menu => {
        if (this.$store.state.auth?.user){
          return menu.authorize.includes(this.$store.state.auth.user.user_info.role);
        }
      });
    },
    advanced_menu() {
      return ADVANCED_MENU.filter(menu => {
        if (this.$store.state.auth?.user){
          return menu.authorize.includes(this.$store.state.auth.user.user_info.role);
        }
      });
    },
    userLogged() {
      if (this.$store.state.auth?.user){
        // return this.$store.state.auth.user;
        return this.$store.state.auth.status.loggedIn;
      }
      return false;
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    handleMenuToogle(event) {
      
      this.removeActiveElement("menu-toggle");
      event.target.parentElement.classList.add("active");
    },
    handleMenuItem(event) {

      this.removeActiveElement("menu-item");
      this.removeActiveElement("menu-link");
      event.target.parentElement.classList.add("active");
    },
    removeActiveElement (classname) {

      const elements = document.querySelectorAll("." + classname);
      elements.forEach((ele) => {
        ele.parentElement.classList.remove("active");
      });
    },
    getActiveMenu(fullPath, item) {

      if (this.$router.currentRoute.value.matched.length > 0)
        return this.$router.currentRoute.value.matched[0].path == item.link;
    },
    getActiveMenuTogle (fullPath, items) {

      if (items.children.length > 0) {
        let active_item = items.children.filter(menu => {
          if (this.$router.currentRoute.value.matched.length > 0)
            return this.$router.currentRoute.value.matched[0].path == menu.link
        });
        return active_item.length > 0 ? true : false;
      }
    },
    settings_sub_menu(menus) {
      return menus.filter(menu => {
        if (this.$store.state.auth?.user){
          return menu.authorize.includes(this.$store.state.auth.user.user_info.role);
        }
      });
    },

  },
}
</script>

<style>
a.menu-link {
  cursor: pointer;
}
#layout-menu {
  z-index: 9999;
}
</style>