<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span class="text-muted fw-light">SubCategory /</span> Add New
    </h4>

    <div class="row">
      <div class="card">
        <div class="row">
          <div class="col-md-7">
            <div class="card1">
              <div class="card-body">
                <div class="row">
                  <div class="mb-3 col-12">
                    <label class="form-label">Category Name</label>
                    <VueMultiselect 
                      v-model="categoryNameSelected" 
                      :options="stateData.listItems" 
                      placeholder="Please choose category" 
                      label="category_name" 
                      :close-on-select="true"
                      :clear-on-select="false"
                    />
                    <div class="error-msg" v-if="v$.stateData.product_category_id.$error"> 
                      {{ v$.stateData.product_category_id.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-8">
                    <label class="form-label">SubCategory Name</label>
                    <div class="position-relative">
                      <input type="text" 
                        class="form-control" 
                        v-model="stateData.sub_category_name"
                      >
                      <div class="error-msg" v-if="v$.stateData.sub_category_name.$error"> 
                        {{ v$.stateData.sub_category_name.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 col-4">
                    <label class="form-label">Symbol</label>
                    <div class="position-relative">
                      <input type="text" 
                        class="form-control"
                        v-model="stateData.symbol"
                      >
                      <div class="error-msg" v-if="v$.stateData.symbol.$error"> 
                        {{ v$.stateData.symbol.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-8">
                    <label class="form-label">Image</label>
                    <div class="position-relative">
                      <input 
                        class="form-control" 
                        type="file" 
                        accept=".jpg, .png"
                        id="image_link"
                        name="image-link"
                        @change="onFileChange"
                      >
                    </div>
                    <div class="mt-2" v-if="isUploadSuccess">
                      <span class="fw-bold text-success">The image is uploaded to server successfully.</span>
                    </div>
                    <div class="mt-2" v-if="isError">
                      <span class="fw-bold text-danger">The image isn't still uploaded to server.</span>
                    </div>
                  </div>
                  <div class="col-4">
                    <!-- <button id="button-upload" 
                      class="btn btn-warning me-1" 
                      @click="submitImageFile()" 
                      v-bind:disabled="isDisabledUpload"
                    >
                        Upload
                    </button> -->
                    <button id="button-clear-image" 
                      class="btn btn-warning" 
                      @click="clearImageFile()"
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-12">
                    <label class="form-label">Description</label>
                    <div class="position-relative">
                      <textarea class="form-control" 
                        rows="5" 
                        name="description"
                        v-model="stateData.description"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <form id="" @submit.prevent="handleSave">
                    <div class="col-md-12 mb-3 plus-minus mt-3">
                      <button type="submit" class="btn btn-primary me-3">Save SubCategory</button>
                      <router-link to="/subcategory" data-i18n="Save SubCategory" class="btn btn-outline-secondary w-45">
                        <i class='bx bx-arrow-back' ></i>
                        Back To List
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="mb-3 col-md-10">
                <div class="p-4 category-left category-top" v-for="(image, key) in images" :key="key">
                  <img class="card-img-top card-img-bottom" :ref="'image'">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { mapState } from "vuex";

export default {
  name: 'SubCategoryAdd',
  components: {
    VueMultiselect
  },
  data() {
    return {
      v$: useValidate(),
      images: [],
      isError: false,
      isUploadSuccess: false,
      // isDisabledUpload: true,
      categoryNameSelected: null,
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.subCategoryAdd.stateData
    }),
  },
  watch: {
    "categoryNameSelected" (newVal) {
      this.stateData.product_category_id = newVal.id;
    }
  },
  beforeCreate() {
    this.$store.dispatch('subCategoryAdd/resetState');
  },
  created() {
    this.$store.dispatch('subCategoryAdd/getListCategory').then(
      () => { 
        console.log("Get list category data successfully !!!");
      },
      (error) => {
        console.log(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Can not get list category data !'
        });
      }
    );
  },
  methods: {
    onFileChange(e) {
      let selectedFiles = e.target.files;
      if (selectedFiles.length > 0) {
        // this.isDisabledUpload = false;
        this.isError = false;
        this.images = [];
        for (let i = 0; i < selectedFiles.length; i++) {
          this.images.push(selectedFiles[i]);
        }

        if (this.images.length > 0) {
          this.stateData.image_link = this.images[0].name;
        }
        
        for (let i = 0; i < this.images.length; i++) {
          let reader = new FileReader();
          reader.onload = () => {
              this.$refs.image[i].src = reader.result;
            };
            reader.readAsDataURL(this.images[i]);
        }

        // Handling upload file onto server
        this.submitImageFile();
      }
    },
    submitImageFile() {
      let formData = new FormData();

      for( let i = 0; i < this.images.length; i++ ){
        let file = this.images[i];
        formData.append("files", file);
      }
      formData.append("service_name", "subcategory");
      this.$store.dispatch('subCategoryAdd/uploadImage', formData).then(
        () => {
          this.isUploadSuccess = true;
          this.isError = false;
        },
        error => {
          console.log(error);
          this.isUploadSuccess = false;
          // this.isDisabledUpload = false;
          this.isError = true;

          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to upload image !'
          });
        }
      );
    },
    clearImageFile() {
      this.images = [];
      let items =  this.$refs["image"];
      if (items != undefined){
        items.splice(0, items.length);
      }
      this.stateData.image_link = "";
      this.isUploadSuccess = false;
      // this.isDisabledUpload = false;
      this.isError = false;
    },
    handleSave() {
      if (this.stateData.image_link !== "" && this.stateData.image_link.indexOf("___") == -1) {
        this.isError = true;
        window.scrollTo(0,0);
        return;
      }
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$store.dispatch('subCategoryAdd/saveSubCategory', this.stateData).then(
          () => {
            let self = this;
            this.$swal.fire(
              'Saved',
              'Sub Category is saved sucessfully !',
              'success'
            ).then(function() {
              self.$router.push('/subcategory');
            });
          },
          error => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to save Sub Category !'
            });
          }
        );
      }
    },
  },
  validations() {
    return {
      stateData: {
        product_category_id: {
          required: helpers.withMessage('CATEGORY NAME cannot be empty', required)
        },
        sub_category_name: {
          required: helpers.withMessage('SUBCATEGORY NAME cannot be empty', required)
        },
        symbol: { 
          required: helpers.withMessage('SYMBOL cannot be empty', required)
        }
      }
    }
  }
}
</script>