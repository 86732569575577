// export const FILE_SERVER = {
//   "domain": "http://127.0.0.1:8000"
// }

// export const FILE_SERVER = {
//   "domain": "https://erp.lamodavietnam.com"
// }

export const CLOSING_TIME = [
  {
    id: 1,
    name: "After Purchase Order (PO)",
    description: "Pay after Purchase Order (PO)"
  },
  {
    id: 2,
    name: "After Delivery",
    description: "Pay after the delivery date"
  },
  {
    id: 3,
    name: "Invoice Date",
    description: "Pay on the invoice date"
  },
  {
    id: 4,
    name: "Bill Date",
    description: "Pay on the bill date"
  },
  {
    id: 5,
    name: "Before Shipment",
    description: "Pay before the shipment date"
  },
  {
    id: 6,
    name: "End of month normally",
    description: "Pay on the end date of month"
  },
  {
    id: 7,
    name: "End of month (exact date)",
    description: "Same as End of month, but get the defined date instead"
  },
  {
    id: 8,
    name: "After full set of document receipt",
    description: "Pay after receiving full set of document receipt"
  }
]

export const EXPORT_IMPORT = [
  {
    id: 1,
    name: "EXPORT",
    value: "E"
  },
  {
    id: 2,
    name: "IMPORT",
    value: "I"
  },
  {
    id: 3,
    name: "DOMESTIC",
    value: "D"
  }
]

export const PRODUCT_TYPE = [
  {
    id: 1,
    name: "PET",
    value: "P"
  },
  {
    id: 2,
    name: "HUMAN",
    value: "H"
  },
  {
    id: 3,
    name: "GOODS",
    value: "G"
  }
]

export const COUNTRIES = [
  {
    id: 1,
    name: "VIET NAM",
    value: "VN"
  },
  {
    id: 2,
    name: "JAPAN",
    value: "JP"
  },
  {
    id: 3,
    name: "CHINA",
    value: "CN"
  },
  {
    id: 4,
    name: "AUSTRALIA",
    value: "AU"
  },
  {
    id: 5,
    name: "NEW ZEALAND",
    value: "NZ"
  },
  {
    id: 6,
    name: "CAMBODIA",
    value: "CB"
  },
  {
    id: 7,
    name: "MALAYSIA",
    value: "MY"
  },
  {
    id: 8,
    name: "USA",
    value: "US"
  },
  {
    id: 9,
    name: "KOREA",
    value: "KR"
  },
  {
    id: 10,
    name: "EUROPEAN",
    value: "EU"
  },
  {
    id: 11,
    name: "THAILAND",
    value: "TH"
  },
  {
    id: 12,
    name: "CANADA",
    value: "CA"
  },
  {
    id: 13,
    name: "OTHER COUNTRY (USD)",
    value: "OC"
  }
]

export const CURRENCY_EXCHANGE = [
  {
    id: 1,
    name: "VIETNAM DONG (VND)",
    code: "VND",
    country_code: "VN",
    ex_rate: 1.00
  },
  {
    id: 2,
    name: "JAPAN YEN (JPY)",
    code: "JPY",
    country_code: "JP",
    ex_rate: 180.20
  },
  {
    id: 3,
    name: "US DOLLAR (USD)",
    code: "USD",
    country_code: "US",
    ex_rate: 23500
  },
  {
    id: 4,
    name: "CHINESE RENMINBI (CNY)",
    code: "CNY",
    country_code: "CN",
    ex_rate: 3.49
  },
  {
    id: 5,
    name: "AUSTRALIAN DOLLAR (AUD)",
    code: "AUD",
    country_code: "AU",
    ex_rate: 16147.86
  },
  {
    id: 6,
    name: "NEW ZEALAND DOLLAR (NZD)",
    code: "NZD",
    country_code: "NZ",
    ex_rate: 14651.03
  },
  {
    id: 7,
    name: "CAMPODIAN RIEL (KHR)",
    code: "KHR",
    country_code: "CB",
    ex_rate: 5.66
  },
  {
    id: 8,
    name: "MALAYSIA RINGGIT (MYR)",
    code: "MYR",
    country_code: "MY",
    ex_rate: 5339.55
  },
  {
    id: 9,
    name: "KOREAN WON (KRW)",
    code: "KRW",
    country_code: "KR",
    ex_rate: 18.68
  },
  {
    id: 10,
    name: "EURO (EUR)",
    code: "EUR",
    country_code: "EU",
    ex_rate: 25.5
  },
  {
    id: 11,
    name: "THAILAND BAHT (THB)",
    code: "THB",
    country_code: "TH",
    ex_rate: 690.6
  },
  {
    id: 12,
    name: "CANADA DOLLAR (CAD)",
    code: "CAD",
    country_code: "CA",
    ex_rate: 17944.78
  },
  {
    id: 13,
    name: "OTHER COUNTRY (USD)",
    code: "USD",
    country_code: "OC",
    ex_rate: 23500
  }
]

export const PREFIXS = [
  {
    id: 1,
    name: "LAMODA (LMD)",
    value: "LMD"
  },
  {
    id: 2,
    name: "PETMODA (PMD)",
    value: "PMD"
  }
]

export const PRODUCT_UNIT = [
  {
    id: 1,
    name: "PIECE",
    value: "pcs"
  },
  {
    id: 2,
    name: "SET",
    value: "set"
  },
  {
    id: 3,
    name: "CARTON BOX",
    value: "ctb"
  },
  {
    id: 4,
    name: "METRE",
    value: "m"
  },
  {
    id: 5,
    name: "KILOMETRE",
    value: "km"
  },
  {
    id: 6,
    name: "KILOGRAM",
    value: "kg"
  }
]

export const TAX_CONFIG = [
  {
    id: 1,
    name: "VALUE ADDED TAX",
    value: 10,
    symbol: "VAT"
  },
  {
    id: 2,
    name: "SPECIAL CONSUMPTION TAX",
    value: 10,
    symbol: "SCT"
  },
  {
    id: 3,
    name: "THE THIRD TAX",
    value: 5,
    symbol: "TAX_3"
  }
]

export const QUALITY_CONTROL_STATUS = [
  {
    id: 1,
    name: "SPOT BUY",
    value: "SB"
  },
  {
    id: 2,
    name: "QUALITY CONTROL",
    value: "QC"
  },
]

export const PRODUCT_CSV_HEADER = [
  {
    code: "no",
    name: "No"
  },
  {
    code: "quality_control_status",
    name: "QC St"
  },
  {
    code: "category",
    name: "Cate"
  },
  {
    code: "sub_category",
    name: "Sub Cate"
  },
  {
    code: "register_date",
    name: "Reg Date"
  },
  {
    code: "consective_no",
    name: "Cons No"
  },
  {
    code: "product_name",
    name: "Product Name"
  },
  {
    code: "product_unit",
    name: "Unit"
  },
  {
    code: "supplier_code",
    name: "Supplier Code"
  },
  {
    code: "description",
    name: "Description"
  },
  {
    code: "product_code",
    name: "Product Code"
  }
]

export const CONTRACT_CSV_HEADER = [
  {
    code: "no",
    name: "No"
  },
  {
    code: "product_code",
    name: "Product Code"
  },
  {
    code: "quantity",
    name: "Quantity"
  },
  {
    code: "buy_price",
    name: "Buy Price"
  },
  {
    code: "sale_price",
    name: "Sale Price"
  },
  {
    code: "tax_buy_name_1",
    name: "tax_buy_name_1"
  },
  {
    code: "tax_buy_value_1",
    name: "tax_buy_value_1"
  },
  {
    code: "tax_buy_name_2",
    name: "tax_buy_name_2"
  },
  {
    code: "tax_buy_value_2",
    name: "tax_buy_value_2"
  },
  {
    code: "tax_buy_name_3",
    name: "tax_buy_name_3"
  },
  {
    code: "tax_buy_value_3",
    name: "tax_buy_value_3"
  },
  {
    code: "tax_sale_name_1",
    name: "tax_sale_name_1"
  },
  {
    code: "tax_sale_value_1",
    name: "tax_sale_value_1"
  },
  {
    code: "tax_sale_name_2",
    name: "tax_sale_name_2"
  },
  {
    code: "tax_sale_value_2",
    name: "tax_sale_value_2"
  },
  {
    code: "tax_sale_name_3",
    name: "tax_sale_name_3"
  },
  {
    code: "tax_sale_value_3",
    name: "tax_sale_value_3"
  }
]

export const SHIPMENT_CSV_HEADER = [
  {
    code: "no",
    name: "No"
  },
  {
    code: "product_code",
    name: "Product Code"
  },
  {
    code: "quantity_in",
    name: "Quantity IN"
  },
  {
    code: "quantity_out",
    name: "Quantity OUT"
  }
]

export const PAYMENT_METHOD = [
  {
    id: 1,
    name_en: "TT Remittance",
    name_vn: "Chuyển khoản",
    value: "TT"
  },
  {
    id: 2,
    name_en: "Letter of Credit (L/C)",
    name_vn: "Thanh toán bằng thư tín dụng",
    value: "LC"
  }
]