import PartnerService from '../services/partner.service';
import PartnerRegistrationService from '../services/partner_registration.service';
// import moment from 'moment'

const getDefaultState = () => {
  return {
    type: null,
    partner_name: "",
    partner_short_name: "",
    partner_code: "",
    partner_code_id: "",
    person_in_charge: "",
    tax_code: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    list_city: [],
    district: "",
    list_district: [],
    ward: "",
    list_ward: [],
    country: "",
    zipcode: "",
    payment_method: "",
    term: "",
    closing_time: "",
    has_deposit: null,
    percent_deposit: "",
    percent_balance: "",
    exact_date: "",
    bank_name_foreign: "",
    bank_name_vn: "",
    bank_name: "",
    swift_code: "",
    bank_account_name: "",
    bank_account_number: ""
  }
}

export const partnerAdd = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    getCityList({commit}) {
      return PartnerService.getCityList().then(
        (response) => {
          commit('getSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getFailure');
          return Promise.reject(error);
        }
      );
    },
    getDistrict({commit}, city_id) {
      return PartnerService.getDistrict(city_id).then(
        (response) => {
          commit('getDistrictSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getDistrictFailure');
          return Promise.reject(error);
        }
      );
    },
    getWard({commit}, district_id) {
      return PartnerService.getWard(district_id).then(
        (response) => {
          commit('getWardSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getWardFailure');
          return Promise.reject(error);
        }
      );
    },
    resetState({commit}) {
      commit("resetState");
    },
    getPartnerRegByCode({commit}, {"page": page, "searchKey": searchKey}) {
      return PartnerRegistrationService.searchPartnerCode({"page": page, "searchKey": searchKey})
      .then(
        (response) => {
          commit('getSearchSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getSearchFailure');
          return Promise.reject(error);
        }
      );
    },
    savePartner({commit}, data) {
      return PartnerService.savePartner(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveFailure");
          return Promise.reject(error);
        }
      );
    },
    getPartnerInformation({commit}, partner_id) {
      return PartnerService.getPartnerInformation(partner_id).then(
        (response) => {
          commit('getPartnerInformationSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getPartnerInformationFailure');
          return Promise.reject(error);
        }
      );
    },
    updatePartner({commit}, data) {
      return PartnerService.updatePartner(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState())
    },
    getSuccess(state, response) {
      state.stateData.list_city = [];
      Object.keys(response).forEach(key => {
        state.stateData.list_city.push(response[key]);
      })
    },
    getFailure(state) {
      state.stateData.list_city = [];
      state.stateData.city = "";
    },
    getDistrictSuccess(state, response) {
      state.stateData.list_district = []
      Object.keys(response).forEach(key => {
        state.stateData.list_district.push(response[key]);
      })
    },
    getDistrictFailure(state) {
      state.stateData.list_district = [];
      state.stateData.district = "";
    },
    getWardSuccess(state, response) {
      state.stateData.list_ward = [];
      Object.keys(response).forEach(key => {
        state.stateData.list_ward.push(response[key]);
      })
    },
    getWardFailure(state) {
      state.stateData.list_ward = [];
      state.stateData.ward = "";
    },
    getSearchSuccess(state, response) {
      if (response.listItems.length > 0){
        state.stateData.partner_code_id = response.listItems[0].id;
      }
      else{
        state.stateData.partner_code_id = "";
      }
    },
    getSearchFailure(state) {
      state.stateData.partner_code_id = "";
    },
    getPartnerInformationSuccess(state, response) {
      let merge = {...state.stateData, ...response}
      state.stateData = merge
      console.log(state.stateData);
    }
  },
  getters: {

  }
}