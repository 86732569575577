import API from "@/config/api.js";
import moment from 'moment'

class ContractRegistrationService {
  
  getListData(page) {
    return API.get(`contract-registration/?page=${page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  searchContractCode(data) {
    return API.get(`contract-registration/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  deleteContractCode(partRegId) {
    return API.delete(`contract-registration/delete/${partRegId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getLatestCode() {
    return API.get('contract-registration/get-latest')
    .then((response) => {
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
  }

  initConsectiveNo(data){
    let today = new Date()
    let flag = moment(data.date).isSame(today, 'day');
    let result = "01"
    if (flag) {
      let num = data.consective_no;
      return ("0" + (parseInt(num) + 1)).slice(-2);
    }
    return result;
  }

  saveCode(data) {
    return API.post('contract-registration/add', {
      contract_code: data.contract_code,
      edi: data.edi,
      phg: data.phg,
      vcj_origin: data.vcj_origin,
      vcj_sales: data.vcj_sales,
      date: data.date,
      consective_no: data.consective_no
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  updateCode(data) {
    return API.put(`contract-registration/edit/${data.id}`, data)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  getContractRegCode(id) {
    return API.get(`contract-registration/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
  }
}

export default new ContractRegistrationService();