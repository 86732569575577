<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Definition</span>
    </h4>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive text-nowrap">
              <div class="row ms-2 me-3">
                <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                  <div class="dataTables_length" id="DataTables_Table_0_length">
                    <div class="dt-buttons">
                      <router-link to="/definition/add" data-i18n="Create Definition" class="dt-button btn btn-primary">
                        <i class="bx bx-plus me-md-2"></i>
                        Create New
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center justify-content-end flex-column flex-md-row pe-3 gap-md-2">
                  <div class="invoice_status col-md-4 mb-3 mb-md-0">
                    <select id="UserRole" class="form-select">
                      <option value=""> Select Type </option>
                      <option value="quotation" class="text-capitalize">Quotation</option>
                      <option value="partner" class="text-capitalize">Partner</option>
                      <option value="contract" class="text-capitalize">Contract</option>
                      <option value="Product" class="text-capitalize">product</option>
                      <option value="Invoice" class="text-capitalize">invoice</option>
                    </select>
                  </div>
                </div>
              </div>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Group</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0">
                  <tr>
                    <td>1</td>
                    <td>
                      <strong>Quotation</strong>
                    </td>
                    <td>1</td>
                    <td>E, I, D</td>
                    <td>
                      <div class="text-truncate" style="max-width: 300px;">
                        Export, Import, Domestic
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <router-link to="/definition/edit/1" data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <strong>Partner</strong>
                    </td>
                    <td>1</td>
                    <td>P, R</td>
                    <td>Payable, Receivable</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <router-link to="/definition/edit/2" data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <strong>Partner</strong>
                    </td>
                    <td>2</td>
                    <td>M, C, S</td>
                    <td>Manufacturer, Customer, Service</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <router-link to="/definition/edit/3" data-i18n="Create Definition" class="text-body me-3">
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <div class="row mx-2">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_paginate paging_simple_numbers" id="">
                    <ul class="pagination">
                      <li class="page-item prev">
                        <a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-left"></i></a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link" href="javascript:void(0);">1</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="javascript:void(0);">2</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="javascript:void(0);">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="javascript:void(0);">4</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="javascript:void(0);">5</a>
                      </li>
                      <li class="page-item next">
                        <a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-right"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card-body"></div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>


export default {
  name: 'DefinitionList',
  components: {

  },
  created() {

  },
}
</script>