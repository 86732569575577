import SubCategoryService from '../services/subCategory.service';

const getDefaultState = () => {
	return {
		id: "",
		product_category_id: "",
		sub_category_name: "",
		symbol: "",
		description: "",
		image_link: "",
		listItems: []
	}
}

export const subCategoryAdd = {
	namespaced: true,
	state: {
		stateData: getDefaultState()
	},
	actions: {
		resetState({commit}) {
			commit("resetState");
		},
		getListCategory({commit}, page=1, size=-1) {
			return SubCategoryService.getListCategory(page, size).then(
				(response) => {
					commit('getSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getFailure');
					return Promise.reject(error);
				}
			);
		},
		uploadImage({commit},formdata) {
			return SubCategoryService.uploadImage(formdata).then(
				(response) => {
					commit('uploadSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit("uploadFailure");
					return Promise.reject(error);
				}
			);
		},
		saveSubCategory({commit}, data) {
			return SubCategoryService.saveSubCategory(data).then(
				(response) => {
					return Promise.resolve(response);
				},
				(error) => {
					commit("saveFailure");
					return Promise.reject(error);
				}
			);
		},
		getSubCategoryInfo ({commit}, id="") {
			return SubCategoryService.getSubCategoryInfo(id).then(
				(response) => {
					commit('getSubCategorySuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getSubCategoryFailure');
					return Promise.reject(error);
				}
			);
		},
		updateSubCategory({commit}, data) {
			return SubCategoryService.updateSubCategory(data).then(
				(response) => {
					return Promise.resolve(response);
				},
				(error) => {
					commit("updateFailure");
					return Promise.reject(error);
				}
			);
		},
	},
	mutations: {
		resetState (state) {
			console.log("run resetState");
			Object.assign(state.stateData, getDefaultState());
		},
		getSuccess(state, response) {
      state.stateData.listItems = response.listItems;
    },
    getFailure(state) {
      console.log("run getFailure");
      Object.assign(state.stateData, getDefaultState())
    },
		uploadSuccess(state, response) {
			state.stateData.image_link = response.image_names[0];
		},
		uploadFailure(state) {
			state.stateData.image_link = "";
		},
		getSubCategorySuccess(state, response) {
			state.stateData.id = response.id;
			state.stateData.product_category_id = response.product_category_id;
			state.stateData.sub_category_name = response.sub_category_name;
			state.stateData.symbol = response.symbol;
			state.stateData.description = response.description;
			state.stateData.image_link = response.image_link;
		},
		getSubCategoryFailure(state) {
			this.resetState(state);
		},
	},
	getters: {
		selectedCategory_test (state) {
			return state.stateData.listItems.filter(item => {
				return item.id == this.stateData.product_category_id; 
			});
		}
	}
}