import ContractService from '../services/contract.service';


const getDefaultState = () => {
  return {
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
    listItems: []
  }
}

export const contractList = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    getListData({commit}, page=1) {
      return ContractService.getListData(page).then(
        (response) => {
          commit('getSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getFailure');
          return Promise.reject(error);
        }
      );
    },
    searchContract({commit}, {"page": page, "searchKey": searchKey}) {
      return ContractService.searchContract({"page": page, "searchKey": searchKey}).then(
        (response) => {
          commit('getSearchSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getSearchFailure');
          return Promise.reject(error);
        }
      );
    },
    deleteContract ({commit}, id="") {
      return ContractService.deleteContract(id).then(
        (response) => {
          // commit('deleteSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('deleteFailure');
          console.log("error: ", error);
          return Promise.reject(error);
        }
      );
    },
    getContractInfo ({commit}, id="") {
      return ContractService.getContractInfo(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('getContractFailure');
          return Promise.reject(error);
        }
      );
    },
    exportContractFile ({commit}, id="") {
      return ContractService.exportContractFile(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('exportContractFailure');
          return Promise.reject(error);
        }
      );
    },
    modifyCostContract({commit}, data) {
      return ContractService.modifyCostContract(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("modifyCostContractFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getFailure(state) {
      console.log("run getFailure");
      Object.assign(state.stateData, getDefaultState())
    },
    getSearchSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getSearchFailure(state) {
      console.log("run getSearchFailure");
      Object.assign(state.stateData, getDefaultState())
    },
    deleteSuccess () {
      console.log("run deleteSuccess");
    },
    deleteFailure() {
      console.log("run deleteFailure");
      // Object.assign(state.stateData, getDefaultState())
    },
    getContractFailure() {
      console.log("run getContractFailure");
    },
    exportContractFailure() {
      console.log("run exportContractFailure");
    },
    modifyCostContractFailure() {
      console.log("run modifyCostContractFailure");
    }
  },
  getters: {

  }
}