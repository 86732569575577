<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Invoice List</span>
    </h4>

    <div class="row">
      <!-- Product List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
          <div class="text-nowrap">
            <div class="row">
              <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                  <div class="dataTables_length" id="DataTables_Table_0_length">
                    <div class="dt-buttons">
                      <router-link to="/invoice/add" data-i18n="Create Definition" class="dt-button btn btn-primary">
                        <i class="bx bx-plus me-md-2"></i>
                        Create New
                      </router-link>
                    </div>
                  </div>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <div class="input-group input-group-merge">
                  <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
                  <input type="text" class="form-control" placeholder="Search invoice code" aria-label="Search..." aria-describedby="basic-addon-search31">
                </div>
              </div>
              <!-- <div class="col-12 col-md-6 d-flex align-items-center justify-content-end flex-column flex-md-row pe-3 gap-md-2">
                <div class="invoice_status col-md-6 mb-3 mb-md-0">
                  <select id="UserRole" class="form-select">
                    <option value=""> Select Status </option>
                    <option value="quotation" class="text-capitalize">Paid</option>
                    <option value="partner" class="text-capitalize">Partial Payment</option>
                    <option value="contract" class="text-capitalize">Draft</option>
                  </select>
                </div>
              </div> -->
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Invoice No</th>
                  <th>Customer</th>
                  <th>Issued Date</th>
                  <th>Total</th>
                  <th>Balance</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr>
                  <td>
                    <strong>LMDVN221001-0001</strong>
                  </td>
                  <td>Customer Name ABC</td>
                  <td>31/12/2022</td>
                  <td>$2771</td>
                  <td><span class="">$666</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/invoice/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>LMDVN221001-0002</strong>
                  </td>
                  <td>Customer Name XYZ</td>
                  <td>01/01/2023</td>
                  <td>$3789</td>
                  <td><span class="badge bg-label-success"> Paid </span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/invoice/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>LMDVN221001-0003</strong>
                  </td>
                  <td>Customer Name QWERTY</td>
                  <td>18/12/2023</td>
                  <td>$3102</td>
                  <td><span class="text-danger">-$153</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/invoice/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>LMDVN221001-0004</strong>
                  </td>
                  <td>Customer Name POIUY</td>
                  <td>20/8/2023</td>
                  <td>$2783</td>
                  <td><span class="badge bg-label-success"> Paid </span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/invoice/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>LMDVN221001-0005</strong>
                  </td>
                  <td>Customer Name BMW</td>
                  <td>30/9/2023</td>
                  <td>$4056</td>
                  <td><span class=""> $888 </span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/invoice/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div class="row mx-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="dataTables_paginate paging_simple_numbers" id="">
                <ul class="pagination">
                    <li class="page-item prev">
                    <a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-left"></i></a>
                    </li>
                    <li class="page-item active">
                    <a class="page-link" href="javascript:void(0);">1</a>
                    </li>
                    <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">2</a>
                    </li>
                    <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">3</a>
                    </li>
                    <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">4</a>
                    </li>
                    <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">5</a>
                    </li>
                    <li class="page-item next">
                    <a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-right"></i></a>
                    </li>
                </ul>
                </div>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <!-- OffCanvas from the right -->
    <div class="row">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScroll" 
        aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility:visible; width:40%;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0 overflow-hidden">
          <div class="d-flex flex-wrap my-2">
            <div class="col-sm-10">
              <span class="fw-bold text-warning"># LMDVN221001-0001</span>
            </div>
            <div class="col-sm-2">
              <span class="fw-bold badge bg-label-success">PAID</span>
            </div>
            <div class="col-sm-12 mt-2">
              <span class="fw-bold">TOTAL QUANTITY: </span>
              <span class="fw-bold text-primary">1,000</span>
            </div>
            <div class="col-sm-6 mt-2">
              <span class="fw-bold">TOTAL AMOUNT: </span>
              <span class="fw-bold text-primary">$2,771</span>
            </div>
            <div class="col-sm-6 mt-2 text-end">
              <span class="fw-bold">BALANCE: </span>
              <span class="text-danger">-$666</span>
            </div>
          </div>
          <h5 class="pb-2 border-bottom mb-2"></h5>
          <div class="info-container">
            <ul class="list-unstyled">
              <li class="mb-3">
                <span class="fw-bold me-2">Customer:</span>
                <span class="">Customer Name ABC</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Contract No:</span>
                <span class="">DHVNNZ220901-0001</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Issued Date:</span>
                <span class="">31/12/2022</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Date of Shipment:</span>
                <span class="">25/12/2022</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Country of Origin:</span>
                <span class="">VIETNAM</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Means of Transport:</span>
                <span class="">PEARL VN2221</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">HAWB:</span>
                <span class="">SGN001987</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Port of Loading:</span>
                <span class="">HO CHI MINH CITY PORT, VIETNAM</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Port of Discharge:</span>
                <span class="">OSAKA PORT, JAPAN</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">FOR ACCOUNT AND RISKS OF MESSRS:</span><br/>
                <span class="">Customer Company Name</span><br/>
                <span class="">Adress of Customer Company ABC XYZ QWERTY BMW ASDFGHJ POIASD</span>
              </li>
            </ul>
            <div class="row mt-4">
              <div class="col-md-6">
                <router-link to="/invoice/edit/1" data-i18n="Contract Registration" class="btn btn-primary w-100">
                  Edit Invoice
                </router-link>
              </div>
              <div class="col-md-6">
                <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
                  <i class='bx bx-arrow-back' ></i>
                  Back to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>

export default {
  name: 'InvoiceList',
  components: {
      
  },
  created() {

  },
}
</script>