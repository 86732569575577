import API from "@/config/api.js";
// import {API_FORM} from "@/config/api.js";
// import moment from 'moment'


class RevenueStatisticService {
	searchRevenue(conditions) {
    return API.get(`statistic/revenue/?start_date=${conditions.start_date}&end_date=${conditions.end_date}&contract_no=${conditions.contract_no}&invoice_no=${conditions.invoice_no}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  exportStatisticFile(conditions) {
    return API.get(`statistic/revenue/export/?start_date=${conditions.start_date}&end_date=${conditions.end_date}&contract_no=${conditions.contract_no}&invoice_no=${conditions.invoice_no}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }
}

export default new RevenueStatisticService();