import API from "@/config/api.js";
import {API_FORM} from "@/config/api.js";
// import moment from 'moment'
import InvoiceRegistrationService from "./invoice_registration.service";
import PartnerService from "./partner.service"
// import ProductService from "./product.service";
import ContractService from "./contract.service";


class ShipmentService {

  searchContract(data) {
    return ContractService.searchContract(data);
  }

  getPartnerInfo(partnerId) {
		return PartnerService.getPartnerInfo(partnerId); 
	}

  searchInvoiceRegByCode(data) {
    return InvoiceRegistrationService.searchInvoiceCode(data);
  }

  getContractInfo(contractId) {
		return ContractService.getContractInfo(contractId);
	}

	getShipmentQuantityRemain(contractId) {
		return ContractService.getShipmentQuantityRemain(contractId);
	}

  saveShipment(request) {
		return API.post('invoice/add', request)
		.then(
			(response) => {
        return response.data;
			},
			(error) => {
        return Promise.reject(error);
			}
		)
	}

	getListData(page) {
    return API.get(`contract/shipment/?page=${page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	searchShipment(data) {
    return API.get(`contract/shipment/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

	getShipmentInfo(contractId) {
		return API.get(`contract/shipment/detail/${contractId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

	deleteShipment(data) {
    return API.delete(`contract/shipment/delete/?receiving_id=${data.receiving_id}&delivery_id=${data.delivery_id}&invoice_id=${data.invoice_id}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  uploadShipmentFile(formdata) {
		return API_FORM.post('common/upload_shipment_file', formdata)
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

  uploadShipmentFileAfter(formdata) {
		return API_FORM.post('common/upload_shipment_file_after', formdata)
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

  deleteUploadedFile(data) {
    return API.put(`contract/shipment/delete_file/?invoice_code_id=${data.invoice_code_id}&el_name=${data.el_name}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  exportDeliveryVoucherFile(deliveryId) {
		return API.get(`shipment/delivery_voucher_export/${deliveryId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

  exportInvoiceFile(invoiceId) {
		return API.get(`shipment/invoice_export/${invoiceId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

  exportReceivingVoucherFile(receivingId) {
		return API.get(`shipment/receiving_voucher_export/${receivingId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

  modifyCostInvoices(request) {
		return API.put('shipment/modify_cost', request)
		.then(
			(response) => {
        return response.data;
			},
			(error) => {
        return Promise.reject(error);
			}
		)
	}
}

export default new ShipmentService();