import ProductRegistrationService from '../services/product_registration.service';
import moment from 'moment'

const getDefaultState = () => {
  return {
    product_code: "",
    category_id: "",
    sub_category_id: "",
    date: null,
    consective_no: "",
    quality_control_status: "",
    list_categories: [],
    list_sub_categories: []
  }
}

export const productRegistrationAdd = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    getLatestCode({commit}) {
      return ProductRegistrationService.getLatestCode().then(
        (response) => {
          commit('getLatestSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getLatestFailure');
          return Promise.reject(error);
        }
      );
    },
    getCategoryList({commit}, page=1, size=-1) {
      return ProductRegistrationService.getCategoryList(page, size).then(
        (response) => {
          commit('getCategorySuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getCategoryFailure');
          return Promise.reject(error);
        }
      );
    },
    getSubCategoryList({commit}, categoryId) {
      return ProductRegistrationService.getSubCategoryList(categoryId).then(
        (response) => {
          commit('getSubCategorySuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getSubCategoryFailure');
          return Promise.reject(error);
        }
      );
    },
    saveCode({commit}, data) {
      return ProductRegistrationService.saveCode(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveFailure");
          return Promise.reject(error);
        }
      );
    },
    resetState({commit}) {
      commit("resetState");
    },
    getProductRegCode({commit}, id) {
      return ProductRegistrationService.getProductRegCode(id).then(
        (response) => {
          commit('getProductRegSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('getProductRegFailure');
          return Promise.reject(error);
        }
      );
    },
    updateCode({commit}, data) {
      console.log("call updateCode module");
      return ProductRegistrationService.updateCode(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getLatestSuccess(state, response) {
      state.stateData.consective_no = ProductRegistrationService.initConsectiveNo(response);
    },
    getLatestFailure(state) {
      state.stateData.consective_no = ""
    },
    generateCode(state) {
      let selected_date = moment(state.stateData.date).format("YYMMDD");
      state.stateData.product_code = state.stateData.category_id + 
        state.stateData.sub_category_id + selected_date +"-" + state.stateData.consective_no;
    },
    resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState())
    },
    getCategorySuccess (state, response) {
      state.stateData.list_categories = response.listItems;
    },
    getCategoryFailure (state) {
      state.stateData.list_categories = [];
    },
    getSubCategorySuccess (state, response) {
      state.stateData.list_sub_categories = response.listItems;
    },
    getSubCategoryFailure (state) {
      state.stateData.list_sub_categories = []
    },
    getProductRegSuccess(state, response) {
      state.stateData.id = response.id;
      state.stateData.product_code = response.product_code;
      state.stateData.category_id = response.category_id;
      state.stateData.sub_category_id = response.sub_category_id;
      state.stateData.date = response.date;
      state.stateData.consective_no = response.consective_no;
      state.stateData.quality_control_status = response.quality_control_status;
    },
    getProductRegFailure(state) {
      this.resetState(state);
    },
  },
  getters: {

  }
}