<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span class="text-muted fw-light">Contract Code Registration /</span> Edit
    </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <h5 class="card-header">Edit Contract Code</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="card-body">
                <form id="formAccountSettingsApiKey" method="POST" onsubmit="return false" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                  <div class="row fv-plugins-icon-container">
                    <div class="mb-3 col-12">
                      <label for="apiAccess" class="form-label">Export / Import / Domestic</label>
                      <div class="position-relative">
                        <VueMultiselect 
                          v-model="eidSelected" 
                          :options="EXPORT_IMPORT_OPTIONS" 
                          placeholder="Please choose type" 
                          label="name" 
                          :close-on-select="true"
                          :clear-on-select="false"
                          @select="onChangeSelect"
                        />
                        <div class="error-msg" v-if="v$.stateData.edi.$error"> 
                          {{ v$.stateData.edi.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-12">
                      <label for="apiAccess" class="form-label">Product Type</label>
                      <div class="position-relative">
                        <VueMultiselect 
                          v-model="phgSelected" 
                          :options="PRODUCT_TYPE_OPTIONS" 
                          placeholder="Please choose type" 
                          label="name" 
                          :close-on-select="true"
                          :clear-on-select="false"
                          @select="onChangeSelect"
                        />
                        <div class="error-msg" v-if="v$.stateData.phg.$error"> 
                          {{ v$.stateData.phg.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-12">
                      <label for="apiAccess" class="form-label">Origin Country</label>
                      <div class="position-relative">
                        <VueMultiselect 
                          v-model="originSelected" 
                          :options="COUNTRIES_OPTIONS" 
                          placeholder="Please choose type" 
                          label="name" 
                          :close-on-select="true"
                          :clear-on-select="false"
                          @select="onChangeSelect"
                        />
                        <div class="error-msg" v-if="v$.stateData.vcj_origin.$error"> 
                          {{ v$.stateData.vcj_origin.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-12">
                      <label for="apiAccess" class="form-label">Sales Country</label>
                      <div class="position-relative">
                        <VueMultiselect 
                          v-model="saleSelected" 
                          :options="COUNTRIES_OPTIONS" 
                          placeholder="Please choose type" 
                          label="name" 
                          :close-on-select="true"
                          :clear-on-select="false"
                          @select="onChangeSelect"
                        />
                        <div class="error-msg" v-if="v$.stateData.vcj_sales.$error"> 
                          {{ v$.stateData.vcj_sales.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-6">
                      <label for="apiAccess" class="form-label">Register Date</label>
                      <div class="position-relative">
                        <flat-pickr 
                          v-model="stateData.date" 
                          :config="calendarConfig" 
                          placeholder="Select a date" 
                          id="register_date"
                          name="register-date" 
                          class="form-control"
                          @on-close="onchange()" 
                        >
                        </flat-pickr>
                        <div class="error-msg" v-if="v$.stateData.date.$error"> 
                          {{ v$.stateData.date.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-6">
                      <label for="apiAccess" class="form-label">Consective No</label>
                      <div class="position-relative">
                        <input class="form-control text-end" 
                          type="text" 
                          name="consective-no" 
                          id="consective_no" 
                          v-model="stateData.consective_no"
                          v-on:keyup="onchange()"
                        >
                        <div class="error-msg" v-if="v$.stateData.consective_no.$error"> 
                          {{ v$.stateData.consective_no.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-3">
                      <button type="submit" @click="generateCode" class="btn btn-outline-success me-2 d-grid w-100">
                        Generate Contract Code
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 pe-5">
              <div class="bg-lighter rounded p-4 mb-3 position-relative">
                <div class="d-flex align-items-center flex-wrap mt-4">
                  <h5 class="mb-0 me-3">Generated Contract Code</h5> 
                </div>
                <div class="alert alert-success d-flex mt-4" role="alert">
                  <div class="d-flex flex-column ps-1">
                    <h6 class="alert-heading d-flex align-items-center fw-bold mb-3 mt-3">{{stateData.contract_code}}</h6>
                  </div>
                </div>
                <div class="mt-3 p-1">
                  <div class="error-msg" v-if="v$.stateData.contract_code.$error"> 
                    {{ v$.stateData.contract_code.$errors[0].$message }}
                  </div>
                </div>
                <div class="row" style="margin-top:14rem">
                  <form id="" @submit.prevent="handleUpdate">
                    <div class="col col-sm-12 text-center">
                      <button type="submit" class="btn btn-primary me-2">Update Code</button>
                      <router-link to="/contract-registration" data-i18n="Contract Registration" class="btn btn-outline-secondary w-45">
                        <i class='bx bx-arrow-back' ></i>
                        Back To Definition
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
import useValidate from '@vuelidate/core'
import { required, numeric, maxLength, helpers } from '@vuelidate/validators'
import { mapState } from "vuex";
import VueMultiselect from 'vue-multiselect'
import {EXPORT_IMPORT, PRODUCT_TYPE, COUNTRIES} from "../../config/common"
import moment from 'moment'


export default {
  name: 'ContractRegistrationEdit',
  components: {
    flatPickr,
    VueMultiselect
  },
  data() {
    return {
      v$: useValidate(),
      calendarConfig: configs,
      eidSelected: null,
      phgSelected: null,
      originSelected: null,
      saleSelected: null
    }
  },
  computed: {
    ...mapState({
      stateData: state => state.contractRegistrationAdd.stateData
    }),
    EXPORT_IMPORT_OPTIONS() {
      return EXPORT_IMPORT;
    },
    PRODUCT_TYPE_OPTIONS() {
      return PRODUCT_TYPE;
    },
    COUNTRIES_OPTIONS() {
      return COUNTRIES;
    }
  },
  watch: {
    "eidSelected" (newVal) {
      this.stateData.edi = newVal.value;
      // this.onchange();
    },
    "phgSelected" (newVal) {
      this.stateData.phg = newVal.value;
      // this.onchange();
    },
    "originSelected" (newVal) {
      this.stateData.vcj_origin = newVal.value;
      // this.onchange();
    },
    "saleSelected" (newVal) {
      this.stateData.vcj_sales = newVal.value;
      // this.onchange();
    },
  },
  beforeCreate() {
    this.$store.dispatch('contractRegistrationAdd/resetState');
  },
  created() {
    let paramID = this.$router.currentRoute._value.params.id;
    this.$store.dispatch('contractRegistrationAdd/getContractRegCode', paramID).then(
      () => { 
        let queryExImOp = this.EXPORT_IMPORT_OPTIONS.filter(item => {
            return item.value === this.stateData.edi
          });
        this.eidSelected = queryExImOp[0];

        let queryProType = this.PRODUCT_TYPE_OPTIONS.filter(item => {
            return item.value === this.stateData.phg
          });
        this.phgSelected = queryProType[0];

        let queryOriginCountry = this.COUNTRIES_OPTIONS.filter(item => {
            return item.value === this.stateData.vcj_origin
          });
        this.originSelected = queryOriginCountry[0];

        let querySaleCountry = this.COUNTRIES_OPTIONS.filter(item => {
            return item.value === this.stateData.vcj_sales
          });
        this.saleSelected = querySaleCountry[0];
      },
      (error) => {
        console.log(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Can not get the contract code value !'
        });
      }
    );
  },
  mounted() {
  },
  methods: {
    handleUpdate() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$store.dispatch('contractRegistrationAdd/updateCode', this.stateData).then(
          () => {
            let self = this;
            this.$swal.fire(
              'Updated',
              'Contract Code is updated sucessfully !',
              'success'
            ).then(function() {
              self.$router.push('/contract-registration');
            });
          },
          error => {
            console.log(error);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to update Contract Code !'
            });
          }
        );
      }
    },
    generateCode() {
      let selected_date = moment(this.stateData.date).format("YYMMDD");
      this.stateData.contract_code = this.stateData.edi + this.stateData.phg  + 
        this.stateData.vcj_origin + this.stateData.vcj_sales + selected_date +"-" + this.stateData.consective_no;
    },
    onchange() {
      this.stateData.contract_code = "";
    },
    onChangeSelect() {
      this.stateData.contract_code = "";
    }
  },
  validations() {
    return {
      stateData: {
        edi: {
          required: helpers.withMessage('This field cannot be empty', required)
        },
        phg: {
          required: helpers.withMessage('This field cannot be empty', required)
        },
        vcj_origin: {
          required: helpers.withMessage('This field cannot be empty', required)
        },
        vcj_sales: {
          required: helpers.withMessage('This field cannot be empty', required)
        },
        date: {
          required: helpers.withMessage('Register Date cannot be empty', required)
        },
        consective_no: { 
          required: helpers.withMessage('Consective No cannot be empty', required),
          numeric: helpers.withMessage('Please input number only', numeric),
          maxLength: helpers.withMessage('Please input maximum 3 letters', maxLength(3)),
        },
        contract_code: {
          required: helpers.withMessage('Please generate new partner code', required)
        }
      }
    }
  }
}
</script>
<style lang="">
  
</style>