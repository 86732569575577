<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Receiving Voucher List</span>
    </h4>

    <div class="row">
      <!-- Product List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
          <div class="text-nowrap">
            <div class="row">
							<div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
								<div class="dataTables_length" id="DataTables_Table_0_length">
									<div class="dt-buttons">
										<router-link to="/receiving/add" data-i18n="Create Definition" class="dt-button btn btn-primary">
											<i class="bx bx-plus me-md-2"></i>
											Create New
										</router-link>
									</div>
								</div>
							</div>
              <div class="col-12 col-md-6 mt-3">
                <div class="input-group input-group-merge">
                  <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
                  <input type="text" class="form-control" placeholder="Search receiving code, name" aria-label="Search..." aria-describedby="basic-addon-search31">
                </div>
              </div>
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Receiving No</th>
                  <th>Contract No</th>
                  <th>Issued Date</th>
                  <th>Quantity</th>
                  <th>Total</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr>
                  <td>
                    <strong>PNK221001-0001</strong>
                  </td>
                  <td>CONTR221001-0001</td>
                  <td>31/12/2022</td>
                  <td>1,000</td>
                  <td><span class="">VND 68,888,000</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/receiving/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
								<tr>
                  <td>
                    <strong>PNK221001-0002</strong>
                  </td>
                  <td>CONTR221001-0002</td>
                  <td>31/12/2022</td>
                  <td>2,000</td>
                  <td><span class="">VND 68,666,000</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/receiving/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
								<tr>
                  <td>
                    <strong>PNK221001-0003</strong>
                  </td>
                  <td>CONTR221001-0003</td>
                  <td>31/12/2022</td>
                  <td>3,000</td>
                  <td><span class="">VND 99,999,000</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/receiving/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
								<tr>
                  <td>
                    <strong>PNK221001-0004</strong>
                  </td>
                  <td>CONTR221001-0004</td>
                  <td>31/12/2022</td>
                  <td>7,000</td>
                  <td><span class="">VND 77,777,000</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/receiving/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
								<tr>
                  <td>
                    <strong>PNK221001-0005</strong>
                  </td>
                  <td>CONTR221001-0005</td>
                  <td>31/12/2022</td>
                  <td>5,000</td>
                  <td><span class="">VND 55,555,000</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/receiving/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div class="row mx-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="dataTables_paginate paging_simple_numbers" id="">
                <ul class="pagination">
                    <li class="page-item prev">
                    <a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-left"></i></a>
                    </li>
                    <li class="page-item active">
                    <a class="page-link" href="javascript:void(0);">1</a>
                    </li>
                    <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">2</a>
                    </li>
                    <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">3</a>
                    </li>
                    <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">4</a>
                    </li>
                    <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">5</a>
                    </li>
                    <li class="page-item next">
                    <a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-right"></i></a>
                    </li>
                </ul>
                </div>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <!-- OffCanvas from the right -->
    <div class="row">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScroll" 
        aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility:visible; width:50%;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0">
          <div class="d-flex flex-wrap my-2">
            <div class="col-sm-10">
              <span class="fw-bold text-warning"># PNK221001-0001</span>
            </div>
            <!-- <div class="col-sm-2">
              <span class="fw-bold badge bg-label-success">PAID</span>
            </div> 
            <div class="col-sm-12 mt-2">
              <span class="fw-bold">QUANTITY: </span>
              <span class="fw-bold text-primary">1,000</span>
            </div> -->
            <div class="col-sm-12 mt-2">
              <span class="fw-bold">TOTAL: </span>
              <span class="fw-bold text-primary">VND 666,888,000</span>
            </div>
          </div>
          <h5 class="pb-2 border-bottom mb-2"></h5>
          <div class="info-container">
            <ul class="list-unstyled">
              <li class="mb-3">
                <span class="fw-bold me-2">Supplier:</span>
                <span class="">Supplier Name ABC</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Contract No:</span>
                <span class="">DHVNNZ220901-0001</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Issued Date:</span>
                <span class="">31/12/2022</span>
              </li>
							<li class="mb-3">
								<div class="table-responsive">
									<table class="table ">
										<thead>
											<tr>
												<th class="border-top-0">Item</th>
												<th class="border-top-0 text-end">Quantity</th>
												<th class="border-top-0 text-end">Buy Price</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<div class="text-truncate" style="max-width: 200px;">
														Item name content 1
													</div>
												</td>
												<td><div class="text-end">9,999</div></td>
												<td>
													<div class="text-end">VND 9,999,999</div>
												</td>
											</tr>
											<tr>
												<td>Item name content 2</td>
												<td><div class="text-end">8,000</div></td>
												<td>
													<div class="text-end">VND 88,888</div>
												</td>
											</tr>
											<tr>
												<td>Item name content 3</td>
												<td><div class="text-end">7,000</div></td>
												<td>
													<div class="text-end">VND 77,777</div>
												</td>
											</tr>
											<tr>
												<td>Item name content 4</td>
												<td><div class="text-end">6,000</div></td>
												<td>
													<div class="text-end">VND 66,000</div>
												</td>
											</tr>
											<tr>
												<td>Item name content 5</td>
												<td><div class="text-end">8,000</div></td>
												<td>
													<div class="text-end">VND 88,000</div>
												</td>
											</tr>
											<tr>
												<td>Item name content 6</td>
												<td><div class="text-end">9,000</div></td>
												<td>
													<div class="text-end">VND 99,000</div>
												</td>
											</tr>
											<tr>
												<td>Item name content 7</td>
												<td><div class="text-end">10,000</div></td>
												<td>
													<div class="text-end">VND 100,000</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</li>
            </ul>
            <div class="row mt-4">
              <div class="col-md-6">
                <router-link to="/receiving/edit/1" data-i18n="Contract Registration" class="btn btn-primary w-100">
                  Edit Receiving Voucher
                </router-link>
              </div>
              <div class="col-md-6">
                <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
                  <i class='bx bx-arrow-back' ></i>
                  Back to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>

export default {
  name: 'ReceivingVoucherList',
  components: {
      
  },
  created() {

  },
}
</script>