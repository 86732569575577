import API from "@/config/api.js";
import {API_FORM} from "@/config/api.js";
import CategoryService from "./category.service";
import SubCategoryService from "./subCategory.service"
import ProductRegistrationService from '../services/product_registration.service'
import PartnerService from "../services/partner.service"

class ProductService {
  
  getListData(page) {
    return API.get(`product/?page=${page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  searchProduct(data) {
    return API.get(`product/search/?searchkey=${data.searchKey}&page=${data.page}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  deleteProduct(productId) {
    return API.delete(`product/delete/${productId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  
  getProductInfo(productId) {
		return API.get(`product/${productId}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
	}

  searchProductRegByCodeName(data) {
    return ProductRegistrationService.searchProductCode(data);
  }

  getListCategory(page, size) {
    return CategoryService.getListData(page, size);
  }

  getListSubCategory(categoryId) {
    return SubCategoryService.getListSubCategoryByCategoryId(categoryId);
  }

  getListSupplier(page, size) {
    return PartnerService.getListData(page, size)
  }

  saveProduct(data) {
    return API.post('product/add', data)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  updateProduct(data) {
    return API.put(`product/edit/${data.id}`, data)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }

  uploadImage(formdata) {
		return API_FORM.post('common/upload_image', formdata)
		.then(
			(response) => {
				return response.data;
			},
			(error) => {
				return Promise.reject(error);
			}
		)
	}

  saveProductMass(data) {
    return API.post(`product/add/mass`, data)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
  }
}

export default new ProductService();