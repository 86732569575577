import SubCategoryService from '../services/subCategory.service';


const getDefaultState = () => {
	return {
		currentPage: 1,
		totalItems: 0,
		totalPages: 0,
		listItems: []
	}
}

export const subCategoryList = {
	namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    getListSubCategory({commit}, page=1) {
			return SubCategoryService.getListSubCategory(page).then(
				(response) => {
					commit('getSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getFailure');
					return Promise.reject(error);
				}
			);
		},
		searchSubCategory({commit}, {"page": page, "searchKey": searchKey}) {
			return SubCategoryService.searchSubCategory({"page": page, "searchKey": searchKey}).then(
				(response) => {
					commit('getSearchSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getSearchFailure');
					return Promise.reject(error);
				}
			);
		},
		deleteSubCategory ({commit}, id="") {
			return SubCategoryService.deleteSubCategory(id).then(
				(response) => {
					// commit('deleteSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('deleteFailure');
					return Promise.reject(error);
				}
			);
		},
		getSubCategoryInfo ({commit}, id="") {
			return SubCategoryService.getSubCategoryInfo(id).then(
				(response) => {
					// commit('deleteSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getCategoryFailure');
					return Promise.reject(error);
				}
			);
		}
  },
  mutations: {
		getSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getFailure(state) {
      console.log("run getFailure");
      Object.assign(state.stateData, getDefaultState())
    },
		getSearchSuccess(state, response) {
      state.stateData.currentPage = response.currentPage;
      state.stateData.totalItems = response.totalItems;
      state.stateData.totalPages = response.totalPages;
      state.stateData.listItems = response.listItems;
    },
    getSearchFailure() {
      console.log("run getSearchFailure");
    },
		deleteSuccess () {
      console.log("run deleteSuccess");
    },
    deleteFailure() {
      console.log("run deleteFailure");
    }
  },
  getters: {
  }
}