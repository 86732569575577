<template>
  <!-- Navbar -->
  <nav
    class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
    id="layout-navbar"
    style="z-index: 1;"
  >
    <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
      <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
        <i class="bx bx-menu bx-sm"></i>
      </a>
    </div>

    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
      <ul class="navbar-nav flex-row align-items-center ms-auto">
        <!-- User -->
        <li class="nav-item navbar-dropdown dropdown-user dropdown">
          <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
            <div class="avatar avatar-online" v-if="signedInAccount.user && signedInAccount.user.user_info.avatar_url">
              <img class="w-px-40 h-auto rounded-circle" 
                :src="domain + signedInAccount.user.user_info.avatar_url"
              >
            </div>
            <div class="avatar" v-else>
              <span class="avatar-initial rounded-circle bg-label-secondary">
                <i class="tf-icons fs-4 bx bx-user"></i>
              </span>
            </div>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <!-- <a class="dropdown-item" href="#"> -->
              <div class="d-flex dropdown-item">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar avatar-online" v-if="signedInAccount.user && signedInAccount.user.user_info.avatar_url">
                    <img class="w-px-40 h-auto rounded-circle" 
                      :src="domain + signedInAccount.user.user_info.avatar_url"
                    >
                  </div>
                  <div class="avatar" v-else>
                    <span class="avatar-initial rounded-circle bg-label-secondary" >
                      <i class="tf-icons fs-4 bx bx-user"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1" v-if="signedInAccount.user">
                  <span class="fw-semibold d-block">{{ signedInAccount.user.user_info.username }}</span>
                  <small class="text-muted">{{ signedInAccount.user.user_info.role }}</small>
                </div>
              </div>
              <!-- </a> -->
            </li>
            <li v-if="signedInAccount.status.loggedIn">
              <div class="dropdown-divider"></div>
            </li>
            <li v-if="signedInAccount.status.loggedIn">
              <a class="dropdown-item" href @click.prevent="myProfile">
                <i class="bx bx-user me-2"></i>
                <span class="align-middle">My Profile</span>
              </a>
            </li>
            <li v-if="signedInAccount.status.loggedIn">
              <a class="dropdown-item" href @click.prevent="changePassword">
                <i class="bx bx-cog me-2"></i>
                <span class="align-middle">Change Password</span>
              </a>
            </li>
            <li>
              <div class="dropdown-divider"></div>
            </li>
            <li>
              <a class="dropdown-item" href @click.prevent="logOut" v-if="signedInAccount.status.loggedIn">
                <i class="bx bx-power-off me-2"></i>
                <span class="align-middle">Log Out</span>
              </a>
              <a class="dropdown-item" href @click.prevent="logIn" v-else>
                <i class="bx bx-power-off me-2"></i>
                <span class="align-middle">Log In</span>
              </a>
            </li>
          </ul>
        </li>
        <!--/ User -->
      </ul>
    </div>
  </nav>
  <!-- / Navbar -->
</template>

<script>
import { mapState } from "vuex";
import EventBus from "../../services/EventBus";

export default {
  name: 'Header',
  data() {
    return {
			domain: process.env.VUE_APP_DOMAIN_FILE_SERVER
    }
  },
  computed: {
    ...mapState({
      signedInAccount: state => state.auth,
    })
  },
  created() {
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    logIn() {
      this.$router.push('/login');
    },
    myProfile() {
      this.$router.push('/account/profile');
    },
    changePassword() {
      this.$router.push('/account/change-password');
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeUnmount() {
    EventBus.remove("logout");
  }
}
</script>