import ContractService from '../services/contract.service';
import moment from 'moment'
import _ from 'lodash';

let today = new Date();
const getDefaultState = () => {
  return {
    contract_code_id: "",
    contract_code: "",
    reference_no: "",
    contract_date: moment(today).format("YYYY-MM-DD"),
    last_delivery_date: moment(today).add(30, 'd').format("YYYY-MM-DD"),
    supplier_id: "",
    supplier_code: "",
    customer_id: "",
    customer_code: "",
    buy_currency: "",
    buy_exchange_rates: "",
    sell_currency: "",
    sell_exchange_rates: "",
    delivery_place: "",
    grand_total_buy_price: "",
    grand_total_buy_text: "",
    grand_total_sell_price: "",
    grand_total_sell_text: "",
    list_suppliers: [],
    list_customers: [],
    list_conditions:[
      {
        id: 1,
        condition: ""
      }
    ],
    list_products: [],
    country_buy: "",
    country_sell: ""
  }
}

export const contractAdd = {
  namespaced: true,
  state: {
    stateData: getDefaultState()
  },
  actions: {
    resetState({commit}) {
      commit("resetState");
    },
    searchContractRegByCode({commit}, {"page": page, "searchKey": searchKey}) {
      return ContractService.searchContractRegByCode({"page": page, "searchKey": searchKey})
      .then(
        (response) => {
          commit('searchContractRegSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('searchContractRegFailure');
          return Promise.reject(error);
        }
      );
    },
    getListSupplier({commit}, page=1, size=-1, type=1) {
			return ContractService.getListSupplier(page, size, type).then(
				(response) => {
					commit('getListSupplierSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getListSupplierFailure');
					return Promise.reject(error);
				}
			);
		},
    getListCustomer({commit}, page=1, size=-1, type=2) {
			return ContractService.getListSupplier(page, size, type).then(
				(response) => {
					commit('getListCustomerSuccess', response);
					return Promise.resolve(response);
				},
				(error) => {
					commit('getListCustomerFailure');
					return Promise.reject(error);
				}
			);
		},
    getPartnerInfo ({commit}, id="") {
      return ContractService.getPartnerInfo(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit('getPartnerFailure');
          return Promise.reject(error);
        }
      );
    },
    searchProduct({commit}, {"page": page, "searchKey": searchKey}) {
      return ContractService.searchProduct({"page": page, "searchKey": searchKey})
      .then(
        (response) => {
          // commit('searchProductSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('searchProductFailure');
          return Promise.reject(error);
        }
      );
    },
    saveContract({commit}, data) {
      return ContractService.saveContract(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("saveFailure");
          return Promise.reject(error);
        }
      );
    },
    getContractInfo({commit}, id="") {
      return ContractService.getContractInfo(id).then(
        (response) => {
          commit('getContractSuccess', response);
          // return Promise.resolve(response);
        },
        (error) => {
          commit('getContractFailure');
          return Promise.reject(error);
        }
      );
    },
    updateContract({commit}, data) {
      return ContractService.updateContract(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    resetState (state) {
      console.log("run resetState");
      Object.assign(state.stateData, getDefaultState())
    },
    searchContractRegSuccess(state, response) {
			if(response.listItems.length > 0) {
				if (!response.listItems[0].supplier_id){
					state.stateData.contract_code_id = response.listItems[0].id;
          state.stateData.contract_code = response.listItems[0].contract_code;
          state.stateData.country_buy = response.listItems[0].vcj_origin;
          state.stateData.country_sell = response.listItems[0].vcj_sales;
				}
			}
		},
		searchContractRegFailure(state) {
			console.log("run searchContractRegFailure");
			state.stateData.contract_code_id = "";
			state.stateData.contract_code = "";
		},
    getListSupplierSuccess(state, response) {
			state.stateData.list_suppliers = response.listItems
		},
		getListSupplierFailure(state) {
			state.stateData.list_suppliers = [];
		},
    getListCustomerSuccess(state, response) {
			state.stateData.list_customers = response.listItems
		},
		getListCustomerFailure(state) {
			state.stateData.list_customers = [];
		},
    getContractSuccess(state, response) {
			// state.stateData = {...state.stateData, ...response};

      let res = JSON.parse(JSON.stringify(response));
      state.stateData = _.merge(state.stateData, response)
      let listProducts = res.contract_detail_list
      state.stateData.list_products = listProducts;
		},
		getContractFailure(state) {
			Object.assign(state.stateData, getDefaultState())
		}
  },
  getters: {

  }
}