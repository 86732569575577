<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Quotation List</span>
    </h4>

    <div class="row">
      <!-- Product List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
          <div class="text-nowrap">
            <div class="row">
              <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                <div class="dataTables_length" id="">
                  <div class="dt-buttons">
                    <router-link to="/quotation/add" data-i18n="Create Definition" class="dt-button btn btn-primary">
                      <i class="bx bx-plus me-md-2"></i>
                      Create New
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <div class="input-group input-group-merge">
                  <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search"></i></span>
                  <input type="text" class="form-control" placeholder="Search quotation number" aria-label="Search..." aria-describedby="basic-addon-search31">
                </div>
              </div>
              <!-- <div class="col-12 col-md-6 d-flex align-items-center justify-content-end flex-column flex-md-row pe-3 gap-md-2">
                <div class="invoice_status col-md-6 mb-3 mb-md-0">
                  <select id="UserRole" class="form-select">
                    <option value=""> Select Status </option>
                    <option value="quotation" class="text-capitalize">New</option>
                    <option value="partner" class="text-capitalize">Expired</option>
                    <option value="contract" class="text-capitalize">Ordered</option>
                  </select>
                </div>
              </div> -->
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Quotation No</th>
                  <th>Company</th>
                  <th>Issued Date</th>
                  <th>Valid Until</th>
                  <th>Total</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr>
                  <td>
                    <strong>E-ADC-220529-0001</strong>
                  </td>
                  <td>Company Name ABC</td>
                  <td>1/11/2022</td>
                  <td>31/11/2022</td>
                  <td><span class="">$6,666</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/quotation/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>E-ADC-220529-0002</strong>
                  </td>
                  <td>Company Name XYZ</td>
                  <td>1/12/2022</td>
                  <td>31/12/2022</td>
                  <td><span class="">$8,888</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/quotation/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>E-ADC-220529-0003</strong>
                  </td>
                  <td>Company Name QWERTY</td>
                  <td>8/6/2022</td>
                  <td>30/6/2022</td>
                  <td><span class="">$99,999</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/quotation/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>E-ADC-220529-0004</strong>
                  </td>
                  <td>Company Name BMW</td>
                  <td>18/6/2022</td>
                  <td>30/7/2022</td>
                  <td><span class="">$7,777</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/quotation/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>E-ADC-220529-0005</strong>
                  </td>
                  <td>Company Name POIASD</td>
                  <td>1/9/2022</td>
                  <td>30/9/2022</td>
                  <td><span class="">$86,868</span></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="#" class="text-body me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScroll" aria-controls="offcanvasScroll">
                        <i class="bx bx-show mx-1"></i>
                      </a>
                      <router-link to="/quotation/edit/1" data-i18n="Create Definition" class="text-body me-3">
                        <i class='bx bx-edit-alt'></i>
                      </router-link>
                      <a href="#" data-bs-toggle="tooltip" class="text-body" data-bs-placement="top">
                        <i class='bx bx-x-circle'></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div class="row mx-2">
							<div class="col-sm-12 col-md-6">
								<div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
								</div>
							</div>
            <div class="col-sm-12 col-md-6">
							<div class="dataTables_paginate paging_simple_numbers" id="">
							<ul class="pagination">
								<li class="page-item prev">
								<a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-left"></i></a>
								</li>
								<li class="page-item active">
								<a class="page-link" href="javascript:void(0);">1</a>
								</li>
								<li class="page-item">
								<a class="page-link" href="javascript:void(0);">2</a>
								</li>
								<li class="page-item">
								<a class="page-link" href="javascript:void(0);">3</a>
								</li>
								<li class="page-item">
								<a class="page-link" href="javascript:void(0);">4</a>
								</li>
								<li class="page-item">
								<a class="page-link" href="javascript:void(0);">5</a>
								</li>
								<li class="page-item next">
								<a class="page-link" href="javascript:void(0);"><i class="tf-icon bx bx-chevrons-right"></i></a>
								</li>
							</ul>
							</div>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <!-- OffCanvas from the right -->
    <div class="row">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScroll" 
        aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility:visible;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0 overflow-hidden">
          <div class="d-flex flex-wrap my-2">
            <div class="col-sm-12">
              <span class="fw-bold text-warning"># E-ADC-220529-0001</span>
            </div>
            <div class="col-sm-12 mt-2">
              <span class="fw-bold">TOTAL: </span>
              <span class="fw-bold text-primary">$66,888</span>
            </div>
          </div>
          <h5 class="pb-2 border-bottom mb-2"></h5>
          <div class="info-container">
            <ul class="list-unstyled">
              <li class="mb-3">
                <span class="fw-bold me-2">Company:</span>
                <span class="">Company Name ABC</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Issued Date:</span>
                <span class="">22/10/2022</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Valid Until:</span>
                <span class="">31/12/2022</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Terms and Conditions:</span>
                <div>
                  <span class="">Terms and Conditions content 1</span>
                </div>
                <div>
                  <span class="">Terms and Conditions content 2</span>
                </div>
                <div>
                  <span class="">Terms and Conditions content 3</span>
                </div>
                <div>
                  <span class="">Terms and Conditions content 4</span>
                </div>
                <div>
                  <span class="">Terms and Conditions content 5</span>
                </div>
                <div>
                  <span class="">Terms and Conditions content 6</span>
                </div>
                <div>
                  <span class="">Terms and Conditions content 7</span>
                </div>
                <div>
                  <span class="">Terms and Conditions content 8</span>
                </div>
                <div>
                  <span class="">Terms and Conditions content 9</span>
                </div>
                <div>
                  <span class="">Terms and Conditions content 10</span>
                </div>
              </li>
            </ul>
            <div class="row">
							<router-link to="/quotation/edit/1" data-i18n="Contract Registration" class="btn btn-primary mb-2 mt-3">
								Edit Invoice
							</router-link>
							<button type="button" class="btn btn-outline-secondary  mb-3" data-bs-dismiss="offcanvas">
								<i class='bx bx-arrow-back' ></i>
								Back to List
							</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>

export default {
  name: 'InvoiceList',
  components: {
      
  },
  created() {

  },
}
</script>