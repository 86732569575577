<template>
  <!-- Content -->
  <div class="row col-md-12 m-0 mt-2 p-2">
    <div class="col-md-6 col-6 mb-3 px-1 pe-2">
      <p class="mb-2">Product Name</p>
      <span class="form-control disabled">
        {{ productInfo.product_name }}
      </span>
    </div>
    <div class="col-md-3 col-3 mb-3">
      <p class="mb-2 repeater-title">Product Code</p>
      <input type="text" 
        class="form-control invoice-item-price" 
        v-model="productInfo.product_code"
        disabled
      >
    </div>
    <div class="col-md-3 col-3"></div>
    <div class="col-md-3 col-3 px-1">
      <p class="mb-2 repeater-title">Quantity In</p>
      <span class="form-control invoice-item-price mb-3 disabled" 
        style="text-align:right;"
      >
        {{ getFormatCurrency(productInfo.quantity_in) }}
      </span>
    </div>
    <div class="col-md-3 col-3 px-2">
      <p class="mb-2 repeater-title">Buy Price</p>
      <div class="input-group mb-3">
        <span class="input-group-text px-2 disabled">{{ stateData.buy_currency }}</span>
        <span class="form-control px-1 disabled" 
          style="text-align:right;">
          {{ getFormatCurrency(productInfo.buy_price) }}
        </span>
      </div>
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Buy Price in VND</p>
      <div class="input-group">
        <span class="input-group-text px-2 disabled">VND</span>
        <span class="form-control px-1 disabled" 
          style="text-align:right;">
          {{ getFormatCurrency(productInfo.buy_price_vnd) }}
        </span>
      </div>
    </div>
    <div class="col-md-3 col-3">
      <p class="mb-2 repeater-title">Product Unit</p>
      <span type="text" class="form-control" style="background-color: #eceef1;">{{ getProductUnitName(productInfo.product_unit) }}&nbsp;</span>
    </div>

    <div class="row col-md-9 col-9 px-2 pe-0">
      <p class="mb-2 repeater-title">Buy TAX</p>
      <div class="col-md-4 col-4" style="margin-left: -0.3rem;">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_1.name"
            disabled
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-2 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_1.value"
            :options="cleaveOptions.tax"
            disabled
          />
        </div>
      </div>
      <div class="col-md-4 col-4">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_2.name"
            disabled
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-2 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_2.value"
            :options="cleaveOptions.tax"
            disabled
          />
        </div>
      </div>
      <div class="col-md-4 col-4">
        <div class="input-group mb-3">
          <select
            class="form-select px-1" 
            name="select-tax"
            v-model="productInfo.tax_buy_3.name"
            disabled
          >
            <option value="">EMPTY</option>
            <option 
              v-for="(ele, index) in taxOptions" 
              :key="index" 
              :value="ele.symbol"
            >
              {{ ele.symbol + " %"}}
            </option>
          </select>
          <cleave class="form-control px-2 me-0" 
            style="max-width: 3rem; text-align: right;"
            placeholder="0.0"
            v-model="productInfo.tax_buy_3.value"
            :options="cleaveOptions.tax"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import { TAX_CONFIG, PRODUCT_UNIT } from "../../config/common.js"

export default {
  name: 'ReceivingProductDetail',
  components: {
    // VueMultiselect
  },
  props: {
    "product": {
			type: Object,
			required: true
		},
    "index": {
      type: Number,
			required: true
    }
  },
  data() {
    return {
      productInfo: this.product,
      key: this.index,
      page: 1,
      productSelected: null,
      cleaveOptions: {
        buy_price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        sell_price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        purchase_total_amount: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        },
        sell_total_amount: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2
        },
        quantity_in: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        quantity_out: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        tax: {
          numeral: true,
          numeralDecimalScale: 1
        }
      }
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      stateData: state => state.shipmentAdd.stateData
    }),
    taxOptions() {
      return TAX_CONFIG;
    },
    productUnits() {
      return PRODUCT_UNIT;
    }
  },
  beforeCreate() {
    this.productInfo = [];
  },
  created() {
    // Handling Product Name selected
    for (let i=1; i<=3; i++) {
      if(this.productInfo["tax_buy_name_"+i]) {
        this.productInfo["tax_buy_"+i] = {
          id: i,
          name: this.productInfo["tax_buy_name_"+i],
          value: this.productInfo["tax_buy_value_"+i],
          is_display: true, 
        }
      }
      else {
        this.productInfo["tax_buy_"+i] = {
          id: i,
          name: "",
          value: "",
          is_display: true, 
        }
      }
    }
  }, 
  mounted() {
    this.productInfo.buy_price_vnd = this.productInfo.buy_price * this.stateData.buy_exchange_rates;
    this.productInfo.sell_price_vnd = this.productInfo.sell_price * this.stateData.sell_exchange_rates;
  },
  methods: {
    getProductUnitName(unit) {
      if(unit == undefined) return "";
      let punit = this.productUnits.filter(item => {
        return item.value == unit;
      });
      if(punit.length > 0) {
        return punit[0].name;
      }
      else {
        return "";
      }
    },
    getFormatCurrency(value) {
      return Intl.NumberFormat().format(value);
    }
  },
}
</script>

<style>
  .delete-item {
    padding: 0.2rem 0.37rem !important;
  }
</style>