<template>
  <!-- Layout wrapper -->
  <div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
      <!-- Menu -->
      <Sidebar v-if="!['Login', 'Register', 'ForgotPassword'].includes($route.name)"/>
      <!-- / Menu -->

      <!-- Layout container -->
      <div class="layout-page">
        <!-- Navbar -->
        <Header v-if="!['Login', 'Register', 'ForgotPassword'].includes($route.name)" />
        <!-- / Navbar -->
        <!-- Content wrapper -->
        <div class="content-wrapper">
          <!-- Content -->
          <router-view />
          <!-- / Content -->

          <!-- Footer -->
          <Footer v-if="!['Login', 'Register', 'ForgotPassword'].includes($route.name)" />
          <!-- / Footer -->
          <div class="content-backdrop fade"></div>
        </div>
        <!-- Content wrapper -->
      </div>
      <!-- / Layout page -->
    </div>

    <!-- Overlay -->
    <div class="layout-overlay layout-menu-toggle"></div>
  </div>
  <!-- / Layout wrapper -->
</template>

<script>
import Header from './Header.vue'
import Sidebar from './Sidebar.vue'
import Footer from './Footer.vue'

export default {
  name: 'MainLayout',
  components: {
    Header,
    Sidebar,
    Footer
  }
}
</script>
<style lang="">
  
</style>