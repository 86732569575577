<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Product List</span>
    </h4>

    <div class="row">
      <!-- Product List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="text-nowrap1">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="mb-4">
                    <div class="dt-buttons">
                      <button class="dt-button btn btn-primary me-3" onclick="document.getElementById('file_upload').click()">
                        Upload Product CSV data
                      </button>
                      <input 
                        class="form-control d-none" 
                        type="file" 
                        accept=".csv"
                        id="file_upload"
                        name="file-upload"
                        @change="handleFileUpload($event)"
                      >
                      <button class="dt-button btn btn-primary" @click="downloadFile()">
                        Download Sample CSV
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 text-end" v-if="csv_content">
                  <form @submit.prevent="handleSave">
                    <div>
                      <button type="submit" class="btn btn-success me-3">Save Products</button>
                    </div>
                  </form>
                </div>
              </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <template v-if="csv_content">
                    <th class="p-1 py-2 text-start" 
                      v-for="(header, index_header) in CSV_HEADER" :key="index_header"
                      style="max-width: 150px; font-size: 0.8rem !important"
                    >
                      {{ showHeader(header.name)}}
                    </th>
                    <!-- <th class="p-1" style="max-width: 100px;font-size: 0.7rem;">Product Code</th> -->
                    <th class="p-1 py-2" style="max-width: 100px;font-size: 0.8rem;">Action</th>
                  </template>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <template v-if="csv_content">
                  <tr v-for="(line, index_row) in csv_content.data" :key="index_row">
                    <td v-for="(column, index_col) in CSV_HEADER" :key="index_col"
                      style="max-width: 150px; font-size: 0.9rem !important"
                      class="p-1 py-2"
                    >
                      {{ line[column.code] }}
                      <div class="error-msg" v-if="errors.length > 0 && line[column.code] == errors[index_row][column.code]"> 
                        Invalid Data
                      </div>
                    </td>
                    <td class="p-1" style="max-width: 100px;">
                      <div class="text-center">
                        <a class="text-body icon-delete" @click="deleteProduct(index_row)">
                          <i class='bx bx-x-circle'></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <br/>
            <div class="row mx-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
              </div>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import {errorAlert} from "../../helper/error.js"
import showConfirmDeleteAlert from "../../helper/error.js"
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import { PRODUCT_CSV_HEADER } from "../../config/common.js"
import _ from 'lodash';
import { saveAs } from 'file-saver';
import axios from 'axios';


export default {
  name: 'ProductMassAdd',
  components: {
    RiseLoader
  },
  data(){
    return {
      color: '#696cff',
      size: '20px',
      isLoading: false,
      domain: process.env.VUE_APP_DOMAIN_FILE_SERVER,
      csv_file: '',
      csv_content: null,
      errors: []
    }
  },
  computed: {
    CSV_HEADER () {
      return PRODUCT_CSV_HEADER;
    }
  },
  beforeCreate() {

  },
  created() {

  },
  methods: {
    handleFileUpload(event){
      this.csv_file = event.target.files[0];
      this.parseFile(event);
    },
    parseFile (event){
      this.$papa.parse(this.csv_file, {
        header: true,
        skipEmptyLines: true,
        complete: function(results) {
          this.errors.length = 0;
          this.csv_content = {};
          this.generateCode(results).then(
            (response) => {
              response.data.forEach(itemObject => {
                let error_obj = {} 
                Object.keys(itemObject).forEach(item => {
                  let result = this.checkValidateData(item, itemObject[item], response.data);
                  if (!result) {
                    error_obj[item] = itemObject[item];
                  }
                });
                this.errors.push(error_obj);
              });
              this.csv_content = response;
            }
          );
          event.target.value = "";
        }.bind(this)
      });
    },
    checkHasErrors() {
      if (this.errors.length > 0) {
        let check = this.errors.filter(item => {
          return Object.keys(item).length > 0;
        });
        if (check.length == 0) {
          this.errors.length = 0;
        }
      }
    },
    showHeader (text){
      if (!text) return "";
      
      return text.split("_").join(" ");
    },
    checkValidateData(field_name, value, array) {
      let result = true;
      if (field_name == this.CSV_HEADER[0].code){
        result = this.checkNumber(value);
      }
      else if (field_name == this.CSV_HEADER[1].code){
        result = this.checkQualityControl(value);
      }
      else if (field_name == this.CSV_HEADER[4].code){
        result = this.checkDateFormat(value);
      }
      else if (field_name == this.CSV_HEADER[5].code) {
        result = this.checkConsectiveNo(value);
      }
      else if (field_name == this.CSV_HEADER[7].code) {
        result = this.checkProductUnit(value);
      }
      else if (field_name == this.CSV_HEADER[8].code) {
        result = this.checkSupplierFormat(value);
      }
      else if (field_name == this.CSV_HEADER[10].code) {
        result = this.checkDuplicatedProductCode(field_name, value, array);
      }
      if (this.checkIsEmpty(value)) {
        return false;
      }

      return result;
    },
    checkIsEmpty(value) {
      return !value;
    },
    checkNumber(value) {
      return !isNaN(value) && _.isInteger(parseInt(value));
    },
    checkQualityControl(value) {
      return value == "QC" || value == "SB"; 
    },
    checkDateFormat(value) {
      let pattern =/^([0-9]{4})\/([0-9]{2})\/([0-9]{2})$/;
      return pattern.test(value);
    },
    checkConsectiveNo(value) {
      let pattern =/^([0-9]{2})$/;
      return pattern.test(value);
    },
    checkProductUnit(value) {
      return value == "pcs" || value == "PCS"; 
    },
    checkSupplierFormat(value) {
      // PM230210-01
      let pattern =/^([A-Z]{2})([0-9]{6})-([0-9]{2})$/;
      return pattern.test(value);
    },
    checkDuplicatedProductCode(field_name, value, array) {
      let result = array.filter(item => {
        return item[field_name] == value;
      });
      return result.length == 1;
    },
    async generateCode(obj) {
      if (!_.isEmpty(obj)){
        obj.data.forEach(item => {
          let selected_date = this.$moment(item.register_date, 'YYYY/MM/DD').format("YYMMDD");
          let qc_status = item.quality_control_status;
          let category = item.category;
          let sub_cate = item.sub_category;
          let cons_no = item.consective_no;
          item["product_code"] = qc_status + "-" + category + sub_cate + selected_date + "-" + cons_no;
        });
        return obj;
      }
    },
    deleteProduct(index) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;
          this.csv_content.data.splice(index, 1);
          this.errors.splice(index,1);
          this.checkHasErrors();
        }
      )      
    },
    handleSave() {
      this.checkHasErrors();
      if(this.errors.length > 0) {
        errorAlert("error", "Invalid Data Error",'Please check the data format before saving !');
        return;
      }
      if(this.csv_content.data.length == 0) {
        errorAlert("error", "Invalid Data Error",'Register data can not be empty!');
        return;
      }
      this.$store.dispatch('productAdd/saveProductMass', this.csv_content.data).then(
        (response) => {
          if (response.has_error) {
            errorAlert("error", "Save Data Error", response.message[0]);
          }
          else {
            let self = this;
            this.$swal.fire(
              'Saved',
              response.message,
              'success'
            ).then(function() {
              self.$router.push('/product');
            });
          }
        },
        error => {
          console.log(error);
          errorAlert("error", "Network Error",'Can not save Product data!');
        }
      );
    },
    downloadFile() {
      let file_name = "/upload/sample/sample_products_mass.csv"
      axios
        .get(this.domain+file_name, {responseType: 'blob'})
        .then(response => {
            saveAs(response.data, "sample_products_mass.csv");
        })
    },
  } 
}
</script>

<style scoped>

</style>