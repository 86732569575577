<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Account List</span>
    </h4>
		<div class="row g-4 mb-4">
			<div class="col-sm-6 col-xl-3">
				<div class="card">
					<div class="card-body">
						<div class="d-flex align-items-start justify-content-between">
							<div class="content-left">
								<span>Registered User</span>
								<div class="d-flex align-items-end mt-2">
									<h4 class="mb-0 me-2">{{ registered_users ? registered_users : "..." }}</h4>
								</div>
							</div>
							<span class="badge bg-label-primary rounded p-2">
								<i class="bx bx-group bx-sm"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-6 col-xl-3">
				<div class="card">
					<div class="card-body">
						<div class="d-flex align-items-start justify-content-between">
							<div class="content-left">
								<span>Inactive Users</span>
								<div class="d-flex align-items-end mt-2">
									<h4 class="mb-0 me-2">{{ inactive_users ? inactive_users : "..." }}</h4>
								</div>
							</div>
							<span class="badge bg-label-danger rounded p-2">
								<i class="bx bx-user-minus bx-sm"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
      <div class="col-sm-4 col-xl-4">
				<div class="card">
					<div class="card-body" style="padding-top: 0.8rem;padding-bottom: 0.8rem;">
            <label class="mb-2">Search</label>
						<div class="input-group input-group-merge mt-2">
              <span class="input-group-text"><i class="bx bx-search"></i></span>
              <input type="text" 
                class="form-control" 
                placeholder="Search account information" 
                v-model="keyword"
                @change="onChangeSearch(page, keyword)"
              >
            </div>
					</div>
				</div>
			</div>
      <div class="col-sm-2 col-xl-2">
        <div class="card">
					<div class="card-body text-center" style="">
            <span class="badge bg-label-primary rounded-circle p-2 mb-1 btn-add-user" 
              data-bs-toggle="modal" 
              data-bs-target="#backDropModal"
              title="Add New User"
            >
              <i class="bx bx-plus bx-md"></i>
            </span>
          </div>
        </div>
      </div>
		</div>
    <div class="row">
      <!-- Account List -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
          <div class="text-nowrap">
            <table class="table table-hover">
              <thead>
                <tr>
                <th>Username</th>
                <th>Role</th>
                <th>Status</th>
                <th>Actions</th>
                </tr>
              </thead>
              <tbody class="">
								<tr v-for="(item, index) in listItems" :key="index">
									<td class="">
                    <div class="d-flex flex-column">
                      <a href="#" 
                        class="text-body" 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasScroll"
                        v-if="!item.deleted_date"
                        @click="handleShowDetail(item.id)"
                      >
                        <span class="fw-semibold">{{ item.username }}</span>
                      </a>
                      <span class="fw-semibold" v-else>{{ item.username }}</span>
                      <small class="text-muted">{{ item.email }}</small>
                    </div>
									</td>
									<td>
										<span class="text-truncate d-flex align-items-center">
											{{ item.role }}
										</span>
									</td>
									<td>
										<span class="badge bg-label-success" v-if="!item.deleted_date">ACTIVE</span>
                    <span class="badge bg-label-secondary" v-else>DEACTIVATED</span>
									</td>
									<td>
                    <div class="d-flex align-items-center">
                      <div v-if="!item.deleted_date">
                        <a href="#" 
                          title="See Account Detail"
                          class="text-body me-3" 
                          data-bs-toggle="offcanvas" 
                          data-bs-target="#offcanvasScroll"
                          @click="handleShowDetail(item.id)"
                        >
                          <i class="bx bx-show mx-1"></i>
                        </a>
                        <router-link :to='"/account/edit/" + item.id' 
                          data-i18n="Create Definition" 
                          class="text-body me-3"
                          title="Edit Account"
                        >
                          <i class='bx bx-edit-alt'></i>
                        </router-link>
                        <a class="text-body icon-delete me-3" 
                          title="Delete Account"
                          @click="handleDeleteAccount(item.id)"
                        >
                          <i class='bx bx-x-circle'></i>
                        </a>
                        <a class="text-body icon-reset" 
                          title="Reset Password"
                          @click="handleResetPassword(item.id)"
                        >
                          <i class='bx bx-lock-open'></i>
                        </a>
                      </div>
                      <div v-else>
                        <a class="text-body icon-restore" 
                          title="Restore Account"
                          @click="handleRestoreAccount(item.id)"
                        >
                          <i class='bx bx-reset fs-4'></i>
                        </a>
                      </div>
                    </div>
                  </td>
								</tr>
              </tbody>
            </table>
            <br/>
            <div class="row mx-2">
							<div class="col-sm-12 col-md-6">
								<div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
								</div>
							</div>
							<div class="col-sm-12 col-md-6">
								<common-paging 
									:items="listItems" 
									:totalItems="totalItems" 
									@changePage="onChangePage"
									@changeSearchPage="onChangeSearch"
									:activePageId="page"
									v-show="showPaging"
									:isSearch="isSearchAccount"
								/>
							</div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

		<!-- OffCanvas from the right -->
    <!-- Modal Display Account Details -->
    <div class="row">
      <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScroll" aria-labelledby="offcanvasScrollLabel" aria-modal="true" role="dialog" style="visibility: visible;">
        <div class="offcanvas-body my-auto mx-0 flex-grow-0">
          <div class="d-flex justify-content-around flex-wrap my-1 py-1">
            <div v-if="detailInfo.avatar_url">
              <img class="d-block rounded" height="100" width="100" :src='detailInfo.avatar_url ? domain + detailInfo.avatar_url : ""'>
            </div>
            <div v-else>
              <div class="avatar avatar-xl me-2">
                <span class="avatar-initial rounded bg-label-primary">{{ getAvatarText(detailInfo.username) }}</span>
              </div>
            </div>
          </div>
          <h5 class="pb-2 border-bottom mb-4"></h5>
          <div class="info-container">
            <ul class="list-unstyled">
              <li class="mb-3">
                <span class="fw-bold me-2">Username:</span>
                <span class="">{{ detailInfo.username }}</span>
              </li>
							<li class="mb-3">
                <span class="fw-bold me-2">Full Name:</span>
                <span class="">{{ detailInfo.last_name +" "+ detailInfo.first_name }}</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Email:</span>
                <span class="">{{ detailInfo.email }}</span>
              </li>
							<li class="mb-3">
                <span class="fw-bold me-2">Phone:</span>
                <span class="">{{ detailInfo.phone ? "+84 " + detailInfo.phone : "" }}</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Role:</span>
                <span class="">{{ detailInfo.role }}</span>
              </li>
              <li class="mb-3">
                <span class="fw-bold me-2">Organization:</span>
                <span class="">{{ detailInfo.company }}</span>
              </li>
              <li class="mb-1">
                <span class="fw-bold me-2">Address:</span>
                <span class="">{{ detailInfo.address }}</span>
              </li>
            </ul>
            <router-link :to='"/account/edit/"+ detailInfo.id' data-i18n="Edit Category" class="btn btn-primary w-45 mb-2 w-100 mt-3">
              Edit User
            </router-link>
            <button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="offcanvas">
              <i class='bx bx-arrow-back' ></i>
              Back to List
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Show Loading-->
    <div class="modal modal-transparent show fade" v-if="isLoading" id="basicModal" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <rise-loader :color="color" :size="size" style="text-align:center;"></rise-loader>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Create New Account-->
    <div class="modal fade" id="backDropModal" data-bs-backdrop="static" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <form class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="backDropModalTitle">Add New Account</h5>
            <button type="button" 
              class="btn-close" 
              data-bs-dismiss="modal"
              @click="closeModalNewUser"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col col-sm-12 mb-2">
                <label for="nameBackdrop" class="form-label">USERNAME</label>
                <input type="text" class="form-control" placeholder="Enter username" v-model="new_user.username">
                <div class="error-msg" v-if="v$.new_user.username.$error"> 
                  {{ v$.new_user.username.$errors[0].$message }}
                </div>
              </div>
              <div class="col col-sm-12 mb-2">
                <label for="emailBackdrop" class="form-label">EMAIL</label>
                <input type="text" class="form-control" placeholder="user_mail@gmail.com" v-model="new_user.email">
                <div class="error-msg" v-if="v$.new_user.email.$error"> 
                  {{ v$.new_user.email.$errors[0].$message }}
                </div>
              </div>
              <div class="col col-sm-12 mb-2 mt-1">
                <div class="d-flex justify-content-between">
                  <label class="form-label">Password</label>
                  <a href="#" @click="generatePassword(10)">
                    <small>Generate Password</small>
                  </a>
                </div>
                <input type="passord" class="form-control" placeholder="············" v-model="new_user.password">
                <div class="error-msg" v-if="v$.new_user.password.$error"> 
                  {{ v$.new_user.password.$errors[0].$message }}
                </div>
              </div>
              <div class="col col-sm-12 mb-2">
                <label for="emailBackdrop" class="form-label">ROLE</label>
                <select class="form-select" v-model="new_user.role">
                  <option value="administrator">Administrator</option>
                  <option value="manager">Manager</option>
                  <option value="accounting">Accountant</option>
                  <option value="user">User</option>
                </select>
                <div class="error-msg" v-if="v$.new_user.role.$error"> 
                  {{ v$.new_user.role.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <form id="" @submit.prevent="handleAddNewUser">
              <button type="button" 
                class="btn btn-label-secondary me-3" 
                data-bs-dismiss="modal"
                ref="Close"
                @click="closeModalNewUser"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">Add User</button>
            </form>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- / Content -->
</template>

<script>
import { mapState } from "vuex";
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import commonPaging from "../common/CommonPaging"
import {errorAlert} from "../../helper/error.js"
import {deletedAlert, restoredAlert} from "../../helper/error.js"
import showConfirmDeleteAlert from "../../helper/error.js"
import RiseLoader from 'vue-spinner/src/RiseLoader.vue'
import { utilities } from "../../services/utilities.js";

export default {
	name: 'AccountList',
  components: {
    commonPaging,
    RiseLoader
  },
  data(){
    return {
      page: 1,
      showPaging: false,
      color: '#696cff',
      size: '20px',
      isLoading: false,
      keyword: "",
      isSearchAccount: false,
      detailInfo: {},
      domain: process.env.VUE_APP_DOMAIN_FILE_SERVER,
      v$: useValidate(),
      new_user: {
        username: "",
        email: "",
        password: "",
        role: "user"
      },
      registered_users: null,
      inactive_users: null,

    }
  },
  computed: {
    ...mapState({
      listItems: state => state.accountList.stateData.listItems,
      totalItems: state => state.accountList.stateData.totalItems
    })
  },
  beforeCreate() {
    this.page = 1;
  },
  created() {
    this.getAllAccountList();
  },
  methods: {
    getAllAccountList() {
      this.$store.dispatch('accountList/getListData').then(
        (response) => { 
          this.showPaging = true;
          this.isSearchAccount = false;
          
          // Calculate the number of Registered users and Inactive users
          this.registered_users = response.totalItems;
          this.inactive_users = response.inactiveItems;
        },
        (error) => {
          console.log(error);
          this.showPaging = false;
          errorAlert("error","Network Error",'Can not get list data account!');
        }
      );
    },
    onChangePage(page, wantCallEmit=true) {
      this.page = page;
      this.isLoading = true;
      this.isSearchAccount = false;

      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('accountList/getListData', page).then(
        (response) => { 
          this.isLoading = false;
          this.showPaging = true;

          // Calculate the number of Registered users and Inactive users
          this.registered_users = response.totalItems;
          this.inactive_users = response.inactiveItems;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showPaging = false;
          errorAlert("error", "Network Error", "Can not get list data account !");
        }
      );
    },
    onChangeSearch(page, searchKey, wantCallEmit=true) {
      if(!this.keyword) {
        this.onChangePage(1);
        return true;
      }
      this.isLoading = true;
      this.isSearchAccount = true;
      this.page = page;
      if(!wantCallEmit) {
        this.isLoading = false;
        return true;
      }
      this.$store.dispatch('accountList/searchAccount', {"page": page, "searchKey":  this.keyword})
      .then(
        (response) => { 
          this.isLoading = false;
          this.showPaging = this.totalItems > 0 ? true : false;
          
          // Calculate the number of Registered users and Inactive users
          this.registered_users = response.totalItems;
          this.inactive_users = response.inactiveItems;
        },
        (error) => {
          this.isLoading = false;
          this.showPaging = false;
          console.log(error);
          errorAlert("error", "Network Error",'Can not get search data!');
        }
      );
    },
    handleShowDetail(userId) {
      this.isLoading = true;
      this.$store.dispatch('accountList/getAccountInfo', userId).then(
        (response) => { 
          this.isLoading = false;
          this.detailInfo = response;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.detailInfo = {};
          errorAlert("error","Network Error",'Can not get Account data !');
        }
      );
    },
    getAvatarText(text){
      return utilities.getAvatarText(text);
    },
    handleDeleteAccount(userId) {
      showConfirmDeleteAlert()
      .then(
        (result) => {
          if(!result) return;
          this.$store.dispatch('accountList/deleteAccount', userId).then(
            () => { 
              this.isLoading = false;
              this.showPaging = true;
              deletedAlert();
              this.isSearchAccount = false;
              if (this.keyword) {
                this.onChangeSearch(1);
              } else {
                this.onChangePage(1);
              }
            },
            (error) => {
              console.log(error);
              this.isLoading = false;
              this.showPaging = false;
              errorAlert("error", "Network Error", "Failed to deleted Account!");
            }
          );
        }
      )
    },
    handleRestoreAccount(userId) {
      showConfirmDeleteAlert("warning", "Restore Account","Are you sure restore this account ?")
      .then(
        (result) => {
          if(!result) return;
          this.isLoading = true;
          this.$store.dispatch('accountList/restoreAccount', userId).then(
            () => { 
              this.isLoading = false;
              this.showPaging = true;
              restoredAlert();
              this.isSearchAccount = false;
              if (this.keyword) {
                this.onChangeSearch(1);
              } else {
                this.onChangePage(1);
              }
            },
            (error) => {
              console.log(error);
              this.isLoading = false;
              errorAlert("error","Network Error",'Can not Restore this Account !');
            }
          );
        }
      );
    },
    handleResetPassword(userId) {
      showConfirmDeleteAlert("warning", "Reset Password","Would you like to reset password for this account ?")
      .then(
        (result) => {
          if(!result) return;
          this.isLoading = true;
          this.$store.dispatch('accountList/resetAccountPassword', userId).then(
            () => { 
              this.isLoading = false;
              // this.showPaging = true;
              this.$swal.fire(
                'Updated',
                'The Account Password is reset sucessfully !',
                'success'
              )
            },
            (error) => {
              console.log(error);
              this.isLoading = false;
              errorAlert("error","Network Error",'Can not reset this Account Password !');
            }
          );
        }
      );
    },
    generatePassword(pass_length) {
      this.new_user.password = utilities.generatePassword(pass_length);
    },
    handleAddNewUser() {
			this.v$.$validate();
			if (!this.v$.$error) {
        this.$store.dispatch('accountList/addNewAccount', this.new_user).then(
          () => {
            let self = this;
            this.$refs.Close.click();
            this.$swal.fire(
              'Updated',
              'The New Account is registered sucessfully !',
              'success'
            ).then(function() {
              self.isSearchAccount = false;
              if (self.keyword) {
                self.onChangeSearch(1);
              } else {
                self.onChangePage(1);
              }
            });
          },
          error => {
            console.log(error);
            this.$refs.Close.click();
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to register the new account !'
            });
          }
        );
      }
    },
    closeModalNewUser() {
      this.v$.$reset();
      this.new_user.username = "";
      this.new_user.email = "";
      this.new_user.password = "";
    }
  },
  validations() {
    return {
      new_user: {
        username: {
          required: helpers.withMessage('USERNAME cannot be empty', required)
        },
        password: {
          required: helpers.withMessage('PASSWORD cannot be empty', required)
        },
        email: {
          required: helpers.withMessage('E-MAIL cannot be empty', required)
        },
        role: {
          required: helpers.withMessage('ROLE cannot be empty', required)
        }
      }
    }
  }
}
</script>

<style scoped>
  a.icon-delete, a.icon-restore, a.icon-reset {
    cursor: pointer;
  }
  a.icon-delete:hover {
    color: red !important;
  }
  a.icon-restore:hover {
    color: #03c3ec !important;
  }
  a.icon-reset:hover {
    color: #ffab00 !important;
  }
  .avatar-xl {
    width: 6rem;
    height: 6rem;
  }
  .avatar-xl .avatar-initial {
    font-size: 2.5rem !important;
  }
  .btn-add-user {
    cursor: pointer;
  }
  .btn-add-user:hover {
    background-color: #696cff !important;
    color: #fff !important;
  }
</style>