<template>
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4">
        <span>Page for testing Component</span>
    </h4>

    <!-- For Test -->
    <!-- <div class="row">
      <form class="source-item py-sm-3">
        <div class="mb-3" data-repeater-list="group-a">
          <div class="repeater-wrapper pt-0 pt-md-4" data-repeater-item="" style="display: none;">
            <div class="d-flex border rounded position-relative pe-0">
              <div class="row w-100 m-0 p-3">
                <div class="col-md-6 col-12 mb-md-0 mb-3 ps-md-0">
                  <p class="mb-2 repeater-title">Item</p>
                  <select class="form-select item-details mb-2">
                    <option selected="" disabled="">Select Item</option>
                    <option value="App Design">App Design</option>
                    <option value="App Customization">App Customization</option>
                    <option value="ABC Template">ABC Template</option>
                    <option value="App Development">App Development</option>
                  </select>
                  <textarea class="form-control" rows="2" placeholder="Item Information"></textarea>
                </div>
                <div class="col-md-3 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Cost</p>
                  <input type="number" class="form-control invoice-item-price mb-2" placeholder="00" min="12">
                  <div>
                    <span>Discount:</span>
                    <span class="discount me-2">0%</span>
                    <span class="tax-1 me-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                    <span class="tax-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                  </div>
                </div>
                <div class="col-md-2 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Qty</p>
                  <input type="number" class="form-control invoice-item-qty" placeholder="1" min="1" max="50">
                </div>
                <div class="col-md-1 col-12 pe-0">
                  <p class="mb-2 repeater-title">Price</p>
                  <p class="mb-0">$24.00</p>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center justify-content-between border-start p-2">
                <i class="bx bx-x fs-4 text-muted cursor-pointer" data-repeater-delete=""></i>
                <div class="dropdown">
                  <i class="bx bx-cog bx-xs text-muted cursor-pointer more-options-dropdown" role="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                  </i>
                  <div class="dropdown-menu dropdown-menu-end w-px-300 p-3" aria-labelledby="dropdownMenuButton">

                    <div class="row g-3">
                      <div class="col-12">
                        <label for="discountInput" class="form-label">Discount(%)</label>
                        <input type="number" class="form-control" id="discountInput" min="0" max="100">
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput1" class="form-label">Tax 1</label>
                        <select name="group-a[0][tax-1-input]" id="taxInput1" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput2" class="form-label">Tax 2</label>
                        <select name="group-a[0][tax-2-input]" id="taxInput2" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                    </div>
                    <div class="dropdown-divider my-3"></div>
                    <button type="button" class="btn btn-label-primary btn-apply-changes">Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="repeater-wrapper pt-0 pt-md-4" data-repeater-item="" style="display: none;">
            <div class="d-flex border rounded position-relative pe-0">
              <div class="row w-100 m-0 p-3">
                <div class="col-md-6 col-12 mb-md-0 mb-3 ps-md-0">
                  <p class="mb-2 repeater-title">Item</p>
                  <select class="form-select item-details mb-2">
                    <option selected="" disabled="">Select Item</option>
                    <option value="App Design">App Design</option>
                    <option value="App Customization">App Customization</option>
                    <option value="ABC Template">ABC Template</option>
                    <option value="App Development">App Development</option>
                  </select>
                  <textarea class="form-control" rows="2" placeholder="Item Information"></textarea>
                </div>
                <div class="col-md-3 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Cost</p>
                  <input type="number" class="form-control invoice-item-price mb-2" placeholder="00" min="12">
                  <div>
                    <span>Discount:</span>
                    <span class="discount me-2">0%</span>
                    <span class="tax-1 me-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                    <span class="tax-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                  </div>
                </div>
                <div class="col-md-2 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Qty</p>
                  <input type="number" class="form-control invoice-item-qty" placeholder="1" min="1" max="50">
                </div>
                <div class="col-md-1 col-12 pe-0">
                  <p class="mb-2 repeater-title">Price</p>
                  <p class="mb-0">$24.00</p>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center justify-content-between border-start p-2">
                <i class="bx bx-x fs-4 text-muted cursor-pointer" data-repeater-delete=""></i>
                <div class="dropdown">
                  <i class="bx bx-cog bx-xs text-muted cursor-pointer more-options-dropdown" role="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                  </i>
                  <div class="dropdown-menu dropdown-menu-end w-px-300 p-3" aria-labelledby="dropdownMenuButton">

                    <div class="row g-3">
                      <div class="col-12">
                        <label for="discountInput" class="form-label">Discount(%)</label>
                        <input type="number" class="form-control" id="discountInput" min="0" max="100">
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput1" class="form-label">Tax 1</label>
                        <select name="group-a[1][tax-1-input]" id="taxInput1" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput2" class="form-label">Tax 2</label>
                        <select name="group-a[1][tax-2-input]" id="taxInput2" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                    </div>
                    <div class="dropdown-divider my-3"></div>
                    <button type="button" class="btn btn-label-primary btn-apply-changes">Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </div><div class="repeater-wrapper pt-0 pt-md-4" data-repeater-item="" style="display: none;">
            <div class="d-flex border rounded position-relative pe-0">
              <div class="row w-100 m-0 p-3">
                <div class="col-md-6 col-12 mb-md-0 mb-3 ps-md-0">
                  <p class="mb-2 repeater-title">Item</p>
                  <select class="form-select item-details mb-2">
                    <option selected="" disabled="">Select Item</option>
                    <option value="App Design">App Design</option>
                    <option value="App Customization">App Customization</option>
                    <option value="ABC Template">ABC Template</option>
                    <option value="App Development">App Development</option>
                  </select>
                  <textarea class="form-control" rows="2" placeholder="Item Information"></textarea>
                </div>
                <div class="col-md-3 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Cost</p>
                  <input type="number" class="form-control invoice-item-price mb-2" placeholder="00" min="12">
                  <div>
                    <span>Discount:</span>
                    <span class="discount me-2">0%</span>
                    <span class="tax-1 me-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                    <span class="tax-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                  </div>
                </div>
                <div class="col-md-2 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Qty</p>
                  <input type="number" class="form-control invoice-item-qty" placeholder="1" min="1" max="50">
                </div>
                <div class="col-md-1 col-12 pe-0">
                  <p class="mb-2 repeater-title">Price</p>
                  <p class="mb-0">$24.00</p>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center justify-content-between border-start p-2">
                <i class="bx bx-x fs-4 text-muted cursor-pointer" data-repeater-delete=""></i>
                <div class="dropdown">
                  <i class="bx bx-cog bx-xs text-muted cursor-pointer more-options-dropdown" role="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                  </i>
                  <div class="dropdown-menu dropdown-menu-end w-px-300 p-3" aria-labelledby="dropdownMenuButton">

                    <div class="row g-3">
                      <div class="col-12">
                        <label for="discountInput" class="form-label">Discount(%)</label>
                        <input type="number" class="form-control" id="discountInput" min="0" max="100">
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput1" class="form-label">Tax 1</label>
                        <select name="group-a[2][tax-1-input]" id="taxInput1" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput2" class="form-label">Tax 2</label>
                        <select name="group-a[2][tax-2-input]" id="taxInput2" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                    </div>
                    <div class="dropdown-divider my-3"></div>
                    <button type="button" class="btn btn-label-primary btn-apply-changes">Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </div><div class="repeater-wrapper pt-0 pt-md-4" data-repeater-item="" style="display: none;">
            <div class="d-flex border rounded position-relative pe-0">
              <div class="row w-100 m-0 p-3">
                <div class="col-md-6 col-12 mb-md-0 mb-3 ps-md-0">
                  <p class="mb-2 repeater-title">Item</p>
                  <select class="form-select item-details mb-2">
                    <option selected="" disabled="">Select Item</option>
                    <option value="App Design">App Design</option>
                    <option value="App Customization">App Customization</option>
                    <option value="ABC Template">ABC Template</option>
                    <option value="App Development">App Development</option>
                  </select>
                  <textarea class="form-control" rows="2" placeholder="Item Information"></textarea>
                </div>
                <div class="col-md-3 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Cost</p>
                  <input type="number" class="form-control invoice-item-price mb-2" placeholder="00" min="12">
                  <div>
                    <span>Discount:</span>
                    <span class="discount me-2">0%</span>
                    <span class="tax-1 me-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                    <span class="tax-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                  </div>
                </div>
                <div class="col-md-2 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Qty</p>
                  <input type="number" class="form-control invoice-item-qty" placeholder="1" min="1" max="50">
                </div>
                <div class="col-md-1 col-12 pe-0">
                  <p class="mb-2 repeater-title">Price</p>
                  <p class="mb-0">$24.00</p>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center justify-content-between border-start p-2">
                <i class="bx bx-x fs-4 text-muted cursor-pointer" data-repeater-delete=""></i>
                <div class="dropdown">
                  <i class="bx bx-cog bx-xs text-muted cursor-pointer more-options-dropdown" role="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                  </i>
                  <div class="dropdown-menu dropdown-menu-end w-px-300 p-3" aria-labelledby="dropdownMenuButton">

                    <div class="row g-3">
                      <div class="col-12">
                        <label for="discountInput" class="form-label">Discount(%)</label>
                        <input type="number" class="form-control" id="discountInput" min="0" max="100">
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput1" class="form-label">Tax 1</label>
                        <select name="group-a[3][tax-1-input]" id="taxInput1" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput2" class="form-label">Tax 2</label>
                        <select name="group-a[3][tax-2-input]" id="taxInput2" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                    </div>
                    <div class="dropdown-divider my-3"></div>
                    <button type="button" class="btn btn-label-primary btn-apply-changes">Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </div><div class="repeater-wrapper pt-0 pt-md-4" data-repeater-item="" style="">
            <div class="d-flex border rounded position-relative pe-0">
              <div class="row w-100 m-0 p-3">
                <div class="col-md-6 col-12 mb-md-0 mb-3 ps-md-0">
                  <p class="mb-2 repeater-title">Item</p>
                  <select class="form-select item-details mb-2">
                    <option selected="" disabled="">Select Item</option>
                    <option value="App Design">App Design</option>
                    <option value="App Customization">App Customization</option>
                    <option value="ABC Template">ABC Template</option>
                    <option value="App Development">App Development</option>
                  </select>
                  <textarea class="form-control" rows="2" placeholder="Item Information"></textarea>
                </div>
                <div class="col-md-3 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Cost</p>
                  <input type="number" class="form-control invoice-item-price mb-2" placeholder="00" min="12">
                  <div>
                    <span>Discount:</span>
                    <span class="discount me-2">0%</span>
                    <span class="tax-1 me-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                    <span class="tax-2" data-bs-toggle="tooltip" data-bs-placement="top">0%</span>
                  </div>
                </div>
                <div class="col-md-2 col-12 mb-md-0 mb-3">
                  <p class="mb-2 repeater-title">Qty</p>
                  <input type="number" class="form-control invoice-item-qty" placeholder="1" min="1" max="50">
                </div>
                <div class="col-md-1 col-12 pe-0">
                  <p class="mb-2 repeater-title">Price</p>
                  <p class="mb-0">$24.00</p>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center justify-content-between border-start p-2">
                <i class="bx bx-x fs-4 text-muted cursor-pointer" data-repeater-delete=""></i>
                <div class="dropdown">
                  <i class="bx bx-cog bx-xs text-muted cursor-pointer more-options-dropdown" role="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                  </i>
                  <div class="dropdown-menu dropdown-menu-end w-px-300 p-3" aria-labelledby="dropdownMenuButton">

                    <div class="row g-3">
                      <div class="col-12">
                        <label for="discountInput" class="form-label">Discount(%)</label>
                        <input type="number" class="form-control" id="discountInput" min="0" max="100">
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput1" class="form-label">Tax 1</label>
                        <select name="group-a[4][tax-1-input]" id="taxInput1" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="taxInput2" class="form-label">Tax 2</label>
                        <select name="group-a[4][tax-2-input]" id="taxInput2" class="form-select tax-select">
                          <option value="0%" selected="">0%</option>
                          <option value="1%">1%</option>
                          <option value="10%">10%</option>
                          <option value="18%">18%</option>
                          <option value="40%">40%</option>
                        </select>
                      </div>
                    </div>
                    <div class="dropdown-divider my-3"></div>
                    <button type="button" class="btn btn-label-primary btn-apply-changes">Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </div></div>
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-primary" data-repeater-create="">Add Item</button>
          </div>
        </div>
      </form>
    </div>

    <div class="col-12">
      <div class="card">
        <h5 class="card-header">Form Repeater</h5>
        <div class="card-body">
          <form class="form-repeater">
            <div data-repeater-list="group-a">
              <div data-repeater-item="" style="">
                <div class="row">
                  <div class="mb-3 col-lg-6 col-xl-3 col-12 mb-0">
                    <label class="form-label" for="form-repeater-3-5">Username</label>
                    <input type="text" id="form-repeater-3-5" class="form-control" placeholder="john.doe">
                  </div>
                  <div class="mb-3 col-lg-6 col-xl-3 col-12 mb-0">
                    <label class="form-label" for="form-repeater-3-6">Password</label>
                    <input type="password" id="form-repeater-3-6" class="form-control" placeholder="············">
                  </div>
                  <div class="mb-3 col-lg-6 col-xl-2 col-12 mb-0">
                    <label class="form-label" for="form-repeater-3-7">Gender</label>
                    <select id="form-repeater-3-7" class="form-select">
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                  <div class="mb-3 col-lg-6 col-xl-2 col-12 mb-0">
                    <label class="form-label" for="form-repeater-3-8">Profession</label>
                    <select id="form-repeater-3-8" class="form-select">
                      <option value="Designer">Designer</option>
                      <option value="Developer">Developer</option>
                      <option value="Tester">Tester</option>
                      <option value="Manager">Manager</option>
                    </select>
                  </div>
                  <div class="mb-3 col-lg-12 col-xl-2 col-12 d-flex align-items-center mb-0">
                    <button class="btn btn-label-danger mt-4" data-repeater-delete="">
                      <i class="bx bx-x"></i>
                      <span class="align-middle">Delete</span>
                    </button>
                  </div>
                </div>
                <hr>
              </div>
            </div>
            <div class="mb-0">
              <button class="btn btn-primary" data-repeater-create="">
                <i class="bx bx-plus"></i>
                <span class="align-middle">Add</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="accordion mt-3 mb-3" id="accordionWithIcon">
      <div class="card accordion-item">
        <h2 class="accordion-header d-flex align-items-center">
          <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionWithIcon-1" aria-expanded="false">
            <i class="bx bx-bar-chart-alt-2 me-2"></i>
            Header Option 1
          </button>
        </h2>

        <div id="accordionWithIcon-1" class="accordion-collapse collapse" style="">
          <div class="accordion-body">
            Lemon drops chocolate cake gummies carrot cake chupa chups muffin topping. Sesame snaps icing marzipan gummi
            bears macaroon dragée danish caramels powder. Bear claw dragée pastry topping soufflé. Wafer gummi bears
            marshmallow pastry pie.
          </div>
        </div>
      </div>

      <div class="accordion-item card">
        <h2 class="accordion-header d-flex align-items-center">
          <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionWithIcon-2" aria-expanded="false">
            <i class="bx bx-briefcase me-2"></i>
            Header Option 2
          </button>
        </h2>
        <div id="accordionWithIcon-2" class="accordion-collapse collapse" style="">
          <div class="accordion-body">
            Dessert ice cream donut oat cake jelly-o pie sugar plum cheesecake. Bear claw dragée oat cake dragée ice
            cream
            halvah tootsie roll. Danish cake oat cake pie macaroon tart donut gummies. Jelly beans candy canes carrot
            cake.
            Fruitcake chocolate chupa chups.
          </div>
        </div>
      </div>

      <div class="accordion-item card">
        <h2 class="accordion-header d-flex align-items-center">
          <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionWithIcon-3" aria-expanded="false">
            <i class="bx bx-gift me-2"></i>
            Header Option 3
          </button>
        </h2>
        <div id="accordionWithIcon-3" class="accordion-collapse collapse" style="">
          <div class="accordion-body">
            Oat cake toffee chocolate bar jujubes. Marshmallow brownie lemon drops cheesecake. Bonbon gingerbread
            marshmallow
            sweet jelly beans muffin. Sweet roll bear claw candy canes oat cake dragée caramels. Ice cream wafer danish
            cookie caramels muffin.
          </div>
        </div>
      </div>
    </div> -->

    <!-- Calendar -->
    <div class="col-sm-3 mb-4">
      <flat-pickr 
        v-model="date" 
        :config="calendar_config" 
        placeholder="Select a date" 
        name="birthdate" 
        class="form-control">
      </flat-pickr>

      generation: {{ generation}}
    </div>

    <!-- Upload Image -->
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3 col-md-12">
          <label class="form-label">Product Image</label>
          <input 
            class="form-control" 
            type="file" 
            id="" 
            tabindex="-1"
            accept="image/jpeg" 
            multiple=""
            @change="onFileChange"
          >
          <!-- <div v-for="(image, key) in images" :key="key">
            <div>
              <img class="preview" :ref="'image'" />
              {{ image.name }}
            </div>
          </div> -->
          <div id="carouselExample" class="carousel slide w-50 mt-4"  style="margin-left:8rem;">
            <ol class="carousel-indicators">
              <li 
                data-bs-target="#carouselExample" 
                :data-bs-slide-to="key1" 
                v-for="(image1, key1) in images" :key="key1"
                :class="{active: getClassActive(key1)}"
                ></li>
              <!-- <li data-bs-target="#carouselExample" data-bs-slide-to="1"></li>
              <li data-bs-target="#carouselExample" data-bs-slide-to="2"></li> -->
            </ol>
            <div class="carousel-inner">
              <!-- <div class="carousel-item" v-for="(image, key) in images" :key="key" :class="{'active' : key == 0}"> -->
              <div class="carousel-item" v-for="(image, key) in images" :key="key" :class="{active: getClassActive(key)}">
                <img class="d-block w-100" :ref="'image'">
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div class="col-md-6 mt-4">
        <button @click="clearImage" class="btn btn-primary">Clear All Images</button>
      </div>
    </div>
    <!-- Currency to Word -->
    <div class="col-md-12">
      Currency To Word: VND {{currency_num}} = {{ currency_text }} VND
    </div>
    
  </div>
  <!-- / Content -->
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { configs } from '@/config/calendar.js';
// import { utilities } from '@/services/generate_sku.js';
import {Converter} from "any-number-to-words";


export default {
  name: 'PageForTest',
  components: {
    flatPickr,
  },
  data() {
    return {
      date: null,
      calendar_config: configs,
      generation: "",
      images: [],
      currency_num: 686868686868,
      currency_text: ""
    }
  },
  computed: {

  },
  created() {
    // let hash = utilities.getHash("SHI-SIZ-S-COL-BLU-GEN-MEN-STY-001");
    // this.generation = utilities.generateRandomLetter(hash, 13);

    this.currency_text = new Converter().toWords(this.currency_num);
    this.currency_num = new Intl.NumberFormat().format(this.currency_num);
  },
  mounted() {

  },
  methods: {
    onFileChange(e) {
      this.images.splice(0, this.images.length);

      let selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.images.push(selectedFiles[i]);
      }
      
      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader();
        reader.onload = (event) => {
          if (this.$refs["image"] != undefined) {
            this.$refs["image"][i].src = event.target.result;
            if (i == 0) {
              this.$refs["image"][i].classList.add("active");
            }
          }
        };

        reader.readAsDataURL(this.images[i]);
      }
    },

    getClassActive (key) {
      return key == 0 ? "active" : "";
    },

    clearImage() {

      this.images = [];
      let items =  this.$refs["image"];
      if (items != undefined){
        items.splice(0, items.length);
      }
      
    }
  }
}
</script>